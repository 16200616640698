dg-1320-landing {
  dg-track-locator-cta, dg-racing-events-calendar-cta, dg-racing-articles-cta {
    min-height: 425px;
  }
  dg-track-locator-cta {
    background-position: center right;
  }
}

.spacer-xs {
  margin-top: 3rem;

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.spacer {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.flex-row {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    > * {
      flex: 1;
    }
  }
}

.container-narrow {
  max-width: 570px;
  margin: 0 auto;
}

.well {
  &.well-transparent {
    background-color: transparent;
  }
}

.content-bordered {
  &.left {
    border-left: 1px solid @light-gray;
    padding-left: 3.5rem;
  }
  &.right {
    border-right: 1px solid @light-gray;
    padding-right: 3.5rem;
  }
}

.list-flush-left {
  font-size: 1.6rem;
  padding-left: 2rem;
  li {
    margin-bottom: 0.75rem;
  }
}

.list-flex {
  display: flex;
  flex-wrap: wrap;
  &.half {
    li {
      flex: 0 0 50%;
      min-width: 50%;
    }
  }
}

.list-child {
  list-style-type: disc;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.section-lead {
  .details {
    @media (min-width: 1200px) {
      p {
        max-width: 800px;

        &.font-bold {
          margin-top: 2rem;
        }
      }

      ul {
        margin-bottom: 2rem;
      }
    }
  }
}

.manage-account{
  text-align: left;
  margin-top: 2rem;
  margin-bottom: 3rem;

  .btn {
    font-size: 1.8rem;
  }

  @media (min-width: 768px) {
    text-align: right;
    margin: 0;
  }
}

.flex-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 2rem 1.5rem;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }

  .heading1 {
    flex: 1;
    margin-bottom: 0;
  }
  .btn-add-vehicle {
    flex: 0 0 75px;
    min-width: 75px;

    @media (min-width: 768px) {
      flex: 0 1 auto;
      min-width: 100px;
    }
    > .fa {
      margin-right: .2rem; // 2px based on 10px
    }
  }
}

fieldset {
  margin:0;
  &.search-bar {
    input {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  } 
}

// Black Banner
.black-banner {
  background-color: @black;
  text-align: center;
  margin: 3rem 0;
  padding: 2rem 1.5rem;
  .btn-lg {
    font-size: 2.4rem; // 24px
    padding: 1rem;
    &.btn-outline-primary-white {
      border: 3px solid #DC0000;
    }
  }
  .btn + .btn {
    margin-left: 10px;
  }

}
