#hpds {
  header {
    padding-top: 4rem;
    padding-bottom: 4rem;
    @media (max-width: 992px) {
      padding-bottom: 0rem;
    }

    img {
      @media (max-width: 756px) {
        padding-bottom: 4rem;
      }
    }
  }
  .heading1 {
    margin-bottom: 4rem;
    @media (max-width: 992px) {
      margin-bottom: 2rem;
    }
    sup {
      top: -1.3em;
      font-size: large;
    }
  }

  .faq-links {
    a {
      min-width: 250px;
      margin: auto;
    }
    @media(max-width: 991px) {
      display: none;
    }
  }

  .faq-links-mobile {
    a {
      width: 100%;
      margin: auto;
    }
    @media(min-width: 992px) {
      display: none;
    }
  }
  .racing-hq {
    max-width: 1600px;
    margin: 0 auto;
    position: relative;
  }
  max-width: 100%;

  #full-width-section {
    background-color: #ECECEC;
    .container-fluid {
      padding: 1.5rem 0;
    }
  }

  //Heading section
  .hero-section {
    //margin-bottom: 25px;
    .heading-contain {
      .heading1 {
        //font-size: 2.5em; //35px
      }
    }
    .subhead {
      p {
        margin-bottom: 0;
      }
    }

    @media (min-width: @screen-sm-min) {
      .heading-contain {
        .heading1 {
          font-size: 3.5em; //49px
        }
      }
      .subhead {
        display: flex;
        flex-direction: row;
        align-items: center;
        p {
          width: 60%;
        }
        .img-responsive {
          width: 40%;
          order: 2;
        }
      }
    }
    @media (min-width: @screen-md-min) {
      .heading-contain {
        .heading1 {
          font-size: 2.75em; //38.5px
          margin-bottom: 20px;
        }
      }
      .subhead {
        p {
          padding-right: 30px;
        }
      }
    }
    @media (min-width: @screen-lg-min) {
      .subhead {
        p {
          width: 50%;
        }
        .img-responsive {
          width: 50%;
        }
      }
    }
  }

  #overview, #exercises {
    @media (min-width: @screen-lg-min) {
      .module {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        width: calc(~'100% - 30px');
      }
    }
  }

  //content sections
  .module {

    @media (min-width: @screen-sm-min) {
      &:not(:first-child) {
        margin-top: 30px;
      }
    }
  }


  &[data-school="Drag Racing"] {
    .flex-row {
      margin-bottom: 30px;

      @media (min-width: @screen-md-min) {
        margin-bottom: 60px;
        &#exercises {
          .flex-column:first-of-type {
            order: 2;
          }
        }
      }
    }

    .img-contain {
      padding-left: 0;
      padding-right: 0;

      @media (min-width: @screen-md-min) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .module {
      @media (min-width: @screen-sm-min) {
        .heading-main {
          //font-size: 3em;
        }
        p {
          //font-size: 1.5em;
        }
      }
      @media (min-width: @screen-md-min) {
        .heading-main {
          //font-size: 2.25em;
        }
        p {
          //font-size: 1.15em;
        }
      }
      @media (min-width: 1440px) {
        .heading-main {
          //font-size: 3em;
        }
        p {
          //font-size: 1.5em;
        }
      }
    }

    .btn-container {
      margin-top: 25px;
      .btn {
        width: 35%;
        &:first-of-type {
          width: 60%;
        }
        &:nth-child(2) {
          float: right;
        }
      }

      @media (min-width: @screen-sm-min) {
        .btn {
          max-width: 200px;
          &:nth-child(2) {
            float: none;
          }
        }
      }
    }

    .callout-banner {
      background-color: #000;
      .img-bondurant-logo {
        max-width: 300px;
      }
      @media (min-width: @screen-sm-min) {
        .flex-row {
          margin-bottom: 0;
        }
        .video-embed {
          order: 2;
        }
      }
      @media (min-width: @screen-md-min) {
        margin-bottom: 60px;
        .flex-column {
          p {
            font-size: 1.6em;
          }
        }
      }
      @media (min-width: 1440px) {
        .flex-column {
          p {
            font-size: 2em;
          }
        }
        .video-link {
          height: 300px;
          overflow: hidden;
          position: relative;
          img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
          }
        }
      }
    }

    .color-bg {
      @media (min-width: @screen-md-min) {
        padding-top: 30px;
      }
    }
  }

}

#drag-racing-video-modal {
  .modal-dialog {
    width: 90%;
    max-width: 992px;
    .modal-content {
      background-color: transparent;
      border: 0;
      .modal-header {
        background-color: @black;
        padding: 5px;
        span {
          color: @white;
        }
        .close {
          background-color: transparent;
        }
      }
      .modal-body {
        padding: 0;
      }
    }
  }
}
