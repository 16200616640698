.barrett-jackson {

  .rules-intro {
    color: black;
    font-weight: bold;
  }

  .prize-package {
    img {
      margin-bottom: 10px;
    }

    img:last-child {
      margin-bottom: 0;
    }
  }

  .agreement-checkbox {
    text-align: center;

    label {
      display: block;
      font-size: 17px;
    }

  }

  .enter-contest {
    button {
      font-size: 2em;
      margin-top: 30px;

      @media(min-width: 425px) {
        width: 80%;
        margin: 30px auto;
      }

      @media(min-width: 768px) {
        width: 60%;
        padding: 15px;
        font-size: 3em;
      }

      @media(min-width: 1200px) {
        width: 50%;
      }

      @media(min-width: 1400px) {
        width: 45%;
      }

      @media(min-width: 1600px) {
        width: 40%;
      }

    }
  }

  p {
    font-size: 21px;
  }

  .option-login {
    color: black;
    font-weight: bold;
    font-size: 22px;

    @media(min-width: 768px) {
      border-left: 4px solid @gray;
      height: 250px;
      position: absolute;
      left: 50%;
      /* margin-left: 7px; */
      top: -250px;
    }

  }

  .verify-login {
    font-weight: bold;
    color: @brand-primary;
    font-size: 21px;
    text-transform: uppercase;
    border-bottom: 2px solid @brand-primary;
    width: 65%;
    text-align: center;
    margin: 0 auto;


    @media(min-width: 768px) {
      width: 50%;
    }

    @media(min-width: 1024px) {
      width: 30%;
    }

  }

  .spacing {
    margin-bottom: 50px;
  }

  .display-flex {
    display: flex;
    flex-direction: column;

    @media(min-width: 768px) {
      flex-direction: row;
    }

    &.center-flex {
      align-items: center;
    }

  }

  .no-padding {
    &.left {
      padding-left: 0;
    }

    &.right {
      padding-right: 0;
    }
  }

  .how-it-works {
    font-size: 21px;
  }

  .main-hero-container {
    @media(min-width: 768px) {
      padding: 0 30px;
    }

    h1 {
      color: black;
      text-transform: uppercase;
      font-weight: bold;

      @media(min-width: 768px) {
        font-size: 50px;
      }

      @media(min-width: 768px) {
        font-size: 76px;
      }

    }

    p {
      @media(min-width: 1024px) {
        font-size: 21px;
      }
    }

  }

  .hero {
      position: relative;
      background: url(/assets/images/barrett-jackson/barrett-jackson-hero-charger.jpg) no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;


      .enter-to-win-date {
        width: 110px;
        margin-bottom: 70px;

        @media(min-width: 768px) {
          width: 230px;
          align-self: flex-start;
          margin-left: 30px;
          margin-top: 30px;
        }

        @media(min-width: 1200px) {
          width: 380px;
        }

      }

      .enter-contest-date {
        width: 130px;
        position: absolute;
        right: 20px;
        bottom: 20px;

        @media(min-width: 768px) {
          width: 270px;
          align-self: flex-end;
          margin-right: 30px;
          margin-bottom: 30px;
        }

        @media(min-width: 1200px) {
          width: 350px;
          margin-bottom: 40px;
        }

      }

      .barrett-logo {
        width: 150px;
        position: absolute;
        left: 7px;
        bottom: 21px;

        @media(min-width: 768px) {
          width: 280px;
          left: auto;
          right: 35px;
          top: 140px;
        }

        @media(min-width: 1200px) {
          width: 350px;
          top: auto;
          bottom: 230px;
        }

      }
  }

  .heading-main {
    &:before {
  		content: '';
  		position: absolute;
  		bottom: -5px;
  		left: 0;
  		border-bottom: 2px solid @brand-primary;
  		width: 100%;
  	}
  	&:after {
  		content: '';
  		position: absolute;
  		bottom: -5px;
  		left: 0;
  		width: 75px;
  		border-bottom: 2px solid @gray;
  	}
  }

  .heading-main-inverted {
    &:before {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      border-bottom: 2px solid @gray;
      width: 100%;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 75px;
      border-bottom: 2px solid @brand-primary;
    }
  }

  .grand-container {
    background-color: @brand-primary;
    color: white;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      width: calc(100% - 2px);
    }

    ul {
      padding-left: 50px;
      padding-top: 25px;
      font-size: 17px;
      width: calc(100% - 2px);


      @media(min-width: 1600px) {
        padding-left: 90px;
        padding-top: 30px;
        font-size: 27px;
      }


      li {
        margin-bottom: 10px;
        width: calc(100% - 2px);

        @media(min-width: 1600px) {
          margin-bottom: 30px;
        }

      }

    }

    h2 {
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      font-size: 40px;

      @media(min-width: 1024px) {
        font-size: 65px
      }
    }

    p {
      @media(min-width: 1024px) {
        font-size: 20px;
      }
    }

  }

  .prize-container {
    background-color: rbga(216, 216, 216, 20);

    ul {
      font-size: 50px;
      padding-left: 0;
      text-align: center;
      color: @brand-primary;

      li {
        display: inline-block;
      }

    }

  }

  .divider {
    border-bottom: 2px solid white;
    width: 70%;
    margin: 0 auto;
  }

  .carousel-prize {
    @media(min-width: 1200px) {
      height: 380px;
    }
  }


  .login-form {
    background-color: #6C6A6A;

    p {
      font-family: 'TradeGothicW01-BoldCn20 675334';
      text-transform: uppercase;
      font-size: 40px;
      text-align: center;
      color: white;
    }

  }

  .logged-in-form {
    background-color: @brand-primary;

    p {
      font-family: 'TradeGothicW01-BoldCn20 675334';
      text-transform: uppercase;
      font-size: 40px;
      text-align: center;
      color: white;
    }

  }


  .barrett-jackson-login {
    label {
      text-transform: uppercase;

      @media(min-width: 768px) {
        margin: 0 auto;
        display: block;
        width: 80%;
      }

      @media(min-width: 1200px) {
        width: 60%;
      }

      @media(min-width: 1600px) {
        width: 50%;
      }

    }

    input {
      @media(min-width: 768px) {
        margin: 0 auto;
        display: block;
        width: 80%;
      }

      @media(min-width: 1200px) {
        width: 60%;
      }

      @media(min-width: 1600px) {
        width: 50%;
      }
    }

    button {
      color: @brand-primary;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 20px;
      border: 2px solid @brand-primary;
      width: 80%;
      margin: 0 auto;
      display: block;

      @media(min-width: 768px) {
        width: 60%;
      }

      @media(min-width: 1200px) {
        width: 40%;
      }

    }

    p {
      text-align: center;
      color: black
    }

  }

  .barrett-rules {
    p {
      @media(min-width: 1024px) {
        font-size: 19px;
      }
    }
  }

  .count-down {

    text-align: center;

    p {
      font-weight: bold;
      color: black;
      text-transform: uppercase;
      font-size: 25px;

      @media(min-width: 768px) {
        display: inline;
        font-size: 35px;
      }

    }

    .contest-timer {
      font-size: 60px;

      @media(min-width: 768px) {
        margin: 0 20px;
        font-size: 70px;
      }

    }
  }

}



.barrett-rules-container {

  .spacer {
    margin-bottom: 30px;
  }

  h1 {
    color: @brand-primary;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 30px;

    @media(min-width: 768px) {
      font-size: 40px;
    }

    @media(min-width: 1200px) {
      font-size: 60px;
    }

  }


  h3 {
    color: black;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 50px;

    @media(min-width: 1200px) {
      font-size: 32px;
    }

  }

}

.contest-ended {
  background-color: #ddd;
  .message-container {
    max-width: 600px;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .h1 {
      font-family: @main-font;
      font-size: 3.4rem;
      margin-top: 1rem;
    }
    .h3 {
      font-size: 2.25rem;
    }
    hr {
      border-color: inherit;
    }

    @media (min-width: 600px) {
      padding-top: 4rem;
      padding-bottom: 4rem;
      .h1 {
        font-size: 3rem;
      }
      .h3 {
        font-size: 2.5rem;
      }
    }

    @media (min-width: @screen-lg-min) {
      max-width: 800px;
      .h1 {
        font-size: 4.5rem;
      }
      .h3 {
        font-size: 3.5rem;
      }
    }
  }
}
