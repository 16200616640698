// Homepage
// Variables
@homepage-gap: 10px; // TODO eventually we're moving to a 30px gap to match the rest of the site and bootstrap
// For the future!
// @homepage-gap-x: 30px;
// @homepage-gap-y: (16px * 1.5); // 24px
// Styles
performance-cars-homepage {
  #thirteen-small {
    position: relative;

    .absolute-div {
      position: absolute;
      bottom: 20px;
      width: 100%;

      .flex-container {
        display: flex;
        justify-content: space-around;
        align-items: center;

        img {
          max-width: 120px;
        }
        span {
          font-size: 22px;
          color: #FFFFFF;
          text-align: center;
          line-height: 27px;
          max-width: 35%;
        }

        div {
          background: #DC0000;
          font-size: 26px;
          color: #FFFFFF;
          text-align: center;
        }
      }

      @media (max-width:@screen-md) {
        bottom: 0;
        height: 100%;

        .flex-container {
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
          padding: 20px 0;

          img {
            margin-bottom: auto;
          }

          span {
            max-width: 75%;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  dg-thirteen-twenty-tile{
    margin-bottom: @homepage-gap;
    @media (min-width: @screen-md-min) {
      display: flex;
      flex-direction: column;
      //min-height: 540px;
      background: url('/assets/images/1320-homepage-tile-md.webp') no-repeat center center;
      body.no-webp & {
          background-image: url('/assets/images/1320-homepage-tile-md.jpg');
      }
      @media (min-width: @screen-lg-min) {
        background-position: left center;
      }
    }
    > a {
      @media (min-width: @screen-md-min) {
        height: 100%;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        padding-left: 700px;
      }
      @media (min-width: @screen-lg-min) {
        padding-left: 950px;
      }
      @media (min-width: 1475px) {
        padding-left: 992px;
      }
      img {
        @media (min-width: @screen-md-min) {
          max-width: 250px
        }
      }
    }
  }
  .poll-container {
    background-color: @black;
    display: flex;
    align-items: center;
    height: 100%;
    dg-poll {
      display: block;
      padding: 30px;
      width: 100%;
      .poll-question {
        color: #FFF;
        font-family: @main-font;
        font-size: 3.2rem;  //32px based on 10px
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      .poll-result {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 32px; // was 47px
        width: 100%;
        text-align: left;
        margin-bottom: 5px;
        background-color: #FFF;
        font-size: 1.8rem; //18px based on 10
        .poll-result-background {
          position: absolute;
          left: 0;
          height: 100%;
          background-color: @secondary-02;
          z-index: 0;
        }
        .poll-result-foreground {
          z-index: 1;
          color: #000;
          position: relative;
          padding: 0 24px 0 16px;
          font-family: @main-font;
          font-size: 1.8rem; //18px based on 10
        }
        .poll-result-foreground-container {
          display: flex;
          align-items: center;
        }
        .percentage {
          font-size: 2rem;  // 20px (was 4.5 rem)
          vertical-align: middle;
          margin-right: 12px;
          display: inline-block;
          sup {
            top: 2px;
            vertical-align: text-top;
            line-height: normal;
            //font-size: 2.7rem;  //27px based on 10px
          }
        }
        &.active {
          .poll-result-foreground::after {
            font-family: FontAwesome;
            content: "\f05d";
            display: inline-block;
            margin-left: 5px;
            position: absolute;
            right: 5px;
            bottom: 50%;
            transform: translateY(50%);
          }
        }
        &.highest {
          .percentage {
            color: @primary-03;
          }
        }
      }
      .total-votes-container {
        display: flex;
        align-items: center;
        color: #FFF;
        .total-votes {
          font-size: 2.5rem; //25px based on 10
          margin-right: 1rem; // 10px based on 10px
        }
        .flyout {
          justify-content: flex-start;
          .btn {
            color: #FFF;
          }
        }
      }
    }
  }
  .feature-tile > .nav-tile {
    padding: 0;
  }
  #events-home {
    dg-event-card {
      display: block;
      margin-bottom: 0;
      overflow: hidden;
      a:hover {
        text-decoration: none;
      }
      .event-img {
        img {
          transform-origin: center;
          transition: all 0.25s ease-in-out;
          transform: scale(1.05);
        }
        .view-event {
          z-index: 3;
        }
      }
      .event-details {
        background: #ddd;
        position: relative;
        z-index: 2;
      }
      &:hover .event-img img {
        transform: scale(1);
      }
    }
  }
  // Keep this until update the homepage gap
  .container-flex, #home-articles {
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;
  }
  .row-flex {
    margin-left: -5px;
    margin-right: -5px;
    div[class^="col-flex-"] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .bottom-gap {
    margin-bottom: @homepage-gap;
  }

  #twenty-five-eight-cta {
    position: relative;
    margin-bottom: @homepage-gap;
    background-color: black;
    min-height: 500px;
    overflow: auto;

    .bg-mobile {
      position: absolute;
      bottom: 0;

      @media (min-width: @screen-md-min) {
        position: static;
        bottom: auto;
      }
    }

    @media (min-width: @screen-sm-min) {
      min-height: 0;
      height: 480px;
    }

    @media (min-width: @screen-md-min) {
      height: auto;
      overflow: visible;
    }

    .twenty-five-eight-content {
      position: absolute;
      z-index: 2;
      top: 0;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      text-align: center;
      height: 100%;
      padding: 10px 10px 30vw;

      h4 {
        margin-bottom: 5px;
      }

      img.logo {
        height: 60px;
        width: auto;
        margin: 0 auto 5px;

        @media (min-width: @screen-xs-min) {
          height: 100px;
        }
      }

      @media (min-width: @screen-sm-min) {
        padding: 10px;
        justify-content: flex-start;
        img.logo {
          height: 40px;
        }
      }

      @media (min-width: @screen-md-min) {
        justify-content: space-around;
        width: 60%;
        right: 0;
        padding: 10px 3vw;
      }

      @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        h4 {
          font-size: 22px;
        }
        p {
          font-size: 16px;
        }
      }

      @media (min-width: @screen-xl-min) {
        padding: 20px 3vw;

        img.logo {
          height: 60px;
        }
      }

      .btn:first-child {
        margin-right: 10px;
      }
    }
  }
}

.power-dollars {
  width: 100%;

  @media (min-width: @screen-md-min) {
    order: 2;
    //margin-bottom: 0 !important;
  }
}
