.preview-gallery {
  padding: 10px;
  border: 1px solid #a5a5a5;;
  display: flex;
  flex-direction: column;
  background-color: #ececec;

  .main-image {
    position: relative;
    height: 300px;
    width: auto;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media screen and (min-width: 768px) {
      height: 440px;
    }
  }

  .thumb-wrapper {
    display: flex;
    flex-direction: row;
    height: 120px;

    .thumb {
      flex: 0 1 33.33333%;
      width: 33.3333%;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @media screen and (min-width: 768px) {
      height: 160px;
    }
  }

  .thumbnails {
    position: relative;
  }

  .view-all {
    min-width: 270px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 64px;
    padding: 5px 32px;
    border: 0;
    border-radius: 32px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 20px;
    opacity: 0.83;

    .fa {
      margin-right: 10px;
    }
  }
}

