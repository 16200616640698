.sign-up-share {
	.share-media-heading .small {
    font-size: 0.45em;
	}
}
#home-articles {
	margin: 0;
	.article-tile {
		.article-title {
			font-size: 24px;
			color: @black;
		}
		.event-img {
			.view-event {
				width: 80%;
				max-width: 180px;
				text-align: center;
			}
		}
		.event-details {
			.post-time {
				font-size: 14px;
			}
		}
	}

	@media (min-width: @screen-sm-min) {
		.article-tile {
			.article-title {
				font-size: 1.75em;
				color: @black;
			}
		}
		.event-container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin-top: 2px;
			.article-tile {
				.tile-container {
					background-color: #ececec;
					height: 100%;
					.event-details {
						height: auto;
						margin-bottom: 0;
					}
				}
			}
		}
	}
	@media (min-width: @screen-md-min) {
		.event-container {
			display: block;
		}
	}

}

#home-top {
	overflow: hidden;

}
.home-racing-hq {
	position: relative;
	.racing-hq-callout {
		background: url('/assets/images/racing-hq.jpg') bottom right no-repeat;
		background-size: cover;
		position: relative;
    @media (min-width: @screen-sm-min) {
      background-position: top left;
    }
		a {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	.heading1 {
		//font-size: 21vw;
    font-size: 6.0rem; //60px based on 10px
	}
	.heading-small {
		//font-size: 4vw;
    font-size: 1.8rem; //18px based on 10px
	}
	.overlay {
		bottom: 0;
		top: auto;
		transform: none;
		background: transparent;
    padding: 35px 20px;
	}
}
.home-racing-hq {
	.racing-hq-callout {
		height: 73vw;
		margin-top: 10px;
	}
}


.sign-up-share {
	i.fa-cloud-upload {
		font-size: 15vw;
	}
	.share-media-heading {
		margin-top: 3vw;
		font-size: 11.7vw;
	}
}
/*#poll	{
	.heading-content {
		padding-left: 3rem;
		padding-right: 3rem;
	}
	.spotlight-container {
		.spotlight-social-link {
			transition: all 0.25s ease-in-out;
			&:hover {
				color: @red1;
			}
		}
	}
	.widgets.vote {
		margin-bottom: 30px;
		height: auto;
		p.vote {
			margin-top: 0px;
		}
		h4 {
			font-size: 36px;
		}
	}
}*/
.pic-of-week-container {
	//padding: 0;
	//margin: 0 15px;
	.pic-of-week-heading {
		background-color: rgba(0, 0, 0, 0.5);
		color: @white;
		position: absolute;
		top: 0;
		left: 0px;
		padding: 10px 10px;
		width: 100%;
		text-transform: uppercase;
		font-family: @main-font;
		font-size: 20px;
	}
}

//TODO There should be no words in CSS files. JS should handle this not CSS.
.recent-heading {
	&:before {
		content: 'RECENT ARTICLES';
		position: absolute;
		left: 35px;
		top: -5px;
		opacity: 0.05;
		font-size: 60px;
	}
}
.spotlight-heading {
	&:before {
		content: 'THE SPOTLIGHT';
		position: absolute;
		left: 35px;
		top: -5px;
		opacity: 0.05;
		font-size: 60px;
		color: @black;
	}
}


.event-container {
	padding-top: 0;
	event-item {
		article {
			width: 100%;
			padding-left: 0;
			padding-right: 0;
			.tile-container {
				.event-details {
					height: auto;
				}
			}
		}
	}
	@media (min-width: @screen-md-min) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 20px;
		event-item {
			margin-bottom: 10px;
			article {
				width: 100%;
				height: 100%;
				.tile-container {
					background-color: #ececec;
					height: 100%;
					.event-details {
						height: auto;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

// video bg
.videobg {
  position: relative;
  width: 100%;
  height: 58vw;
  overflow: hidden;
  background: #111; /* bg color, if video is not high enough */
  //margin-bottom: 20px;
}

/* horizontally center the video */
.videobg-width {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

/* set video aspect ratio and vertically center */
.videobg-aspect {
  position: absolute;
  width: 100%;
  height: 0;
  top: -9999px;
  bottom: -9999px;
  margin: auto;
  padding-bottom: 56.25%; /* 16:9 ratio */
  overflow: hidden;

}

.videobg-make-height {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
}

.videobg-hide-controls {
  box-sizing: content-box;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 55px 97.7777px;
  top: -55px;
  left: -97.7777px;
}

.videobg iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0 none;
}

@media (min-width: @screen-sm-min) {

	.home-racing-hq {
		.heading1 {
			font-size: 90px;
		}
		.heading-small {
			font-size: 22px;
		}
		.racing-hq-callout {
			height: 33vw;
			margin-top: 0;
		}
	}

	#programs-carousel {
		height: 243px;
		.carousel-inner > {
			.item {
				height: 243px;
			}
		}
	}
	.programs-carousel {
		.racing-hq {
			margin-bottom: 0px;
		}
	}

	.sign-up-share {
		.share-media-heading {
			font-size: 4.7vw;
		}
	}

	/*#poll	{
		.heading-content {
			@media (min-width: 1200px) {
				padding-left: 4.5rem;
			}
			.spotlight-video {

			}
		}

		.widgets.vote {
			height: 268px;
			p.vote {
				a {
					font-size: 16px;
					padding: 5px 0;
				}
			}
			ul.vote-option {
				li {
					padding: 5px 15px;
					a {
						font-size: 16px;
					}
				}
			}
			h4 {
				font-size: 27px;
			}
		}
	}*/
.widgets.vote {
	input[type=radio].voting-check + label.css-label {
		padding: 10px;
	}
}


	.pic-of-week-container {
		//padding: 0;
		//margin: 0;
		.pic-of-week-heading {
			top: 0;
			left: 0px;
			width: 100%;
		}
	}
	.videobg {
    height: 500px;
  }

}
@media (min-width: @screen-md-min) {
	.home-racing-hq {
		.racing-hq-callout {
			height: 33vw;
		}
	}

	/*#poll	{
		.widgets.vote {
			height: 347px;
			p.vote {
				a {
					font-size: 18px;
					padding: 10px 0;
				}
			}

			ul.vote-option {
				li {
					padding: 20px 15px;
					a {
						font-size: 18px;
					}
				}
			}
			h4 {
				font-size: 3vw;
			}
		}
	}*/
.widgets.vote {
	input[type=radio].voting-check + label.css-label {
		padding: 20px;
	}
}

	#programs-carousel {
		height: 300px;
		.carousel-inner > {
			.item {
				height: 300px;
			}
		}
	}
}
@media (min-width: @screen-lg-min) {

	/*#poll	{
		.widgets.vote {
			height: 419px;
			h4 {
				font-size: 3vw;
			}
		}
	}*/
	#programs-carousel {
		height: 32.8vw;
		.carousel-inner > {
			.item {
				height: 32.8vw;
			}
		}
	}
}
@media (min-width: 1600px) {
	.home-racing-hq {
		.racing-hq-callout {
			height: 500px;
		}
	}

	/*#poll	{
		.widgets.vote {
			h4 {
				font-size: 47px;
			}
		}
	}*/
	#programs-carousel {
		height: auto;
		.carousel-inner > {
			.item {
				height: auto;
			}
		}
	}

}

@media (min-width: @screen-sm-min) {
	.pad-left-sm {
		padding-left: 5px;
	}
	.pad-right-sm {
		padding-right: 5px;
	}
}


#sign-up-tile {
	margin-top: 25px;
	margin-bottom: 50px;
	.sign-up-share {
		.share-media-heading {
			font-size: 5em;
			margin-top: 0;
			.small {
				font-size: 0.75em;
			}
		}
	}
	@media (min-width: @screen-sm-min) {
		.sign-up-share {
			padding: 70px 0;
			background-size: 50%;
			.share-media-heading {
				font-size: 6em;
			}
		}
	}
	@media (min-width: @screen-md-min) {
		margin-top: 80px;
		.sign-up-share {
			background-size: 80%;
			.share-media-heading {
				font-size: 4.25em;
			}
			.icon {
				padding-left: 0;
				padding-right: 0;
				.fa-cloud-upload {
					font-size: 6.5em;
				}
			}
		}
	}
	@media (min-width: @screen-lg-min) {
		margin-top: 65px;
		.sign-up-share {
			background-size: 370px;
			padding: 100px 0;
			.share-media-heading {
				font-size: 4.5em;
			}
			.fa-cloud-upload {
				font-size: 12em;
    		margin-top: -0.15em;
			}
		}
	}
	@media (min-width: 1440px) {
		margin-top: 60px;
		.sign-up-share {
			background-size: 420px;
			padding: 70px 0;
			.share-media-heading {
				font-size: 5.75em;
		    margin-top: 35px;
			}
			.icon {
				.fa-cloud-upload {
					font-size: 12em;
					margin-top: 0;
				}
			}
		}
	}
	@media (min-width: 1600px) {
		.sign-up-share {
			background-size: 440px;
			.share-media-heading {
				font-size: 6.5em;
			}
			.icon {
				.fa-cloud-upload {
					font-size: 12.5em;
				}
			}
		}
	}
}
.pic-of-week-container {
	a {
		color: @white;
		text-decoration: underline;
	}
	.social-icons {
		li {
			a {
				font-size: 2em;
				color: white;
			}
		}
	}
	@media (min-width: @screen-md-min) {
		h3 {
			margin-top: 0;
		}
	}
	@media (min-width: 1440px) {
		h3 {
			font-size: 2.25em;
		}
		p {
			font-size: 1.25em;
		}
		.social-icons {
			li {
				a {
					font-size: 3em;
				}
			}
		}
	}
}
/*#poll {
	.spotlight-subheading {
		color: @white;
		font-size: 1.8rem;
	}
	.heading-content {
		margin-bottom: 3rem;
	}

	@media (min-width: @screen-sm-min) {
		.spotlight-subheading {
			font-size: 2.4rem;
		}
		.btn {
			font-size: 2.4;
		}
	}
	@media (min-width: @screen-md-min) {
		padding-left: 0;
		padding-right: 0;
		.spotlight-heading {
			font-size: 3.2rem;
		}
		.spotlight-subheading {
			font-size: 1.6rem;
			margin-top: 2rem;
			margin-bottom: 2rem;
		}
	}
	@media (min-width: @screen-lg-min) {
		.spotlight-heading {
			font-size: 4rem;
		}
	}
	@media (min-width: 1440px) {
		.spotlight-subheading {
			font-size: 2.1rem;
		}
	}
}*/
#share-media {
	@media (min-width: @screen-md-min) {
		/*#poll {
			float: right;
		}*/
	}
	@media (min-width: @screen-lg-min) {
		margin-top: 50px;
	}
}
