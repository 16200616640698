.image-copy-list {
  counter-reset: images;
  list-style: none;
  padding: 0;

  li {
    padding: 20px 0;
  }
  img {
    padding-bottom: 20px;
    display: block;
    max-width: 285px;
    margin: 0 auto;
  }
  &.numbered .image-container {
    position: relative;
    &::after {
      counter-increment: images;
      content: counter(images);
      position: absolute;
      right: 20px;
      bottom: 20px;
      font-size: 96px;
      font-weight: bold;
      color: #fff;
    }
  }
}

@media (min-width: @screen-sm-min) {
  .image-copy-list {
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .text-container {
      margin-left: 30px;
    }

    .image-container {
      flex: 0 0 290px;
    }
  }
}
