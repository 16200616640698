dg-cdm-hiring-process-page {
  #cdm-hero {
    background-position: 30% top;

    .hero-content {
      justify-content: flex-start;

      img {
        margin-left: 5%;
        max-width: 50%;
      }
    }

    @media (min-width: @screen-xs-min) {
      background-position: 25% top;

      .hero-content img {
        max-width: 40%;
      }
    }

    @media (min-width: @screen-sm-min) {
      background-position: center top;
      .hero-content img {
        max-width: 25%;
      }
    }
    @media (min-width: @screen-lg-min) {
      .hero-content img {
        margin-left: 8%
      }
    }

    @media (min-width: @screen-xl-min) {
      .hero-content img {
        margin-left: 12.5%
      }
    }
  }
  .not-a-button {
    font-size: 20px;
    background-color: black;
    color: white;
    text-transform: uppercase;
    padding: 10px 20px;
    position: absolute;
    bottom: -20px;
    right: 20px;
    z-index: 3; // on top of video container

    @media (min-width: @screen-lg-min) {
      right: -80px
    }
  }
}

#cdm-hiring-timeline {
  color: @white;
  background-color: @black;
  position: relative;

  .card-overflow-wrap {
    position: relative;
    overflow-x: auto;
  }

  .cdm-card {
    min-height: auto;
    height: 200px;
    width: 1600px;
  }

  .cdm-card-content {
    min-height: auto;
    position: relative;
  }

  #timeline-labels {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0;

    .label-container {
      width: 100%;
      height: 100px;
      position: relative;
    }

    .timeline-label {
      position: absolute;
      text-transform: uppercase;
      bottom: 0;
      color: @light-gray;

      &:nth-child(1) {
        left: 40px;
      }
      &:nth-child(2) {
        left: 342px;
      }
      &:nth-child(3) {
        left: 585px;
      }
      &:nth-child(4) {
        left: 965px;
      }
      &:nth-child(5) {
        left: 1260px;
      }

      &:after {
        content: "";
        display: block;
        height: 40px;
        margin-top: 10px;
        border-left: 1px solid @light-gray;
      }

      &.right:after {
        border-left: none;
        border-right: 1px solid @light-gray;
      }
    }

  }

  #timeline-events {
    height: 100px;
    width: 100%;
    padding: 0 40px;
    border-top: 1px solid @white;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .timeline {
    display: flex;
    justify-content: space-between;
    font-size: @font-size-lg;

    .active {
      border-bottom: 3px solid @brand-primary;
    }
  }

  #contest {
    flex: 0 0 58%;
  }

  #hiring {
    flex: 0 0 33%;
  }

  .overlay-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 200px;
    z-index: 2;
    pointer-events: none;
  }

  #helper-arrows {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 75px;
    font-size: 32px;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 75%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 75%, rgba(0,0,0,1) 100%);
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 75%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: @light-gray;

    i {
      text-decoration: blink;
      animation-name: blinker;
      animation-duration: 0.6s;
      animation-iteration-count:infinite;
      animation-timing-function:ease-in-out;
      animation-direction: alternate;
      &:nth-child(1) {
      }
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
    @keyframes blinker {
      from {opacity: 1.0;}
      to {opacity: 0.0;}
    }

  }
}

#cdm-hiring-content {
  background-image: url('/assets/images/chief-donut-maker/hiring-background.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  #the-process-video {
    position: relative;
    padding: 50px 65px;

    @media screen and (min-width: @screen-lg-min) {
      margin-bottom: 100px;
      padding: 100px 100px 0;
    }

    h2 {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 25px;
      transform: translateY(-50%);
    }
  }

  .video-preview {
    max-width: 1140px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
    }

    .button-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btn-rounded {
      position: static;

      &::before {
        cursor: pointer;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      @media (min-width: @screen-md-min) {
        font-size: 40px;
        width: 80px;
        height: 80px;
      }
    }
  }

  .tagline {
    color: white;
    position: absolute;
    right: -25px;
    top: 45%;

    @media (min-width: @screen-sm-min) {
      right: -50px;
      top: 50%;
    }

    @media screen and (min-width: @screen-lg-min) {
      right: -20px;
    }

  }
}

#cdm-hiring-carousel {
  margin-bottom: 100px;

  h2 {
    margin-bottom: 50px;
  }
}

#cdm-hiring-tips {
  padding: 20px;

  .tips-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;
    counter-reset: count;

    @media (min-width: @screen-md-min) {
      justify-content: flex-start;
    }
  }

  .giga {
    text-align: center;

    @media (min-width: @screen-sm-min) {
      text-align: left;
    }
  }

  .empty-tip {
    flex: 0 0 calc(~'33.3333% - 20px');
    max-width: 360px;
    height: 360px;
    margin: 0 10px 20px;
  }


  .tip {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: @light-gray;
    background-color: @black;
    margin: 0 10px 20px;
    opacity: .75;
    transition: all 0.25s ease-in-out;
    flex: 0 0 300px;
    height: 300px;
    width: 300px;
    counter-increment: count;

    &:hover, &:focus {
      opacity: 1;
    }

    &::before {
      content: counter(count);
      line-height: 300px;
      font-size: 250px;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      opacity: .25;
      z-index: 0;
    }

    @media (min-width: @screen-md-min) {
      flex: 0 0 calc(~'33.3333% - 20px');
      max-width: 360px;
      height: 360px;
      max-height: 360px;

      &::before {
        line-height: 360px;
      }
    }

    .tip-label {
      font-size: 16px;
      padding: 15px 0;
      text-transform: uppercase;
    }

    .tip-content {
      color: @white;
      text-transform: uppercase;
      font-size: 24px;
    }

    button {
      width: 40px;
      height: 40px;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .btn-rounded {
    position: static;
    border: 1px solid @light-gray;
    background-color: transparent;
    color: @light-gray;
    height: 25px;
    width: 25px;

    &::before {
      cursor: pointer;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

#cdm-ready {
  background-color: #000;
  color: white;
  display: flex;
  justify-content: space-between;

  .end {
    display: none;
  }

  .ready-content {
    padding: 50px 10px;

    h3, ol {
      margin-bottom: 40px;
    }

    li {
      margin-bottom: 10px;
    }

    @media (min-width: @screen-md-min) {
      padding: 100px 0;
      max-width: 800px;
      margin: 0 auto;
    }
  }

  @media (min-width: @screen-md-min) {
    .end {
      display: block;
      flex: 0 0 60px;
      position: relative;
      min-height: 300px;
      white-space: nowrap;
      overflow: hidden;

      .vertical-text {
        width: 60px;
      }
    }
  }
}
