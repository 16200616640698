// For fixed navigation
body {
  // &.fixed-nav, &.fixed-hero-active {
  //   padding-top: 62px !important;
  // }
  &.fixed-nav {
    padding-top: 62px !important;
    //@media (max-width: 374px) {
    //  padding-top: 114px !important;
    //  // padding-top: 132px !important;
    //}
    //@media (min-width: 375px) {
    //  // padding-top: 114px !important;
    //  padding-top: 62px !important;
    //}
    //@media (min-width: 768px) {
    //  // padding-top: 110px !important;
    //  padding-top: 62px !important;
    //}
  }
  &.fixed-hero-active {
    @media (max-width: 1199px) {
      padding-top: 62px !important;
    }
  }
}

.site-wrapper-xl {
  max-width: 1920px;
  margin: 0 auto;
}

.section-half {
  padding-top: 35px;

  @media (min-width: 992px) {
    display: flex;
  }

  .split {
    &:nth-child(2n) {
      padding-top: 35px;
    }
    @media (min-width: 992px) {
      &:nth-child(2n) {
        padding-top: 0;
      }
      flex: 0 0 50%;
    }
  }
}

.section-split {
  @media (min-width: 992px) {
    display: flex;

    .three-fourths {
      flex: 0 0 75%;

      &.power-dollars {
        order: 0;
      }
    }
    .one-fourth {
      flex: 0 0 25%;
    }
  }
}

//strip padding
.p-0 {
  padding: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}

main {
  background-color: $white;
}

.relative {
  position: relative;
}

.mobile-hidden {
 display: none !important;
}

sup {
  &.scaled {
    font-size: 50%;
    top: -0.75em;
  }
}

// specific styling to prevent footer from rendering at the top of the page on news articles while waiting for articles to render
body {
  &.single-article {
    main {
      min-height: 100vh;
    }
  }
}

// Cookie consent banner
// Updating font sizes on mobile to avoid zooming on apple devices
#onetrust-banner-sdk #onetrust-accept-btn-handler, 
#onetrust-banner-sdk #onetrust-reject-all-handler, 
#onetrust-banner-sdk #onetrust-pc-btn-handler, 
#onetrust-pc-sdk .save-preference-btn-handler {
  @media (max-width: 1200px) {
    font-size: 16px !important;
  }
}


// bg color classes
@each $name, $value in $theme-colors {
  .bg-#{$name} {
    background-color: $value !important;
  }
}


// DG EU global
body.dg-eu {
  padding-top: 0 !important;
}