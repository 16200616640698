@toggle-width: 0.25rem;
@toggle-height: 1.5rem;
@toggle-width-lg: 0.5rem;
@toggle-height-lg: 2rem;

// Removed for IE support :sadface:
//:root {
//  --toggle-width: 0.25rem;
//  --toggle-height: 1.5rem;
//
//  @media (min-width: 768px) {
//    --toggle-width: 0.5rem;
//    --toggle-height: 2rem;
//  }
//}

.icon-toggle(@rotation) {
  position: absolute;
  top: 50%;
  left: 1.5rem;
  //right: 1.25rem;
  //transform: translateY(-50%) rotate(@rotation);
  width: @toggle-width;
  height: @toggle-height;
  background-color: currentColor;
  transform-origin: center center;
  //transition: all 0.25s ease-in-out;
  @media (min-width: 768px) {
    width: @toggle-width-lg;
    height: @toggle-height-lg;
  }
}

.icon-toggle-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 1rem 1.5rem 1rem 3rem;
  text-decoration: none;
  color: @red1;
  &:hover {
    text-decoration: none;
  }
  &:hover, &:focus {
    color: @brand-primary-hover;
  }
  &:before, &:after {
    transition: all 0.25s ease-in-out;
    transform: translateY(-50%) rotate(270deg);
  }
  &:before {
    content: '';
    .icon-toggle(0deg);
  }
  &:after {
    content: '';
    .icon-toggle(90deg);
  }
  > * {
    flex: 1;
  }
  .question-q {
    flex: 0 0 3rem;
    width: 3rem;
  }

  &[aria-expanded="false"] {
    &:before {
      //content: '';
      //.icon-toggle(0deg);

    }
    &:after {
      //content: '';
      //.icon-toggle(90deg);
    }
  }

  &[aria-expanded="true"] {
    &:before {
      //transform: translateY(-50%) rotate(270deg);
      opacity: 0;
    }
    &:after {
      //transform: translateY(-50%) rotate(270deg);
    }
  }

  &.collapsed {
    &:before {
      //content: '';
      //.icon-toggle(0deg);
      transform: translateY(-50%) rotate(0deg);
    }
    &:after {
      //content: '';
      //.icon-toggle(90deg);
      transform: translateY(-50%) rotate(90deg);
    }
  }

  @media (min-width: 768px) {
    padding-left: 4rem;
    &:before, &:after {
      left: 2rem;
    }
  }
  @media (min-width: 992px) {
    padding-left: 5rem;
    &:before, &:after {
      left: 2.5rem;
    }
  }
}

.panel-group {
  .panel {
    .panel-heading {
      padding: 0;
      .icon-toggle-link {
        &:focus {
          color: @red1;
        }
      }
    }
  }

  // hwy 93
  &#car-show-list {
    border-top: 5px solid @location-car-show;

  }

  &#race-list {
    border-top: 5px solid @location-race;
  }

  &#auction-list {
    border-top: 5px solid @location-auction;
  }

  &#drag-strip-list {
    border-top: 5px solid @location-drag-strip;
  }

  &#driving-experience-list {
    border-top: 5px solid @location-driving-experience;
  }

  &#iconic-drive-list {
    border-top: 5px solid @location-iconic-drive;
  }

  &#attraction-list {
    border-top: 5px solid @location-attraction;
  }

  &#diner-restaurant-list {
    border-top: 5px solid @location-diner-restaurant;
  }

  &#lodging-list {
    border-top: 5px solid @location-lodging;
  }

  &#rest-stop-list {
    border-top: 5px solid @location-rest-stop;
  }

  dg-highway-location-list & {
    .icon-toggle-link {
      color: @black;
      &:focus {
        color: @black;
      }
      &:before, &:after {
        color: @black;
      }
    }
  }
}

.cta-text {
  text-align: center;
  padding: 0 2rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    text-align: left;
    padding: 0;
  }
  p {
    font-size: 2rem;
  }
}

#faq-accordion, dg-accordion .panel-group {
	i.fa {
		float: left;
		line-height: 32px;
		margin-right: 10px;
		color: @brand-primary;
		font-size: 16px;
	}
	.heading2 {
		margin: 0;
		color: #585858;
		//font-size: 21px;
    text-transform: none;
    @media (min-width: @screen-sm-min) {
      font-size: 25px;
    }
    @media (max-width: 762px) {
      font-size: 2rem;
    }
	}
	.panel {
		border-radius: 0;
		background-color: #f5f5f5;
	}
	.panel-heading {
		overflow: hidden;
		+ .panel-collapse {
			> .panel-body {
				border-top: 0;
			}
		}
	}
	.panel-default {
		border: 0;
		margin-bottom: 15px;
	}
	.panel-body {
		padding-left: 40px;
		padding-top: 0;
	}
	.question-q {
		float: left;
		width: 20%;
	}
	.question {
		float: left;
		width: 80%;
	}
}


// MY VEHICLES ACCORDION
dg-user-profile {
  .my-garage{
    .vehicle-tabs {
      .tab-content {
        .profile-accordion {
          padding: 1.5rem;
          padding-top: 0;
          padding-bottom: 0;
          &.badges-container {
            background-color: @white;
            margin-top: 0;
          }

          .btn-accordion {
            width: 100%;
            color: @dark-gray;
            text-align: left;
            background-color: @white;
            border: 0;
            border-top: 1px solid @lighter-gray;
            border-bottom: 1px solid @lighter-gray;
            padding: 1.25rem 1.2rem;
            transition: all 0.25s ease-in-out;

            @media (min-width: 768px) {
              display: none;
            }

            &[aria-expanded="true"] {
              background-color: @red1;
              color: @white;

              i {
                transform: rotate(180deg);
              }
            }

            i {
              float: right;
              transform-origin: center center;
            }
          }

          .collapse {

            @media (min-width: 768px) {
              display: block;

              &[id^="badges-"] {
                .well {
                  background-color: @light-gray-bg;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  margin-bottom: 0;

                  > div {
                    padding-left: 0;
                    padding-right: 0;

                    &.cta-1320 {
                      flex: 0 0 100%;
                      position: relative;
                      margin-bottom: 1.5rem;

                      .cta-text {
                        .btn {
                          position: absolute;
                          right: 0;
                        }
                      }
                    }
                    &:nth-child(n+2) {
                      flex: 0 0 25%;

                      //IE 10 & 11 Alignment fix
                      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        flex: 0 0 20%;
                      }
                    }
                  }
                }
              }
            }

            @media (min-width: 1200px) {
              &[id^="badges-"] {
                .well {
                  > div {
                    &.cta-1320 {
                      flex: 0 0 30%;
                      min-width: 30%;
                    }

                    &:nth-child(n+2) {
                      flex: 0 0 17.5%;

                      //IE 10 & 11 Alignment fix
                      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        flex: 0 0 15%;
                      }
                    }
                  }
                }
              }
            }

            .well {
              background-color: @white;
              border: 0;
            }
          }
        }
      }
    }
  }
}
