//tab nav
.tabs-full-width, 
.dg-tabs > .nav-tabs {
  li {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0; //bs override

    @media (min-width: 992px) {
      flex: 1;
      max-width: 100%;
    }
    @media (min-width: 1200px) {
      flex: 0 0 250px;
      max-width: 250px;
    }
  }
}

.tab-pane {

  .download-subheading {
    margin: 20px 0;
    font-size: 1.8rem;
    .instructions {
      display: inline-block;
      font-family: inherit;
    }
  }

  @media (min-width: @screen-md-min) {
    .download-heading {
      font-size: 3.5rem;
      float: left;
    }
    .download-subheading {
      font-size: 1.6rem;
      float: left;
      margin-top: 3rem;
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }

  @media (min-width: @screen-lg-min) {
    .download-subheading {
      font-size: 2rem;
    }
  }

  .flex-container {
    @media (min-width: 600px) {
      display: flex;
      flex-direction: row;
    }
    @media (min-width: @screen-md-min) {
      flex-wrap: wrap;
    }
  }
}

.ringtone-step-container {
  display: flex;
  flex-direction: column;
  .ringtone-step {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    .step-content {
      background-color: lighten(@lighter-gray, 5%);
      //box-shadow: 0px 10px 10px fade(@black, 10%);
      padding: 1.5rem;
    }
  }

  @media (min-width: @screen-sm-min) {
    flex-direction: row;
    flex-wrap: wrap;
    .ringtone-step {
      flex: 0 0 50%;
      .step-content {
        height: 130px;
      }
    }
  }
  @media (min-width: @screen-md-min) {
    flex-direction: column;
    .ringtone-step {
      flex: 0 1 auto;
      flex-direction: row;
      width: 100%;
      .img-contain {
        flex: 0 0 40%;
      }
      .step-content {
        background-color: transparent;
        height: auto;
      }
    }
  }
  @media (min-width: @screen-lg-min) {
    .ringtone-step {
      font-size: 2rem;
      .step-content {
        padding: 0 1.5rem;
        p {
          margin-top: 0;
        }
      }
    }
  }
}

.device-instructions {
  @media (min-width: @screen-lg-min) {
    p, ol li {
      font-size: 2rem;
    }
  }
}

.form-1320 .tab-container, .dg-tabs.tab-container {
  .nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 0;
    > li > a {
      border: 0;
    }
    .nav-item {
      flex: 1;
      .nav-link {
        font-size: @font-size-lg;
        color:@body-color;
        border-bottom: 4px solid @secondary-01;
        text-decoration: none;
        &:hover {
          background-color: @secondary-01;
        }
      }
      &.active {
        .nav-link {
          color: @brand-primary;
          border: 0;
          border-bottom: 4px solid @brand-primary;
          &:hover {
            background-color: #FFF;
          }
        }
      }
    }
  }
  .tab-content {
    .tab-pane {
      position: relative;
      padding-top: 3rem;
      //min-height: 650px;

      &.active {
        animation: from-left 0.5s forwards ease-in-out;
        &:nth-child(-n+2):last-child {
          animation: from-right 0.5s forwards ease-in-out;
        }
      }
    }
  }
  &.stack-xs .nav-tabs {
    flex-wrap: wrap;
    .nav-item {
      flex: 0 0 100%;
      @media (min-width: @screen-sm-min) {
        flex: 1;
      }
    }
  }
}