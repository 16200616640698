@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // Chrome/Safari
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }
  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // Firefox
  & {
    scrollbar-color: $foreground-color $background-color; /* thumb and track color */
    scrollbar-width: thin;
  }

  // IE
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}