#cdm-rules {
  background-image: url('/assets/images/chief-donut-maker/donut-background.jpg');
  background-position: center;
  background-size: 100%;
  background-repeat: repeat-y;
  padding: 50px 0;

  .black-box {
    padding: 20px;
    margin: 0 20px;
    background-color: @black;
    color: @white;

    @media (min-width: @screen-sm-min) {
      padding: 60px;
    }
  }

  .text-indent {
    text-indent: 20px;
  }
}
