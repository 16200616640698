.feature-tile {
  position: relative;
  display: block;
  overflow: hidden;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(0deg, $black 10.4%, rgba($black, 0) 100%);
  }

  &:hover {
    > img {
      transform: scale(1);
    }
  }

  > img {
    transform: scale(1.05);
    transform-origin: center center;
    transition: all 0.25s ease-in-out;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .feature-tile-content {
    position: absolute;
    top: auto;
    bottom: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: auto;
    padding: 20px;
    color: $white;

    @media (min-width: 1440px) {
      padding: 45px;
    }

    img {
      width: 100%;
      max-width: 350px;
      height: auto;
      margin-bottom: 25px;
      transform-origin: center center;
      transition: all 0.25s ease-in-out;
    }

    p {
      font-size: 18px;

      @media (min-width: 1440px) {
        font-size: 28px;
      }
    }
  }
}
