.offset-block {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    width: 100%;
    height: 260px;
  }

  .offset-content {
    text-align: center;
    padding: 300px 10px 20px;
  }

  .giga {
    margin-bottom: 20px;
  }

  p {
    color: white;
    text-shadow: 2px 2px 2px @black;
  }

  @media (min-width: @screen-xs-min) {
    &::after {
      height: 360px;
    }

    .offset-content {
      padding-top: 400px;
    }
  }

  @media (min-width: @screen-sm-min) {
    height: 450px;
    margin-bottom: 100px;

    &::after {
      width: 66.6667%;
      height: 100%;
    }

    &.right {
      &::after {
        left: 20px;
      }

      .offset-content {
        right: 0;
        width: 50%;
      }
    }

    &.left {
      &::after {
        right: 20px;
      }

      .offset-content {
        width: 65%;

        p {
          width: 70%;
        }
      }
    }

    .offset-content {
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: left;
      z-index: 2;

      p {
        padding: 0 10px;
        font-size: 24px;
        max-width: 500px;
      }
    }
  }

  @media (min-width: @screen-md-min) {

    &.left {
      &::after {
        right: 15%;
      }
    }

    &.right {
      .offset-content {
        right: 0;
        width: 40%;
      }
    }
  }
}
