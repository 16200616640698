.hero {
  position: relative;
  //background-image: url('/assets/images/red-demon-poster.jpg');
  background-color: @black;
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 250px;
  transition: background-position 0.5s ease-in-out;
  margin-bottom: 1rem;

  @media (min-width: 600px) {
    height: 400px;
  }
  @media (min-width: @screen-lg-min) {
    height: 600px;
    background-position: -25px center;
    &.in {
      background-position: 0 center;
    }
  }


  //Hero Video
  video {
    height: 250px;
    width: auto;
    background-size: cover;

    @media (min-width: 600px) {
      min-width: 100%;
      min-height: 120%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
      //transition: opacity 1s ease-in-out;

      //class added for fade transition of video element
      &.fade-back {
        //opacity: 0.08;
      }
    }
    @media (max-width: 1199px) {
      //transition: opacity 10s ease-in-out; //10s
      //animation: fade-back 5s 5s forwards; //5s 5s
    }
    @media (min-width: @screen-md-min) {
      min-height: 160%;
    }
    @media (min-width: @screen-lg-min) {
      min-height: 140%;
    }
    @media (min-width: 1500px) {
      min-height: 150%;
    }
  }


  .overlay {
    //background-image: url('/assets/images/hero/brotherhood/gradient.png');
    //background-repeat: no-repeat;
    //background-size: cover;
    background-color: @black;
    background-position: center left;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0.5;
    height: 100%;
    width: 100%;

    @media (min-width: 600px) {
      //opacity: 0.6;
    }
  }
  img {
    width: 100%;
    height: auto;
  }


  .callout-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 50%;
    left: auto;
    right: 0;
    transform: translateY(-50%);
    z-index: 2;
    padding: 0 15px;

    &.barrett-jackson-home {
      width: 100%;
      right: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      flex-direction: column;

      @media(min-width: 768px) {
        position: relative;
      }

      .callout-block {
        display: block;
        background-color: @black;
        padding: 0 5px;
        margin: 5px 0;
        width: 100%;
        font-size: 1.5em;
        font-family: @main-font;
      }
    }

    .enter-to-win-date {
      width: 110px;
      margin-bottom: 80px;

      @media(min-width: 768px) {
        width: 230px;
        align-self: flex-start;
        margin-left: 30px;
        margin-top: 40px;
      }

      @media(min-width: 1200px) {
        width: 380px;
      }

    }

    .enter-contest-date {
      width: 130px;
      position: absolute;
      right: 20px;
      bottom: 0;

      @media(min-width: 768px) {
        width: 270px;
        align-self: flex-end;
        margin-right: 30px;
        margin-bottom: 30px;
      }

      @media(min-width: 1200px) {
        width: 350px;
        margin-bottom: 40px;
      }

    }

    .barrett-logo {
      width: 140px;
      position: absolute;
      left: 10px;
      bottom: 0;

      @media(min-width: 768px) {
        width: 280px;
        left: auto;
        right: 40px;
        top: 140px;
      }

      @media(min-width: 1200px) {
        width: 350px;
        top: auto;
        bottom: 200px;
      }

    }

  }

  #hero-last-chance & {
    background-image: url('/assets/images/ultimate-last-chance/last-chance-hero-v2-m.jpg');
    background-position: center top;
    @media (min-width: 600px) {
      background-image: url('/assets/images/ultimate-last-chance/last-chance-hero-v2-sm.jpg');
    }
    @media (min-width: @screen-md-min) {
      background-image: url('/assets/images/ultimate-last-chance/last-chance-hero-v2-md.jpg');
    }
    @media (min-width: @screen-lg-min) {
      background-image: url('/assets/images/ultimate-last-chance/last-chance-hero-v2-lg.jpg');
      height: 500px;
    }
  }

  &.landing-hero {
    background-image: url('/assets/images/1320/1320-hero-xs.jpg');
    background-position: left bottom;
    height: 300px;
    position: relative;

    @media (min-width: 768px) {
      background-image: url('/assets/images/1320/1320-hero-sm.jpg');
      height: 375px;
    }
    @media (min-width: 1200px) {
      background-image: url('/assets/images/1320/1320-hero-lg.jpg');
      height: 420px;
    }
    @media (min-width: 1440px) {
      background-position: center bottom;
      height: 500px;
    }

    .logo {
      position: absolute;
      top: 2.5rem;
      left: 50%;
      transform: translateX(-50%);
      width: 245px;

      @media (min-width: 768px) {
        left: 2rem;
        transform: translateX(0);
        width: 320px;
      }
      @media (min-width: 1440px) {
        top: 1.5rem;
        width: 420px;
      }
    }

    a {
      display: block;
      height: 100%;
      position: relative;

      .btn {
        position: absolute;
        bottom: 1.5rem;
        right: 1.5rem;
        height: auto;
        width: auto;

        @media (min-width: 768px) {
          right: auto;
          left: 2rem;
          bottom: 2rem;
        }
        @media (min-width: 1440px) {
          left: 4rem;
          bottom: 3rem;
          font-size: 2.8rem;
          min-width: 250px;
        }
      }
    }
  }
  &.landing-hero-1320 {
    background: none;
    height: auto;
    overflow: hidden;
  }
  &.owner-perks {
    height: auto;
    margin-bottom: 1rem;
    margin-top: 1rem;
    overflow: hidden;
    position: relative;
    img {
      display: block;
      transition: all .3s ease-in-out;
      &.img-hover {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 0;
      }
    }
    &:hover {
      img.img-non-hover {
        opacity: 0;
        visibility: hidden;
      }
      img.img-hover {
        opacity: 1;
        visibility: visible;
      }
    }
  }

}

.page-hero {
  height: 350px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &:before {
    content: '';
    background-color: fade(@black, 50%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .heading-contain {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;

    .callout-block {
      display: block;
      background-color: @black;
      color: @white;
      font-family: @main-font;
      font-size: 4em;
      line-height: 1;
      margin: 10px auto;
    }
  }

  &#drag-racing-hero {
    height: 450px;
    background-image: url('/assets/images/hero/hpds/drag-school-hero-mobile.jpg');
    margin-bottom: 0;

    @media (min-width: @screen-sm-min) {
      background-image: url('/assets/images/hero/hpds/drag-school-hero-tablet.jpg');
      .heading-contain {
        top: auto;
        left: auto;
        bottom: 50px;
        right: 50px;
        transform: translate(0, 0);
      }
    }
    @media (min-width: @screen-md-min) {
      &:before {
        background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.5));
      }
    }
    @media (min-width: @screen-lg-min) {
      background-image: url('/assets/images/hero/hpds/drag-school-hero-desktop.jpg');
      height: 600px;
    }
    @media (min-width: 1440px) {
      .heading-contain {
        width: 400px;
        .callout-block {
          font-size: 5.25em;
        }
        .btn {
          font-size: 2em;
        }
      }
    }
  }
}
