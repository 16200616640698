$thumb-width-sm: 340px;

#interior-gallery {
  padding: 0;

  @media (min-width: 768px) {
    padding: 0 1.5rem;
  }

  #interior-image-slider {
    .container-fluid {
      @media (min-width: 992px) {
        display: flex;
      }
    }
  }

  .interior-image-wrapper {
    @media (min-width: 992px) {
      flex: 0 0 $thumb-width-sm;
      width: $thumb-width-sm;
    }

    .swiper-container {

      @media (min-width: 768px) {
        width: 100%;
      }

      .swiper-wrapper {
        @media (min-width: 768px) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          transform: none !important;
        }
        @media (min-width: 992px) {
          flex-direction: column;
        }

        .swiper-slide {
          @media (min-width: 768px) {
            flex: 0 0 48%;
            width: 48%;
            height: auto;
          }
          @media (min-width: 992px) {
            flex: 0 0 48%;
            width: 48%;
            height: auto;
          }

          &.swiper-slide-duplicate {
            display: none;
          }
        }
      }
    }
  }

  .image-wrap {

    .img {
      cursor: pointer;
      
      &.active {
        @media (min-width: 992px) {
          border: 3px solid $primary;
        }
      }

      @media (min-width: 992px) {
        height: 135px;
      }

      img {
        @media (min-width: 768px) {
          width: 100%;
          height: auto;
        }
        @media (min-width: 992px) {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

      }
    }

    .description {
      font-family: $font-heading-bold;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.2;
      text-transform: uppercase;
      text-align: center;
      padding: 1rem;

      @media (min-width: 768px) {
        display: flex;
        text-align: left;
        padding: 1rem 0;
      }
      @media (min-width: 992px) {
        padding-top: 0.5rem;
      }

      div {
        font-size: 1.4rem;

        @media (min-width: 768px) {
          flex: 1;
        }

        &:last-child {
          font-family: $font-heading-bold;
          color: $primary;

          @media (min-width: 768px) {
            flex: 0 1 auto;
          }
        }

        span:not(.icon-container) {
          display: flex;
          align-items: center;
          justify-content: center;

          @media (min-width: 768px) {
            display: block;
            padding-right: 1rem;
          }
        }
      }
    }
  }

  .indicators {
    display: flex;
    justify-content: center;
    padding: 0 1.5rem;

    @media (min-width: 768px) {
      display: none;
    }

    .dot, .swiper-pagination-bullet {
      display: block; 
      width: 1.4rem;
      height: 1.4rem;
      border: 2px solid $gray-400;
      border-radius: 50%;
      transition: all 0.25s ease-in-out;
      margin-right: 1rem;
  
      &:last-child {
        margin-right: 0;
      }
  
      &.swiper-pagination-bullet-active, &.active {
        background-color: $gray-400;
      }
    }
    
  }

  .icon-container {
    cursor: pointer;
    color: $gray-600;
    // display: inline-flex;
    // align-items: center;
    // justify-content: center;
    // width: 2rem;
    // height: 2rem;
    // font-size: 1.4rem;
    // color: $gray-600;
    // border: 1px solid $gray-600;
    // border-radius: 50%;
    // padding: 0.5rem;
    // transform: scale(0.75);
  }
  
  .additional-options {
    padding: 0 1.5rem;
    padding-top: 3.5rem;

    @media (min-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
    @media (min-width: 992px) {
      margin-top: auto;
      padding-top: 2rem;
    }

    h3 {
      font-size: 2rem;
    }

    .list-underline {

      @media (min-width: 768px) {
        columns: 2;
        column-gap: 2.5rem;
      }

      li {
        display: flex;
        justify-content: space-between;
        font-family: $font-heading-bold;
        font-size: 1.4rem;
        padding: 1rem 0;
        border-bottom: 1px solid $gray-400;

        @media (min-width: 768px) {
          font-size: 1.6rem;
          break-inside: avoid-column;
        }

        &:first-child {
          padding-top: 0;
        }

        &:nth-child(3) {
          @media (min-width: 768px) {
            break-after: column;
          }
        }
        &:nth-child(4) {
          @media (min-width: 768px) {
            padding-top: 0;
          }
        }
      }
    }
  }

  .price {
    font-family: $font-heading-bold;
    color: $primary;
  }

  .full-size-image-wrapper {
    @media (min-width: 992px) {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-left: 2rem;
    }
    @media (min-width: 1200px) {
      padding-left: 3rem;
    }

    .feature-image {
      display: none;
      position: relative;

      @media (min-width: 992px) {
        display: block;
        flex: 1;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .icon-trigger {
        cursor: pointer;
        position: absolute;
        top: auto;
        left: auto;
        bottom: 0;
        right: 0;
        font-size: 3rem;
        color: $white;
        padding: 2rem;
      }
    }
  }
}

// modal
.modal {
  .package-modal {
    max-width: 480px;

    .modal-content {
      .modal-header {
        display: flex;
        align-items: center;
        padding: 1rem 1.5rem;
        padding-top: 0;
        padding-right: 0;

        p {
          margin-bottom: 0;
        }
        .modal-close {
          position: static;
          padding: 0.75rem;
          margin-left: auto;
        }
      }
      .modal-body {
        padding: 1.5rem;
        padding-top: 0;

        ul {
          padding-left: 2.25rem;
        }
      }
    }
  }
}