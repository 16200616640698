#hwy93-banner {
  display: flex;
  background-image: url('/assets/images/hero/hwy93/highway-hero-md.jpg');
  background-position: center;

  @media (min-width: 600px) {
    background-position: -100px center;
  }
  @media (min-width: 768px) {
    background-position: center center;
  }
  @media (min-width: 992px) {
    background-image: url('/assets/images/hero/hwy93/highway-hero-xl.jpg');
  }
  @media (min-width: 1600px) {
    background-position: center top;
  }


  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    padding: 20px;

    h3 {
      padding: 10px;
      background-color: #000;
      color: #fff;
      margin-bottom: 20px;
    }

    img {
      width: 100px;
      margin-bottom: auto;
    }

    .heading-container {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      min-height: 350px;
    }

    @media (min-width: 992px) {
      flex-direction: row;
    }
  }

  .video-cta {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (min-width: 600px) {
    .site-wrapper-xl {
      width: 100%;
    }

    .hero-content {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      height: 100%;
      width: 100%;

      img {
        width: 200px;
        margin-bottom: auto;
      }
    }

    .video-cta {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      button {
        &:focus {
          outline: none;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .hero-content img {
      margin: 20px 0;
    }

    h3 {
      padding: 20px;
      margin-bottom: 40px;
    }

    .video-cta {
      .fa-stack {
        font-size: 250%;
      }
    }
  }

  @media (min-width: 1200px) {
    .hero-content img {
      width: 250px;
      margin: 40px 0;
    }
  }

  @media (min-width: 1400px) {
    .hero-content .heading-container {
      margin-left: 5%;
    }
  }
}
