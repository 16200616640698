.input-btn {
	margin-bottom: 15px;
	//font-size: 18px;
	a {
		text-transform: uppercase;
		font-size: 1.5em;
	}
	.form-control {
		//border-radius: 0;
		//height: 39px;
		//text-transform: uppercase;
	}
	button.btn {
		border-radius: 0;
		background-color: @brand-primary;
		background-image: none;
		color: @white;
		padding: 4px 13px;
		font-size: 17px;
	}
  input.search-icon {
    text-indent: -999999999px;
    background: @red1 url('/assets/images/search-icon.png') center no-repeat;
    color: @white;
    padding: 9px 19px;
  }
  input.location-icon {
    text-indent: -999999999px;
    background: @red1 url('/assets/images/location-icon.png') center no-repeat;
    color: @white;
    padding: 9px 19px;
  }

	.styled-select select {
	   background: transparent;
	   border: none;
	   font-size: 14px;
	   // height: 29px;
     padding: 7px;
	   width: 110%;
	}

	.styled-select.slate {
	   background: url('/assets/images/dropdown-arrow.jpg') no-repeat right center;
	   // height: 34px;
	   width: 100%;
	   overflow: hidden;
	}

	.styled-select.slate select {
	   border: 1px solid #ccc;
	   font-size: 16px;
	   // height: 34px;
	   width: 110%;
	   option {
	   	width: 100%;
	   }
	}
}

#categories {
	background-color: #282b2d;
	padding: 50px 0;
	.category {
		position: relative;
		width: 100%;
		margin-bottom: 30px;
		.category-bg {
			position: absolute;
			top: 5%;
			width: 100%;
			padding: 5px 0px 5px 10px;
			background-color: rgba(48, 48, 48, 0.7);
			border-left: solid 5px @brand-primary;
			.category-heading {
				color: @white;
				font-size: 17.6vw;
				text-transform: uppercase;
				padding: 10px;
				margin: 0;
				position: relative;
			}
			.category-heading.tips {
				font-size: 12.3vw;
			}
		}
	}
}



.category-type {
	color: @white;
	position: relative;
	i {
		position: absolute;
		z-index: 1;
		top: 10px;
		left: 10px;
		font-size: 1.2em;
	}
	&:before {
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 60px 60px 0 0;
		border-color: @brand-primary transparent transparent transparent;
		position: absolute;
		top: 0;
  }
}

.articles-clear {
	clear: both;
}
.article-container {
	margin-bottom: 30px;
	overflow: hidden;
}
.article-image {
	margin-bottom: 10px;
	position: relative;
}
.article-teaser {
	position: absolute;
  top: 70%;
  right: 5%;
  width: 80%;
	.category-name {
		font-family: @main-font-lt;
		background-color: @brand-primary;
		color: @white;
		font-size: 1.5vw;
		display: inline-block;
		padding: 5px 10px;
		float: right;
		clear: both;
		margin: 0;
		text-transform: uppercase;
	}
	.description {
		font-family: @main-font;
		color: @white;
		margin: 0;
		float: right;
		display: inline-block;
		background-color: @dark-gray;
		padding: 5px 10px;
		font-size: 1.5vw;
		line-height: 1.6vw;
		text-transform: uppercase;
		clear: both;
	}
}

.dodge-post {
	background: url('../../../assets/images/sponsored-post-bg.png') top left no-repeat;
	padding-bottom: 20px;
	margin-bottom: 0;
	.sponsored-title {
		color: @brand-primary;
		text-transform: uppercase;
		font-family: @main-font;
	}
}


// SIGN UP AND SHARE YOUR MEDIA
section#share-media {
  a {
    display: block;
  }
}
.sign-up-share {
  position: relative;
  background: url('../../../assets/images/upload-bg.png') top right no-repeat @white;
  overflow: hidden;
  background-size: 60%;
  padding: 30px 0;
  .share-media-heading {
    font-size: 17.7vw;
    color: #818181;
    text-transform: uppercase;
    line-height: 0.6em;
    margin-top: 0;
    .small {
      font-size: 0.56em;
    }
  }
  i.fa-cloud-upload {
    font-size: 20vw;
    color: @brand-primary;
  }
}



// POLL
/*#poll {
	background-color: @black;
	padding-top: 30px;
	padding-bottom: 30px;
	position: relative;
	//margin-top: 20px;
	.widgets.vote {
	  background: url('../../images/vote.jpg') center no-repeat;
	  background-size: cover;
	  padding: 25px 20px;
	  overflow: hidden;
	  h4 {
	    font-size: 39px;
	    text-transform: uppercase;
	    font-family: @main-font-lt;
	    margin: 0px 0px 20px 0px;
	    color: @dark-gray;
	    strong {
	        font-family: @main-font;
	    }
	  }
	  h4.bold {
	  	text-align: center;
	  	margin: 0 0 3px 0;
	  	font-size: 30px;
	  	font-weight: normal;
	  }
	  .large {
	  	font-weight: bold;
	  	font-size: 45px;
	  	line-height: 36px;
	  }
	  ul.vote-option {
	    list-style: none;
	    margin: 0;
	    padding: 0;
	    li {
	      transition: .35s ease-in-out;
	      -webkit-transition: .35s ease-in-out;
	      -moz-transition: .35s ease-in-out;
	      padding: 15px 15px;
	      margin: 0px 0px 10px 0px;
	      text-align: center;
	      background: #333333;
	      color: @white;
	      text-transform: uppercase;
	      font-size: 21px;

	      // &:hover {
	      //   background: @red2;
	      // }
	      a {
	        color: #fff;
	        font-size: 18px;
	        display: block;
	      }
	    }
	  }
	  p.vote {
	    background: @red1;
	    text-align: center;
	    margin: 30px 0px 0px 0px;
	    a {
	      color: #fff;
	      display: block;
	      padding: 10px 0px;
	      font-size: 18px;
	      font-family: @main-font-lt;
	      &:hover {
	        text-decoration: none;
	        background: @red2;
	      }
	    }
	  }
	  p.join-conversation {
	    border: 1px solid @red1;
	    text-align: center;
	    margin: 10px 0px 0px 0px;
	    a {
	      color: @red1;
	      display: block;
	      padding: 10px 0px;
	      font-size: 18px;
	      font-family: @main-font-lt;
	      // &:after {
	      //   font-family: FontAwesome;
	      //   content: "\f178";
	      // }
	      &:hover {
	        text-decoration: none;
	        background: @red2;
	        color: #fff;
	      }
	    }
	  }
	}

	.heading-content {
		@media (min-width: 1200px) {
			padding-left: 4.5rem;
		}
	}

	//If the default social feed is replaced by a featured video
	//Toggle data attribute to false in markup to use default styling
	&[data-video-sibling="true"] {
		@media (min-width: @screen-md-min) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-top: 10px;
	    padding-bottom: 10px;
		}
		@media (min-width: 1440px) {
			padding-left: 40px;
			padding-right: 40px;
			.spotlight-heading {
				margin-bottom: 3rem;
		    //font-size: 4.5em;
			}
			.spotlight-image {
				width: 40%;
			}
			.spotlight-content {
				width: 60%;
				h3 {
					margin-top: 1em;
				}
			}
		}
	}
	.spotlight-container {

		// If traditional layout
		@media (min-width: 992px) {
			margin-top: 4rem;
		}
		@media (min-width: 1200px) {
			//margin-top: 3.5rem;
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}
} */


.widgets.vote {
	input[type=radio].voting-check {
		position: absolute;
		z-index: -1000;
		left: -1000px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height: 1px;
		width: 1px;
		margin: -1px;
		padding: 0;
		border: 0;
	}

	input[type=radio].voting-check + label.css-label {
		padding: 20px;
		display: block;
		// background-repeat: no-repeat;
		// background-position: 0 0;
		vertical-align: middle;
		cursor: pointer;

	}

	input[type=radio].voting-check:checked + label.css-label {
		background-color: @brand-primary;
	}
	label.css-label {
		background-color: #333;
		font-size: 20px;
		color: @white;
		text-align: center;
		font-weight: normal;
		text-transform: uppercase;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
}

// #poll:before {
// 	content: '';
// 	position: absolute;
// 	left: 0;
// 	top: -36px;
// 	width: 100%;
// 	height: 120px;
// 	background: @brand-primary;
// 	-webkit-transform: skewY(-2.5deg);
// 	-moz-transform: skewY(-2.5deg);
// 	-ms-transform: skewY(-2.5deg);
// 	-o-transform: skewY(-2.5deg);
// 	transform: skewY(-2.5deg);
// 	-webkit-backface-visibility: hidden;
// }
.ghost-btn {
	border: solid 2px #333;
	border-radius: 3px;
	padding: 7px 30px;
	color: #7a7a7a;
	// margin-top: 4px;
	margin-bottom: 4px;
	text-transform: uppercase;
  transition: .35s ease-in-out;
  -webkit-transition: .35s ease-in-out;
  -moz-transition: .35s ease-in-out;
  background-color: transparent;
	i {
		margin-left: 4px;
	}
	&:hover {
		background-color: #333;
		color: @white;
	}
}
.ghost-btn.red {
	color: @brand-primary;
	border-color: @brand-primary;
	&:hover {
		color: @white!important;
		background-color: @brand-primary;
	}
}
.ghost-btn.white {
	color: @white;
	border-color: @white;
	&:hover {
		color: @brand-primary!important;
		background-color: @white;
	}
}






.btn-solid.categories-dropdown {
	margin-bottom: 10px;
	width: 100%;
	text-align: left;
	i {
		float: right;
		line-height: 26px;
	}
}

.vip-bg {
	background: url('/assets/images/racing-hq/vip-bg.jpg') top center no-repeat;
	background-size: cover;
	padding: 50px 0;
	.become-a-vip {
		padding-top: 5%;
		padding-bottom: 10%;
		color: @white;
	}
}
.vip-gallery-container {
	margin: 40px 0;
	overflow: hidden;
}
.vip-gallery {
	margin-bottom: 30px;
}

// SIGN IN MODAL
.modal {
	.modal-dialog {
		.modal-content {
			border-radius: 0;
			.modal-header {
				border-bottom: none;
			}
			.close {
				color: @white;
				font-weight: normal;
				font-family: @main-font-lt;
				font-size: 14px;
				background-color: @gray;
				opacity: 1;
				padding: 5px;
				border-radius: 0;
				text-shadow: none;
				span {
					font-size: 20px;
				}
				&:hover {

				}
			}
			.social-signin-btns {
				margin-bottom: 30px;
			}
			.modal-separator {
				background: url('../../../assets/images/signup-gradient.jpg') no-repeat @white;
				background-position: center 10px;
				min-height: 96px;
				text-align: center;
				clear: both;
				.or-copy {
					font-family: @main-font-lt;
					text-transform: uppercase;
					font-size: 18px;
					border: solid 1px #dfdfdf;
					border-radius: 100%;
					padding: 3px;
					background-color: @white;
					color: @light-gray;
				}
				.create-account {
					margin: 20px 0;
					a {
						font-size: 16px;
						font-family: @main-font;
						margin: 10px 0;
					}
				}
			}
			.modal-member-login {
				background-color: #f1f1f1;
				padding: 15px 0;
				font-size: 1.15em;
				text-align: center;
				font-weight: bold;
				clear: both;
				a {
					display: block;
					color: @gray;
					span {
						color: @red1;
					}
				}
			}
		}
	}

	@media (min-width: @screen-sm-min) {
		font-size: 16px;
	}
}
#signinModal {
	.modal-body {
		text-align: center;
	}
	.btn {
		margin-top: 30px;
	}
}
.modal-body {
	overflow: hidden;
}
.signup-form {
	input {
		width: 100%;
		margin-bottom: 5px;
	}
	input[type="checkbox"] {
		width: auto;
	}
	label {
		font-weight: normal;
	}
	.terms-conditions {
		margin: 10px 0 0px;
	}
	.create-btn {
		margin-bottom: 20px;
	}
}
.create-form {
	display: none;
  transition: .35s ease-in-out;
  -webkit-transition: .35s ease-in-out;
  -moz-transition: .35s ease-in-out;
}
.create-form, .signin-form {
  .back-btn {
  	margin: 0 0 10px 10px;
  	display: block;
  }
}
.signin-form {
	display: none;
}


#locator .intro-copy {
	padding: 20px;
}
.intro-copy {
	padding: 10px 0;
}

@media (min-width: @screen-sm-min) {
	#categories {
		.category {
			.category-bg {
				.category-heading {
					font-size: 2.3vw;
				}
				.category-heading.tips {
					font-size: 2.3vw;
				}
			}
		}
	}
	.btn-solid.categories-dropdown {
		margin-bottom: 0px;
	}

	// SIGN UP AND SHARE YOUR MEDIA
	.sign-up-share {
		.share-media-heading {
			font-size: 6.7vw;
			margin-top: 6vw;
		}
	}

	/*#poll {
		.widgets.vote {
		  h4 {
		    font-size: 50px;
		  }
		  h4.bold {
		  	font-size: 30px;
		  }
		  .large {
		  	font-size: 60px;
		  }
		}
	}*/
	.topics {
	  ul {
			.clearfix();
	    li {
				width: 100%;
	      a {
	        width: inherit;
	      }
	    }
	  }
	}

	.vip-bg {
		.become-a-vip {
			padding-bottom: 20%;
		}
	}
}

.muut .m-expanded .m-seed .m-facelink, .muut .m-create .m-preview .m-facelink, .muut .m-facelink { display: none; }



@media (min-width: @screen-md-min) {
	.topics {
	  ul {
	    li {
	      a {
	        //width: 120px;
	      }
	    }
	  }
	}

}

@media (min-width: @screen-lg-min) {
	.topics {
	  ul {
	    li {
				display: block;
				width: ~'calc(50% - 5px)';
				float: left;
				a {
					width: 100%;
				}
	    }
	  }
	}
}
