// CAROUSEL

#main-carousel {
	.carousel-inner {
		width: 100%;
	}
	.carousel-control {
		z-index: 100;
		top: auto;
		left: auto;
		bottom: 32%;
		width: auto;
		opacity: 1;
		i {
			padding: 0px 10px;
			font-size: 39px;
			background-color: @brand-primary;
			&:hover {
				background-color: darken(@brand-primary, 10%)
			}
		}
	}
	.carousel-control.left {
		right: 50px;
	}
	.carousel-control.right {
		right: 13px;
	}
	.carousel-caption {
		position: static;
		width: 100%;
		left: 0;
		top: 5%;
		padding: 0;
		bottom: auto;
		text-align: left;
		font-family: @main-font-lt;
		text-transform: uppercase;
		.carousel-copy {
			background-image: none;
			background-color: #333;
			padding-top: 20px;
			padding-right: 29%;
			padding-bottom: 20px;
			padding-left: 5%;
			font-size: 20px;
			text-shadow: 1px 1px 2px @black;
			line-height: 22px;
		}
		.cta-btn {
			padding-top: 0px;
			padding-left: 5%;
			padding-bottom: 20px;
			background-color: #333;
			a {
				background-color: @brand-primary;
				display: inline-block;
				padding: 10px 20px;
				color: @white;
				&:hover {
					background-color: darken(@brand-primary, 10%);
				}
			}
		}
	}
}





@media (min-width: @screen-sm-min) {
	#main-carousel {
		.carousel-control {
			bottom: 10%;
			i {
				padding: 4px 20px;
				font-size: 59px;
			}
		}
		.carousel-control.left {
			right: 100px;
		}
		.carousel-control.right {
			right: 36px;
		}

		.carousel-caption {
			position: absolute;
			width: 50%;
			left: 0;
			top: 5%;
			.carousel-copy {
				background: url('../../../assets/images/caption-bg.png') top left repeat-y;
				padding-right: 12%;
				padding-left: 10%;
				font-size: 40px;
				line-height: 42px;
			}
			.cta-btn {
				padding-top: 20px;
				padding-left: 10%;
				background-color: transparent;
			}
		}
	}
}

