// Alerts
.alert {
  border-radius: 0;
  border: 0;
  h4 {
    margin-bottom: 0;
  }
  p {
    margin: 0 0 10px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a:not(.alert-link) {
    color: #FFF;
    text-decoration: underline;
    &:hover, &:focus {
      color: #FFF;
    }
  }
  > .row > .col-md-6 {
    @media (max-width: @screen-md-min) {
      padding: 15px;
    }
    &:after {
      .clearfix();
    }
    &:first-child {
      @media (max-width: @screen-md-min) {
        margin-top: -15px;
      }
    }
    &:last-child {
      @media (max-width: @screen-md-min) {
        margin-bottom: -15px;
      }
    }
  }
}
.context-icon {
  font-size: 35px;
  margin-right: 5px;
}
.bg-success {
  background-color: @brand-success;
  color: #FFF;
  > .row > .col-md-6:last-child {
    @media (max-width: @screen-md-min) {
      background-color: #0f9728;
    }
  }
}
.bg-danger {
  background-color: @red1;
  color: #FFF;
  > .row > .col-md-6:last-child {
    @media (max-width: @screen-md-min) {
      background-color: #ce2d2d;
    }
  }
  .alert-link {
    color: #FFF;
    text-decoration: underline;
    &:hover {
      color: lighten(@red1, 50%);
    }
  }
}
.bg-warning {
  background-color: @brand-warning;
  color: #000;
  a, a:not(.alert-link) {
    color: darken(@brand-warning, 30%);
    font-weight: bold;
    font-family: @main-font;
    &:hover, &:focus {
      color: darken(@brand-warning, 50%);
    }
  }
  > .row > .col-md-6:last-child {
    @media (max-width: @screen-md-min) {
      //background-color: #0f9728;
    }
  }
}
.alert-banner {
  padding: 1rem;
  position: relative;
  .close-alert {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    color: inherit;
    opacity: 0.75;
    position: absolute;
    top: .5rem;
    right: 1rem;
    transition: all 0.25s ease-in-out;
    z-index: 99999;
    &:hover {
      opacity: 1;
    }
  }
  .flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: slide-in-right 0.5s forwards;
    .context-icon {
      flex: 0 0 40px;
      font-size: 4rem;
      margin-right: 1.5rem;
    }
    .context-message {
      flex: 1;
      h4, p {
        margin-top: 0;
        margin-bottom: 0;
      }
      h4 {
        font-family: @main-font;
        font-size: 1.8rem;
      }
      p {
        font-size: 1.6rem;
      }
    }
  }
}
