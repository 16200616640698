#cdm-experience-look-forward {
  background-color: black;
  padding: 40px 0;

  > h2 {
    margin-bottom: 20px;
  }
}

#experience-track-day::after {
  background-image: url('/assets/images/chief-donut-maker/experience-offset-track-day.jpg');
}

#experience-detroit-auto-show::after {
  background-image: url('/assets/images/chief-donut-maker/experience-offset-detroit-auto-show.jpg');
}

#experience-pit-crew::after {
  background-image: url('/assets/images/chief-donut-maker/experience-offset-pit-crew.jpg');
}
