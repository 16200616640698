#dg-poll {
  &.poll-container {
    background-image: url('/assets/images/bg-grunge.jpg');
    background-size: cover;
    background-position: center center;

    dg-poll {
      display: flex !important;
      align-items: center;
      justify-content: center;
      padding: 50px 35px;

      form {
        max-width: 400px;

        @media (min-width: 1440px) {
          max-width: 550px;
        }

        .poll-question {
          font-size: 22px;
          line-height: 1.25;
          margin-bottom: 35px;

          @media (min-width: 1440px) {
            font-size: 30px;
          }
        }
        .poll-form {
          margin-bottom: 0;

          .btn-radio {
            border: 0;
            margin-bottom: 10px;

            label {
              display: flex;
              padding: 0;
              overflow: hidden;

              @media (min-width: 1440px) {
                font-size: 21px;
              }
              
              span {
                &:not(.letter) {
                  display: flex;
                  align-items: center;
                  padding-left: 35px;
                  
                  @media (max-width: 400px) {
                    line-height: 1;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    padding-left: 45px;
                  }
                }

                &.letter {
                  position: relative;
                  z-index: 1;
                  color: $white;
                  padding: 5px 10px;
                  background-color: $primary-dark;
                  border-radius: 0;
                  box-shadow: none;

                  &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: auto;
                    right: -20px;
                    z-index: -1;
                    display: block;
                    width: 180%;
                    height: 100%;
                    background-color: $primary-dark;
                    transform: skew(-40deg);

                    @media (max-width: 400px) {
                      height: 120%;
                    }
                  }
                }
              }
            }
          }

          button[type="submit"] {
            display: block;
            font-size: 14px;
            margin: 0 auto;
            margin-top: 20px;

            @media (min-width: 1440px) {
              font-size: 21px;
            }
          }
        }
      }
    }
  }
}