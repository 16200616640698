// Colors
$primary: #DC0000;
$primary-dark: #C9071B;
$gray-light: #EDEDED;
$gray-mid: #C4C4C4;
$gray: #D9D9D9;
$gray-600: #73738C;
$gray-500: #7F7F7F;
$gray-400: #A5A5A5;
$gray-200: #ECECEC;
$gray-50: #f8f8f8;
$gray-dark: #505050;
$gray-darkest: #282B2D;
$black: #000000;
$white: #FFFFFF;

$theme-colors: (
  "primary":        $primary,
  "primary-dark":   $primary-dark,
  "gray-light":     $gray-light,
  "gray-mid":       $gray-mid,
  "gray":           $gray,
  "gray-50":        $gray-50,
  "gray-600":       $gray-600,
  "gray-500":       $gray-500,
  "gray-400":       $gray-400,
  "gray-200":       $gray-200,
  "gray-dark":      $gray-dark,
  "gray-darkest":   $gray-darkest,
  "black":          $black,
  "white":          $white
);

// Fonts
$font-primary: 'Roboto', sans-serif;
$font-condensed: 'Roboto Condensed', sans-serif;
$font-heading-bold: 'Trade Gothic LT W01 Bd Cn No-2', sans-serif;
$font-heading-light: 'Trade Gothic LT W01 Cond No-1', sans-serif;

// Nav measurements
$navbar-height: 62px;
$banner-height: 56px;

// Angle skew
$accent-skew: -40deg;

$transition-global: 0.25s ease-in-out;