img-carousel {
  width: inherit;
  position: relative;
  overflow: hidden;
  display: block;
  margin-top: 2rem;
  margin-bottom: 4rem;
  .carousel-container {
    --x-position: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //height: 400px;
    .carousel-image {
      height: inherit;
      flex: 0 1 auto;
      margin-bottom: 1rem;
      img {
        width: 100%;
        height: auto;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: 600px) {
    .carousel-container {
      flex-wrap: nowrap;
      .carousel-image {
        margin-bottom: 0;
        margin-right: 1rem;
        &:last-of-type {
          margin-bottom: 0;
          margin-right: 0;
        }
      }
    }
  }

}
