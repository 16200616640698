.banner-container {
  .banner {
    display: block;
    width: 100%;

    &.callout-banner {
      background-color: $primary;
      font-family: $font-condensed;
      font-size: 14px;
      text-transform: none;
      color: $black;

      @media (min-width: 1200px) {
        font-size: 18px;
        padding: 10px 0;
      }
      @media (min-width: 1600px) {
        font-size: 21px;
      }

      &:hover {
        background-color: lighten($gray-darkest, 15%);
      }

      p { 
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-size: inherit; 
      }

      .font-bold {
        font-weight: 700;
      }

      .title {
        margin-right: 10px;

        @media (min-width: 768px) {
          margin-right: 15px;
        }
      }
      .cta {
        color: $primary-dark;
        text-decoration: underline;
      }
    }

    &#livestream-banner {
      color: $white;
      background-color: $primary-dark;
      padding: 10px 5px;

      @media (min-width: 768px) {
        padding: 15px 5px;
      }

      &:hover {
        background-color: $primary;
      }

      span {
        font-size: 12px;
        line-height: 1;

        @media (min-width: 420px) {
          font-size: 14px;
        }
        @media (min-width: 600px) {
          font-size: 16px;
        }

        @media (min-width: 768px) {
          font-size: 20px;
        }
      }
    }
  }
}

.power-dollars {
  .incentive-container {
    .banner-logo {
      img {
        width: 235px;
        transform: rotate(-15deg);
        transform-origin: center center;
        margin-bottom: 45px;

        @media (min-width: 1200px) {
          margin-top: -10px;
          margin-bottom: 0;
        }

        @media (min-width: 1920px) {
          width: 280px;
        }
      }
    }
  }
}


// Banner padding offsets for top of page.
// body.bracket-banner-open {
//   @media (min-width: 768px) {
//     padding-top: 120px;
//   }
//   @media (min-width: 992px) {
//     padding-top: 120px;
//   }
// }

@import 'banners/hwy93';
