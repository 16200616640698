.cdm-card {
  display: flex;
  min-height: 300px;
  background-color: @black;
  color: @white;
  text-align: center;

  &.bg-white {
    background-color: @white;
    color: @black;
  }

  &.bg-black-transparent {
    background-color: rgba(0,0,0, .75);
  }

  .cdm-card-label {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60px;
    font-size: large;
    border-right: 1px solid @light-gray;

    .cdm-card-label-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-90deg) ;
      white-space: nowrap;
    }
  }

  .cdm-card-content {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(100% - 60px);
    display: flex;
    min-height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;

    p:not(.lead) {
      margin-bottom: 0;
    }

    @media (min-width: @screen-md-min) {
      .lead {
        font-size: 3rem;
      }
    }
  }
}
