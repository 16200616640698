.comment-menu {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0,0,0,.5);
  max-width: 450px;

  .section-title, .menu-item {
    padding: 10px 20px;
  }

  .section-title {
    margin: 0;
    background-color: #F8F8F8;
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: .5px solid #BDBDBD;
    cursor: pointer;

    .fa {
      margin-right: 10px;
    }

    &.active {
      border-left: 4px solid @brand-primary;
      padding-left: 16px;

      .comment-meta {
        font-weight: bold;
      }
    }
  }
}
