&#rkn-countdown {
  $cta-button-height: 44px;

  background-image: url('/assets/images/rkn2022/smoke-background.jpg');
  background-position: center;
  color: white;
  position: relative;
  //padding-bottom: $cta-button-height;

  display: flex;
  flex-direction: column;

  .livestream-copy, .livestream-container {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .livestream-copy {
    padding: 20px;
    img {
      margin-bottom: 20px;
    }
    h2.giga {
      font-size: 6rem;
    }

    h2:last-child {
      font-size: 3rem;
      margin-bottom: 0;
    }
  }

  .livestream-container {
    padding: 20px;

    countdown {
      font-family: 'Roboto Condensed', 'Roboto', sans-serif;
      font-size: 64px;
      font-weight: bold;

      .seconds {
        color: #dc0000;
      }
    }

    #livestream-embed { //
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      width: 100%;
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (min-width: 568px) {
    .livestream-copy {
      padding: 50px;
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;


    .livestream-copy {
      h2.giga {
        font-size: 8rem;
      }

      img {
        margin-bottom: 30px;
      }
    }

    .livestream-container {
      padding: 40px;

      countdown {
        font-size: 96px;
      }
    }
  }

  @media (min-width: 1200px) {
    .livestream-container {
      padding: 40px 5vw;

      countdown {
        font-size: 128px;
      }
    }
  }
}
