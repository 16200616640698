@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}