
// CDM Page Specific Sections
#cdm-job-description {
  background-image: url('/assets/images/chief-donut-maker/donut-background.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  #now-hiring-video {
    position: relative;
    padding: 50px 65px;

    @media screen and (min-width: @screen-lg-min) {
      margin-bottom: 100px;
      padding: 100px 100px 0;
    }

    h2 {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 25px;
      transform: translateY(-50%);
    }
  }

  .video-preview {
    max-width: 1140px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
    }

    .button-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btn-rounded {
      position: static;


      &::before {
        cursor: pointer;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      @media (min-width: @screen-md-min) {
        font-size: 40px;
        width: 80px;
        height: 80px;
      }
    }
  }

  .tagline {
    color: white;
    position: absolute;
    right: -25px;
    top: 45%;

    @media (min-width: @screen-sm-min) {
      right: -50px;
      top: 50%;
    }

    @media screen and (min-width: @screen-lg-min) {
      right: -20px;
    }

  }

  #job-description {
    margin-bottom: 100px;

    .job-title {
      margin: 100px 0 40px;
      line-height: 1;

      .h2 {
        margin-bottom: 0;
      }
    }

    .black-box {
      padding: 20px;
      margin: 0 20px;
      background-color: @black;
      color: @white;

      @media (min-width: @screen-sm-min) {
        padding: 60px;
        margin: 0 50px;
      }
    }
  }


}

#cdm-job-salary {
  background-color: @black;
  color: @white;
  text-align: center;
  padding: 100px 0;

  h2 {
    margin-bottom: 0;
  }
}

.cdm-job-carousel {
  margin-bottom: 100px;
  position: relative;

  h2 {
    margin-bottom: 50px;
  }
}
