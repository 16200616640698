livestream-banner {
  flex: 0 0 100%;
}

#livestream {

  @v-spacing: 2rem;

  .header {

    @media (min-width: @screen-sm-min) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;
    }

    .heading {
      .event-title {
        font-family: @main-font;
        font-size: 2.5rem;
        margin-top: 0;
        border-bottom: solid 1px @white;
      }
      @media (min-width: @screen-sm-min) {
        flex: 1.25;
        .event-title {
          margin-bottom: 0;
        }
      }
      @media (min-width: @screen-md-min) {
        flex: 2.75;
      }
    }

    .logos {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: @v-spacing;
      margin-bottom: @v-spacing;
      .child {
        flex: 1;
        padding: 1rem;
      }
      @media (min-width: @screen-sm-min) {
        flex: 1;
        margin-top: 0;
        margin-bottom: 0;
        .child {
          padding: 0;
          &:first-of-type {
            margin-right: 2rem;
          }
        }
      }
      @media (min-width: @screen-md-min) {
        .child {
          &:nth-child(2) {
            margin-top: 0.75rem;
          }
        }
      }
      @media (min-width: 1440px) {
        .child {
          margin-left: auto;
          max-width: 150px;
        }
      }
    }


  }

  .video-module {
    margin-left: -15px;
    margin-right: -15px;
    .video-player {
      margin-bottom: @v-spacing;
    }
    .video-sidebar {

      .nav-tabs {
        display: flex;
        flex-direction: row;
        border-bottom: 2px solid @red1;
        li {
          flex: 1;
          margin-bottom: 0;
          a {
            font-family: @main-font;
            font-size: 2.5rem;
            color: fade(@darkish-gray, 35%);
            border-radius: 0;
          }
          &.active {
            a {
              color: @darkish-gray;
            }
          }
        }
      }

      .tab-pane {
        padding: 2rem 1rem;
        margin-bottom: 0;

        .event-schedule {
          margin-bottom: 1.5rem;
          &:last-of-type {
            margin-bottom: 0;
          }
          li {
            font-size: 2rem;
            color: @dark-gray;
            margin-bottom: 0.25rem;
            &.lead {
              font-family: @main-font;
              font-size: 2.25rem;
              margin-bottom: 0;
              span {
                color: @red1;
              }
            }
            &.sub {
              font-size: 2rem;
            }
            .time {
              font-family: @main-font;
            }
          }

          @media (min-width: @screen-sm-min) {
            width: 50%;
            float: left;
            &:nth-child(2) {
              float: right;
            }
          }
          @media (min-width: @screen-md-min) {
            width: 100%;
            float: none;
            &:nth-child(2) {
              float: none;
            }
          }
        }
      }

      .sponsor {
        padding: 2rem;
        text-align: center;
        span {
          display: block;
        }
        img {
          max-width: 250px;
        }

        @media (min-width: @screen-sm-min) {
          clear: both;
          padding: 3rem;
        }
      }

    }

    &.no-sidebar {
      .video-player {
        flex: 0 1 100%;
      }
      @media (min-width: @screen-md-min) {
        height: auto;
        .video-player {
          iframe {
            max-height: none;
          }
        }
      }
    }

    @media (min-width: @screen-sm-min) {
      .video-sidebar {
        margin-bottom: 4rem;
      }
    }

    @media (min-width: @screen-md-min) {
      @mod-height: 500px;

      display: flex;
      flex-direction: row;
      margin-bottom: 3rem;
      height: @mod-height;

      .video-player {
        //flex: 2.5;
        flex: 0 1 70%;
        margin-bottom: 0;
        height: inherit;
        iframe {
          max-height: @mod-height;
        }
      }
      .video-sidebar {
        flex: 0 1 30%;
        margin-bottom: 0;
        .nav-tabs {
          li {
            a {
              font-size: 1.75rem;
            }
          }
        }
        .tab-pane {
          background-color: fade(@light-gray, 15%);
          overflow-y: scroll;
          height: 400px;
          &#schedule {
            .event-schedule {
              li {
                font-size: 1.5rem;
                &.lead {
                  font-size: 2rem;
                }
                &.sub {
                  font-size: 1.75rem;
                }
              }
            }
          }
        }
        .sponsor {
          padding: 1rem;
          img {
            max-width: 150px;
          }
        }
      }
    }

    @media (min-width: 1440px) {
      @mod-height: 605px;

      height: @mod-height;

      .video-player {
        flex: 0 1 75%;
        iframe {
          max-height: @mod-height;
        }
      }
      .video-sidebar {
        flex: 0 1 25%;
        .tab-pane {
          height: 500px;
        }
      }
    }
    @media (min-width: 1600px) {
      @mod-height: 670px;

      height: @mod-height;

      .video-player {
        flex: 0 1 75%;
        iframe {
          max-height: @mod-height;
        }
      }
      .video-sidebar {
        flex: 0 1 25%;
        .tab-pane {
          height: 570px;
        }
      }
    }
  }


  #homepage-content & {
    background-image: url('/assets/images/livestream-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: @black;
    padding: 1rem 2.5rem;
    margin-bottom: 1rem;
    .video-module {
      .video-player {
        margin-bottom: 0;
      }
      .video-sidebar {
        background-color: @white;
        .nav-tabs {
          display: none;
        }
      }
    }

    @media (min-width: 600px) {
      .video-module {
        max-width: 480px;
        margin: 0 auto;
      }
    }
    @media (min-width: @screen-sm-min) {
      padding: 2.5rem;
      .video-module {
        max-width: 640px;
      }
    }
    @media (min-width: @screen-md-min) {
      .video-module {
        max-width: 960px;
        .video-sidebar {
          .tab-content {
            .tab-pane {
              height: 440px;
            }
          }
        }
      }
    }
    @media (min-width: @screen-lg-min) {
      .video-module {
        max-width: 1024px;
        height: 500px;

        &.no-sidebar {
          max-width: 889px;
        }
        .video-player {
          height: inherit;
          .embed-responsive-item {
            height: inherit;
            max-height: 500px;
          }
        }
      }
    }
    @media (min-width: 1440px) {
      .video-module {
        max-width: 1200px;
      }
    }
  }

  &.unsponsored {
    .video-module {
      .video-sidebar {
        height: 100%;
        
        .nav-tabs {
          display: none;
        }
        .tab-content {
          height: inherit;
          .tab-pane {
            height: inherit !important;
          }
        }
      }
    }
  }

}
