@import '~swiper/swiper.less';

#cdm-carousel {
  overflow: visible;

  .swiper-slide {
    width: 80%;
  }


  .cdm-card-content p {
    max-width: 600px;
  }

}

.carousel-wrap {
  position: relative;

  .carousel-button {
    display: none;
    position: absolute;
    height: 50px;
    width: 50px;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    font-size: 64px;
    color: @white;
    border: 0;

    &.swiper-button-disabled {
      color: @gray;
      cursor: not-allowed;
    }


    &#carousel-next {
      right: 4vw;
    }

    &#carousel-prev {
      left: 4vw;
    }

    @media (min-width: @screen-sm-min) {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
    }
  }
}

#cdm-carousel, #cdm-elimination-carousel  {
  .iframe-loading {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  iframe {
    z-index: 2;
    -webkit-transform:translate3d(0,0,0);
  }

  .swiper-pagination {
    position: absolute;
    bottom: -60px;
    width: 100%;
    text-align: center;
  }

  .carousel-nav-item {
    display: inline-block;
    height: 10px;
    width: 40px;
    background-color: rgba(255, 255, 255, .75);
    margin-right: 20px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background-color: rgb(255, 255, 255);
    }
  }
}

#cdm-elimination-carousel  {

  swiper {
    padding-bottom: 60px;
  }

  .swiper-pagination {
    bottom: 0;
    z-index: 10;
    counter-reset: episode 4;
  }
  .carousel-nav-item {
    counter-increment: episode -1;
    &::after {
      content: counter(episode);
      padding-top: 15px;
      display: block;
      font-size: 20px;
    }
  }
}
