/* .bs-datepicker */
.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: #fff;
  box-shadow: 0 0 10px 0 #aaa;
  position: relative;
  z-index: 1;
  /* button */
  /* .bs-datepicker-head */
  /* .bs-datepicker-body */
  /* .current-timedate */
  /* .bs-datepicker-multiple */
  /* .bs-datepicker-btns */
  /*.bs-datepicker-custom-range */
  /* .bs-datepicker-predefined-btns */
  /* .bs-datepicker-buttons */
}

.bs-datepicker:after {
  clear: both;
  content: '';
  display: block;
}

.bs-datepicker bs-day-picker {
  float: left;
}

.bs-datepicker button:hover,
.bs-datepicker button:focus,
.bs-datepicker button:active,
.bs-datepicker input:hover,
.bs-datepicker input:focus,
.bs-datepicker input:active,
.bs-datepicker-btns button:hover,
.bs-datepicker-btns button:focus,
.bs-datepicker-btns button:active,
.bs-datepicker-predefined-btns button:active,
.bs-datepicker-predefined-btns button:focus {
  outline: none;
}

.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  text-align: justify;
  /* .bs-datepicker-head button */
}

.bs-datepicker-head:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.bs-datepicker-head button {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  background: transparent;
  text-align: center;
  cursor: pointer;
  color: #fff;
  transition: 0.3s;
}

.bs-datepicker-head button[disabled], .bs-datepicker-head button[disabled]:hover, .bs-datepicker-head button[disabled]:active {
  background: rgba(221, 221, 221, 0.3);
  color: #f5f5f5;
  cursor: not-allowed;
}

.bs-datepicker-head button.next, .bs-datepicker-head button.previous {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.bs-datepicker-head button.next span, .bs-datepicker-head button.previous span {
  font-size: 28px;
  line-height: 1;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.bs-datepicker-head button.current {
  border-radius: 15px;
  max-width: 155px;
  padding: 0 13px;
}

.bs-datepicker-head button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.bs-datepicker-head button:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.bs-datepicker-body {
  padding: 10px;
  border-radius: 0 0 3px 3px;
  min-height: 232px;
  min-width: 278px;
  border: 1px solid #e9edf0;
  /* .bs-datepicker-body table */
}

.bs-datepicker-body .days.weeks {
  position: relative;
  z-index: 1;
}

.bs-datepicker-body table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  /* .bs-datepicker-body table.days */
  /* .bs-datepicker-body table.weeks */
}

.bs-datepicker-body table th {
  font-size: 13px;
  color: #9aaec1;
  font-weight: 400;
  text-align: center;
}

.bs-datepicker-body table td {
  color: #54708b;
  text-align: center;
  position: relative;
  padding: 0;
}

.bs-datepicker-body table td span {
  display: block;
  margin: 0 auto;
  font-size: 13px;
  border-radius: 50%;
  position: relative;
  /*z-index: 1;*/
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
  cursor: pointer;
}

.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #e9edf0;
  transition: 0s;
}

.bs-datepicker-body table td.is-active-other-month:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-active-other-month:not(.disabled):not(.selected) {
  background-color: #e9edf0;
  transition: 0s;
  cursor: pointer;
}

.bs-datepicker-body table td span.disabled,
.bs-datepicker-body table td.disabled span {
  color: #9aaec1;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span {
  color: #fff;
}

.bs-datepicker-body table td span.is-other-month,
.bs-datepicker-body table td.is-other-month span {
  color: rgba(0, 0, 0, 0.25);
}

.bs-datepicker-body table td.active {
  position: relative;
}

.bs-datepicker-body table td.active.select-start:before {
  left: 35%;
}

.bs-datepicker-body table td.active.select-end:before {
  left: -85%;
}

.bs-datepicker-body table td span.active.select-start:after,
.bs-datepicker-body table td span.active.select-end:after,
.bs-datepicker-body table td.active.select-start span:after,
.bs-datepicker-body table td.active.select-end span:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  top: 0;
  border-radius: 50%;
}

.bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 6px;
  bottom: 6px;
  left: -2px;
  right: -2px;
  box-sizing: content-box;
  background: transparent;
}

.bs-datepicker-body table td.active.select-start + td.active:before {
  left: -20%;
}

.bs-datepicker-body table td:last-child.active:before {
  border-radius: 0 3px 3px 0;
  width: 125%;
  left: -25%;
}

.bs-datepicker-body table td span[class*="select-"],
.bs-datepicker-body table td[class*="select-"] span {
  border-radius: 50%;
  color: #fff;
}

.bs-datepicker-body table.days td.active:not(.select-start):before, .bs-datepicker-body table.days td.in-range:not(.select-start):before,
.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before {
  background: #e9edf0;
}

.bs-datepicker-body table.days span {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.bs-datepicker-body table.days span.select-start {
  z-index: 2;
}

.bs-datepicker-body table.days span.is-highlighted.in-range:before, .bs-datepicker-body table.days span.in-range.select-end:before {
  background: none;
  right: 0;
  left: 0;
}

.bs-datepicker-body table.days td.select-start + td.select-end:before,
.bs-datepicker-body table.days td.select-start + td.is-highlighted:before,
.bs-datepicker-body table.days td.active + td.is-highlighted:before,
.bs-datepicker-body table.days td.active + td.select-end:before,
.bs-datepicker-body table.days td.in-range + td.is-highlighted:before,
.bs-datepicker-body table.days td.in-range + td.select-end:before {
  background: #e9edf0;
  width: 100%;
}

.bs-datepicker-body table.weeks tr td:nth-child(2).active:before {
  border-radius: 3px 0 0 3px;
  left: 0;
  width: 100%;
}

.bs-datepicker-body table:not(.weeks) tr td:first-child:before {
  border-radius: 3px 0 0 3px;
}

.bs-datepicker-body table.years td span {
  width: 46px;
  height: 46px;
  line-height: 45px;
  margin: 0 auto;
}

.bs-datepicker-body table.years tr:not(:last-child) td span {
  margin-bottom: 8px;
}

.bs-datepicker-body table.months td {
  height: 52px;
}

.bs-datepicker-body table.months td span {
  padding: 6px;
  border-radius: 15px;
}

.bs-datepicker .current-timedate {
  color: #54708b;
  font-size: 15px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid #e9edf0;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: uppercase;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.bs-datepicker .current-timedate span:not(:empty):before {
  content: "";
  width: 15px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
}

.bs-datepicker-multiple {
  border-radius: 4px 0 0 4px;
}

.bs-datepicker-multiple + .bs-datepicker-multiple {
  margin-left: 10px;
}

.bs-datepicker-multiple .bs-datepicker {
  box-shadow: none;
  position: relative;
}

.bs-datepicker-multiple .bs-datepicker:not(:last-child) {
  padding-right: 10px;
}

.bs-datepicker-multiple .bs-datepicker + .bs-datepicker:after {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
  position: absolute;
  top: 25px;
  left: -8px;
}

.bs-datepicker-multiple .bs-datepicker .left {
  float: left;
}

.bs-datepicker-multiple .bs-datepicker .right {
  float: right;
}

.bs-datepicker-container {
  padding: 15px;
}

.bs-datepicker .bs-media-container {
  display: flex;
}

@media (max-width: 768px) {
  .bs-datepicker .bs-media-container {
    flex-direction: column;
  }
}

.bs-datepicker-custom-range {
  padding: 15px;
  background: #eee;
}

.bs-datepicker-predefined-btns button {
  width: 100%;
  display: block;
  height: 30px;
  background-color: #9aaec1;
  border-radius: 4px;
  color: #fff;
  border: 0;
  margin-bottom: 10px;
  padding: 0 18px;
  text-align: left;
  transition: 0.3s;
}

.bs-datepicker-predefined-btns button:hover {
  background-color: #54708b;
}

.bs-datepicker-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #e9edf0;
}

.bs-datepicker-buttons .btn-default {
  margin-left: 10px;
}

/* .bs-timepicker */
.bs-timepicker-container {
  padding: 10px 0;
}

.bs-timepicker-label {
  color: #54708b;
  margin-bottom: 10px;
}

.bs-timepicker-controls {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.bs-timepicker-controls button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  background-color: #e9edf0;
  color: #54708b;
  font-size: 16px;
  font-weight: 700;
  vertical-align: middle;
  line-height: 0;
  padding: 0;
  transition: 0.3s;
}

.bs-timepicker-controls button:hover {
  background-color: #d5dadd;
}

.bs-timepicker-controls input {
  width: 35px;
  height: 25px;
  border-radius: 13px;
  text-align: center;
  border: 1px solid #e9edf0;
}

.bs-timepicker .switch-time-format {
  text-transform: uppercase;
  min-width: 54px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #e9edf0;
  background: #fff;
  color: #54708b;
  font-size: 13px;
}

.bs-timepicker .switch-time-format img {
  vertical-align: initial;
  margin-left: 4px;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1080;
}

/* screen size < 1024px */
@media (max-width: 768px) {
  .bs-datepicker-multiple {
    display: flex;
  }
  .bs-datepicker-multiple + .bs-datepicker-multiple {
    margin-top: 10px;
    margin-left: 0;
  }
}

/* theming */
.theme-default .bs-datepicker-head {
  background-color: #777;
}

.theme-default .bs-datepicker-predefined-btns button.selected {
  background-color: #777;
}

.theme-default .bs-datepicker-body table td span.selected,
.theme-default .bs-datepicker-body table td.selected span,
.theme-default .bs-datepicker-body table td span[class*="select-"]:after,
.theme-default .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #777;
}

.theme-default .bs-datepicker-body table td.week span {
  color: #777;
}

.theme-default .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #777;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-green .bs-datepicker-head {
  background-color: #5cb85c;
}

.theme-green .bs-datepicker-predefined-btns button.selected {
  background-color: #5cb85c;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #5cb85c;
}

.theme-green .bs-datepicker-body table td.week span {
  color: #5cb85c;
}

.theme-green .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #5cb85c;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-blue .bs-datepicker-head {
  background-color: #5bc0de;
}

.theme-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #5bc0de;
}

.theme-blue .bs-datepicker-body table td span.selected,
.theme-blue .bs-datepicker-body table td.selected span,
.theme-blue .bs-datepicker-body table td span[class*="select-"]:after,
.theme-blue .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #5bc0de;
}

.theme-blue .bs-datepicker-body table td.week span {
  color: #5bc0de;
}

.theme-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #5bc0de;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-dark-blue .bs-datepicker-head {
  background-color: #337ab7;
}

.theme-dark-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #337ab7;
}

.theme-dark-blue .bs-datepicker-body table td span.selected,
.theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td span[class*="select-"]:after,
.theme-dark-blue .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #337ab7;
}

.theme-dark-blue .bs-datepicker-body table td.week span {
  color: #337ab7;
}

.theme-dark-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #337ab7;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-red .bs-datepicker-head {
  background-color: #d9534f;
}

.theme-red .bs-datepicker-predefined-btns button.selected {
  background-color: #d9534f;
}

.theme-red .bs-datepicker-body table td span.selected,
.theme-red .bs-datepicker-body table td.selected span,
.theme-red .bs-datepicker-body table td span[class*="select-"]:after,
.theme-red .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #d9534f;
}

.theme-red .bs-datepicker-body table td.week span {
  color: #d9534f;
}

.theme-red .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #d9534f;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-orange .bs-datepicker-head {
  background-color: #f0ad4e;
}

.theme-orange .bs-datepicker-predefined-btns button.selected {
  background-color: #f0ad4e;
}

.theme-orange .bs-datepicker-body table td span.selected,
.theme-orange .bs-datepicker-body table td.selected span,
.theme-orange .bs-datepicker-body table td span[class*="select-"]:after,
.theme-orange .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #f0ad4e;
}

.theme-orange .bs-datepicker-body table td.week span {
  color: #f0ad4e;
}

.theme-orange .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #f0ad4e;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body.fixed-nav {
  padding-top: 62px !important;
}

@media (max-width: 1199px) {
  body.fixed-hero-active {
    padding-top: 62px !important;
  }
}

.site-wrapper-xl {
  max-width: 1920px;
  margin: 0 auto;
}

.section-half {
  padding-top: 35px;
}

@media (min-width: 992px) {
  .section-half {
    display: flex;
  }
}

.section-half .split:nth-child(2n) {
  padding-top: 35px;
}

@media (min-width: 992px) {
  .section-half .split {
    flex: 0 0 50%;
  }
  .section-half .split:nth-child(2n) {
    padding-top: 0;
  }
}

@media (min-width: 992px) {
  .section-split {
    display: flex;
  }
  .section-split .three-fourths {
    flex: 0 0 75%;
  }
  .section-split .three-fourths.power-dollars {
    order: 0;
  }
  .section-split .one-fourth {
    flex: 0 0 25%;
  }
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

main {
  background-color: #FFFFFF;
}

.relative {
  position: relative;
}

.mobile-hidden {
  display: none !important;
}

sup.scaled {
  font-size: 50%;
  top: -0.75em;
}

body.single-article main {
  min-height: 100vh;
}

@media (max-width: 1200px) {
  #onetrust-banner-sdk #onetrust-accept-btn-handler,
  #onetrust-banner-sdk #onetrust-reject-all-handler,
  #onetrust-banner-sdk #onetrust-pc-btn-handler,
  #onetrust-pc-sdk .save-preference-btn-handler {
    font-size: 16px !important;
  }
}

.bg-primary {
  background-color: #DC0000 !important;
}

.bg-primary-dark {
  background-color: #C9071B !important;
}

.bg-gray-light {
  background-color: #EDEDED !important;
}

.bg-gray-mid {
  background-color: #C4C4C4 !important;
}

.bg-gray {
  background-color: #D9D9D9 !important;
}

.bg-gray-50 {
  background-color: #f8f8f8 !important;
}

.bg-gray-600 {
  background-color: #73738C !important;
}

.bg-gray-500 {
  background-color: #7F7F7F !important;
}

.bg-gray-400 {
  background-color: #A5A5A5 !important;
}

.bg-gray-200 {
  background-color: #ECECEC !important;
}

.bg-gray-dark {
  background-color: #505050 !important;
}

.bg-gray-darkest {
  background-color: #282B2D !important;
}

.bg-black {
  background-color: #000000 !important;
}

.bg-white {
  background-color: #FFFFFF !important;
}

body.dg-eu {
  padding-top: 0 !important;
}

#primary-nav {
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0;
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  #primary-nav {
    position: fixed;
  }
}

@media (min-width: 1200px) {
  #primary-nav:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100vw;
    height: 62px;
    background: linear-gradient(to right, #C9071B 50%, #505050 50%);
  }
}

.fixed-nav #primary-nav {
  position: fixed;
}

#primary-nav .navbar-brand:focus {
  background-color: #282B2D;
}

#primary-nav .navbar-header {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  max-width: 1920px;
  height: 62px;
  background-color: #000000;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  #primary-nav .navbar-header {
    font-size: 16px;
  }
}

#primary-nav .navbar-header .navbar-content {
  display: flex;
}

#primary-nav .navbar-header .navbar-content .control {
  flex: 0 0 62px;
  position: relative;
  display: flex;
  order: 1;
}

@media (min-width: 992px) {
  #primary-nav .navbar-header .navbar-content .control {
    flex: 0 0 90px;
  }
}

@media (min-width: 1200px) {
  #primary-nav .navbar-header .navbar-content .control {
    flex: 0 0 30px;
  }
}

@media (min-width: 1440px) {
  #primary-nav .navbar-header .navbar-content .control {
    flex: 0 0 75px;
  }
}

#primary-nav .navbar-header .navbar-content .control:before, #primary-nav .navbar-header .navbar-content .control:after {
  content: '';
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  height: 100%;
  display: block;
  transform: skew(-40deg);
  background-color: #C9071B;
}

#primary-nav .navbar-header .navbar-content .control:before {
  right: -10px;
  width: 200px;
}

#primary-nav .navbar-header .navbar-content .control:after {
  right: -45px;
  width: 25px;
}

#primary-nav .navbar-header .navbar-content .control .navbar-toggle {
  background-color: rgba(0, 0, 0, 0);
  color: white;
  margin: 0;
  border: 0;
  border-radius: 0;
  width: 100%;
  font-size: 20px;
}

#primary-nav .navbar-header .navbar-content .control .navbar-toggle:hover {
  background-color: rgba(0, 0, 0, 0);
}

@media (min-width: 768px) {
  #primary-nav .navbar-header .navbar-content .control .navbar-toggle {
    display: block;
  }
}

#primary-nav .navbar-header .navbar-content .navbar-collapse {
  display: none;
  order: 0;
  z-index: 1;
  background-color: #C9071B;
  border: 0;
}

#primary-nav .navbar-header .navbar-content .navbar-collapse.in-view {
  display: block;
}

@media (max-width: 1199px) {
  #primary-nav .navbar-header .navbar-content .navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    padding-top: 62px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 60%, rgba(0, 0, 0, 0.7));
  }
}

#primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav {
  display: flex;
  flex-direction: row;
  height: 100%;
  animation: fade-in 0.25s ease-in-out forwards;
}

@media (max-width: 1199px) {
  #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav {
    flex-direction: column;
    overflow: auto;
    padding: 25px 0;
  }
  #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav::-webkit-scrollbar-thumb {
    background: rgba(80, 80, 80, 0.5);
  }
  #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav::-webkit-scrollbar-track {
    background: rgba(80, 80, 80, 0.05);
  }
  #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav {
    scrollbar-color: rgba(80, 80, 80, 0.5) rgba(80, 80, 80, 0.05);
    /* thumb and track color */
    scrollbar-width: thin;
  }
  #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav {
    scrollbar-face-color: rgba(80, 80, 80, 0.5);
    scrollbar-track-color: rgba(80, 80, 80, 0.05);
  }
}

#primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li {
  font-size: 16px;
  line-height: 1;
}

@media (max-width: 1199px) {
  #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li {
    font-size: 20px;
    padding: 10px 40px;
  }
}

#primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li a, #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li div.header-nav {
  display: flex;
  align-items: center;
  height: 100%;
  color: #FFFFFF;
  font-size: inherit;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 15px;
}

@media (max-width: 1199px) {
  #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li a, #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li div.header-nav {
    height: auto;
    padding: 10px;
    padding-left: 0;
  }
}

#primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li .dropdown-menu {
  padding: 0;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  animation: fade-in 0.25s ease-in-out forwards;
}

@media (max-width: 1199px) {
  #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li .dropdown-menu {
    max-width: 400px;
  }
}

#primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li .dropdown-menu li {
  font-size: 14px;
}

@media (max-width: 1199px) {
  #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li .dropdown-menu li {
    font-size: 16px;
    padding-left: 15px;
    border-left: 1px solid #C9071B;
  }
}

#primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li .dropdown-menu li a {
  display: block;
  font-size: inherit;
  color: #FFFFFF;
  background-color: #505050;
  padding: 10px 15px;
}

@media (max-width: 1199px) {
  #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li .dropdown-menu li a {
    background-color: rgba(0, 0, 0, 0);
  }
}

#primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li .dropdown-menu li a:hover, #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li .dropdown-menu li a:focus, #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li .dropdown-menu li a.active {
  color: #000000;
  background-color: #FFFFFF;
}

#primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li.open > a, #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li.open > div.header-nav {
  background-color: #505050;
}

@media (max-width: 1199px) {
  #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li.open > a, #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li.open > div.header-nav {
    background-color: rgba(0, 0, 0, 0);
  }
}

@media (max-width: 1199px) {
  #primary-nav .navbar-header .navbar-content .navbar-collapse .navbar-nav > li.open .dropdown-menu {
    position: static;
  }
}

#primary-nav .navbar-header .logo-container {
  flex: 1 1 auto;
  padding-left: 85px;
}

@media (min-width: 992px) {
  #primary-nav .navbar-header .logo-container {
    flex: 0 1 auto;
    padding-left: 115px;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  #primary-nav .navbar-header .logo-container {
    padding-left: 50px;
  }
}

@media (min-width: 1440px) {
  #primary-nav .navbar-header .logo-container {
    padding-left: 115px;
  }
}

#primary-nav .navbar-header .logo-container a {
  display: block;
  height: 100%;
  padding: 10px 15px;
  float: none !important;
}

#primary-nav .navbar-header .logo-container a img {
  height: 42px;
  width: auto;
}

#primary-nav .navbar-header .user-controls {
  display: none;
  flex: 0 1 auto;
  position: relative;
  max-width: 200px;
}

@media (min-width: 768px) {
  #primary-nav .navbar-header .user-controls {
    display: block;
    margin-left: 35px;
  }
}

#primary-nav .navbar-header .user-controls:before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 75%;
  height: 100%;
  display: block;
  transform: skew(-40deg);
  background-color: #505050;
  transition: all 0.25s ease-in-out;
}

#primary-nav .navbar-header .user-controls:after {
  content: '';
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  width: 75%;
  height: 100%;
  display: block;
  background-color: #505050;
  transition: all 0.25s ease-in-out;
}

#primary-nav .navbar-header .user-controls:hover:before, #primary-nav .navbar-header .user-controls:hover:after {
  background-color: #373636;
}

#primary-nav .navbar-header .user-controls ul {
  margin-bottom: 0;
  padding-left: 0;
  height: 100%;
}

#primary-nav .navbar-header .user-controls ul li {
  height: inherit;
  font-size: inherit;
}

#primary-nav .navbar-header .user-controls ul li a {
  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-size: 1em;
  color: #FFFFFF;
  text-decoration: none;
  text-transform: uppercase;
}

#primary-nav .navbar-header .user-controls ul li a span {
  flex: 0 0 100%;
  z-index: 1;
  text-align: right;
}

#primary-nav .navbar-header .user-controls ul li a:focus {
  color: #DC0000;
}

#primary-nav .navbar-header .user-controls ul li .dropdown-menu {
  padding: 0;
  border: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0);
  animation: fade-in 0.25s ease-in-out forwards;
}

@media (max-width: 1199px) {
  #primary-nav .navbar-header .user-controls ul li .dropdown-menu {
    max-width: 400px;
  }
}

#primary-nav .navbar-header .user-controls ul li .dropdown-menu li {
  font-size: 14px;
  height: auto;
}

@media (max-width: 1199px) {
  #primary-nav .navbar-header .user-controls ul li .dropdown-menu li {
    font-size: 16px;
    padding-left: 15px;
    border-left: 1px solid #C9071B;
  }
}

#primary-nav .navbar-header .user-controls ul li .dropdown-menu li a {
  display: block;
  font-size: inherit;
  color: #FFFFFF;
  background-color: #505050;
  padding: 10px 15px;
}

@media (max-width: 1199px) {
  #primary-nav .navbar-header .user-controls ul li .dropdown-menu li a {
    background-color: rgba(0, 0, 0, 0);
  }
}

#primary-nav .navbar-header .user-controls ul li .dropdown-menu li a:hover, #primary-nav .navbar-header .user-controls ul li .dropdown-menu li a:focus, #primary-nav .navbar-header .user-controls ul li .dropdown-menu li a.active {
  color: #000000;
  background-color: #FFFFFF;
}

#primary-nav .navbar-header .nav-item-visible {
  display: none;
}

@media (min-width: 1200px) {
  #primary-nav .navbar-header .nav-item-visible {
    display: block;
  }
}

#primary-nav .navbar-header .nav-item-visible.mobile {
  display: block;
}

@media (min-width: 1200px) {
  #primary-nav .navbar-header .nav-item-visible.mobile {
    display: none;
  }
}

#primary-nav .navbar-header .nav-item-visible a {
  display: flex;
  align-items: center;
  height: 100%;
  color: #FFFFFF;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 10px;
}

#primary-nav .navbar-header .nav-item-visible a:hover, #primary-nav .navbar-header .nav-item-visible a:focus {
  color: #DC0000;
}

#primary-nav .navbar-header .nav-item.mobile-only {
  display: block;
}

@media (min-width: 768px) {
  #primary-nav .navbar-header .nav-item.mobile-only {
    display: none;
  }
}

.fixed-nav {
  padding-top: 118px;
}

.banner-container .banner {
  display: block;
  width: 100%;
}

.banner-container .banner.callout-banner {
  background-color: #DC0000;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  text-transform: none;
  color: #000000;
}

@media (min-width: 1200px) {
  .banner-container .banner.callout-banner {
    font-size: 18px;
    padding: 10px 0;
  }
}

@media (min-width: 1600px) {
  .banner-container .banner.callout-banner {
    font-size: 21px;
  }
}

.banner-container .banner.callout-banner:hover {
  background-color: #4c5256;
}

.banner-container .banner.callout-banner p {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: inherit;
}

.banner-container .banner.callout-banner .font-bold {
  font-weight: 700;
}

.banner-container .banner.callout-banner .title {
  margin-right: 10px;
}

@media (min-width: 768px) {
  .banner-container .banner.callout-banner .title {
    margin-right: 15px;
  }
}

.banner-container .banner.callout-banner .cta {
  color: #C9071B;
  text-decoration: underline;
}

.banner-container .banner#livestream-banner {
  color: #FFFFFF;
  background-color: #C9071B;
  padding: 10px 5px;
}

@media (min-width: 768px) {
  .banner-container .banner#livestream-banner {
    padding: 15px 5px;
  }
}

.banner-container .banner#livestream-banner:hover {
  background-color: #DC0000;
}

.banner-container .banner#livestream-banner span {
  font-size: 12px;
  line-height: 1;
}

@media (min-width: 420px) {
  .banner-container .banner#livestream-banner span {
    font-size: 14px;
  }
}

@media (min-width: 600px) {
  .banner-container .banner#livestream-banner span {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .banner-container .banner#livestream-banner span {
    font-size: 20px;
  }
}

.power-dollars .incentive-container .banner-logo img {
  width: 235px;
  transform: rotate(-15deg);
  transform-origin: center center;
  margin-bottom: 45px;
}

@media (min-width: 1200px) {
  .power-dollars .incentive-container .banner-logo img {
    margin-top: -10px;
    margin-bottom: 0;
  }
}

@media (min-width: 1920px) {
  .power-dollars .incentive-container .banner-logo img {
    width: 280px;
  }
}

#hwy93-banner {
  display: flex;
  background-image: url("/assets/images/hero/hwy93/highway-hero-md.jpg");
  background-position: center;
}

@media (min-width: 600px) {
  #hwy93-banner {
    background-position: -100px center;
  }
}

@media (min-width: 768px) {
  #hwy93-banner {
    background-position: center center;
  }
}

@media (min-width: 992px) {
  #hwy93-banner {
    background-image: url("/assets/images/hero/hwy93/highway-hero-xl.jpg");
  }
}

@media (min-width: 1600px) {
  #hwy93-banner {
    background-position: center top;
  }
}

#hwy93-banner .hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  padding: 20px;
}

#hwy93-banner .hero-content h3 {
  padding: 10px;
  background-color: #000;
  color: #fff;
  margin-bottom: 20px;
}

#hwy93-banner .hero-content img {
  width: 100px;
  margin-bottom: auto;
}

#hwy93-banner .hero-content .heading-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 350px;
}

@media (min-width: 992px) {
  #hwy93-banner .hero-content {
    flex-direction: row;
  }
}

#hwy93-banner .video-cta {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 600px) {
  #hwy93-banner .site-wrapper-xl {
    width: 100%;
  }
  #hwy93-banner .hero-content {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
  #hwy93-banner .hero-content img {
    width: 200px;
    margin-bottom: auto;
  }
  #hwy93-banner .video-cta {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #hwy93-banner .video-cta button:focus {
    outline: none;
  }
}

@media (min-width: 992px) {
  #hwy93-banner .hero-content img {
    margin: 20px 0;
  }
  #hwy93-banner h3 {
    padding: 20px;
    margin-bottom: 40px;
  }
  #hwy93-banner .video-cta .fa-stack {
    font-size: 250%;
  }
}

@media (min-width: 1200px) {
  #hwy93-banner .hero-content img {
    width: 250px;
    margin: 40px 0;
  }
}

@media (min-width: 1400px) {
  #hwy93-banner .hero-content .heading-container {
    margin-left: 5%;
  }
}

body.modal-open .modal .modal-dialog.modal-video .modal-content .modal-header {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

body.modal-open .modal .modal-dialog.modal-video .modal-content .modal-header .modal-title {
  flex: 0 0 100%;
  order: 1;
  padding: 0 2.5rem;
}

body.modal-open .modal .modal-dialog.modal-video .modal-content .modal-header .modal-close {
  position: static;
  margin-left: auto;
  padding: 0 10px;
}

body.modal-open .modal .modal-dialog.modal-video .modal-content .modal-body {
  padding-top: 0;
}

body.modal-open .modal .modal-dialog.modal-video .modal-content .modal-body .description {
  margin-top: 0;
}

body.modal-open .modal .modal-dialog.modal-video .modal-content .modal-body .embed-responsive {
  margin-bottom: 0 !important;
}

body.modal-open .modal .modal-dialog.modal-full .modal-content {
  padding-top: 60px;
}

body.modal-open .modal .modal-dialog.modal-full .modal-content .modal-header .modal-close {
  top: 25px;
  right: 25px;
  line-height: 0;
}

.modal#news-modal .modal-dialog, .modal#event-modal .modal-dialog {
  padding-top: 0;
}

body.fixed-nav .modal#news-modal .modal-dialog, body.fixed-nav .modal#event-modal .modal-dialog {
  padding-top: 62px;
}

.modal#news-modal .modal-dialog .modal-close-container, .modal#event-modal .modal-dialog .modal-close-container {
  top: 35px;
}

body.fixed-nav .modal#news-modal .modal-dialog .modal-close-container, body.fixed-nav .modal#event-modal .modal-dialog .modal-close-container {
  top: calc(62px + 35px);
}

.modal .package-modal, .modal .allocation-modal {
  max-width: 480px;
}

.modal .package-modal .modal-content .modal-header, .modal .allocation-modal .modal-content .modal-header {
  display: flex;
  align-items: flex-start !important;
  padding: 1rem 1.5rem;
  padding-top: 0;
  padding-right: 0;
}

.modal .package-modal .modal-content .modal-header p, .modal .allocation-modal .modal-content .modal-header p {
  margin-bottom: 0;
}

.modal .package-modal .modal-content .modal-header .modal-close, .modal .allocation-modal .modal-content .modal-header .modal-close {
  position: static;
  line-height: 0;
  padding: 0.75rem;
  margin-left: auto;
}

.modal .package-modal .modal-content .modal-body, .modal .allocation-modal .modal-content .modal-body {
  padding: 1.5rem;
  padding-top: 0;
}

.modal .package-modal .modal-content .modal-body ul, .modal .allocation-modal .modal-content .modal-body ul {
  padding-left: 2.25rem;
}

.modal .package-modal.features-modal .modal-content, .modal .features-modal.allocation-modal .modal-content {
  padding: 2rem;
}

.modal .allocation-modal {
  width: 90%;
  max-width: 1200px !important;
}

.modal .allocation-modal .modal-content .modal-body .intro {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .modal .allocation-modal .modal-content .modal-body .intro {
    flex-direction: row;
  }
}

.modal .allocation-modal .modal-content .modal-body .intro .logo {
  max-width: 110px;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .modal .allocation-modal .modal-content .modal-body .intro .logo {
    flex: 0 0 110px;
    max-width: none;
    margin: 0;
    margin-right: 2rem;
    margin-bottom: 0;
  }
}

@media (min-width: 1440px) {
  .modal .allocation-modal .modal-content .modal-body .intro .logo {
    flex: 0 0 130px;
  }
}

.modal .allocation-modal .modal-content .modal-body .intro .logo img {
  width: 100%;
  height: auto;
}

.modal .allocation-modal .modal-content .modal-body .intro .description {
  flex: 1;
}

.modal .allocation-modal .modal-content .modal-body .intro .description p {
  font-size: 1.4rem;
}

@media (min-width: 1200px) {
  .modal .allocation-modal .modal-content .modal-body .intro .description p {
    font-size: 1.6rem;
  }
}

.modal .allocation-modal .modal-content .modal-body .intro .description p.heading-sm {
  font-size: 2rem;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .modal .allocation-modal .modal-content .modal-body .intro .description p.heading-sm {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }
}

.modal .allocation-modal .modal-content .modal-body .dealerships {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}

@media (min-width: 1200px) {
  .modal .allocation-modal .modal-content .modal-body .dealerships {
    gap: 1.5rem;
  }
}

.modal .allocation-modal .modal-content .modal-body .dealerships .province-group {
  flex: 0 0 100%;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}

.modal .allocation-modal .modal-content .modal-body .dealerships .province-group .province-wrap {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
}

.modal .allocation-modal .modal-content .modal-body .dealerships .province-group .dealership-wrap {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.modal .allocation-modal .modal-content .modal-body .dealerships .province-group .province-heading {
  flex: 0 0 calc((100% / 3) - 1.5rem);
  font-family: "Trade Gothic LT W01 Bd Cn No-2", sans-serif;
  font-size: 1.6rem;
  text-align: center;
  text-transform: uppercase;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #000000;
}

@media (min-width: 1200px) {
  .modal .allocation-modal .modal-content .modal-body .dealerships .province-group .province-heading {
    font-size: 2rem;
  }
}

.modal .allocation-modal .modal-content .modal-body .dealerships .province-group .dealership {
  flex: 0 0 100%;
  display: flex;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .modal .allocation-modal .modal-content .modal-body .dealerships .province-group .dealership {
    flex: 0 0 calc((100% / 2) - 1.5rem);
  }
}

@media (min-width: 1200px) {
  .modal .allocation-modal .modal-content .modal-body .dealerships .province-group .dealership {
    flex: 0 0 calc((100% / 3) - 1.5rem);
  }
}

.modal .allocation-modal .modal-content .modal-body .dealerships .province-group .dealership.disabled-link a {
  pointer-events: none !important;
  cursor: default !important;
}

.modal .allocation-modal .modal-content .modal-body .dealerships .province-group .dealership .orders {
  display: flex;
  flex-direction: column;
  background-color: #DC0000;
  padding: 0.5rem 1rem;
}

.modal .allocation-modal .modal-content .modal-body .dealerships .province-group .dealership .orders span {
  font-family: "Trade Gothic LT W01 Cond No-1", sans-serif;
  font-size: 1.6rem;
  line-height: 1.2;
  color: #FFFFFF;
  text-transform: uppercase;
  text-align: center;
}

.modal .allocation-modal .modal-content .modal-body .dealerships .province-group .dealership .orders span.amount {
  font-family: "Trade Gothic LT W01 Bd Cn No-2", sans-serif;
  font-size: 2.8rem;
}

.modal .allocation-modal .modal-content .modal-body .dealerships .province-group .dealership .info {
  flex: 1;
  padding: 0 1rem;
}

.modal .allocation-modal .modal-content .modal-body .dealerships .province-group .dealership .info p {
  font-size: 1.6rem;
  line-height: 1.2;
  text-transform: uppercase;
}

.modal .allocation-modal .modal-content .modal-body .dealerships .province-group .dealership .info p.name {
  font-family: "Trade Gothic LT W01 Bd Cn No-2", sans-serif;
  font-size: 1.8rem;
  color: #DC0000;
  margin-bottom: 0.75rem;
}

.modal .allocation-modal .modal-content .modal-body .dealerships .province-group .dealership .info p.location {
  font-family: "Trade Gothic LT W01 Cond No-1", sans-serif;
  margin-bottom: 0;
}

.modal .allocation-modal .modal-content .modal-body .dealerships .province-group .dealership .info .dealer-link {
  text-decoration: none;
}

.modal .video-modal {
  width: 100%;
  max-width: 1200px;
}

.modal .video-modal .modal-content .modal-container .modal-header {
  display: flex;
  justify-content: flex-end;
  background: linear-gradient(to bottom, #1a1a1a, #333333);
  padding: 0;
}

.modal .video-modal .modal-content .modal-container .modal-header .modal-close {
  position: static;
  padding: 1rem;
  color: #FFFFFF;
}

.modal .video-modal .modal-content .modal-container .modal-body {
  padding: 0;
}

.modal .modal-lb-video {
  width: 95%;
  max-width: 1200px;
  background-color: black;
}

.modal .modal-lb-video .modal-content {
  border: 0;
}

.modal .modal-lb-video .modal-container {
  background-color: black;
}

.modal .modal-lb-video .modal-container .modal-header {
  display: flex;
  padding: 0.5rem 1.5rem;
}

.modal .modal-lb-video .modal-container .modal-header .modal-close {
  position: static;
  margin-left: auto;
  color: white;
}

.modal .modal-lb-video .modal-container .modal-body {
  padding: 0;
}

.modal .modal-card {
  max-width: 460px;
}

.modal .modal-card .modal-content {
  border-radius: 40px;
}

.modal .modal-card .modal-content .modal-header {
  padding: 0;
}

.modal .modal-card .modal-content .modal-header .modal-close {
  top: 1rem;
  right: 2rem;
  z-index: 1;
}

.modal .modal-card .modal-content .modal-body {
  font-family: "Roboto Condensed", sans-serif;
  padding: 60px 15px;
}

@media (min-width: 600px) {
  .modal .modal-card .modal-content .modal-body {
    padding: 60px 40px;
  }
}

.modal .modal-card .modal-content .modal-body .modal-heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

.modal .modal-card .modal-content .modal-body ul {
  padding-left: 25px;
}

.modal .modal-card .modal-content .modal-body ul li {
  font-size: 16px;
}

.modal .modal-card .modal-content .modal-body .acknowledge {
  padding: 20px 0;
}

.modal .modal-card .modal-content .modal-body .acknowledge .custom-control {
  display: flex;
  margin-bottom: 20px;
}

.modal .modal-card .modal-content .modal-body .acknowledge .custom-control .custom-control-input {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.modal .modal-card .modal-content .modal-body .acknowledge .custom-control .custom-control-input:checked {
  background-color: #DC0000;
  border-color: #DC0000;
}

.modal .modal-card .modal-content .modal-body .acknowledge .custom-control .custom-control-input:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 6px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.modal .modal-card .modal-content .modal-body .acknowledge .custom-control .custom-control-input:focus {
  outline: none;
  box-shadow: none;
}

.card {
  padding: 15px;
}

.card.card-event {
  background-color: #FFFFFF;
  transform: scale(0.97);
  transform-origin: center center;
  transition: all 0.25s ease-in-out;
}

@media (min-width: 1440px) {
  .card.card-event {
    padding: 30px;
  }
}

.card.card-event:hover {
  transform: scale(1);
}

.card.card-event:hover a {
  text-decoration: none;
}

.card.card-event .tile-container {
  display: flex;
  flex-direction: column;
}

.card.card-event .tile-container .event-img {
  position: relative;
}

.card.card-event .tile-container .event-img .date-label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 21px;
  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  background-color: #C9071B;
  width: 50px;
  height: 50px;
}

.card.card-event .tile-container .event-img .date-label > span {
  line-height: 1;
}

.card.card-event .tile-container .event-img .date-label > span.month {
  font-size: 0.65em;
  font-weight: 200;
}

.card.card-event .tile-container .event-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.card.card-event .tile-container .event-details .event-time {
  font-size: 14px;
  margin-bottom: 0;
}

.card.card-event .tile-container .event-details .event-name {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: 700;
}

@media (min-width: 1440px) {
  .card.card-event .tile-container .event-details .event-name {
    font-size: 20px;
  }
}

.card.card-event .tile-container .event-details .event-location {
  font-size: 14px;
  margin-top: auto;
}

.card.card-event .tile-container .event-details .event-location i {
  font-size: 1.25em;
  margin-right: 15px;
}

.descriptor {
  padding: 2rem 1rem;
  margin-top: -1rem;
  opacity: 0;
  animation: fade-in 0.25s ease-in-out forwards;
}

@media (min-width: 768px) {
  .descriptor {
    flex: 0 0 100%;
  }
}

.descriptor .close {
  opacity: 1;
}

.descriptor .price {
  font-weight: 700;
  font-size: 2.4rem;
  text-transform: uppercase;
}

.descriptor .product-contents {
  display: inline-flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .descriptor .product-contents {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.descriptor .product-contents li {
  display: flex;
  padding: 1rem;
  padding-left: 0;
}

@media (min-width: 768px) {
  .descriptor .product-contents li {
    padding-right: 2rem;
  }
}

.descriptor .product-contents li .marker {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #000000;
  margin-right: 0.75rem;
  margin-top: 0.6em;
}

[data-id="demon-product-carousel"] .swiper-wrapper .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tile-grid {
  display: flex;
  flex-wrap: wrap;
}

.tile-grid > * {
  flex: 0 0 100%;
}

@media (min-width: 375px) {
  .tile-grid > * {
    flex: 0 0 50%;
  }
}

@media (min-width: 992px) {
  .tile-grid > * {
    flex: 0 0 25%;
  }
}

@media (min-width: 375px) {
  .tile-grid.three-up > * {
    flex: 0 0 50%;
    aspect-ratio: auto;
  }
}

@media (min-width: 375px) and (max-width: 991px) {
  .tile-grid.three-up > *:last-child {
    flex: 0 0 100%;
  }
}

@media (min-width: 992px) {
  .tile-grid.three-up > * {
    flex: 0 0 calc(100% / 3);
  }
}

#home-articles {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: auto;
}

@media (min-width: 1024px) {
  #home-articles {
    height: 420px;
    flex-wrap: nowrap;
  }
}

@media (min-width: 1200px) {
  #home-articles {
    height: 500px;
  }
}

@media (min-width: 1440px) {
  #home-articles {
    height: 600px;
  }
}

#home-articles > * {
  height: 100%;
}

#home-articles .feature {
  flex: 0 0 100%;
  height: 400px;
}

@media (min-width: 768px) {
  #home-articles .feature {
    height: auto;
  }
}

@media (min-width: 1024px) {
  #home-articles .feature {
    flex: 0 0 50%;
    height: 100%;
  }
}

#home-articles .feature .article-tile > a .article-meta-container {
  background: linear-gradient(0.64deg, #C9071B 3.5%, rgba(0, 0, 0, 0) 95%);
}

#home-articles .feature .article-tile > a .article-meta-container > * {
  max-width: 600px;
}

@media (min-width: 768px) {
  #home-articles .secondary {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  #home-articles .secondary {
    flex: 0 0 25%;
    flex-direction: column;
  }
}

#home-articles .secondary .article-tile {
  height: 250px;
}

@media (min-width: 768px) {
  #home-articles .secondary .article-tile {
    flex: 0 0 50%;
    height: 100%;
  }
}

@media (min-width: 1200px) {
  #home-articles .secondary .article-tile {
    height: 50%;
  }
}

#home-articles .secondary .article-tile a:before {
  background: linear-gradient(0deg, #000000 10.4%, rgba(0, 0, 0, 0) 100%);
}

@media (min-width: 1024px) {
  #home-articles .secondary .article-tile a .article-meta-container {
    padding: 20px;
  }
}

#home-articles .secondary .article-tile a .article-meta-container .entry-title {
  font-size: 1.75em;
}

@media (min-width: 1200px) {
  #home-articles .secondary .article-tile a .article-meta-container .entry-title {
    font-size: 1.5em;
  }
}

@media (min-width: 1440px) {
  #home-articles .secondary .article-tile a .article-meta-container .entry-title {
    font-size: 1.75em;
  }
}

@media (min-width: 1200px) {
  #home-articles .secondary .article-tile a .article-meta-container .article-meta {
    font-size: 1em;
  }
}

@media (min-width: 1600px) {
  #home-articles .secondary .article-tile a .article-meta-container .article-meta {
    font-size: 1.25em;
  }
}

#home-articles .listing-container {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #FFFFFF;
}

@media (min-width: 1024px) {
  #home-articles .listing-container {
    flex: 0 0 25%;
  }
}

#home-articles .listing-container:after {
  content: '';
  position: absolute;
  top: auto;
  bottom: 45px;
  left: 0;
  width: 100%;
  height: 45px;
  background: linear-gradient(to top, #FFFFFF 20%, rgba(255, 255, 255, 0));
}

@media (max-width: 1023px) {
  #home-articles .listing-container:after {
    display: none;
  }
}

#home-articles .listing-container .articles {
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 20px;
}

#home-articles .listing-container .articles::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

#home-articles .listing-container .articles::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
}

#home-articles .listing-container .articles::-webkit-scrollbar-track {
  background: rgba(80, 80, 80, 0.05);
}

#home-articles .listing-container .articles {
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(80, 80, 80, 0.05);
  /* thumb and track color */
  scrollbar-width: thin;
}

#home-articles .listing-container .articles {
  scrollbar-face-color: rgba(0, 0, 0, 0.5);
  scrollbar-track-color: rgba(80, 80, 80, 0.05);
}

@media (min-width: 768px) and (max-width: 1023px) {
  #home-articles .listing-container .articles {
    display: flex;
  }
}

@media (max-width: 1023px) {
  #home-articles .listing-container .articles {
    padding-bottom: 0;
  }
}

#home-articles .listing-container .btn-view-news {
  flex: 0 0 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: #C9071B;
}

@media (min-width: 1024px) {
  #home-articles .listing-container .btn-view-news {
    font-size: 16px;
  }
}

#home-articles .listing-container .btn-view-news:hover {
  color: #FFFFFF;
  background-color: #980514;
}

#home-articles .article-tile {
  height: 100%;
}

#home-articles .article-tile a {
  position: relative;
  display: block;
  height: 100%;
  overflow: hidden;
}

#home-articles .article-tile a:before {
  content: '';
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 75%;
  background: linear-gradient(0deg, #000000 15%, rgba(0, 0, 0, 0) 100%);
}

#home-articles .article-tile a:hover .article-img img {
  transform: scale(1.05);
}

#home-articles .article-tile a .article-img {
  height: 100%;
}

#home-articles .article-tile a .article-img img {
  object-fit: cover;
  height: 100%;
  transform: scale(1);
  transform-origin: center center;
  transition: all 0.25s ease-in-out;
}

#home-articles .article-tile a .article-meta-container {
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  font-size: 14px;
  color: #FFFFFF;
  padding: 15px;
}

@media (min-width: 1024px) {
  #home-articles .article-tile a .article-meta-container {
    padding: 40px;
  }
}

@media (min-width: 1440px) {
  #home-articles .article-tile a .article-meta-container {
    font-size: 16px;
  }
}

#home-articles .article-tile a .article-meta-container .entry-title {
  font-size: 1.75em;
}

@media (min-width: 1440px) {
  #home-articles .article-tile a .article-meta-container .entry-title {
    font-size: 2em;
  }
}

#home-articles .article-tile a .article-meta-container .author {
  display: block;
  margin-bottom: 1.25rem;
}

#home-articles .article-tile a .article-meta-container .description p {
  font-size: 1.4em;
  margin-bottom: 2rem;
}

#home-articles .article-tile a .article-meta-container .article-meta {
  font-size: 1.4em;
  color: #FFFFFF;
}

#home-articles .article-listing {
  border-bottom: 1px solid #D9D9D9;
}

@media (min-width: 768px) and (max-width: 1023px) {
  #home-articles .article-listing {
    flex: 0 0 calc(100% / 3);
    border-right: 1px solid #D9D9D9;
    border-bottom: 0;
  }
}

@media (max-width: 1023px) {
  #home-articles .article-listing:nth-child(n + 4) {
    display: none;
  }
}

#home-articles .article-listing:last-child {
  border-bottom: 0;
}

@media (min-width: 768px) and (max-width: 1023px) {
  #home-articles .article-listing:last-child {
    border-right: 0;
  }
}

#home-articles .article-listing a {
  display: flex;
  color: #000000;
  text-decoration: none;
  padding: 20px 15px;
  transition: all 0.25s ease-in-out;
}

#home-articles .article-listing a:hover {
  background-color: #EDEDED;
}

#home-articles .article-listing a .article-img {
  flex: 0 0 60px;
  display: flex;
  align-items: center;
  order: 1;
}

@media (min-width: 1440px) {
  #home-articles .article-listing a .article-img {
    flex: 0 0 90px;
  }
}

#home-articles .article-listing a .article-img img {
  object-fit: cover;
  height: 60px;
}

@media (min-width: 1440px) {
  #home-articles .article-listing a .article-img img {
    height: 90px;
  }
}

#home-articles .article-listing a .article-meta-container {
  flex: 1;
  font-size: 10px;
  padding-right: 10px;
}

@media (min-width: 1440px) {
  #home-articles .article-listing a .article-meta-container {
    font-size: 14px;
  }
}

#home-articles .article-listing a .article-meta-container .entry-title {
  font-size: 2em;
  margin-bottom: 1rem;
}

#home-articles .article-listing a .article-meta-container .author {
  display: block;
  font-size: 1.6em;
  margin-bottom: 1rem;
}

#home-articles .article-listing a .article-meta-container .article-meta {
  font-size: 1.4em;
}

#videos-section {
  padding: 35px 0;
}

@media (min-width: 1440px) {
  #videos-section {
    padding: 100px 0;
  }
}

#videos-section .slider-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 35px;
}

@media (min-width: 1440px) {
  #videos-section .slider-header {
    margin-bottom: 50px;
  }
}

#videos-section .slider-header .heading {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
  margin-right: 25px;
}

@media (min-width: 1200px) {
  #videos-section .slider-header .heading {
    font-size: 32px;
  }
}

#videos-section .slider-header .btn {
  font-size: 14px;
  margin-bottom: 0;
  padding: 8px 20px;
}

#videos-section .dg-tabs .nav-tabs {
  display: flex;
}

#videos-section .dg-tabs .nav-tabs .nav-item {
  flex: 0 1 auto;
  max-width: none;
  margin-right: 15px;
  margin-bottom: 15px;
}

#videos-section .dg-tabs .nav-tabs .nav-item .nav-link {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  padding: 10px 15px;
  border: 2px solid #D9D9D9;
}

#videos-section .dg-tabs .nav-tabs .nav-item .nav-link.active, #videos-section .dg-tabs .nav-tabs .nav-item .nav-link:hover {
  background-color: #FFFFFF;
  border: 2px solid #C9071B;
}

#videos-section .dg-tabs .tab-content .tab-pane {
  margin-bottom: 0;
}

#videos-section .dg-tabs .tab-content .tab-pane.active {
  animation: fade-in 0.25s ease-in-out forwards;
}

#videos-section .dg-tabs .tab-content .tab-pane .tile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#videos-section .dg-tabs .tab-content .tab-pane .tile-container .video-thumbnail {
  flex: 0 0 47%;
  overflow: hidden;
}

@media (min-width: 568px) {
  #videos-section .dg-tabs .tab-content .tab-pane .tile-container .video-thumbnail {
    flex: 0 0 23%;
  }
}

#videos-section .dg-tabs .tab-content .tab-pane .tile-container .video-thumbnail a {
  position: relative;
  display: block;
}

#videos-section .dg-tabs .tab-content .tab-pane .tile-container .video-thumbnail a:hover i {
  color: #C9071B;
}

#videos-section .dg-tabs .tab-content .tab-pane .tile-container .video-thumbnail a img {
  width: 100%;
  height: auto;
}

#videos-section .dg-tabs .tab-content .tab-pane .tile-container .video-thumbnail a i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.25s ease-in-out;
  font-size: 40px;
  color: #FFFFFF;
}

#videos-section .dg-tabs .tab-content .tab-pane .tile-container .video-thumbnail .video-description {
  padding: 10px 0;
}

#videos-section .dg-tabs .tab-content .tab-pane .tile-container .video-thumbnail .video-description p {
  font-size: 16px;
  margin-bottom: 0;
}

#video-modal-heading {
  padding-right: 20px;
}

#events-slider {
  position: relative;
  background-color: #EDEDED;
  padding: 35px 0;
}

@media (min-width: 1440px) {
  #events-slider {
    padding: 100px 0;
  }
}

#events-slider .slider-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 35px;
}

@media (min-width: 1440px) {
  #events-slider .slider-header {
    margin-bottom: 50px;
  }
}

#events-slider .slider-header .heading {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
  margin-right: 25px;
}

@media (min-width: 1200px) {
  #events-slider .slider-header .heading {
    font-size: 32px;
  }
}

#events-slider .slider-header .btn {
  font-size: 14px;
  margin-bottom: 0;
  padding: 8px 20px;
}

#events-slider .slider-header .slider-controls {
  margin-top: 10px;
  width: 100%;
}

@media (min-width: 568px) {
  #events-slider .slider-header .slider-controls {
    width: auto;
    margin-left: auto;
  }
}

#events-slider .slider-header .slider-controls .carousel-button {
  font-size: 16px;
  color: #FFFFFF;
  border-radius: 0;
  border: 0;
  background-color: #000000;
  width: 35px;
  height: 35px;
}

#events-slider .slider-header .slider-controls .carousel-button:hover {
  background-color: #C9071B;
}

#events-slider .slider-header .slider-controls .carousel-button.swiper-button-disabled {
  background-color: #D9D9D9;
}

#events-slider .slider-header .slider-controls .carousel-button:first-of-type {
  margin-right: 10px;
}

#events-slider .carousel-container .swiper-container {
  width: 100%;
}

#events-slider .carousel-container .swiper-container .swiper-wrapper .swiper-slide {
  height: auto;
}

#events-slider .carousel-container .swiper-container .swiper-wrapper .swiper-slide .event-homepage {
  margin-bottom: 0;
}

#events-slider .carousel-container .swiper-container .swiper-wrapper .swiper-slide .event-homepage article {
  height: 100%;
}

#events-slider .carousel-container .swiper-container .swiper-wrapper .swiper-slide .event-homepage article a .tile-container {
  height: 100%;
}

#feature-slider {
  --slider-height: 300px;
  --item-spacing: 15px;
  --img-base: 85px;
  --img-expanded: 300px;
  --padding-right: 165px;
  position: relative;
  padding: 35px 0.8rem;
  background-color: #FFFFFF;
}

@media (min-width: 1440px) {
  #feature-slider {
    --slider-height: 360px;
    --item-spacing: 15px;
    --img-base: 125px;
    --img-expanded: 420px;
    --padding-right: 145px;
  }
}

@media (min-width: 1600px) {
  #feature-slider {
    --img-expanded: 460px;
  }
}

@media (min-width: 1800px) {
  #feature-slider {
    --slider-height: 420px;
    --item-spacing: 20px;
    --img-base: 170px;
    --img-expanded: 460px;
    --padding-right: 180px;
  }
}

@media (min-width: 1200px) {
  #feature-slider {
    padding: 35px 0;
  }
}

#feature-slider .text-white {
  color: white !important;
}

#feature-slider .toggle-auto-play {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 1200px) {
  #feature-slider .toggle-auto-play {
    right: 0;
    bottom: 40px;
  }
}

#feature-slider .toggle-auto-play .btn-link:focus-visible {
  border-color: white;
  border-width: 1px;
}

#feature-slider .toggle-auto-play .btn-link i.fa {
  margin-right: 0;
}

#feature-slider .feature-wrapper {
  display: flex;
  overflow: hidden;
}

#feature-slider .feature-wrapper.hide-mobile {
  height: 0;
  visibility: hidden;
}

#feature-slider .feature-wrapper .carousel-container {
  flex: 0 0 100%;
  align-self: center;
  overflow: hidden;
  margin: 0 auto;
}

@media (min-width: 768px) {
  #feature-slider .feature-wrapper .carousel-container {
    flex: 0 0 330px;
  }
}

#feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  #feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-pagination {
    justify-content: flex-start;
  }
}

@media (min-width: 992px) {
  #feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-pagination {
    justify-content: center;
  }
}

#feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-pagination .carousel-nav-item {
  cursor: pointer;
  display: block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  background-color: #C4C4C4;
}

#feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-pagination .carousel-nav-item:last-of-type {
  margin-right: 0;
}

#feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-pagination .carousel-nav-item.active {
  background-color: #C9071B;
}

#feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-wrapper .swiper-slide {
  text-align: center;
}

@media (min-width: 768px) {
  #feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-wrapper .swiper-slide {
    text-align: left;
  }
}

@media (min-width: 992px) {
  #feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-wrapper .swiper-slide {
    text-align: center;
  }
}

#feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-wrapper .swiper-slide .slide-container .slide-logo {
  display: block;
  max-width: 275px;
  margin: 0 auto 10px;
}

@media (min-width: 768px) {
  #feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-wrapper .swiper-slide .slide-container .slide-logo {
    margin: 0 0 10px;
  }
}

@media (min-width: 992px) {
  #feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-wrapper .swiper-slide .slide-container .slide-logo {
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  #feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-wrapper .swiper-slide .slide-container .slide-logo {
    max-width: 175px;
  }
}

@media (min-width: 1440px) {
  #feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-wrapper .swiper-slide .slide-container .slide-logo {
    max-width: 240px;
  }
}

#feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-wrapper .swiper-slide .slide-container .slide-heading {
  font-size: 32px;
  line-height: 1.2;
}

@media (min-width: 1440px) {
  #feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-wrapper .swiper-slide .slide-container .slide-heading {
    font-size: 30px;
  }
}

#feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-wrapper .swiper-slide .slide-container .slide-description {
  font-size: 16px;
}

@media (min-width: 1440px) {
  #feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-wrapper .swiper-slide .slide-container .slide-description {
    font-size: 24px;
  }
}

@media (min-width: 1800px) {
  #feature-slider .feature-wrapper .carousel-container .swiper-container .swiper-wrapper .swiper-slide .slide-container .btn {
    margin: 0 auto;
  }
}

#feature-slider .feature-wrapper .carousel-images {
  display: flex;
  width: 100%;
  position: relative;
  padding-right: var(--padding-right);
}

#feature-slider .feature-wrapper .carousel-images .item {
  flex: 0 0 var(--img-base);
  display: flex;
  height: var(--slider-height);
  overflow: hidden;
  margin-right: var(--item-spacing);
  transition: all 0.25s ease-in-out;
  transform: skew(-30deg);
  transform-origin: bottom left;
}

#feature-slider .feature-wrapper .carousel-images .item .image {
  position: relative;
  flex: 0 0 var(--img-base);
  height: 100%;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}

#feature-slider .feature-wrapper .carousel-images .item .image:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(1.17deg, rgba(0, 0, 0, 0.7) 0.65%, rgba(0, 0, 0, 0.56) 82.41%);
  mix-blend-mode: luminosity;
  transition: opacity 0.25s ease-in-out;
}

#feature-slider .feature-wrapper .carousel-images .item .image:hover:before {
  opacity: 0;
}

#feature-slider .feature-wrapper .carousel-images .item .image img {
  position: absolute;
  left: 50%;
  height: 100%;
  width: auto;
  transform: translateX(-50%) skew(30deg);
  transition: all 0.25s ease-in-out;
}

@media (min-width: 1600px) {
  #feature-slider .feature-wrapper .carousel-images .item .image img {
    height: 105%;
  }
}

#feature-slider .feature-wrapper .carousel-images .item .image[data-ga_label="Dodge Complete Performance Package"] img {
  position: absolute;
  top: 50%;
  left: auto;
  right: -30%;
  transform: translateX(0) translateY(-50%) skew(30deg);
  height: 100%;
}

@media (min-width: 1440px) {
  #feature-slider .feature-wrapper .carousel-images .item .image[data-ga_label="Dodge Complete Performance Package"] img {
    height: 120%;
  }
}

@media (min-width: 1600px) {
  #feature-slider .feature-wrapper .carousel-images .item .image[data-ga_label="Dodge Complete Performance Package"] img {
    right: -23%;
    height: 100%;
  }
}

@media (min-width: 1800px) {
  #feature-slider .feature-wrapper .carousel-images .item .image[data-ga_label="Dodge Complete Performance Package"] img {
    right: -28%;
  }
}

#feature-slider .feature-wrapper .carousel-images .item .content {
  display: none;
  padding: 20px;
  font-size: 14px;
  text-align: center;
  opacity: 0;
  transform: skew(30deg);
}

@media (min-width: 1200px) {
  #feature-slider .feature-wrapper .carousel-images .item .content {
    font-size: 16px;
    transform: skew(30deg) translateX(10px);
  }
}

@media (min-width: 1800px) {
  #feature-slider .feature-wrapper .carousel-images .item .content {
    font-size: 20px;
    transform: skew(30deg) translateX(0);
    padding-left: 60px;
  }
}

@media (min-width: 1920px) {
  #feature-slider .feature-wrapper .carousel-images .item .content {
    font-size: 24px;
  }
}

#feature-slider .feature-wrapper .carousel-images .item .content .indicators {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

#feature-slider .feature-wrapper .carousel-images .item .content .indicators .indicator {
  cursor: pointer;
  display: block;
  width: calc(10px * 1.56);
  height: 10px;
  margin-right: 10px;
  background-color: #C4C4C4;
  transform: skew(-30deg) translateY(-20px);
}

#feature-slider .feature-wrapper .carousel-images .item .content .indicators .indicator:last-of-type {
  margin-right: 0;
}

#feature-slider .feature-wrapper .carousel-images .item .content .indicators .indicator.active {
  background-color: #C9071B;
}

#feature-slider .feature-wrapper .carousel-images .item .content .indicators .indicator:hover {
  background-color: #505050;
}

#feature-slider .feature-wrapper .carousel-images .item .content .slide-logo {
  width: 230px;
  height: auto;
  margin-bottom: 15px;
}

#feature-slider .feature-wrapper .carousel-images .item .content .slide-heading {
  font-size: 1.5em;
}

#feature-slider .feature-wrapper .carousel-images .item .content .slide-description {
  font-size: 1em;
}

#feature-slider .feature-wrapper .carousel-images .item.active {
  flex: 1;
}

#feature-slider .feature-wrapper .carousel-images .item.active .image {
  flex: 0 0 var(--img-expanded);
}

#feature-slider .feature-wrapper .carousel-images .item.active .image:before {
  opacity: 0;
}

#feature-slider .feature-wrapper .carousel-images .item.active .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fade-in 0.25s ease-in-out 0.25s forwards;
}

#feature-slider .feature-wrapper .carousel-images .item:not(.end):last-child {
  margin-right: 0;
}

#feature-slider .feature-wrapper .carousel-images .item.end {
  position: absolute;
  left: calc((var(--img-base) * 2) * -1 - var(--item-spacing));
  width: calc(var(--img-base) * 2);
  background-color: #C9071B;
}

#feature-slider .feature-wrapper .carousel-images .item.end:last-of-type {
  left: auto;
  right: -25px;
}

@media (min-width: 1440px) {
  #feature-slider .feature-wrapper .carousel-images .item.end:last-of-type {
    right: calc(var(--img-base) * -1);
  }
}

@media (min-width: 1800px) {
  #feature-slider .feature-wrapper .carousel-images .item.end:last-of-type {
    margin-right: 10px;
  }
}

#feature-image-slider .feature-image-wrapper {
  position: relative;
  height: 200px;
  overflow: hidden;
}

#feature-image-slider .image-wrap {
  height: 200px;
  clip-path: polygon(calc(200px / 2) 0%, 100% 0%, calc(100% - (200px / 2)) 100%, 0% 100%);
  cursor: pointer;
}

#feature-image-slider img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (min-width: 992px) {
  #feature-image-slider .feature-image-wrapper {
    height: 300px;
  }
  #feature-image-slider .image-wrap {
    height: 300px;
    clip-path: polygon(calc(300px / 2) 0%, 100% 0%, calc(100% - (300px / 2)) 100%, 0% 100%);
  }
}

@media (min-width: 1600px) {
  #feature-image-slider .feature-image-wrapper {
    height: 400px;
  }
  #feature-image-slider .image-wrap {
    height: 400px;
    clip-path: polygon(calc(400px / 2) 0%, 100% 0%, calc(100% - (400px / 2)) 100%, 0% 100%);
  }
}

#feature-image-slider .carousel-button {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  transform: translateY(-50%);
  transition: all 0.25s ease-in-out;
  background: 0;
  border: 0;
  font-size: 1.8rem;
  color: #FFFFFF;
  text-shadow: 0px 0px 15px black;
}

@media (min-width: 992px) {
  #feature-image-slider .carousel-button {
    font-size: 2rem;
    left: 20px;
  }
}

#feature-image-slider .carousel-button:hover {
  color: #DC0000;
}

#feature-image-slider .carousel-button#carousel-next {
  left: auto;
  right: 0;
}

@media (min-width: 992px) {
  #feature-image-slider .carousel-button#carousel-next {
    right: 20px;
  }
}

#mission-banner {
  position: relative;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  background-image: url("/assets/images/we-are-dodgegarage.jpg");
  background-size: cover;
  background-position: center right;
  padding: 60px 30px;
}

#mission-banner:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.756723) 66%, black 100%);
}

@media (min-width: 1440px) {
  #mission-banner {
    padding: 100px 30px;
  }
}

#mission-banner .mission {
  position: relative;
  z-index: 1;
  max-width: 550px;
  font-size: 14px;
}

@media (min-width: 1200px) {
  #mission-banner .mission {
    font-size: 18px;
  }
}

@media (min-width: 1600px) {
  #mission-banner .mission {
    max-width: 650px;
    font-size: 21px;
  }
}

#mission-banner .mission .mission-heading {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  #mission-banner .mission .mission-heading {
    font-size: 32px;
  }
}

@media (min-width: 1600px) {
  #mission-banner .mission .mission-heading {
    font-size: 45px;
  }
}

#mission-banner .mission .subheading {
  display: block;
  font-size: 1.4em;
  margin-bottom: 20px;
}

@media (min-width: 1600px) {
  #mission-banner .mission .subheading {
    font-size: 20px;
  }
}

#mission-banner .mission p {
  font-size: 1.4em;
}

#mission-banner .logo {
  position: absolute;
  top: 15px;
  left: auto;
  right: 15px;
  width: 150px;
}

@media (min-width: 1440px) {
  #mission-banner .logo {
    top: 25px;
    right: 25px;
  }
}

@media (min-width: 1600px) {
  #mission-banner .logo {
    width: 225px;
  }
}

#mission-banner .logo img {
  width: 100%;
  height: auto;
}

.banner-social#dodge-social-banner {
  height: 320px;
  padding: 0 !important;
  background: #282B2D;
}

@media (min-width: 768px) {
  .banner-social#dodge-social-banner {
    height: 170px;
  }
}

.banner-social#dodge-social-banner .content-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  bottom: 10px;
  max-width: 100% !important;
  padding-left: 0;
}

@media (min-width: 768px) {
  .banner-social#dodge-social-banner .content-inner {
    flex-wrap: nowrap;
  }
}

.banner-social#dodge-social-banner .content-inner .juicer-container {
  background: none;
  width: 100%;
}

@media (min-width: 768px) {
  .banner-social#dodge-social-banner .content-inner .juicer-container {
    max-height: 170px;
    overflow: hidden;
  }
}

.banner-social#dodge-social-banner .content-inner .juicer-container dg-juicer {
  max-width: none;
  height: 100% !important;
}

.banner-social#dodge-social-banner .content-inner .juicer-container dg-juicer .juicer-post {
  flex: 0 0 calc(100% / 3);
  height: 100%;
  max-height: 120px;
  border: 0;
}

@media (min-width: 768px) {
  .banner-social#dodge-social-banner .content-inner .juicer-container dg-juicer .juicer-post {
    max-height: none;
    height: 50%;
  }
}

@media (min-width: 992px) {
  .banner-social#dodge-social-banner .content-inner .juicer-container dg-juicer .juicer-post {
    flex: 0 0 calc(100% / 6);
    height: 100%;
  }
}

.banner-social#dodge-social-banner .content-inner .social-ctas {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

@media (max-width: 767px) {
  .banner-social#dodge-social-banner .content-inner .social-ctas {
    flex-direction: row;
    padding: 20px 0;
    margin-top: 10px;
  }
}

@media (min-width: 1200px) {
  .banner-social#dodge-social-banner .content-inner .social-ctas {
    flex: 0 0 25%;
  }
}

.banner-social#dodge-social-banner .content-inner .social-ctas h3 {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .banner-social#dodge-social-banner .content-inner .social-ctas h3 {
    font-size: 24px;
    margin-right: 20px;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .banner-social#dodge-social-banner .content-inner .social-ctas h3 {
    font-size: 28px;
  }
}

@media (min-width: 1920px) {
  .banner-social#dodge-social-banner .content-inner .social-ctas h3 {
    font-size: 40px;
  }
}

.banner-social#dodge-social-banner .content-inner .social-ctas .social-icons {
  order: 1;
  flex-grow: inherit;
  margin-bottom: 1px;
}

.banner-social#dodge-social-banner .content-inner .social-ctas .social-icons li {
  font-size: 20px !important;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .banner-social#dodge-social-banner .content-inner .social-ctas .social-icons li {
    font-size: 24px !important;
  }
}

@media (min-width: 1200px) {
  .banner-social#dodge-social-banner .content-inner .social-ctas .social-icons li {
    font-size: 32px !important;
  }
}

@media (min-width: 1920px) {
  .banner-social#dodge-social-banner .content-inner .social-ctas .social-icons li {
    font-size: 55px !important;
  }
}

.banner-social#dodge-social-banner .content-inner .social-ctas .social-icons li:last-of-type {
  margin-right: 0;
}

#dg-poll.poll-container {
  background-image: url("/assets/images/bg-grunge.jpg");
  background-size: cover;
  background-position: center center;
}

#dg-poll.poll-container dg-poll {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 50px 35px;
}

#dg-poll.poll-container dg-poll form {
  max-width: 400px;
}

@media (min-width: 1440px) {
  #dg-poll.poll-container dg-poll form {
    max-width: 550px;
  }
}

#dg-poll.poll-container dg-poll form .poll-question {
  font-size: 22px;
  line-height: 1.25;
  margin-bottom: 35px;
}

@media (min-width: 1440px) {
  #dg-poll.poll-container dg-poll form .poll-question {
    font-size: 30px;
  }
}

#dg-poll.poll-container dg-poll form .poll-form {
  margin-bottom: 0;
}

#dg-poll.poll-container dg-poll form .poll-form .btn-radio {
  border: 0;
  margin-bottom: 10px;
}

#dg-poll.poll-container dg-poll form .poll-form .btn-radio label {
  display: flex;
  padding: 0;
  overflow: hidden;
}

@media (min-width: 1440px) {
  #dg-poll.poll-container dg-poll form .poll-form .btn-radio label {
    font-size: 21px;
  }
}

#dg-poll.poll-container dg-poll form .poll-form .btn-radio label span:not(.letter) {
  display: flex;
  align-items: center;
  padding-left: 35px;
}

@media (max-width: 400px) {
  #dg-poll.poll-container dg-poll form .poll-form .btn-radio label span:not(.letter) {
    line-height: 1;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 45px;
  }
}

#dg-poll.poll-container dg-poll form .poll-form .btn-radio label span.letter {
  position: relative;
  z-index: 1;
  color: #FFFFFF;
  padding: 5px 10px;
  background-color: #C9071B;
  border-radius: 0;
  box-shadow: none;
}

#dg-poll.poll-container dg-poll form .poll-form .btn-radio label span.letter:before {
  content: '';
  position: absolute;
  top: 0;
  left: auto;
  right: -20px;
  z-index: -1;
  display: block;
  width: 180%;
  height: 100%;
  background-color: #C9071B;
  transform: skew(-40deg);
}

@media (max-width: 400px) {
  #dg-poll.poll-container dg-poll form .poll-form .btn-radio label span.letter:before {
    height: 120%;
  }
}

#dg-poll.poll-container dg-poll form .poll-form button[type="submit"] {
  display: block;
  font-size: 14px;
  margin: 0 auto;
  margin-top: 20px;
}

@media (min-width: 1440px) {
  #dg-poll.poll-container dg-poll form .poll-form button[type="submit"] {
    font-size: 21px;
  }
}

performance-cars-homepage .cta-tile {
  aspect-ratio: 1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

performance-cars-homepage .cta-tile a {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  font-family: "Roboto Condensed", sans-serif;
  color: #FFFFFF;
  text-decoration: none;
  padding: 30px 15px;
}

@media (min-width: 375px) {
  performance-cars-homepage .cta-tile a {
    height: 50vw;
  }
}

@media (min-width: 992px) {
  performance-cars-homepage .cta-tile a {
    padding: 30px;
  }
}

@media (min-width: 992px) {
  performance-cars-homepage .cta-tile a {
    height: 25vw;
    max-height: 480px;
  }
}

performance-cars-homepage .cta-tile a:before {
  content: '';
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: -1;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
}

@media (min-width: 992px) {
  performance-cars-homepage .cta-tile a:before {
    height: 70%;
  }
}

performance-cars-homepage .cta-tile a .cta-content {
  width: 100%;
  margin-top: auto;
}

@media (min-width: 992px) {
  performance-cars-homepage .cta-tile a .cta-content {
    padding-bottom: 20px;
  }
}

performance-cars-homepage .cta-tile a .cta-content .title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
}

@media (min-width: 1600px) {
  performance-cars-homepage .cta-tile a .cta-content .title {
    font-size: 28px;
  }
}

performance-cars-homepage .cta-tile a .cta-content .description {
  font-size: 14px;
  line-height: 1.3;
  text-transform: none;
  margin-top: 0 !important;
}

@media (min-width: 1600px) {
  performance-cars-homepage .cta-tile a .cta-content .description {
    font-size: 18px;
  }
}

performance-cars-homepage dg-downloads-cta {
  background-image: url("/assets/images/cta-tiles/cta-bg-downloads.jpg");
}

performance-cars-homepage dg-bondurant-cta {
  background-image: url("/assets/images/cta-tiles/cta-bg-radford.jpg");
}

performance-cars-homepage dg-bondurant-cta a > div {
  display: block;
}

performance-cars-homepage thirteen-twenty-cta {
  background-image: url("/assets/images/cta-tiles/cta-bg-1320.jpg");
}

performance-cars-homepage direct-connection-cta {
  background-image: url("/assets/images/cta-tiles/cta-bg-direct-connection.jpg");
}

performance-cars-homepage dg-drag-pak-cta {
  background-image: url("/assets/images/drag-pak/drag-pak-homepage-feature.jpg");
}

performance-cars-homepage dg-nhra-membership-cta {
  background-image: url("/assets/images/cta-tiles/cta-bg-nhra.jpg");
}

performance-cars-homepage hellcat-availability-cta.cta-tile {
  background-image: url("/assets/images/cta-tiles/durango-srt-burnout-bg.jpg");
}

performance-cars-homepage hellcat-availability-cta.cta-tile .logo {
  display: block;
  margin: 0 auto 20px;
  max-width: 80%;
}

performance-cars-homepage .text-logo-overlay {
  margin-bottom: 15px;
  aspect-ratio: auto;
}

@media (min-width: 768px) {
  performance-cars-homepage .text-logo-overlay {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  performance-cars-homepage .text-logo-overlay {
    height: inherit;
  }
}

performance-cars-homepage .text-logo-overlay a {
  height: 100%;
  align-items: center;
  padding: 50px 15px;
  text-align: center;
}

@media (min-width: 600px) {
  performance-cars-homepage .text-logo-overlay a {
    padding: 75px 15px;
  }
}

@media (min-width: 1440px) {
  performance-cars-homepage .text-logo-overlay a {
    padding: 105px 15px;
  }
}

performance-cars-homepage .text-logo-overlay a:before {
  display: none;
}

performance-cars-homepage .text-logo-overlay a .cta-content {
  text-align: center;
  margin: auto;
  padding-bottom: 0;
}

performance-cars-homepage .text-logo-overlay a .cta-content img {
  width: 100%;
  max-width: 375px;
  height: auto;
  margin-bottom: 25px;
}

@media (min-width: 600px) {
  performance-cars-homepage .text-logo-overlay a .cta-content .title {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  performance-cars-homepage .text-logo-overlay a .cta-content .title {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  performance-cars-homepage .text-logo-overlay a .cta-content .title {
    font-size: 32px;
  }
}

performance-cars-homepage .text-logo-overlay a .cta-content .title sup {
  font-size: 50%;
  top: -0.75em;
}

performance-cars-homepage jailbreak-cta.cta-tile {
  background-image: url("/assets/images/cta-tiles/burnout-bg.jpg");
  margin-bottom: 15px;
  aspect-ratio: auto;
}

@media (min-width: 768px) {
  performance-cars-homepage jailbreak-cta.cta-tile {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  performance-cars-homepage jailbreak-cta.cta-tile {
    height: inherit;
  }
}

performance-cars-homepage jailbreak-cta.cta-tile a {
  height: 100%;
  align-items: center;
  padding: 50px 15px;
}

@media (min-width: 600px) {
  performance-cars-homepage jailbreak-cta.cta-tile a {
    font-size: 18px;
    padding: 75px 15px;
  }
}

@media (min-width: 1440px) {
  performance-cars-homepage jailbreak-cta.cta-tile a {
    padding: 105px 15px;
  }
}

performance-cars-homepage jailbreak-cta.cta-tile a:before {
  display: none;
}

performance-cars-homepage jailbreak-cta.cta-tile a .cta-content {
  text-align: center;
  margin: auto;
  padding-bottom: 0;
}

performance-cars-homepage jailbreak-cta.cta-tile a .cta-content img {
  width: 100%;
  max-width: 375px;
  height: auto;
  margin-bottom: 25px;
}

@media (min-width: 600px) {
  performance-cars-homepage jailbreak-cta.cta-tile a .cta-content .title {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  performance-cars-homepage jailbreak-cta.cta-tile a .cta-content .title {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  performance-cars-homepage jailbreak-cta.cta-tile a .cta-content .title {
    font-size: 32px;
  }
}

performance-cars-homepage jailbreak-cta.cta-tile a .cta-content .title sup {
  font-size: 50%;
  top: -0.75em;
}

#demon-after-delivery {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 100%), url("../../assets/images/rkn/rkn-smoke-bg.webp"), no-repeat;
  background-size: cover;
  background-position: center center;
  margin-top: 0;
  margin-bottom: 3.5rem;
}

@media (min-width: 992px) {
  #demon-after-delivery {
    height: 100%;
  }
}

#demon-after-delivery a {
  display: block;
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}

@media (min-width: 992px) {
  #demon-after-delivery a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

#demon-after-delivery a:hover {
  color: white;
}

#demon-after-delivery .content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.8rem;
  line-height: 1.2;
  text-transform: uppercase;
  padding: 3rem 1.5rem;
}

@media (min-width: 768px) {
  #demon-after-delivery .content {
    font-size: 4rem;
    padding: 1.5rem 3rem;
  }
}

@media (min-width: 992px) {
  #demon-after-delivery .content {
    font-size: 2.5rem;
    padding: 1.5rem 3rem;
  }
}

@media (min-width: 1200px) {
  #demon-after-delivery .content {
    justify-content: center;
    font-size: 3.2rem;
    line-height: 1;
    font-weight: 500;
  }
}

@media (min-width: 1440px) {
  #demon-after-delivery .content {
    font-size: 3.6rem;
    padding: 1.5rem 5rem;
  }
}

@media (min-width: 1600px) {
  #demon-after-delivery .content {
    font-size: 4.2rem;
    width: 800px;
  }
}

@media (min-width: 1920px) {
  #demon-after-delivery .content {
    font-size: 4.6rem;
    width: 900px;
  }
}

#demon-after-delivery .content img {
  width: 80px;
  height: auto;
  margin-right: 1.5rem;
}

@media (min-width: 768px) {
  #demon-after-delivery .content img {
    width: 160px;
    margin-right: 2.5rem;
  }
}

@media (min-width: 992px) {
  #demon-after-delivery .content img {
    width: 100px;
    margin-right: 1.5rem;
  }
}

@media (min-width: 1200px) {
  #demon-after-delivery .content img {
    width: 120px;
  }
}

@media (min-width: 1440px) {
  #demon-after-delivery .content img {
    width: 130px;
  }
}

@media (min-width: 1600px) {
  #demon-after-delivery .content img {
    width: 140px;
    margin-right: 3rem;
  }
}

@media (min-width: 1920px) {
  #demon-after-delivery .content img {
    width: 175px;
  }
}

@media (min-width: 1440px) {
  #demon-after-delivery .content span {
    margin: auto;
  }
}

#demon-after-delivery .content sup {
  font-size: 50%;
  top: -0.75em;
}

.hero-wrapper {
  width: 100%;
  height: 420px;
}

@media (min-width: 1200px) {
  .hero-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
  }
}

@media (min-width: 768px) {
  .hero-wrapper {
    height: 400px;
  }
}

@media (min-width: 992px) {
  .hero-wrapper {
    height: 500px;
  }
}

@media (min-width: 1200px) {
  .hero-wrapper {
    height: 600px;
  }
}

.hero-wrapper > * {
  display: block;
  height: 100%;
}

.hero-wrapper.never-lift {
  overflow: hidden;
}

@media (max-width: 884px) {
  .hero-wrapper.never-lift {
    height: auto;
  }
}

@media (min-width: 885px) and (max-width: 1199px) {
  .hero-wrapper.never-lift {
    height: 420px;
  }
}

.hero-wrapper .fixed-hero {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
}

.hero-wrapper .fixed-hero#hero-cdm {
  display: flex;
  background-image: url("/assets/images/hero/cdm/cdm-banner-bg-winner-md.jpg");
  background-position: center;
}

@media (min-width: 600px) {
  .hero-wrapper .fixed-hero#hero-cdm {
    background-position: -100px center;
  }
}

@media (min-width: 768px) {
  .hero-wrapper .fixed-hero#hero-cdm {
    background-position: center center;
  }
}

@media (min-width: 992px) {
  .hero-wrapper .fixed-hero#hero-cdm {
    background-image: url("/assets/images/hero/cdm/cdm-banner-bg-winner-xl.jpg");
  }
}

@media (min-width: 1600px) {
  .hero-wrapper .fixed-hero#hero-cdm {
    background-position: center top;
  }
}

.hero-wrapper .fixed-hero#hero-cdm .site-wrapper-xl {
  width: 100%;
}

.hero-wrapper .fixed-hero#hero-cdm .site-wrapper-xl .img-logo {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 150px;
  height: auto;
  display: none;
}

@media (min-width: 600px) {
  .hero-wrapper .fixed-hero#hero-cdm .site-wrapper-xl .img-logo {
    width: 180px;
    display: block;
  }
}

@media (min-width: 992px) {
  .hero-wrapper .fixed-hero#hero-cdm .site-wrapper-xl .img-logo {
    top: 50%;
    transform: translateY(-50%);
    width: 225px;
  }
}

@media (min-width: 1200px) {
  .hero-wrapper .fixed-hero#hero-cdm .site-wrapper-xl .img-logo {
    width: 300px;
  }
}

@media (min-width: 1600px) {
  .hero-wrapper .fixed-hero#hero-cdm .site-wrapper-xl .img-logo {
    left: 100px;
    width: 360px;
  }
}

.hero-wrapper .fixed-hero#hero-cdm .site-wrapper-xl .hero-content {
  position: absolute;
  top: auto;
  left: 20px;
  bottom: 20px;
}

@media (min-width: 992px) {
  .hero-wrapper .fixed-hero#hero-cdm .site-wrapper-xl .hero-content {
    left: auto;
    right: 20px;
  }
}

@media (min-width: 1200px) {
  .hero-wrapper .fixed-hero#hero-cdm .site-wrapper-xl .hero-content {
    top: 50%;
    left: auto;
    bottom: auto;
    right: 35px;
    transform: translateY(-50%);
  }
}

.hero-wrapper .fixed-hero#hero-cdm .site-wrapper-xl .hero-content .on-black {
  display: inline;
  background: #000000;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 1.5;
  box-shadow: -10px 0 0 #000000, 10px 0 0 #000000;
  word-wrap: break-word;
  padding: 10px 0;
}

@media (min-width: 992px) {
  .hero-wrapper .fixed-hero#hero-cdm .site-wrapper-xl .hero-content .on-black {
    font-size: 32px;
  }
}

.hero-wrapper .fixed-hero#hero-cdm .site-wrapper-xl .hero-content .btn {
  margin-top: 20px;
  margin-left: -10px;
}

.hero-wrapper .fixed-hero#hwy93-hero {
  display: flex;
  background-image: url("/assets/images/hero/hwy93/highway-hero-md.jpg");
  background-position: center;
}

@media (min-width: 600px) {
  .hero-wrapper .fixed-hero#hwy93-hero {
    background-position: -100px center;
  }
}

@media (min-width: 768px) {
  .hero-wrapper .fixed-hero#hwy93-hero {
    background-position: center center;
  }
}

@media (min-width: 992px) {
  .hero-wrapper .fixed-hero#hwy93-hero {
    background-image: url("/assets/images/hero/hwy93/highway-hero-xl.jpg");
  }
}

@media (min-width: 1600px) {
  .hero-wrapper .fixed-hero#hwy93-hero {
    background-position: center top;
  }
}

.hero-wrapper .fixed-hero#hwy93-hero .hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 20px;
}

.hero-wrapper .fixed-hero#hwy93-hero .hero-content h3 {
  padding: 10px;
  background-color: #000;
  color: #fff;
  margin-bottom: 20px;
}

.hero-wrapper .fixed-hero#hwy93-hero .hero-content img {
  width: 100px;
  margin-bottom: auto;
}

.hero-wrapper .fixed-hero#hwy93-hero .hero-content .heading-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

@media (min-width: 992px) {
  .hero-wrapper .fixed-hero#hwy93-hero .hero-content {
    flex-direction: row;
  }
}

.hero-wrapper .fixed-hero#hwy93-hero .video-cta {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 600px) {
  .hero-wrapper .fixed-hero#hwy93-hero .site-wrapper-xl {
    width: 100%;
  }
  .hero-wrapper .fixed-hero#hwy93-hero .hero-content {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
  .hero-wrapper .fixed-hero#hwy93-hero .hero-content img {
    width: 200px;
    margin-bottom: auto;
  }
  .hero-wrapper .fixed-hero#hwy93-hero .video-cta {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .hero-wrapper .fixed-hero#hwy93-hero .video-cta button:focus {
    outline: none;
  }
}

@media (min-width: 992px) {
  .hero-wrapper .fixed-hero#hwy93-hero .hero-content img {
    margin: 20px 0;
  }
  .hero-wrapper .fixed-hero#hwy93-hero h3 {
    padding: 20px;
    margin-bottom: 40px;
  }
  .hero-wrapper .fixed-hero#hwy93-hero .video-cta .fa-stack {
    font-size: 250%;
  }
}

@media (min-width: 1200px) {
  .hero-wrapper .fixed-hero#hwy93-hero .hero-content img {
    width: 250px;
    margin: 40px 0;
  }
}

@media (min-width: 1400px) {
  .hero-wrapper .fixed-hero#hwy93-hero .hero-content .heading-container {
    margin-left: 5%;
  }
}

.hero-wrapper .fixed-hero#rkn-countdown {
  background-image: url("/assets/images/rkn2022/smoke-background.jpg");
  background-position: center;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
}

.hero-wrapper .fixed-hero#rkn-countdown .livestream-copy, .hero-wrapper .fixed-hero#rkn-countdown .livestream-container {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-wrapper .fixed-hero#rkn-countdown .livestream-copy {
  padding: 20px;
}

.hero-wrapper .fixed-hero#rkn-countdown .livestream-copy img {
  margin-bottom: 20px;
}

.hero-wrapper .fixed-hero#rkn-countdown .livestream-copy h2.giga {
  font-size: 6rem;
}

.hero-wrapper .fixed-hero#rkn-countdown .livestream-copy h2:last-child {
  font-size: 3rem;
  margin-bottom: 0;
}

.hero-wrapper .fixed-hero#rkn-countdown .livestream-container {
  padding: 20px;
}

.hero-wrapper .fixed-hero#rkn-countdown .livestream-container countdown {
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
  font-size: 64px;
  font-weight: bold;
}

.hero-wrapper .fixed-hero#rkn-countdown .livestream-container countdown .seconds {
  color: #dc0000;
}

.hero-wrapper .fixed-hero#rkn-countdown .livestream-container #livestream-embed {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  width: 100%;
  height: 0;
}

.hero-wrapper .fixed-hero#rkn-countdown .livestream-container #livestream-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 568px) {
  .hero-wrapper .fixed-hero#rkn-countdown .livestream-copy {
    padding: 50px;
  }
}

@media (min-width: 992px) {
  .hero-wrapper .fixed-hero#rkn-countdown {
    flex-direction: row;
  }
  .hero-wrapper .fixed-hero#rkn-countdown .livestream-copy h2.giga {
    font-size: 8rem;
  }
  .hero-wrapper .fixed-hero#rkn-countdown .livestream-copy img {
    margin-bottom: 30px;
  }
  .hero-wrapper .fixed-hero#rkn-countdown .livestream-container {
    padding: 40px;
  }
  .hero-wrapper .fixed-hero#rkn-countdown .livestream-container countdown {
    font-size: 96px;
  }
}

@media (min-width: 1200px) {
  .hero-wrapper .fixed-hero#rkn-countdown .livestream-container {
    padding: 40px 5vw;
  }
  .hero-wrapper .fixed-hero#rkn-countdown .livestream-container countdown {
    font-size: 128px;
  }
}

@media (min-width: 1200px) {
  .no-webp .hero-wrapper #garage-teaser .teaser-inner {
    background-size: 1890px 600px;
  }
}

@supports (-webkit-touch-callout: none) {
  @media (min-width: 885px) and (max-width: 1199px) {
    .hero-wrapper #garage-teaser .article-cta {
      right: 23% !important;
      top: 13% !important;
    }
  }
}

body.fixed-hero-active {
  overflow-x: hidden;
}

@media (min-width: 1200px) {
  body.fixed-hero-active {
    margin-top: 600px;
  }
}

@media (min-width: 1440px) {
  body.fixed-hero-active {
    margin-top: 600px;
  }
}

.feature-tile {
  position: relative;
  display: block;
  overflow: hidden;
  height: 100%;
}

.feature-tile:before {
  content: '';
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(0deg, #000000 10.4%, rgba(0, 0, 0, 0) 100%);
}

.feature-tile:hover > img {
  transform: scale(1);
}

.feature-tile > img {
  transform: scale(1.05);
  transform-origin: center center;
  transition: all 0.25s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature-tile .feature-tile-content {
  position: absolute;
  top: auto;
  bottom: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: auto;
  padding: 20px;
  color: #FFFFFF;
}

@media (min-width: 1440px) {
  .feature-tile .feature-tile-content {
    padding: 45px;
  }
}

.feature-tile .feature-tile-content img {
  width: 100%;
  max-width: 350px;
  height: auto;
  margin-bottom: 25px;
  transform-origin: center center;
  transition: all 0.25s ease-in-out;
}

.feature-tile .feature-tile-content p {
  font-size: 18px;
}

@media (min-width: 1440px) {
  .feature-tile .feature-tile-content p {
    font-size: 28px;
  }
}

#global-footer {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 12px;
  color: #FFFFFF;
  background-color: #000000;
  padding: 45px 15px;
  z-index: 2;
}

@media (min-width: 992px) {
  #global-footer {
    padding: 25px 15px;
  }
}

@media (min-width: 1200px) {
  #global-footer {
    padding: 45px 25px;
  }
}

@media (min-width: 1600px) {
  #global-footer {
    font-size: 14px;
  }
}

#global-footer .footer-logo {
  display: block;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  #global-footer .footer-logo {
    max-width: 200px;
    margin-left: 0;
    margin-bottom: 25px;
  }
}

#global-footer p, #global-footer a {
  font-size: inherit;
  color: #FFFFFF;
}

#global-footer .footer-heading {
  font-size: 21px;
  text-transform: uppercase;
}

#global-footer .content-container .content-links {
  color: #FFFFFF;
}

#global-footer .content-container .content-links > a {
  color: inherit;
  margin: 0 5px;
}

@media (min-width: 1200px) {
  #global-footer .content-container .content-links > a {
    font-size: 14px;
  }
}

#global-footer .content-container .content-links > a:first-child {
  margin-left: 0;
}

#global-footer .content-container .content-links > a:hover {
  color: #DC0000;
}

#global-footer .content-container .content-links > a:focus {
  text-decoration: underline;
}

#global-footer .quick-link-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 45px;
}

#global-footer .quick-link-container li {
  flex: 0 0 50%;
}

#global-footer .quick-link-container li a {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  #global-footer .quick-link-container li a {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  #global-footer .quick-link-container li a {
    font-size: 14px;
  }
}

#global-footer .quick-link-container li a:hover {
  color: #C9071B;
}

#global-footer .copyright, #global-footer .sign-up, #global-footer .brand-links {
  margin-bottom: 45px;
}

@media (min-width: 992px) {
  #global-footer .copyright, #global-footer .sign-up, #global-footer .brand-links {
    margin-bottom: 25px;
  }
}

@media (min-width: 1200px) {
  #global-footer .quick-links {
    max-width: 520px;
    margin-top: 17px;
    float: right;
  }
}

@media (min-width: 1200px) {
  #global-footer .quick-links .link-wrap {
    margin-left: -40px;
    margin-right: 40px;
  }
}

#global-footer .brand-links {
  padding: 0 25px;
}

@media (min-width: 992px) {
  #global-footer .brand-links {
    margin-bottom: 0;
    padding: 0;
    width: auto !important;
  }
}

#global-footer .brand-links .brand-link-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: white;
}

@media (min-width: 992px) {
  #global-footer .brand-links .brand-link-list {
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 0;
  }
}

#global-footer .brand-links .brand-link-list li {
  padding: 10px 5px;
}

#global-footer .brand-links .brand-link-list sup, #global-footer .brand-links .brand-link-list sub {
  font-size: 7px;
}

#global-footer .brand-links .brand-link-list a {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}

#global-footer .brand-links .brand-link-list a:hover, #global-footer .brand-links .brand-link-list a:focus {
  color: #DC0000;
}

#global-footer .partner-logos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  #global-footer .partner-logos {
    flex-direction: row;
    justify-content: center;
  }
}

@media (min-width: 992px) {
  #global-footer .partner-logos {
    flex: 1;
    justify-content: flex-start;
    margin-left: 20px;
    width: auto !important;
  }
}

#global-footer .partner-logos a {
  padding: 15px;
  max-width: 300px;
}

@media (min-width: 768px) {
  #global-footer .partner-logos a {
    max-width: none;
    flex: 0 0 200px;
  }
}

@media (min-width: 1200px) {
  #global-footer .partner-logos a {
    flex: 0 0 210px;
  }
}

@media (min-width: 1440px) {
  #global-footer .partner-logos a {
    flex: 0 0 300px;
  }
}

#global-footer .partner-logos a > img {
  width: 100%;
  height: auto;
}

#global-footer .partner-logos a.driveability {
  flex: 0 0 50%;
}

@media (min-width: 768px) {
  #global-footer .partner-logos a.driveability {
    flex: 0 0 300px;
  }
}

@media (min-width: 1200px) {
  #global-footer .partner-logos a.driveability {
    flex: 0 0 320px;
  }
}

@media (min-width: 1440px) {
  #global-footer .partner-logos a.driveability {
    flex: 0 0 400px;
  }
}

#global-footer .form-group.inline-combo {
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  font-size: 14px;
}

#global-footer .form-group.inline-combo .form-control, #global-footer .form-group.inline-combo .btn {
  font-size: inherit;
  border-radius: 0;
}

@media (min-width: 768px) {
  #global-footer .form-group.inline-combo .form-control, #global-footer .form-group.inline-combo .btn {
    padding: 5px 8px;
  }
}

@media (min-width: 992px) {
  .row-lg-flex {
    display: flex;
    align-items: center;
  }
}

dg-highway-map .gm-style-iw.gm-style-iw-c {
  max-width: 300px !important;
  padding: 12px !important;
}

.hero-video {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7F7F7F;
  text-align: center;
  min-height: 325px;
}

@media (min-width: 768px) {
  .hero-video {
    min-height: 420px;
  }
}

@media (min-width: 1200px) {
  .hero-video {
    min-height: 520px;
  }
}

@media (min-width: 1440px) and (min-height: 700px) {
  .hero-video {
    min-height: 640px;
  }
}

.hero-video .hero-headline {
  position: relative;
  z-index: 2;
  font-size: 4rem;
  color: #FFFFFF;
  text-shadow: 0px 5px 15px black;
}

@media (min-width: 768px) {
  .hero-video .hero-headline {
    font-size: 5.4rem;
  }
}

@media (min-width: 1440px) and (min-height: 700px) {
  .hero-video .hero-headline {
    font-size: 6.2rem;
  }
}

.hero-video .hero-headline sup {
  font-size: 50%;
  top: -0.75em;
}

.hero-video .bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.hero-video .controls {
  position: absolute;
  top: auto;
  left: auto;
  bottom: 1rem;
  right: 1rem;
}

.hero-video .controls .overlay-control {
  font-size: 1.8rem;
  color: #FFFFFF;
  background-color: black;
  border: 0;
  padding: 0.5rem 1rem;
}

.colors {
  display: flex;
  flex-wrap: wrap;
}

.colors .color-item {
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .colors .color-item {
    flex: 0 0 calc(100% / 7);
  }
}

.colors .color-item .swatch {
  cursor: pointer;
  position: relative;
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  transition: all 0.25s ease-in-out;
}

.colors .color-item .swatch:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #000000;
}

.colors .color-item .price {
  font-size: 1.2em;
  color: #DC0000;
  line-height: 1;
  font-weight: 700;
}

.colors .color-item .price.first {
  margin-top: auto;
}

.colors .color-item.active .swatch {
  border: 5px solid #DC0000;
  border-color: #DC0000;
}

#interior-gallery {
  padding: 0;
}

@media (min-width: 768px) {
  #interior-gallery {
    padding: 0 1.5rem;
  }
}

@media (min-width: 992px) {
  #interior-gallery #interior-image-slider .container-fluid {
    display: flex;
  }
}

@media (min-width: 992px) {
  #interior-gallery .interior-image-wrapper {
    flex: 0 0 340px;
    width: 340px;
  }
}

@media (min-width: 768px) {
  #interior-gallery .interior-image-wrapper .swiper-container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  #interior-gallery .interior-image-wrapper .swiper-container .swiper-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    transform: none !important;
  }
}

@media (min-width: 992px) {
  #interior-gallery .interior-image-wrapper .swiper-container .swiper-wrapper {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  #interior-gallery .interior-image-wrapper .swiper-container .swiper-wrapper .swiper-slide {
    flex: 0 0 48%;
    width: 48%;
    height: auto;
  }
}

@media (min-width: 992px) {
  #interior-gallery .interior-image-wrapper .swiper-container .swiper-wrapper .swiper-slide {
    flex: 0 0 48%;
    width: 48%;
    height: auto;
  }
}

#interior-gallery .interior-image-wrapper .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-duplicate {
  display: none;
}

#interior-gallery .image-wrap .img {
  cursor: pointer;
}

@media (min-width: 992px) {
  #interior-gallery .image-wrap .img.active {
    border: 3px solid #DC0000;
  }
}

@media (min-width: 992px) {
  #interior-gallery .image-wrap .img {
    height: 135px;
  }
}

@media (min-width: 768px) {
  #interior-gallery .image-wrap .img img {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 992px) {
  #interior-gallery .image-wrap .img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

#interior-gallery .image-wrap .description {
  font-family: "Trade Gothic LT W01 Bd Cn No-2", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  padding: 1rem;
}

@media (min-width: 768px) {
  #interior-gallery .image-wrap .description {
    display: flex;
    text-align: left;
    padding: 1rem 0;
  }
}

@media (min-width: 992px) {
  #interior-gallery .image-wrap .description {
    padding-top: 0.5rem;
  }
}

#interior-gallery .image-wrap .description div {
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  #interior-gallery .image-wrap .description div {
    flex: 1;
  }
}

#interior-gallery .image-wrap .description div:last-child {
  font-family: "Trade Gothic LT W01 Bd Cn No-2", sans-serif;
  color: #DC0000;
}

@media (min-width: 768px) {
  #interior-gallery .image-wrap .description div:last-child {
    flex: 0 1 auto;
  }
}

#interior-gallery .image-wrap .description div span:not(.icon-container) {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  #interior-gallery .image-wrap .description div span:not(.icon-container) {
    display: block;
    padding-right: 1rem;
  }
}

#interior-gallery .indicators {
  display: flex;
  justify-content: center;
  padding: 0 1.5rem;
}

@media (min-width: 768px) {
  #interior-gallery .indicators {
    display: none;
  }
}

#interior-gallery .indicators .dot, #interior-gallery .indicators .swiper-pagination-bullet {
  display: block;
  width: 1.4rem;
  height: 1.4rem;
  border: 2px solid #A5A5A5;
  border-radius: 50%;
  transition: all 0.25s ease-in-out;
  margin-right: 1rem;
}

#interior-gallery .indicators .dot:last-child, #interior-gallery .indicators .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

#interior-gallery .indicators .dot.swiper-pagination-bullet-active, #interior-gallery .indicators .dot.active, #interior-gallery .indicators .swiper-pagination-bullet.swiper-pagination-bullet-active, #interior-gallery .indicators .swiper-pagination-bullet.active {
  background-color: #A5A5A5;
}

#interior-gallery .icon-container {
  cursor: pointer;
  color: #73738C;
}

#interior-gallery .additional-options {
  padding: 0 1.5rem;
  padding-top: 3.5rem;
}

@media (min-width: 768px) {
  #interior-gallery .additional-options {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  #interior-gallery .additional-options {
    margin-top: auto;
    padding-top: 2rem;
  }
}

#interior-gallery .additional-options h3 {
  font-size: 2rem;
}

@media (min-width: 768px) {
  #interior-gallery .additional-options .list-underline {
    columns: 2;
    column-gap: 2.5rem;
  }
}

#interior-gallery .additional-options .list-underline li {
  display: flex;
  justify-content: space-between;
  font-family: "Trade Gothic LT W01 Bd Cn No-2", sans-serif;
  font-size: 1.4rem;
  padding: 1rem 0;
  border-bottom: 1px solid #A5A5A5;
}

@media (min-width: 768px) {
  #interior-gallery .additional-options .list-underline li {
    font-size: 1.6rem;
    break-inside: avoid-column;
  }
}

#interior-gallery .additional-options .list-underline li:first-child {
  padding-top: 0;
}

@media (min-width: 768px) {
  #interior-gallery .additional-options .list-underline li:nth-child(3) {
    break-after: column;
  }
}

@media (min-width: 768px) {
  #interior-gallery .additional-options .list-underline li:nth-child(4) {
    padding-top: 0;
  }
}

#interior-gallery .price {
  font-family: "Trade Gothic LT W01 Bd Cn No-2", sans-serif;
  color: #DC0000;
}

@media (min-width: 992px) {
  #interior-gallery .full-size-image-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
  }
}

@media (min-width: 1200px) {
  #interior-gallery .full-size-image-wrapper {
    padding-left: 3rem;
  }
}

#interior-gallery .full-size-image-wrapper .feature-image {
  display: none;
  position: relative;
}

@media (min-width: 992px) {
  #interior-gallery .full-size-image-wrapper .feature-image {
    display: block;
    flex: 1;
  }
}

#interior-gallery .full-size-image-wrapper .feature-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

#interior-gallery .full-size-image-wrapper .feature-image .icon-trigger {
  cursor: pointer;
  position: absolute;
  top: auto;
  left: auto;
  bottom: 0;
  right: 0;
  font-size: 3rem;
  color: #FFFFFF;
  padding: 2rem;
}

.modal .package-modal, .modal .allocation-modal {
  max-width: 480px;
}

.modal .package-modal .modal-content .modal-header, .modal .allocation-modal .modal-content .modal-header {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  padding-top: 0;
  padding-right: 0;
}

.modal .package-modal .modal-content .modal-header p, .modal .allocation-modal .modal-content .modal-header p {
  margin-bottom: 0;
}

.modal .package-modal .modal-content .modal-header .modal-close, .modal .allocation-modal .modal-content .modal-header .modal-close {
  position: static;
  padding: 0.75rem;
  margin-left: auto;
}

.modal .package-modal .modal-content .modal-body, .modal .allocation-modal .modal-content .modal-body {
  padding: 1.5rem;
  padding-top: 0;
}

.modal .package-modal .modal-content .modal-body ul, .modal .allocation-modal .modal-content .modal-body ul {
  padding-left: 2.25rem;
}

/* Hide the default checkbox */
.custom-control-input {
  display: none;
}

/* Style the custom checkbox */
.custom-checkbox {
  position: relative;
  display: inline-block;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #000000;
  cursor: pointer;
}

/* Checkmark style when checked */
.custom-checkbox:after {
  content: '';
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* When the input is checked, show the checkmark */
.custom-control-input:checked + .custom-checkbox {
  background-color: #DC0000;
  border-color: #DC0000;
}

.custom-control-input:checked + .custom-checkbox:after {
  display: block;
}

/* Label style */
.custom-control-label {
  font-weight: normal;
  margin-left: 10px;
  margin-bottom: 0;
  user-select: none;
  cursor: pointer;
}

.custom-control {
  display: flex;
}
