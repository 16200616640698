.stencils {
  //background-color: black;
  color: @black;

  @media (min-width: 992px) {
    background-image: url('/assets/images/stencils/dodge-halloween-bg.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    position: relative;
    color: @white;
    background-color: @black;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, @black 20%, fade(@black, 0));
    }
  }
  @media (min-width: 1600px) {
    background-position: 115% center;
  }

  .mobile-directions {
    color: @white;

    @media (min-width: 1200px) {
      display: none;
    }
  }

  .stencil-container {
    padding-bottom: 3rem;

    @media (min-width: 1200px) {
      overflow: hidden;
    }

    stencils {
      display: flex;
      flex-wrap: wrap;

      @media (min-width: 768px) {
        justify-content: space-between;
      }

      @media (min-width: 1200px) {
        flex-wrap: wrap;
        margin-left: 0;
        margin-right: 0;
        width: 65%;
        overflow-x: hidden;
      }
      @media (min-width: 1440px) {
        width: 55%;
      }

      .stencil {
        flex: 0 0 100%;
        max-width: 100%;
        font-size: 1.8rem;
        padding: 2rem;

        @media (max-width: 991px) {
          color: @black;
          padding: 2rem 0;
        }

        @media (min-width: 768px) {
          flex: 0 0 49%;
          max-width: 49%;
        }
        @media (min-width: 992px) {
          flex: 0 1 calc(100% / 3);
          max-width: calc(100% / 3);
          font-size: 2rem;
          color: @white;
        }

        img {
          border: 1px solid @black;
          border-bottom: 0;
        }

        &.stencil-all {
          display: flex;
          flex-direction: column;
          flex: 0 0 100%;
          max-width: 100%;

          @media (min-width: 768px) {
            align-items: center;
          }

          p {
            visibility: hidden;
          }

          .btn {
            flex: 0 1 auto;
            font-size: 1.8rem;
            white-space: normal;

            @media (min-width: 768px) {
              font-size: 2.8rem;
              width: auto;
            }
            @media (min-width: 1200px) {
              padding: 2rem;
              font-size: 2rem;
              white-space: nowrap;
              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .download-heading {
    
    @media (min-width: 992px) {
      font-size: 3.5rem;
      padding-left: 1.5rem;
    }
  }
  .download-subheading {
    @media (min-width: 992px) {
      padding-left: 1.5rem;
    }
  }
}


.holiday-cards {
  .download-heading {
    @media (min-width: 768px) {
      font-size: 3.2rem;
    }
  }
  .stencil-container {
    border: 5px solid @red1;
    padding: 2.5rem 1.5rem;

    holiday-cards {
      @media (min-width: 600px) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .download-item {
      margin-bottom: 3rem;
      a {
        display: block;
        box-shadow: 0px 2px 4px fade(@black, 35%);

        &.btn {
          margin-top: 1rem;
        }
      }

      @media (min-width: 600px) {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 1.5rem;
      }
      @media (min-width: 768px) {
        flex: 0 0 calc(100% / 3);
        max-width: calc(100% / 3);
        &:nth-last-child(-n+2) {
          margin-bottom: 0;
        }
      }
      @media (min-width: 992px) {
        flex: 0 0 20%;
        max-width: 20%;
        margin-bottom: 0;
      }
    }
  }
}

.downloads {
  &.stencil {
    background-color: @white;
    color: @black;

    p {
      text-align: center;
      margin-bottom: 0.25rem;
    }

    a:first-child {
      &:hover {
        img {
          opacity: 1;
        }
      }
    }

    img {
      border: 1px solid @black;
      border-bottom: 0;
      margin: 0;
    }
  }
}

.btn-dl-all {
  display: block; 
  max-width: 350px; 
  width: 100%;
  margin: 1.25rem auto 2.25rem 0;

  @media (min-width: 768px) {
    margin: 2rem 0 0 auto;
  }
}