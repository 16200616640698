#videos-section {
  padding: 35px 0;

  @media (min-width: 1440px) {
    padding: 100px 0;
  }

  .slider-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 35px;

    @media (min-width: 1440px) {
      margin-bottom: 50px;
    }

    .heading {
      font-family: $font-condensed;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 0;
      margin-right: 25px;

      @media (min-width: 1200px) {
        font-size: 32px;
      }
    }
    .btn {
      font-size: 14px;
      margin-bottom: 0;
      padding: 8px 20px;
    }
  }

  .dg-tabs {

    // Nav styling
    .nav-tabs {
      display: flex;

      .nav-item {
        flex: 0 1 auto;
        max-width: none;
        margin-right: 15px;
        margin-bottom: 15px;

        .nav-link {
          font-family: $font-condensed;
          font-size: 14px;
          font-weight: bold;
          color: $black;
          padding: 10px 15px;
          border: 2px solid $gray;

          &.active, &:hover {
            background-color: $white;
            border: 2px solid $primary-dark;
          }
        }
      }
    }

    // Tab styling
    .tab-content {
      .tab-pane {
        margin-bottom: 0;

        &.active {
          animation: fade-in 0.25s ease-in-out forwards;
        }

        .tile-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .video-thumbnail {
            flex: 0 0 47%;
            overflow: hidden;

            @media (min-width: 568px) {
              flex: 0 0 23%;
            }

            a {
              position: relative;
              display: block;

              &:hover {
                i { color: $primary-dark; }
              }

              img {
                width: 100%;
                height: auto;
              }

              i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all 0.25s ease-in-out;
                font-size: 40px;
                color: $white;
              }
            }

            .video-description {
              padding: 10px 0;

              p {
                font-size: 16px;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

#video-modal-heading {
  padding-right: 20px;
}
