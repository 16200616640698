.site-wrapper:not(.cdm-page), .site-wrapper-xl:not(.cdm-page) {
  // Forms
  .form-control {
    //border-radius: 0;
    font-size: @body-font-size;
    padding: @input-padding-y @input-padding-x;
    height: 44px;
    border: 1px solid @light-gray;
    box-shadow: none;
  }
  .form-control-static:not(.username-verification) {
    @media (min-width: 1200px) {
      font-size: 1.2rem;
    }
  }
  .form-control-static.username-verification {
    font-size: 1.6rem;
    font-family: @main-font;
  }
  label.control-label {
    font-size: @body-font-size;
    width: 100%;
  }
  .dg-radios, .dg-checkbox {
    display: flex;

    label {
      display: flex;
      align-items: center;
      padding-left: 0;

      &+ label {
        margin-left: 2.5rem;
      }

      input[type="radio"], input[type="checkbox"] {
        display: none;

        &:checked ~ .radio-icon {
          border-color: @red1;
          &:before {
            transform: translate(-50%, -50%) scale(1);
          }
        }
        &:checked ~ .checkbox-icon {
          border-color: @red1;
          background: @red1;
          &:before {
            //content: '\f00c';
            transform: translate(-50%, -50%) scale(.8);
          }
        }
      }

      .radio-icon {
        position: relative;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 2px solid @light-gray;
        margin-right: 0.75rem;
        transition: all 0.25s ease-in-out;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0);
          transform-origin: center center;
          transition: all 0.25s ease-in-out;
          width: 1rem;
          height: 1rem;
          background-color: @red1;
          border-radius: 50%;
        }
      }
      .checkbox-icon {
        position: relative;
        width: 2rem;
        height: 2rem;
        //border-radius: 50%;
        border-radius: 4px;
        border: 2px solid @light-gray;
        margin-right: 0.75rem;
        transition: all 0.25s ease-in-out;

        &:before {
          content: '\f00c';
          font-family: FontAwesome;
          font-weight: normal;
          font-style: normal;
          display: inline-block;
          text-decoration: inherit;
          position: absolute;
          top: 30%;
          left: 47%;
          transform: translate(-50%, -50%) scale(0);
          transform-origin: center center;
          transition: all 0.25s ease-in-out;
          width: 100%;
          height: 100%;
          //background-color: @red1;
          color: #FFF;
          font-size: 90%;
          //border-radius: 50%;
        }
      }
      .label-text {
        order: 1;
      }

    }
  }
  .btn-radio {
    display: block;
    text-align: left;
    margin-bottom: 5px;
    border: 2px solid transparent;
    padding: 0;
    background-color: #FFF;
    font-size: 1.8rem; //18px based on 10
    color: #000000;
    font-family: @main-font;
    & + .btn-radio {
      margin-top: 0;
    }
    &.active {
      color: @primary-03;
      border-color: @primary-03;
    }
    input {
      position: absolute;
      clip: rect(0,0,0,0);
      pointer-events: none;
    }
    label {
      display: block;
      width: 100%;
      padding: 6px 12px;
      white-space: normal;
    }
    .letter {
      display: inline-block;
      text-align: center;
      background: #F3F3F3;
      box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.18);
      border-radius: 50%;
      padding: 2px 8px 2px 10px;
      margin-right: 12px;
      text-transform: uppercase;
    }
  }
  label.img-upload {
    cursor: pointer;
  }

  // Input Groups
  .input-group {
    .input-group-btn:last-child > .btn {
      border-top-right-radius: @default-border-radius;
      border-bottom-right-radius: @default-border-radius;
    }
  }

  // Feedback states
  .has-success {
    .form-control-validation(@brand-success; @brand-success; @brand-success);
  }
  .has-warning {
    .form-control-validation(@brand-warning; @brand-warning; @brand-warning);
  }
  .has-error {
    .form-control-validation(@brand-danger; @brand-danger; @brand-danger);
  }

}
