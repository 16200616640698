// Ecwid
.ec-cart__next.ec-cart-next {
  display: none;
}
.ecwid-floatLeftPanel.ecwid-Checkout-DeliveryMethod {
  clear: both;
  .gwt-HTML.ecwid-Checkout-blockTitle {
    display: none;
  }
  .ecwid-MaskableWidget {
    display: none;
  }
}
