dg-drag-strip-showdown, dg-event-list-leaderboards {
  section {
    margin: 50px auto;
    h2 {
      font-family: @main-font;
      color: #000000;
      text-transform: uppercase;
      margin-bottom: 2rem;
    }
  }
  .row-flex {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-left: -15px;
    margin-right: -15px;
    //align-items: center;
    > div {
      float: none;
      @media (max-width: @screen-md-min) {
        width: 100%;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
      }
    }
  }
  // Contest Hero
  .landing-hero {
    display: block;
    background-image: url("/assets/images/drag-strip-showdown/hero-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    margin: 0 -15px 20px -15px;
    padding: 80px 30px;
    position: relative;
    @media (min-width: @screen-sm-min) {
      margin-left: 0;
      margin-right: 0;
    }
    img {
      max-width: 750px;
      margin: 0 auto;
      position: relative;
      z-index: 2;
    }
    &:after {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-color: rgba(0,0,0,0.5);
    }
  }
  // Contest Intro
  section.contest-intro {
    > .row > div {
      padding: 0 30px;
      @media (min-width: @screen-md-min) {
        padding: 0 50px;
      }
      &:nth-child(2) {
        &:after {
          @media (min-width: @screen-sm-min) {
            content: '';
            position: absolute;
            top: 25%;
            left: 0;
            height: 50%;
            border-left: 2px solid @grey3;
          }
        }
      }
    }
    p, li {
      font-size: 20px;
    }
    .sweepstakes-prize-img {
      max-width: 275px;
    }
  }

  // Countdown Timer
  .next-event-timer {
    background-color: #000000;
    color: #FFF;
    text-align: center;
    text-transform: uppercase;
    height: 100%;
    display: flex;
    align-items: center;
    > div {
      width: 100%;
    }
    .line-1 {
      font-family: @main-font;
      font-size: 18px;
      @media (min-width: @screen-sm-min) {
        font-size: 32px;
      }
    }
    .line-2 {
      font-family: @main-font;
      font-size: 25px;
      @media (min-width: @screen-sm-min) {
        font-size: 50px;
      }
      @media (min-width: @screen-lg-min) {
        font-size: 64px;
      }
    }
    hr {
      max-width: 105px;
      border-top: 2px solid #FFF;
    }
    .timer {
      font-family: @main-font;
      font-size: 50px;
      letter-spacing: -2.7px;
      @media (min-width: @screen-sm-min) {
        font-size: 100px;
      }
      @media (min-width: @screen-lg-min) {
        font-size: 130px;
      }
    }
  }

  // Enter Contest CTA
  .user-enter-cta-container {
    /*background-image: url("/assets/images/drag-strip-showdown/user-enter-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;*/
    background-color: #000000;
    color: #FFF;
    > div {
      max-width: 1000px;
      padding: 1.45rem 0;
    }
    h2 {
      /* Login or signup to e: */
      font-family: @main-font;
      color: #FFFFFF;
      text-transform: uppercase;
    }
    .btn {
      min-width: 150px;
    }
    .row-flex > div {
      padding: 20px;
    }
    .row-flex > div:nth-child(2) {
      text-align: center;
      //border-left: 2px solid #FFF;
      &:after {
        @media (min-width: @screen-md-min) {
          content: '';
          position: absolute;
          top: 25%;
          left: 0;
          height: 50%;
          border-left: 2px solid #FFF;
        }
      }
      h3 {
        font-family: @main-font;
        color: #FFFFFF;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
    }
    dg-login-form {
      .form-group {
        margin-bottom: 10px;
      }
      label {
        display: block;
        color: #FFFFFF;
        font-weight: 400;
      }
      input {
        //color: #585858;
        //background: #FFFFFF;
        //border: 2px solid #ADADAD;
        //border-radius: 4px;
        width: 100%;
        max-width: 350px;
        //padding: 6px 12px;
      }
      a {
        color: #FFF;
        font-family: @main-font;
      }
      button {
        &:extend(.btn-primary);
        &:hover {
          &:extend(.btn-primary:hover);
        }
      }
    }
    .countdown {
      p {
        vertical-align: middle;
        line-height: 60px;
        font-style: italic;
        @media (min-width: @screen-sm-min) {
          line-height: 110px;
        }
        @media (min-width: @screen-lg-min) {
          line-height: 140px;
        }
      }
      .timer {
        font-family: @main-font;
        font-style: normal;
        font-size: 50px;
        letter-spacing: -2.7px;
        vertical-align: middle;
        display: inline-block;
        margin: 0 5px;
        @media (min-width: @screen-sm-min) {
          font-size: 100px;
        }
        @media (min-width: @screen-lg-min) {
          font-size: 130px;
        }
      }
    }
  }

  // Upcoming Events
  .upcoming-events, .contest-rules {
    max-width: 1000px;
    hr {
      clear: both;
    }
  }
  // Event Text Card
  .event-text-card {
    display: block;
    margin-bottom: 20px;
    text-transform: uppercase;
    p {
      margin-top: 0;
      margin-bottom: 2px;
      color: @grey;
    }
    h3 {
      font-family: @main-font;
      font-size: 28px;
      color: #000000;
      margin-top: 0;
      margin-bottom: 2px;
    }
    &:hover {
      p, h3 {
        color: @red1;
      }
    }
  }


  // Social Sharing
  .addthis_toolbox {
    text-align: right;
    position: absolute;
    top: 1rem;
    right: 1rem;

    .at-share-btn:nth-child(3), .at-share-btn:nth-child(4), .at-share-btn:nth-child(5) {
      display: none;
    }
    // overrides to dynamically built sizing
    &.addthis_32x32_style {

      @custom-sizing: 4.5rem;

      @media (min-width: 600px) {
        span {
          width: @custom-sizing !important;
          height: @custom-sizing !important;
          line-height: @custom-sizing !important;
          > .at-icon {
            width: inherit !important;
            height: inherit !important;
          }
        }
      }
    }
  }
}

// Leaderboards
.leaderboard-header {
  padding: 25px;
  position: relative;
  background-image: url("/assets/images/drag-strip-showdown/upcomming-leaderboard-banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 185px;
  color: #FFF;
  &.is-national {
    background-image: url("/assets/images/drag-strip-showdown/national-leaderboard-banner.png");
  }
  &.is-event::before {
    background: rgba(0,0,0,0.75);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .live-updates {
    position: relative;
  }
  h1 {
    position: absolute;
    bottom: 30px;
    font-family: @main-font;
    @media (min-width: @screen-lg-min) {
      font-size: 40px;
    }
    small {
      font-family: @main-font-lt;
      font-size: 50%;
      display: block;
    }
  }

}
.leaderboard-table {
  width: 100%;
  //margin-bottom: 50px;
  border: 1px solid #D3D3D3;
  thead {
    tr {
      background: #ECECEC;
    }
    th {
      font-family: @main-font;
      font-size: 18px;
      color: @red1;
      padding: 3px 5px;
      @media (min-width: @screen-md-min) {
        font-size: 21px;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #D3D3D3;
    }
    td {
      font-size: 18px;
      padding: 5px;
      background-color: #F9F9F9;
      vertical-align: top;
      &:first-child {
        font-family: @main-font;
        text-align: center;
      }
      @media (min-width: @screen-md-min) {
        font-size: 21px;
      }
    }
    tr.user-race {
      border: 2px solid @red1;
      td {
        background-color: #FFFFFF;
      }
    }
  }
}

// Homepage Hero
dg-drag-strip-showdown-hero {
  display: block;
  background-color: #000;
  background-image: url("/assets/images/drag-strip-showdown/homepage/mobile-hero-img.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  margin: 0 auto 10px auto;
  position: relative;
  @media (min-width: @screen-sm-min) {
    background-image: url("/assets/images/drag-strip-showdown/hero-bg.jpg");
    background-size: cover;
    background-position: center center;
    margin: 10px auto;
  }
  > .clickable-hero {
    padding: 50px 15px;
    display: block;
    color: #FFF;
    position: relative;
    z-index: 2;
    > .container-fluid {
      max-width: 1175px;
      > .row > div {
        &:nth-child(2) {
          margin-top: 50px;
          @media (min-width: @screen-md-min) {
            margin-top: 0;
            padding-left: 50px;
          }
        }
      }
    }
    .contest-logo-container {
      margin-top: 25px;
      margin-bottom: 50px;
      @media (min-width: @screen-md-min) {
        margin: 0;
      }
    }
  }
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
  }

  // Leaderboard
  dg-leaderboard, .dg-leaderboard {
    display: block;
    .leaderboard-header.is-national {
      background: none;
      padding: 0;
      min-height: 0px;
      h1 {
        //&:extend(.lead);
        //text-align: center;
        &:extend(h2);
        &:extend(.heading2);
        &:extend(.text-uppercase);
        text-align: center;
        margin-top: 0;
        position: static;
      }
    }
    .leaderboard-table {
      width: 100%;
      border: 0;
      thead {
        tr {
          background-color: rgba(42,42,42,0.8);
        }
        th {
          font-family: @main-font;
          font-size: 18px;
          padding: 5px;
          color: #FFF;
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid rgba(42,42,42,0.8);
          &.first-place {
            font-family: @main-font;
          }
        }
        td {
          font-size: 15px;
          padding: 10px 5px;
          background-color: rgba(0,0,0,0.8);
          &:first-child {
            text-align: center;
          }
        }
      }
    }
  }

  // Sponsors
  .sponsor-container {
    max-width: 350px;
    margin: 25px auto 10px auto;
  }
}

// Full Leaderboard
.full-leaderboard-container {
  max-width: 800px;
  margin: 15px auto;
}

// Inner Leaderboard Pages
dg-event-list-leaderboards, dg-event-single-leaderboard, dg-national-leaderboard-page {
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
}
