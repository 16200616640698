.modal .modal-dialog.cdm-video {
  background-color: rgba(0,0,0,.5);

  .modal-content {
    margin-top: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    left: 0;

    //.modal-close i {
    //  @shadow-border: 1px;
    //  text-shadow: -@shadow-border -@shadow-border 3px #000,
    //    @shadow-border -@shadow-border 3px #000,
    //    -@shadow-border @shadow-border 3px #000,
    //    @shadow-border @shadow-border 3px #000;
    //}
  }
}
