.flex(@direction, @wrap) {
  display: flex;
  flex-direction: @direction;
  flex-wrap: @wrap;
}

.btn-reset() {
  background-color: transparent;
  border: 0;
}

.btn-ghost(@color) {
  border: 2px solid @color;
  color: @color;
}

.btn-theme(@bg, @color) {
  background-color: @bg;
  border-color: @bg;
  color: @color;
  transition: all 0.25s ease-in-out;

  &:hover {
    background-color: darken(@bg, 10%);
    border-color: darken(@bg, 10%);
  }
}

// Aspect ratio helper for embedded media.
// Provide dimensions ex. .aspect-ratio(16, 9)
.aspect-ratio(@width, @height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: (@height / @width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Forms
.form-control-validation(@text-color: #585858; @border-color: @light-gray; @background-color: #FFF) {
  // Color the label and help text
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label  {
    color: @text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: @border-color;
    .box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
    &:focus {
      border-color: darken(@border-color, 10%);
      @shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(@border-color, 20%);
      .box-shadow(@shadow);
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: @text-color;
    border-color: @border-color;
    background-color: @background-color;
  }
  // Optional feedback icon
  .form-control-feedback {
    color: @text-color;
  }
}

// Tile CTAs
// TODO research how to pass default parameters for heights
.tile-cta() {
  display: block;
  position: relative;
  height: 100%;
  //min-height: 200px;
  overflow: hidden;
  @media (min-width: @screen-lg-min) {
    min-height: 250px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    background-size: cover;
    transform-origin: center;
    transition: all .25s ease-in-out;
    transform: scale(1.05);
    //transform: translate(-50%, -50%) scale(1.05);
  }
  a {
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 2;
    height: 100%;
    padding: 30px;
    font-family: @main-font;
    text-transform: uppercase;
    font-size: 3rem; // 40px based on 10px
    line-height: normal;
    color: #FFF;
    text-decoration: none;
    @media (min-width: @screen-lg-min) {
      font-size: 3.75rem; // 42.5px based on 10px
    }
    &:hover, &:focus {
      text-decoration: none;
      color: #FFF;
    }
  }
  &:hover, &:focus {
    &::after {
      transform: scale(1);
    }
    a {
      color: #FFF;
    }
  }
  &.active a {
    border: 5px solid @primary-03;
  }
}
