.tile-grid {
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 0 0 100%;
    @media (min-width: 375px) {
      flex: 0 0 50%;
    }
    @media (min-width: 992px) {
      flex: 0 0 25%;
    }
  }

  &.three-up {
    > * {
      @media (min-width: 375px) {
        flex: 0 0 50%;
        aspect-ratio: auto;

        &:last-child {
          @media (max-width: 991px) {
            flex: 0 0 100%;
          }
        }
      }

      @media (min-width: 992px) {
        flex: 0 0 calc(100% / 3);
      }
    }
  }
}