/*#gallery > .container > .row {
	display: flex;
	flex-wrap: wrap;
	> [class^="col-"]:not(.col-xs-12) {
		float: none;
		position: relative;
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		@media (min-width: @screen-sm-min) {
			flex: 0 0 25%;
			max-width: 25%;
		}
	}
}*/

dg-gallery-card {
	display: block;
	border: solid 1px @secondary-02;
	margin-bottom: 2.4rem; // 24px based on 10px
}

.read-more-link {
	font-size: @body-font-size;
	font-family: @main-font;
	text-transform: uppercase;
	display: inline-block;
	padding: 5px 10px;
	color: #FFF;
	> i {
		margin-left: 0.4em;
		position: relative;
    top: 2px;
    font-size: 1.1em;
	}
}

.article-details {
	margin: 30px 0;
}

.select-dropdown {
   height: 29px;
   overflow: hidden;
   width: 100%;
   margin-bottom: 15px;
}

.select-dropdown select {
   background: transparent;
   border: none;
   font-size: 14px;
   height: 29px;
   padding: 5px; /* If you add too much padding here, the options won't show in IE */
   width: 110%;
}

.select-dropdown.slate {
   background: url('/assets/images/dropdown-arrow.jpg') no-repeat right center;
   height: 40px;
   width: 100%;
}

.select-dropdown.slate select {
   border: 1px solid #ccc;
   font-size: 16px;
   height: 40px;
   width: 110%;
}

input.submit {
  background: @red1;
  color: @white;
  padding: 6px 25px;
  margin-right: 10px!important;
  &:before {
    content: "\f087";
    font-family: FontAwesome;
  }
}


.img-container {
	padding: 10px;
	margin-bottom: 20px;
	border: solid 1px @secondary-02;
	a {
		//color: @darkish-gray;
		text-decoration: none;
		&:hover {

		}
	}
	.article-meta {
		margin-top: 0.8rem; // 8px based on 10px
	}
}
.img-container.active {
	// a.img-bg {
	// 	background-color: @black;
	// 	display: block;
	// }
	// img {
	// 	opacity: .6;
	// }
	// border: solid 1px @red1;
	box-shadow: 0px 0px 6px #999;
}


.img-timestamp {
	margin-right: 10px;
}


.img-details {
	margin-bottom: 40px;
	overflow: hidden;
	.close-btn {
		color: @light-gray;
		text-transform: uppercase;
		font-size: 20px;
	}
}

#image-gallery {
	.modal-dialog {
		width: 100%;
	}
}
.clear-btn {
	margin-top: 10px;
}

@media (min-width: @screen-sm-min) {

	#image-gallery {
		.modal-dialog {
			width: 80%;
		}
	}
}

// WORDPRESS GALLERY STYLING
.gallery, .wp-block-gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 15px;
	margin: 35px 0;

	// css injected text ont accessible by screen readers
	// &:before {
	// 	content: 'Explore the gallery';
	// 	font-size: 2.4rem;
	// 	width: 100%;
	// 	margin: 2rem auto;
	// 	border-top: 1px solid @black;
	// 	padding-top: 1rem;
	// }

	.gallery-item,
	.blocks-gallery-item,
	.wp-block-image {
		flex: 0 0 calc(~"100% / 2 - 10px");
		display: flex;
		aspect-ratio: 4/3;
		margin: 0;

		@media (min-width: 992px) {
			/* If there are exactly 3 items in a row, set them each to 33% */
			&:nth-child(3n),
			&:nth-child(3n-1),
			&:nth-child(3n-2) {
				flex: 0 0 calc(~"100% / 3 - 10px");
			}
	
			/* If there are exactly 2 items in the last row, set them each to take up equal space */
			&:nth-last-child(2):not(:nth-child(3n)) {
				flex: 1;
			}
	
			/* If there is only 1 item in the last row, set it to take up the full row */
			&:nth-last-child(1):not(:nth-child(3n)) {
				flex: 1;
			}
		}



		a {
			position: relative;
			height: 100%;
			overflow: hidden;
			border: 1px solid @light-gray;
			transition: all 0.25s ease-in-out;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				background-color: @red1;
				mix-blend-mode: multiply;
				opacity: 0;
				z-index: 1;
				transition: all 0.25s ease-in-out;
			}

			&:hover {
				border-color: @red1;
				&:before {
					opacity: 0.3;
				}
			}

			img {
				min-width: 100%;
				min-height: 100%;
				object-fit: cover;
			}
		}
		.gallery-caption, figcaption {
			display: none;
		}
	}
}
.single-article-container .page-content article .entry-content ul.wp-block-gallery {
	padding: 0;
}