$indicator-size: 10px;
$rhombus-skew: 30deg;

#feature-slider {

  //vars
  --slider-height: 300px;
  --item-spacing: 15px;
  --img-base: 85px;
  --img-expanded: 300px;
  --padding-right: 165px;

  @media (min-width: 1440px) {
    --slider-height: 360px;
    --item-spacing: 15px;
    --img-base: 125px;
    --img-expanded: 420px;
    --padding-right: 145px;
  }
  @media (min-width: 1600px) {
    --img-expanded: 460px;
  }
  @media (min-width: 1800px) {
    --slider-height: 420px;
    --item-spacing: 20px;
    --img-base: 170px;
    --img-expanded: 460px;
    --padding-right: 180px;
  }

  position: relative;
  padding: 35px 0.8rem;
  background-color: $white;

  @media (min-width: 1200px) {
    padding: 35px 0;
  }

  .text-white {
    color: white !important;
  }

  .toggle-auto-play {
    position: absolute;
    bottom: 0;
    right: 0;

    @media (min-width: 1200px) {
      right: 0;
      bottom: 40px;
    }

    .btn-link {
      &:focus-visible {
        border-color: white;
        border-width: 1px;
      }
      i.fa {
        margin-right: 0;
      }
    }
  }

  .feature-wrapper {
    display: flex;
    overflow: hidden;

    &.hide-mobile {
      height: 0;
      visibility: hidden;
    }

    .carousel-container {
      flex: 0 0 100%;
      align-self: center;
      overflow: hidden;
      margin: 0 auto;

      @media (min-width: 768px) {
        flex: 0 0 330px;
      }

      .swiper-container {
        .swiper-pagination {
          display: flex;
          justify-content: center;
          margin-bottom: 25px;

          @media (min-width: 768px) {
            justify-content: flex-start;
          }
          @media (min-width: 992px) {
            justify-content: center;
          }

          .carousel-nav-item {
            cursor: pointer;
            display: block;
            width: $indicator-size;
            height: $indicator-size;
            margin-right: $indicator-size;
            background-color: $gray-mid;

            &:last-of-type {
              margin-right: 0;
            }

            &.active {
              background-color: $primary-dark;
            }
          }
        }

        .swiper-wrapper {
          .swiper-slide {
            text-align: center;

            @media (min-width: 768px) {
              text-align: left;
            }

            @media (min-width: 992px) {
              text-align: center;
            }
            .slide-container {
              .slide-logo {
                display: block;
                max-width: 275px;
                margin: 0 auto 10px;

                @media (min-width: 768px) {
                  margin: 0 0 10px;
                }
                @media (min-width: 992px) {
                  margin: 0 auto;
                  margin-bottom: 10px;
                }
                @media (min-width: 1200px) {
                  max-width: 175px;
                }
                @media (min-width: 1440px) {
                  max-width: 240px;
                }
              }
              .slide-heading {
                font-size: 32px;
                line-height: 1.2;

                @media (min-width: 1440px) {
                  font-size: 30px;
                }
              }
              .slide-description {
                font-size: 16px;

                @media (min-width: 1440px) {
                  font-size: 24px;
                }
              }
              .btn {
                @media (min-width: 1800px) {
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
    }

    .carousel-images {
      display: flex;
      width: 100%;
      position: relative;
      padding-right: var(--padding-right);

      .item {
        flex: 0 0 var(--img-base);
        display: flex;
        height: var(--slider-height);
        overflow: hidden;
        margin-right: var(--item-spacing);
        transition: all $transition-global;
        transform: skew(-$rhombus-skew);
        transform-origin: bottom left;

        .image {
          position: relative;
          flex: 0 0 var(--img-base);
          height: 100%;
          overflow: hidden;
          transition: all $transition-global;
          cursor: pointer;

          &:before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background: linear-gradient(1.17deg, rgba($black, 0.7) 0.65%, rgba($black, 0.56) 82.41%);
            mix-blend-mode: luminosity;
            transition: opacity $transition-global;
          }

          &:hover {
            &:before { opacity: 0; }
          }

          img {
            position: absolute;
            left: 50%;
            height: 100%;
            width: auto;
            transform: translateX(-50%) skew($rhombus-skew);
            transition: all $transition-global;

            @media (min-width: 1600px) {
              height: 105%;
            }
          }

          // Special image styling for MVP slide
          &[data-ga_label="Dodge Complete Performance Package"] {
            img {
              position: absolute;
              top: 50%;
              left: auto;
              right: -30%;
              transform: translateX(0) translateY(-50%) skew($rhombus-skew);
              height: 100%;

              @media (min-width: 1440px) {
                height: 120%;
              }
              @media (min-width: 1600px) {
                right: -23%;
                height: 100%;
              }
              @media (min-width: 1800px) {
                right: -28%;
              }
            }
          }
        }
        
        .content {
          display: none;
          padding: 20px;
          font-size: 14px;
          text-align: center;
          opacity: 0;
          transform: skew($rhombus-skew);

          @media (min-width: 1200px) {
            font-size: 16px;
            transform: skew($rhombus-skew) translateX(10px);
          }
          @media (min-width: 1800px) {
            font-size: 20px;
            transform: skew($rhombus-skew) translateX(0);
            padding-left: 60px;
          }
          @media (min-width: 1920px) {
            font-size: 24px;
          }

          .indicators {
            display: flex;
            justify-content: center;
            margin-bottom: 25px;

            .indicator {
              cursor: pointer;
              display: block;
              width: calc(#{$indicator-size} * 1.56);
              height: $indicator-size;
              margin-right: $indicator-size;
              background-color: $gray-mid;
              transform: skew(-$rhombus-skew) translateY(-20px);

              &:last-of-type {
                margin-right: 0;
              }

              &.active {
                background-color: $primary-dark;
              }

              &:hover {
                background-color: $gray-dark;
              }
            }
          }

          .slide-logo {
            width: 230px;
            height: auto;
            margin-bottom: 15px;
          }

          .slide-heading {
            font-size: 1.5em;
          }
          .slide-description {
            font-size: 1em;
          }
        }

        &.active {
          flex: 1;

          .image {
            flex: 0 0 var(--img-expanded);

            &:before {
              opacity: 0;
            }
          }
          .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            animation: fade-in 0.25s ease-in-out 0.25s forwards;
          }
        }

        &:not(.end) {
          &:last-child {
            margin-right: 0;
          }
        }

        &.end {
          position: absolute;
          left: calc((var(--img-base) * 2) * -1 - var(--item-spacing));
          width: calc(var(--img-base) * 2);
          background-color: $primary-dark;

          &:last-of-type {
            left: auto;
            // right: calc(var(--img-base) * -1 + var(--item-spacing));
            right: -25px;

            @media (min-width: 1440px) {
              right: calc(var(--img-base) * -1);
            }
            @media (min-width: 1800px) {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
