.sidebar-container {
  padding: 20px;
  display: block;
  font-size: 2rem; // 20px based on 10px
  margin-bottom: 1.6rem; // 16px based on 10px

  nav {
    overflow-y: auto !important;
    max-height: ~"calc(100vh - 187px)";
  }

  @media screen and (min-width: @screen-md-min) {
    position: sticky;
    top: 70px;
  }

   ul {
     list-style: none;
     margin-top: 20px;
     padding: 0;
     &.child-list {
       padding-left: 40px;
     }
  }

  a {
    //text-decoration: none;
    color: @black;
    position: relative;

    sup, sub {
      display: inline-block;
      text-decoration: none;
    }

    &:hover, &:focus {
      color: @brand-primary;
    }

    &+ .child-list {
      display: none;
    }

    .menu-arrow {
      margin-left: 10px;
      pointer-events: none;
    }

    &.expanded .menu-arrow i {
      transform: rotate(-180deg);
    }

    &.expanded + .child-list {
      display:block;
    }

    &.active {
      position: relative;
      color: @brand-primary;

      //&:before {
      //  content: "";
      //  position: absolute;
      //  top: 2px;
      //  left: -10px;
      //  display: block;
      //  width: 5px;
      //  height: 20px;
      //  background-color: @brand-primary; //
      //}
    }
  }

  .btn.quick-nav-dropdown {
    display: block;
    background-color: @secondary-07;
    text-align: left;
    font-size: 2rem; // 20px based on 10px
    font-family: @main-font;
    &:hover, &:focus, &[aria-expanded="true"] {
      background-color: @brand-primary;
      color: #FFF;
    }
    @media (min-width: @screen-sm-min) {
      &:hover, &:focus {
        background-color: @brand-primary-hover;
      }
    }
    &::before, &::after {
      //right: 20px;
      @media (min-width: @screen-sm-min) {
        //content: none
      }
    }
  }
  #collapse-categories {
    border-left: 1px solid @secondary-02;
    border-right: 1px solid @secondary-02;
    border-bottom: 1px solid @secondary-02;
  }

  ul {
    padding: 5px 0;
    margin: 2px 0 0;
    text-align: left;
    list-style: none;
    > li > a {
      display: block;
      padding: 5px 20px;
      clear: both;
      color: @dark-gray;
      font-size: 2.0rem; // 20px based on 10px
      text-decoration: underline;
      &:hover, &:focus, &.active {
        color: @brand-primary;
        background-color: fade(@brand-primary, 5%);
      }
    }
  }
}


