#cdm-application-status {
  background-image: url('/assets/images/chief-donut-maker/light-asphalt-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 50px 0;

  .cdm-lead-image {
    margin-bottom: 50px;
  }

  #applicant-info, .cdm-card {
    margin: 0 20px;
  }

  #applicant-info {
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
    padding: 20px;
    margin-bottom: 20px;

    dl {
      display: flex;
      flex-direction: row;
      align-items: center;
      dt {
        font-size: 16px;
        margin-right: 20px;
      }
    }

    dd.applicant-data {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    @media (min-width: @screen-md-min) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .applicant-data, .applicant-stage {
    font-size: xx-large;
  }

  .applicant-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;

    @media (min-width: @screen-md-min) {
      padding: 50px;
    }
  }

  .stage-checkbox {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    border: 2px solid @white;

    &.checked::after {
      display: block;
      content: url('/assets/images/chief-donut-maker/cdm-checkbox.svg');
    }

    @media (min-width: @screen-sm-min) {
      margin-left: 50px;
    }
  }

  .cdm-card {
    margin-bottom: 20px;
  }

  #cdm-form {
    .form-group {
      margin-bottom: 20px;
    }
    label, input[type="text"] {
      text-align: left;
      width: 100%;
    }

    @media (min-width: @screen-sm-min) {
      .form-group {
        display: flex;
        flex-wrap: wrap;
      }
      label {
        flex: 0;
        min-width: 210px;
      }
      input[type="text"] {
        flex: 1;
      }
    }
  }

  .black-box {
    padding: 20px;
    margin: 0 20px;
    background-color: @black;
    color: @white;

    @media (min-width: @screen-sm-min) {
      padding: 60px;
    }
  }
}
