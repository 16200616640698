:root {
  --details-gradient: linear-gradient(to top, @black, fade(@black, 0));
  @media (min-width: @screen-md-min) {
    --article-height: 350px;
    --btn-height: 65px;
    --feature-width: 60%;
  }
  @media (min-width: @screen-lg-min) {
    --article-height: 450px;
  }
  @media (min-width: 1440px) {
    --article-height: 500px;
  }
}

.article-loader {
  height: 100%;
  color: @black;
  background-color: @white;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    width: 42px;
    height: 42px;
    line-height: 42px;
  }

  &.loader-dark {
    background-color: @black;
    color: @white;
  }
}

#homepage-content {
  #home-articles {
    position: relative;
    //min-height: 810px;

    // @media (min-width: @screen-sm-min) {
    //   min-height: 0;
    //   height: 690px;
    //   overflow: hidden;
    // }
    // @media (min-width: @screen-md-min) {
    //   height: 420px;
    // }
    // @media (min-width: @screen-lg-min) {
    //   height: 480px;

    //   @media (min-height: 700px) {
    //     height: 580px;
    //   }
    //   @media (min-height: 800px) {
    //     height: 650px;
    //   }
    // }

    &.event-active {
      margin-bottom: 10px;
    }
  }

  [data-section="dss-home"] {
    position: relative;

    @media (min-width: 768px) {
      min-height: 510px;
    }
    @media (min-width: 992px) {
      min-height: 465px;
    }
  }
}

recent-articles, dg-recent-articles {

  &[placement="footer"] {
    .event-container {
      padding-left: 3rem;
      padding-right: 3rem;

      @media (min-width: 768px) {
        display: flex;
      }
      .article-tile {
        background-color: @grey3;
        margin-bottom: 2rem;

        @media (min-width: 768px) {
          flex: 1;
          margin-right: 1rem;
          &:last-child {
            margin-right: 0;
          }
        }

        .tile-container {
          .event-details {
            padding: 1rem;
            padding-bottom: 2rem;
            .meta-container {
              .article-title {
                font-size: 2.4rem;
              }

              @media (min-width: 1200px) {
                .article-title {
                  font-size: 3.2rem;
                  margin-top: 1.5rem;
                }
              }
            }
          }
        }
      }
      .view-news {
        display: none;
      }
    }
  }

}

// Grid Variables
@homepage-gap: 10px; // TODO eventually we're moving to a 30px gap to match the rest of the site and bootstrap
@featured-article-height-sm: 350px;
@featured-article-height-lg: 500px;
//@featured-article-height-xl: 500px;
@btn-view-more-height: 62px;
@details-gradient: linear-gradient(to top, @black, fade(@black, 0));
.desktop-recent-article() {
  .article-img {
    height: 100%;
    img {
      object-fit: cover;
      height: 100%;
    }
  }
  .article-meta-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background: @details-gradient;
    border-top: 0;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
}
// For the future!
// @homepage-gap-x: 30px;
// @homepage-gap-y: (16px * 1.5); // 24px
/*
--details-gradient: linear-gradient(to top, @black, fade(@black, 0));
  @media (min-width: @screen-md-min) {
    --article-height: 350px;
    --btn-height: 65px;
    --feature-width: 60%;
  }
  @media (min-width: @screen-lg-min) {
    --article-height: 450px;
  }
  @media (min-width: 1440px) {
    --article-height: 500px;
  }
*/

// dg-recent-articles {
//   display: flex;
//   flex-wrap: wrap;
//   margin: 0 -(@homepage-gap / 2);
//   @media (min-width: @screen-md-min) {
//     display: block;
//   }
//   article {
//     position: relative;
//     width: 100%;
//     margin-bottom: @homepage-gap;
//     padding-left: 5px;
//     padding-right: 5px;
//     a, a:hover, a:focus {
//       display: block;
//       background-color: #000;
//       height: 100%;
//       color: #FFF;
//       text-decoration: none;
//       position: relative;
//     }
//     .article-meta-container {
//       padding: 15px;
//       border-top: 1px solid @secondary-06;
//     }
//     .entry-title {
//       font-family: @main-font;
//       margin-top: 0;
//       margin-bottom: 0.8rem; // 8px based on 10px
//       font-size: @body-font-size;
//       text-transform: uppercase;
//       //color: @body-color;
//     }
//     .article-meta {
//       color: #FFF;

//       .article-tags {
//         text-transform: uppercase;
//         margin-left: auto;
//       	margin-right: 1.6rem;
//         font-size: 16px;
//         a {
//           margin-right: 1.6rem;
//         }
//       }
//     }

//     @media (min-width: @screen-sm-min) {
//       &:first-child {
//         flex: 0 0 100%;
//         max-width: 100%;
//         height: @featured-article-height-sm;
//         .desktop-recent-article();
//         .entry-title {
//           font-size: @article-font-size;
//         }
//         .article-meta-container {
//           text-align: center;
//           .article-meta {
//             justify-content: center;
//           }
//         }
//       }
//       &:nth-child(2), &:nth-child(3) {
//         flex: 0 0 50%;
//         max-width: 50%;
//         height: 258px;
//         .desktop-recent-article();
//       }
//     }
//     @media (min-width: @screen-md-min) {
//       float: left;
//       &:first-child {
//         width: 50%;
//         max-width: none;
//         flex-basis: auto;
//       }
//       &:nth-child(2), &:nth-child(3) {
//         width: 25%;
//         height: ((@featured-article-height-sm + @btn-view-more-height) / 2);
//         max-width: none;
//         flex-basis: auto;
//       }
//     }
//     @media (min-width: @screen-lg-min) {
//       &:first-child {
//         height: @featured-article-height-lg;
//       }
//       &:nth-child(2), &:nth-child(3) {
//         height: ((@featured-article-height-lg + @btn-view-more-height) / 2);
//       }
//     }
//   }
//   .btn-view-news {
//     background-color: @secondary-06;
//     border-color: @secondary-06;
//     color: #FFF;
//     padding: 1.5rem 3rem;
//     &:hover {
//       background-color: @secondary-07;
//       color: #FFF;
//     }
//     @media (min-width: @screen-md-min) {
//       float: left;
//       height: @btn-view-more-height;
//       width: ~'calc(60% - 5px)';
//       margin-top: -(@btn-view-more-height + @homepage-gap); // The height + gap
//     }
//     /*@media (min-width: 992px) {
//       float: left;
//       width: 60%; //IE Fallback
//       width: var(--feature-width);
//       height: 65px; //IE Fallback
//       height: var(--btn-height);
//       line-height: 2;
//       margin-top: -65px; //IE Fallback
//       margin-top: ~'calc(var(--btn-height) * -1)';
//       border-right: 2px solid @white;
//       border-bottom: 2px solid @white;
//     }*/
//   }
//   &::after {
//     content: " ";
//     display: table;
//     clear: both;
//   }
// }