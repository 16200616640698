.cdm-page {

  // Flexbox column fixes
  .container-flex {
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;
  }

  // background headings
  .giga {
    font-size: 36px;
    margin-bottom: 0;

    @media screen and (min-width: @screen-xs-min) {
      font-size: 44px;
    }

    @media screen and (min-width: @screen-sm-min) {
      font-size: 56px;
    }

    @media screen and (min-width: @screen-md-min) {
      font-size: 96px;
    }

    @media screen and (min-width: @screen-lg-min) {
      font-size: 112px;
    }
  }

  .line-height-sm {
    line-height: 1.2;
  }

  span {
    &[class^="on-"] {
      display: inline;
      line-height: 1;
      padding: 0 5px;
      text-transform: uppercase;

      @media (min-width: @screen-lg-min) {
        padding: 0 10px;
      }
    }
    &.on-white {
      color: @black;
      background-color: @white;
    }
    &.on-black {
      color: @white;
      background-color: @black;
    }
    &.on-red {
      color: @white;
      background-color: @brand-primary;
    }
  }

  .vertical-text:not(.cdm-card-label-text) {
    font-size: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg); // rotate AFTER!
    margin: 0;

    @media (min-width: @screen-sm-min) {
      font-size: 2rem;
    }
  }

  .bottom-gap {
    margin-bottom: @homepage-gap;
  }

  .rball {
    font-size: small;
  }
}

.cdm-lead-image {
  max-width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
}
