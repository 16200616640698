// Drag Pak
dg-drag-pak, dg-drag-pak-access {
  display: block;
  .site-wrapper .page-header {
    background-color: #000;
    background-image: url('/assets/images/drag-pak/hero-v2.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    margin-top: 0;
    padding: 150px 30px 30px 30px;
    color: #FFF;
    text-align: center;

    @media (min-width: @screen-sm-min) {
      background-position: center center;
      min-height: 350px;
    }
    @media (min-width: @screen-lg-min) {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      min-height: 450px;
    }
    > * {
      position: relative;
      z-index: 2;
    }
    h1 {
      font-size: 3.4rem; // 34px based on 10px
      text-shadow: 0 4px 12px rgba(0,0,0,0.12);
    }
    hr {
      max-width: 75px;
    }
    p {
      margin: 0 auto;
      font-size: 4.9rem; // 49px based on 10px
      font-family: @main-font;
      line-height: normal;
      text-transform: uppercase;
      text-shadow: 0 4px 12px rgba(0,0,0,0.12);
      @media (min-width: @screen-sm-min) {
        font-size: 5.4rem; // 54px based on 10px
      }
    }
    // &:after {
    //   content: "";
    //   position: absolute;
    //   z-index: 1;
    //   bottom: 0;
    //   left: 0;
    //   pointer-events: none;
    //   background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(0,0,0, 1) 80%);
    //   width: 100%;
    //   height: 250px;
    //   transition: all 0.35s ease-in-out;
    // }
  }
  .slideshow-container {
    position: relative;

  }
  .tech-specs {
    min-height: 410px;
    background-color: #000;
    background-image: url('/assets/images/drag-pak/tech-specs-sm.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    color: #FFF;
    @media (min-width: 410px) {
      background-size: 100% auto;
    }
    @media (min-width: @screen-sm-min) {
      background-image: url('/assets/images/drag-pak/tech-specs-lg.jpg');
      background-size: cover;
    }
    > div {
      width: 100%;
      max-width: 716px;
      margin-left: auto;
      margin-right: auto;
    }
    .spec {
      font-size: 4.8rem; // 48px based on 10px
      font-family: @main-font;
    }
    .label {
      font-size: 2.4rem; // 24px based on 10px
      font-family: @main-font;
    }
  }
  .well {
    background-color: @secondary-01;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .header-container {
    text-align: center;
    margin-bottom: 25px;

    @media (min-width: 992px) {
      margin-bottom: 50px;
    }

    h1 {
      font-size: 20px;

      @media (min-width: 992px) {
        font-size: 34px;
      }
    }
    h2 {
      font-size: 32px;

      @media (min-width: 992px) {
        font-size: 46px;
      }
      @media (min-width: 1200px) {
        font-size: 54px;
      }
    }
    hr {
      max-width: 75px;
      border-width: 2px;
      border-color: @black;
      margin: 15px auto;
    }
  }
}

.vehicle-package {
  max-height: 500px;
  position: relative;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
  border: 1px solid @secondary-04;
  border-radius: 4px;
  margin-bottom: 10px;
  @media (min-width: @screen-md-min) {
    max-height: none;
    height: 100%;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 80%);
    width: 100%;
    height: 100px;
    transition: all 0.35s ease-in-out;
    @media (min-width: @screen-md-min) {
      content: none;
    }
  }
  > .btn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    z-index: 2;
    display: block;
    width: 100%;
    @media (min-width: @screen-md-min) {
      display: none;
    }
    &.btn-view-less {
      display: none;
    }
  }
  &.active {
    max-height: 10000px;
    &:after {
      opacity: 0;
    }
    > .btn.btn-view-more {
      display: none;
    }
    > .btn.btn-view-less {
      display: block;
    }
  }
  .caption {
    padding: 5px 15px;
  }
  .package-content {
    padding: 1.6rem; // 16px based on 10px

    .content-header {
      @media (min-width: 768px) {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
    }
    .title-and-price {
      span {
        display: inline-block;
        &.price {
          text-transform: none;
        }
      }
    }
    .price-note {
      font-size: 14px;
      line-height: 1;

      @media (min-width: 768px) {
        margin-bottom: 0;
        margin-left: 10px;
      }
    }
    ul {
      list-style: none; /* Remove default bullets */
      padding-left: 20px;
      li::before {
        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: @primary-03; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
      }
    }
  }
  &:not(.base-package) {

    @media (min-width: @screen-md-min) {
      display: flex;
      flex-wrap: wrap;
    }

    .package-img {
      @media (min-width: 992px) {
        flex: 0 0 45%;
      }
      @media (min-width: 1200px) {
        flex: 40%;
      }
    }

    .title-and-price {
      flex-wrap: wrap;
      line-height: 1.2;

      @media (min-width: 992px) {
        flex: 0 0 55%;
        padding: 0 1.6rem;
      }

      @media (min-width: 1200px) {
        flex: 60%;
        align-items: center;
      }

      span:first-child {
        @media (min-width: 992px) {
          margin-top: auto;
        }
      }

      .price {
        flex: 0 0 100%;
        line-height: 2;

        @media (min-width: 992px) {
          margin-bottom: auto;
        }
      }
    }

    .package-content {
      
      @media (min-width: 992px) {
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 0;

        ul {
          flex: 0 0 48%;

          // li {
            

          //   &:nth-child(odd) {
          //     border-right: 1px solid #A5A5A5;
          //   }
          // }
        }
      }
    }
  }
}

.expanding-gallery-container {
  max-height: 500px;
  position: relative;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
  padding-bottom: 50px;
  @media (min-width: @screen-md-min) {
    max-height: 225px;
  }
  @media (min-width: @screen-lg-min) {
    max-height: 275px;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 60%);
    width: 100%;
    height: 100px;
    transition: all 0.35s ease-in-out;
  }
  > .btn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    z-index: 2;
    display: block;
    margin-left: auto;
    margin-right: auto;
    &.btn-view-less {
      display: none;
    }
  }
  &.active {
    max-height: 10000px;
    &:after {
      opacity: 0;
    }
    > .btn.btn-view-more {
      display: none;
    }
    > .btn.btn-view-less {
      display: block;
    }
  }
}

.title-and-price {
  font-family: @main-font;
  font-size: @h4-font-size;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: (@h4-font-size / 2);
  span {
    display: block;
    &.price {
      color: @primary-03;

      &.included {
        font-size: 2rem;
      }
    }
    &.divider {
      background-color: @secondary-06;
      height: 1px;
      width: 25px;
      display: block;
      padding-top: 0;
      padding-bottom: 0;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.package-category {
  background-color: #000;
  color: #FFF;
  text-transform: none;
  text-align: center;
  padding: 5px;
}

.add-on-packages-contaier {
  .row-flex {
    min-height: ~"calc(100% - 60px)";
  }
}

.livery-options, .color-options {
  .title-and-price {
    justify-content: center;
  }
}

.dealer-information {
  border: 1px solid @secondary-02;
  border-radius: @input-border-radius;
  padding: 25px;
  margin-bottom: 15px;
  font-size: 1.8rem;  // 18px based on 10px
}

.order-summary {
  border: 1px solid @secondary-02;
  border-radius: 0.8rem; // 8px based on 10px
  section {
    //text-align: center;
    padding: 30px 30px;
    &:not(:first-child) {
      border-top: 1px solid @secondary-02;
    }
    > .row-flex {
      max-width: 760px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .summary-title {
    font-size: 4.0rem; // 40px based on 10px
    font-family: @main-font;
    text-transform: uppercase;
    margin-bottom: 2.0rem; // 20px based on 10px
  }
  .summary-subtitle {
    font-size: @body-font-size;
    text-transform: uppercase;
    font-family: @main-font;
    //margin-top: 1.6rem; // 16px based on 10px
    margin-bottom: 0.2rem; // 2px based on 10px
  }
  .summary-options {
    font-size: @font-size-lg; // 20px based on 10px
    margin-bottom: 1.6rem; // 16px based on 10px
  }
  .msrp {
    font-size: 1.8rem; // 18px based on 10px
    margin-bottom: 0.2rem; // 2px based on 10px
  }
  .total {
    font-size: 2.6rem; // 26px based on 10px
    font-family: @main-font;
  }
}

dg-confirmation {
  display: block;
  .alert {
    font-size: 1.8rem; // 18px based on 10px
    .row-flex {
      align-items: center;
    }
    .btn {
      font-size: 1.8rem; // 18px based on 10px
    }
  }
}

dg-drag-pak-countdown {
  position: fixed;
  bottom: 15px;
  right: 15px;
  margin-right: 1.6rem; // 16px based on 10px
  border: 1px solid @primary-01;
  background-color: @secondary-07;
  color: #fff;
  padding: 25px;
  max-width: 165px;
  line-height: normal;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 50%;
  z-index: 2;
  @media (min-width: @screen-lg-min) {
    right: calc(~'50% - '((@screen-lg-min/2) - 20px));
  }
  div {
    font-size: @body-font-size;
    &:last-child {
      font-size: @font-size-lg;
    }
  }
}

.feature-list-container {
  background-color: @secondary-01;
  padding: 15px;
  margin: 25px 0;
}

.features-list {
  list-style: none;
  padding: 0;

  @media (min-width: 768px) {
    column-count: 2;
    column-gap: 10px;
  }
  @media (min-width: 992px) {
    column-count: 3;
  }

  > li {
    background-color: @white;
    padding: 15px;
    margin-bottom: 10px;
    transition: all 0.5s ease-in-out;

    -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
               break-inside: avoid;
    
    &:hover {
      .box-shadow(0 3px 10px fade(@black, 35%));
    }

    &:not(:hover) {
      color: fade(@black, 65%);
    }

    span {
      font-family: @main-font;
      font-size: 21px;
    }

    ul {
      list-style-type: disc;
      padding-left: 25px;
      li {

      }
    }
  }
}

#hpds {
  .features-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      display: block;
      column-count: 2;
      column-gap: 2.5rem;
    }
    @media (min-width: 992px) {
      display: block;
      column-count: 2;
    }
  
    > li {
      background-color: @white;
      padding: 3rem;
      margin-bottom: 2.5rem;
      transition: all 0.5s ease-in-out;
  
      -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
                 break-inside: avoid;
      
      &:hover {
        .box-shadow(0 3px 10px fade(@black, 35%));
      }
  
      &:not(:hover) {
        color: fade(@black, 65%);
      }
  
      span, .li-title {
        text-transform: uppercase;
        font-family: @main-font;
        font-size: 21px;
      }

      .contacts {
        margin-top: 3rem;
        margin-bottom: 4rem;
      }
  
      ul {
        list-style-type: disc;
        padding-left: 25px;
        li {
          margin-bottom: 2rem;
        }
        p {
          margin-bottom: 2rem;
        }
      }
    }
  }
}

// homepage feature
#drag-pak-feature {
  padding: 0;
  overflow: hidden;

  .cta-tile {
    display: block;
    position: relative;
    margin-top: 15px;

    @media (min-width: 992px) {
      margin-top: 0;
    }

    img {
      width: 100%;
      height: auto;
    }
    .tile-caption {
      position: absolute;
      top: auto;
      bottom: 0;
      width: 100%;
      font-family: @main-font;
      font-size: 2.0rem; // 20px based on 10px
      line-height: 24px;
      color: #FFFFFF;
      text-align: center;
      text-transform: uppercase;
      padding: 5px 15px;

      @media (min-width: @screen-sm-min) { 
        font-size: 4rem; // 26px based on 10px   
        line-height: 1;
      }
      @media (min-width: 1440px) { 
        font-size: 4.9rem;
      }

      span {
        display: block;
        max-width: 250px;
        margin: 0 auto;

        @media (min-width: @screen-sm-min) { 
          max-width: 450px;
          margin-bottom: 25px; 
        }
        @media (min-width: @screen-md-min) { 
          margin-bottom: 0; 
        }
        @media (min-width: @screen-lg-min) { 
          text-shadow: 0px 2px 2px fade(@black, 25%), 0px 3px 6px fade(@black, 15%), 0px 4px 12px fade(@black, 12%);
        }
        @media (min-width: 1440px) { 
          max-width: 570px;
          margin-bottom: 10px;
        }
      }
    }
  }

  &.alt {
    img {
      width: 100%;
      height: auto;
    }

    .cta-tile {
      text-decoration: none;
      .tile-caption {
        position: static;

        @media (min-width: 1440px) {
          font-size: 5.9rem;

          span {
            max-width: 660px;
          }
        }
      }
    }

    .row {
      @media (min-width: 992px) {
        display: flex;

        .cta-tile {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }
  }
}

// Video Modal
#video-drag-pak {
  .modal-body {
    padding: 2.5rem 0 0 0;
  }
}

#drag-pak-feature {
  .play-container {
    .play-button {
      width: 100px !important;
    }
  }
}

// Caption styling for gallery
#baguetteBox-overlay .full-image figcaption { font-size: 16px; line-height: 3; }