//ANIMATIONS

@keyframes slide-in {

	//variables used to define translate values
	@start-pos: 	2000px;
	@end-pos: 		0;

	from {
		opacity: 0;
		transform: translateX(@start-pos);
	}
	to {
		opacity: 1;
		transform: translateX(@end-pos);
	}

}

@keyframes slide-in-right {

	//variables used to define translate values
	@start-pos: 	-100px;
	@end-pos: 		0;

	from {
		opacity: 0;
		transform: translateX(@start-pos);
	}
	to {
		opacity: 1;
		transform: translateX(@end-pos);
	}

}

@keyframes slide-in-v {

	//variables used to define translate values
	@start-pos: 	-200px;
	@end-pos: 		0;

	from {
		opacity: 0;
		transform: translateY(@start-pos);
	}
	to {
		opacity: 1;
		transform: translateY(@end-pos);
	}

}

@keyframes slide-up-v {
	//variables used to define translate values
	@start-pos: 		0;
	@end-pos: 	-300px;

	from {
		margin-top: @start-pos;
	}
	to {
		margin-top: @end-pos;
	}
}

@keyframes slide-down-v {
	//variables used to define translate values
	@start-pos: 		-150px;
	@end-pos: 	0;

	from {
		margin-top: @start-pos;
	}
	to {
		margin-top: @end-pos;
	}
}

@keyframes fade-in {

	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}

}

@keyframes fade-back {

	from {
		opacity: 1;
	}
	to {
		opacity: 0.06;
	}

}

@keyframes recordPulse {
	from {
		text-shadow: 0 0 0.5rem fade(@red1, 90%);
	}
	to {
		text-shadow: 0 0 1rem fade(@red1, 90%);
	}
}


.animation-loop (@i) when (@i > 0) {

	@stag: 150 * (@i - 1); // skip first

	.article-container:nth-child(@{i}) {
		animation-delay: ~"@{stag}ms";
	}

	.animation-loop(@i - 1);

}

@iterations: 10; // specify iteration count. set value to be > the amount of posts on page
.animation-loop(@iterations); // execute loop
