@nav-height-mobile: 55px + 48px;
@nav-height-tablet: 125px + 48px;
@nav-height-primary: 92px + 48px;

@live-banner-height: 48px;


.modal {
  z-index: 100;
  .modal-dialog {
    margin-top: @nav-height-mobile + 10px;
    @media (min-width: 768px) {
      margin-top: @nav-height-tablet + 10px;
    }
    @media (min-width: 992px) {
      margin-top: @nav-height-primary + 10px;
    }

    &.modal-xl {
      width: 90%;
      max-width: 1024px;
    }
  }
  &#signinModal {
    z-index: 10
  }
  .modal-full, .modal-full-lg {
    width: 100vw;
    height: 100vh;
    margin: 0;

    @media (min-width: 992px) {
      margin: 0 auto;
    }

    .modal-content {
      height: 100%;
      padding-top: @nav-height-mobile;
      margin: 0 auto;
      overflow-y: auto;

      @media (min-width: 768px) {
        padding-top: @nav-height-tablet;
      }
      @media (min-width: 992px) {
        padding-top: @nav-height-primary;
      }

      .fixed-nav & {
        padding-top: @nav-height-mobile;

        @media (min-width: 768px) and (max-width: 991px) {
          padding-top: @nav-height-tablet;
        }
      }

      .bracket-banner-open &, .nav-banner-open & {
        padding-top: (@navbar-height-xs + 76px);
        @media (min-width: @screen-sm-min) {
          padding-top: (@navbar-height-sm + 54px);
        }
        @media (min-width: @screen-md-min) {
          padding-top: (@navbar-height-md + 82px);
        }
      }

      .modal-container {
        max-width: 768px;
        width: 100%;
        height: inherit;
        margin: 0 auto;

        // @media (min-width: 600px) and (min-height: 768px) {
        //   display: flex;
        //   flex-direction: column;
        //   justify-content: center;
        // }
        &.modal-container-lg {
          max-width: none;
          &:extend(.container);
        }
        .modal-header {
          padding: 2.5rem;
          .modal-close {
            font-size: 2.8rem;
            background-color: transparent;
            border: 0;
            position: absolute;
            top: @nav-height-mobile;
            right: 1rem;

            .fixed-nav & {
              top: @nav-height-mobile;

              @media (min-width: 768px) and (max-width: 991px) {
                top: @nav-height-tablet;
              }
            }

            @media (min-width: 768px) {
              top: @nav-height-tablet;
            }
            @media (min-width: 992px) {
              top: @nav-height-primary;
            }
            //.bracket-banner-open .modal#news-modal .modal-dialog
            .bracket-banner-open &, .nav-banner-open & {
              top: (@navbar-height-xs + 106px);

              @media (min-width: 380px) {
                top: (@navbar-height-xs + 76px);
              }

              @media (min-width: @screen-sm-min) {
                top: (@navbar-height-sm + 54px);
              }
              @media (min-width: @screen-md-min) {
                top: (@navbar-height-md + 82px);
              }
            }
            .fixed-nav.bracket-banner-open &, .fixed-nav.nav-banner-open & {
              top: (@nav-height-mobile + 76px);
              @media (min-width: 768px) and (max-width: 991px) {
                top: (@nav-height-tablet + 82px);
              }
            }
          }
        }

        .modal-body {
          width: 100%;
          overflow-y: auto;
          background-color: white;
        }
      }
    }
    // transparent image preview lightbox
    &.modal-preview {
      .modal-content {
        box-shadow: none;
        border: 0;
        background-color: transparent;

        .modal-container {
          max-width: 100%;
          .modal-header .modal-close {
            color: @white;
          }
          .modal-body {
            background-color: transparent;
          }
        }
      }
    }
  }
  .modal-full {
    max-width: @screen-xl-min;
    .modal-content {
      max-width: @screen-xl-min;
    }
  }
  .modal-full-lg {
    max-width: @screen-lg-min - 30px;
    .modal-content {
      max-width: @screen-lg-min - 30px;
    }
  }
  &#news-modal, &#event-modal {

    .modal-close-container {
      position: absolute;
      top: 86px;
      z-index: 5;
      width: 100%;
      display: flex;
      padding: 1rem 1.5rem;

      @media (min-width: 768px) {
        top: 129px;
      }
      @media (min-width: 992px) {
        top: 92px;
        padding: 1rem 3.5rem;
      }

      // .bracket-banner-open &, .nav-banner-open & {
      //   top: (@navbar-height-xs + 96px);

      //   @media (min-width: 380px) {
      //     top: (@navbar-height-xs + 76px);
      //   }
      //   @media (min-width: @screen-sm-min) {
      //     top: (@navbar-height-sm + 74px);
      //   }
      //   @media (min-width: @screen-md-min) {
      //     top: (@navbar-height-md + 82px);
      //   }
      // }

      .modal-close {
        background-color: #FFF;
        font-size: 2.8rem;
        line-height: 0;
        text-shadow: none;
        opacity: 1;
        border: 0;
        margin-left: auto;
        padding: 5px 8px;
        @media (min-width: 992px) {
          right: 25px;
        }
      }
    }

    .modal-dialog {
      //padding-top: 66px;
      min-height: 100vh;

      .modal-content {
        padding-top: 0;
        .modal-header {
          position: static;
          padding: 0;
          // .modal-close {
          //   position: fixed;
          //   z-index: 5;
          //   background-color: #FFF;
          //   line-height: 0;
          //   text-shadow: none;
          //   opacity: 1;
          //   padding: 5px 8px;
          //   margin: 10px 10px 0 0;
          //   @media (min-width: 992px) {
          //     margin-right: 25px;
          //   }
          // }
        }
        .modal-body {
          padding: 0;
        }
      }
    }
    body.fixed-nav & {
      .modal-dialog {
        /*@media (min-width: 992px) {
          padding-top: 54px;
        }*/
      }
    }
  }

}
.modal-backdrop {
  z-index: 10;
}
// TODO Optimize for modals that are not full width
.modal-header {
  padding: 2.5rem;
  .modal-title {
    font-size: 3.2rem; // 32px
    margin-bottom: 0;
    padding-right: 2rem;
  }
  .modal-close {
    font-size: 2.8rem;
    background-color: transparent;
    border: 0;
    position: absolute;
    //top: @nav-height-mobile;
    right: 1rem;
    padding: 0;
  }
}
.modal .modal-body {
  padding: 2.5rem;
  p {
    &.description {
      margin-top: -2.5rem; // -25px based on 10px
    }
  }
}

.run-details {
  .details {
    display: flex;
    flex-wrap: wrap;

    .slip-img {
      display: none;
    }
    .user-info {

    }

    .run-stat {
      &[data-stat="et"] {

      }
      &[data-stat="sixty"] {

      }
      &[data-stat="speed"] {

      }
      &[data-stat="category"] {

      }
      &[data-stat="vehicle"] {

      }
      &[data-stat="track"] {

      }
      &[data-stat="mods"] {

      }
    }
  }
}

// Video modal styling
.modal {
  z-index: 100; // on top of banner and nav
  .modal-dialog {
    &.neverlift-video, &.cdm-video {
      height: 100vh;
      width: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;

      //@media (min-width: @screen-sm-min) {
      //  justify-content: center;
      //}

      @media (max-height: 550px) {
        padding-top: 170px;
        justify-content: flex-start;
      }

      .modal-content {
        width: 90%;
        max-width: 768px;
        height: auto;
        margin-top: 140px;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: 1024px) {

          @media (max-height: 899px) {
            margin-top: 170px;
            max-width: 550px;
          }
          @media (min-height: 900px) {
            margin-top: 50px;
          }
        }

        @media (min-width: 1200px) {
          @media (min-height: 750px) { max-width: 900px; }
        }

        .modal-container {
          position: relative;

          .modal-header {
            position: absolute;
            top: -50px;
            left: auto;
            right: 0;
            padding: 0;

            .modal-close {
              position: static;
              color: @white;
            }
          }
          .modal-body {
            padding: 0;
          }
        }
      }
    }

    &.modal-package-info {
      max-width: 480px;
      .modal-header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid @light-gray;
        padding: 15px 2.5rem;
  
        span {
          font-family: @main-font;
          font-size: 21px;
          text-transform: uppercase;

          @media (min-width: 768px) {
            font-size: 32px;
          }
        }
  
        .modal-close {
          position: static;
          margin-left: auto;
        }
      }
      .modal-body {
        .list-package {
          padding-left: 0;

          li {
            font-size: 14px;
            line-height: 1.2;
            margin-bottom: 15px;

            @media (min-width: 768px) {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

// Availibility modal styling for HPL
// Modal styling
.availability-modal {
  width: 95%;
  max-width: 768px;
  margin-top: 35px !important; // force modal to open higher on all viewports. Override bs defaults in media queries.

  .modal-container {
    .modal-header {
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;

      p {
        margin-bottom: 0;
      }
    }

    .modal-body {
      padding-top: .5rem;
    }
  }
}
