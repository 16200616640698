dg-user-profile, dg-manage-account {
  display: block;


  // Styles for both
  .heading1 small {
    font-size: 2rem;
    text-transform: capitalize;
  }

  .garage-user-header {
    padding: 1.5rem;

    .manage-account {
      margin-left: -1.5rem;
      display: flex;
      flex-wrap: wrap;
      .btn {
        flex: 1;

        &.btn-cart {
          display: flex;
          align-items: center;
          justify-content: center;

          .quantity {
            background-color: @white;
            font-size: 75%;
            color: @red1;
            padding: 0.25rem 0.5rem;
            border-radius: 25%;
            margin-left: 1rem;
          }
        }

        &.btn-leaderboard {
          flex: 0 0 100%;
          max-width: none;
          margin-top: 1rem;
        }


        @media (min-width: 600px) {
          max-width: 175px;
        }
        @media (min-width: 1200px) {

        }
        &:nth-child(1) {
          margin-right: 1rem;
        }
      }
      .addthis_inline_share_toolbox {
        margin-top: 1rem;
        @media (min-width: @screen-md-min) {
          margin-left: auto;
        }
      }
      .flyout {
        flex: 0 0 100%;
        max-width: none;
        margin-top: 1rem;
        .btn {
          margin-right: 0;
        }
      }
    }

    @media (min-width: 768px) {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;

      > div {
        &:first-of-type {
          flex: 1;
        }
        &:last-of-type {
          flex: 0 0 235px;
          min-width: 235px;
        }
        &.header-public {
          flex: 0 0 100%;
          //min-width: 100%;
          padding: 2rem 0;
        }
      }
    }
    @media (min-width: 992px) {
      margin-bottom: 0;
    }
    @media (min-width: 1200px) {
      > div {
        &:last-of-type {
          flex: 0 0 300px;
          min-width: 300px;
        }
        &.header-public {
          flex: 1;
        }
      }
      .heading1 {
        font-size: 5rem;
      }
    }
  }

  .vehicle-tabs-container {
    background-color: @light-gray-bg;
    padding-top: 1.5rem;

    @media (min-width: 768px) {
      background-color: transparent;
    }
  }

  .vehicle-tabs {

    @media (max-width: 767px) {
      .nav-tabs {
        display: none;
      }
      .tab-content {
        padding-top: 0;
        .tab-pane {
          display: block;
          margin-bottom: 2rem;
          box-shadow: 0px 2px 4px fade(@black, 15%);
          .vehicle-details {
            .vehicle-header {
              float: left;
            }
            .btn-edit-vehicle {
              float: right;
            }
          }
        }
      }
    }

    > .nav-tabs {
      @media (min-width: 768px) {
        //padding: 0 2rem;
      }

      .nav-item {
        .nav-link {
          border: 0;
          background: none;
          color: @dark-gray;
          border-bottom: 4px solid transparent;

          @media (min-width: 768px) {
            font-size: 1.8rem;
          }
          @media (min-width: 1200px) {
            font-size: 2.4rem;
          }

          &.active {
            color: @red1;
            border-color: @red1;
          }
        }
      }
    }
    > .tab-content {
      background-color: @white;
      padding: 1.5rem;
      padding-top: 0;

      .badges-container {
        //margin-top: 15px;
        padding: 15px;

        .badge-boxes {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 7rem;
          position: relative;
          padding-bottom: 2rem;
          border-bottom: 1px solid @light-gray;

          @media (min-width: 600px) {
            border-bottom: 0;
            margin-top: 0;
          }

          // remove border from last slot
          // last child will not work because of markup
          &[data-category="speed"] {
            border-bottom: 0;
            padding-bottom: 0;
          }

          @media (min-width: 768px) {
            margin-top: 2rem;
            border-bottom: 0;
            padding-bottom: 0;
          }

          .badge-label {
            width: 100%;
            font-family: @main-font;
            font-size: 1.4rem;
            text-align: center;
            text-transform: uppercase;
            position: absolute;
            top: -3rem;
            left: 50%;
            transform: translateX(-50%);

            @media (min-width: 992px) {
              font-size: 1.6rem;
              top: -3rem;
            }
            @media (min-width: 1200px) {
              top: -2rem;
            }
          }

          &.member-badge {
            //@media (min-width: 768px) {
              .badge-box {
                .badge-1320 {
                  .single-badge {
                    .btn {
                      width: 100%;
                      max-width: 200px;

                      @media (min-width: 768px) {
                        font-size: 1.4rem;
                      }
                      @media (min-width: 992px) {
                        font-size: 1.6rem;
                        width: 85%;
                      }
                      @media (min-width: 1200px) {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            //}
          }

          // CTA to buy membership items
          &.memmber-cta {
            //@media (min-width: 768px) {
              //height: 250px;
              margin-top: 0;
              .badge-box {
                height: 100%;
                .badge-1320 {
                  .single-badge {
                    height: 100%;
                    img {
                      height: auto;
                      width: 180px;
                    }
                  }
                }
              }
            //}
          }

          .btn-slot {
            .btn-reset();
            font-size: 2.4rem;

            i {
              transition: all 0.25s ease-in-out;
              transform-origin: center center;
              transform: scale(1);
            }

            &:hover, &:focus {
              outline: 0;
              i {
                transform: scale(1.2);
              }
            }


            &[disabled] {
              opacity: 0.25;
            }

            &.slot-next {
              order: 1;
            }
          }

          .badge-box {
            position: relative;
            height: 125px;
            overflow: hidden;
            margin: 1rem 0;

            &.public {
              height: 75px;
            }

            .article-loader {
              background-color: inherit;
            }
            .badges {
              transition: all 0.25s ease-in-out;
            }


            .badge-1320 {
              text-align: center;

              img {
                height: 75px;
                width: auto;
                margin: 0 auto;
              }
              .btn {
                margin: 0 auto;
                margin-top: 1rem;
                font-size: 14px;

                span {
                  &:nth-child(2) {
                    display: none;
                  }
                }

                &.btn-unavailable {
                  background-color: transparent;
                  border-color: @dark-gray;
                  color: @dark-gray;
                  width: 50%;
                  span {
                    &:nth-child(1) {
                      display: none;
                    }
                    &:nth-child(2) {
                      display: block;
                    }
                  }
                }
                @media (min-width: 768px) {
                  //width: 75%;
                }
              }

              .single-badge {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 150px;
                padding-bottom: 3rem;
              }
            }
          }
        }
      }

      .tab-pane {
        background-color: @white;

        @media (min-width: 768px) {
          padding: 1.5rem 0;
          padding-top: 3rem;
        }

        .vehicle-tab-lead {
          margin-bottom: 3rem;
        }

        .vehicle-img-container {
          position: relative;
          padding-top: 56.25%; //16:9 ratio
          overflow: hidden;
          background-size: cover;
          background-position: center center;

          img {
            display: none;
            //position: absolute;
            //top: 50%;
            //left: 0;
            //transform: translateY(-50%);
            //width: 100%;
            //height: auto;
            //min-width: 100%;
            //max-width: none;
            //min-height: 100%;
          }
        }

        .vehicle-details {
          padding: 1.5rem 3rem;
          display: flex;
          flex-wrap: wrap;
          padding: 1.5rem 2.5rem;

          .vehicle-header {
            font-family: @main-font;
            text-transform: uppercase;
            margin-bottom: 0;
            flex: 0 0 90%;
            min-width: 90%;
          }

          .btn-edit-vehicle {
            text-align: right;
            padding-top: 0;
            padding-bottom: 0;
            flex: 0 0 10%;
            min-width: 10%;
            order: 1;
          }

          .vehicle-mods {
            flex: 0 0 100%;
            width: 100%;
            order: 1;
            margin-top: 2rem;
            .vehicle-mods-desc {
              white-space: pre-wrap;
              max-height: 300px;
              overflow-y: auto;

              @media (min-width: 768px) {
                max-height: 225px;
              }
              @media (min-width: 992px) {
                max-height: 275px;
              }
              @media (min-width: 1200px) {
                max-height: 260px;
              }
              @media (min-width: 1300px) {
                max-height: 350px;
              }
              @media (min-width: 1600px) {
                max-height: 390px;
              }
            }
          }

          .subheading {
            font-size: 2.1rem;
          }

          @media (min-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            padding: 0 0 0 1.5rem;

            .vehicle-header {
              flex: 0 0 100%;
              width: 100%;
            }
            .btn-edit-vehicle {
              text-align: left;
              padding-left: 0;
              flex: 0 1 auto;
              min-width: 110px;
              order: 0;
              margin-bottom: 1.5rem;
              i {
                margin-right: 0.5rem;
              }
            }
          }
          @media (min-width: 992px) {
            .vehicle-header {
              font-size: 3.2rem;
            }
            .subheading {
              font-size: 2.4rem;
            }
            p {
              font-size: 1.8rem;
            }
          }
          @media (min-width: 1200px) {
            .vehicle-header {
              font-size: 4rem;
            }
            .subheading {
              font-size: 3rem;
            }
          }
        }

      }
    }
  }

  .vehicle-header {
    margin-top: 0;
  }


  .submission-table {
    .table {
      font-size: 1.6rem;
      border: 1px solid @dark-gray;
      margin-top: 2rem;
      thead {
        background-color: @dark-gray;
        tr {
          th {
            color: @white;
          }
        }
      }
      tbody {
        tr {
          td {
            &.edit {
              line-height: 0;
              .btn-link {
                font-size: inherit;
                color: inherit;
                line-height: 1;
                text-decoration: underline;
                text-transform: uppercase;
                text-align: center;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }


  .public-profile { // Styles for public profiles only
    .my-garage-tabs > ul.nav-tabs {
      display: none;
    }
    .vehicle-tabs {
      .tab-content {
        .badges-container {
          background: #FFF;
        }
      }
    }
  }


  .my-garage-container { // Styles specific for logged in users
    .my-garage-tabs {
      margin-top: 3rem;

      > ul.nav-tabs {
        max-width: 1600px;
        margin: 0 auto;
        border: 0;
        .nav-link {
          font-size: 2.5rem; // 40px
          background-color: @lightest-gray;
          border-radius: 0;
          &.active {
            color: @red1;
            border: 0;
            border-bottom: 4px solid @red1;
          }
        }
      }
      > .tab-content {
        border-top: 1px solid #ddd;
        background-color: @lightest-gray;
        position: relative;
        .btn-add-vehicle {
        }
      }
    }
    .btn-edit-vehicle {
      text-transform: uppercase;
      color: @gray;
    }
    .vehicle-tabs {
      .tab-content {
        //background: #FFF;
        .badges-container {
          background: @lightest-gray;
        }
        .tab-pane {
          background-color: @white;
        }
      }
    }
    .submissions-container {
      .collapse {
        .well {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

}
