// Video List and Page
dg-video-page {
  display: block;
  @media (min-width: @screen-md-min) {
    padding-left: 1.6rem; // 16px based on 10px
    padding-right: 1.6rem; // 16px based on 10px
  }
}

// Videos
dg-video-list {
	display: block;
	.video-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    .video-thumbnail {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      @media (min-width: @screen-sm-min) {
        flex: 0 0 calc(100% / 3);
        max-width: calc(100% / 3);
      }
      @media (min-width: @screen-lg-min) {
        flex: 0 0 calc(100% / 4);
        max-width: calc(100% / 4);
      }
    }
    .video-description {
      margin-top: 10px;
    }
  }
  .video-thumbnail {
    a {
      display: block;
      position: relative;
      &:after {
        content: '';
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 51 66'%3E%3Cdefs%3E%3Cpath id='a' d='M36.667 22.917v64.166L87.083 55z'/%3E%3Cpath id='c' d='M0 0h229v229H0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(-36 -22)'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cg mask='url(%23b)'%3E%3Cuse fill='%23FFF' transform='translate(-59.583 -59.583)' xlink:href='%23c'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center center;
        background-size: contain;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 25%;
        height: 25%;
        transition: all .25s ease-in-out;
      }
      &:hover:after, &:focus:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 51 66'%3E%3Cdefs%3E%3Cpath id='a' d='M36.667 22.917v64.166L87.083 55z'/%3E%3Cpath id='c' d='M0 0h229v229H0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(-36 -22)'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cg mask='url(%23b)'%3E%3Cuse fill='%23DC0000' transform='translate(-59.583 -59.583)' xlink:href='%23c'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
