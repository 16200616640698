//TYPOGRAPHY


// HEADINGS
h1, .h1, .heading1 {
	font-family: @main-font;
	text-transform: uppercase;
	font-size: @h1-font-size;
	position: relative;
	margin-top: 0;
	margin-bottom: 10px;
	&:after {
		font-size: 2.5em;
		opacity: 0.05;
		position: absolute;
		top: -83%;
		left: 3%;
	}

	@media (min-width: @screen-sm-min) {
		&:after {
			font-size: 3em;
		}
	}
}
h2, .h2, .heading2 {
	font-family: @main-font;
	font-size: @h2-font-size;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: (@h2-font-size / 2);
}
h3, .h3, .heading3 {
	font-family: @main-font;
	font-size: @h3-font-size;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 1.5rem; // 15px based on 10px
}
h4, .h4, .heading4 {
	font-family: @main-font;
	font-size: @h4-font-size;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: (@h4-font-size / 2);
}

.heading-main {
	//font-family: @main-font;
	text-transform: uppercase;
	//font-size: 40px;
	//margin: 20px 0 30px;
	//color: @gray;
	margin-top: 2.0rem; // 20px based on 10px
	margin-bottom: 3.2rem; // 32px based on 10px
	position: relative;
	&:before {
		content: '';
		position: absolute;
		bottom: -5px;
		left: 0;
		border-bottom: 2px solid @gray;
		width: 100%;
	}
	&:after {
		content: '';
		position: absolute;
		bottom: -5px;
		left: 0;
		width: 75px;
		border-bottom: 2px solid @brand-primary;
	}
}

.heading-sm {
	color: @gray;
	font-size: 22px;
	font-family: @main-font;
}

//adds margin to page heading to separate from navigaiton.
.heading-adjust {
	margin-top: 2rem;
}

.livestream-pulse {
	color: @red1;
	animation: recordPulse 1s infinite alternate both;
}

.uppercase {
	text-transform: uppercase;
}
a {
	color: @brand-primary;
	transition: .35s ease-in-out;
	-webkit-transition: .35s ease-in-out;
	-moz-transition: .35s ease-in-out;
	text-decoration: underline;
	&:hover {
		//color: darken(@brand-primary, 6.5%);
		color: @brand-primary-hover;
		//text-decoration: none;
	}
	.inline-icon {
		display: inline-flex;
    align-self: center;
    width: 1em;
		height: 1em;
		&.inline-icon-right {
			margin-left: .25em;
		}
		&.inline-icon-left {
			margin-right: .25em;
		}
	}
}
strong {
  font-family: @main-font;
}
p {
	margin-top: 0;
	margin-bottom: 1.6rem; // 16px based on 10px
}

//text helpers
.realign {
	font-size: 50%;
	&.superscript {
		top: -1em;
	}
	&.subscript {
		top: 0.5em;
	}
	&.baseline {
		top: -1em;
	}
}

small {
	color: inherit !important;
}


//HEADING WITH IMAGE AS CONTENT
.heading-img {
	margin-bottom: 2rem;
	.img-title {
		width: 100%;
		height: auto;
		max-width: 420px;
	}
}


// Control Text
.text-success {
  color: @brand-success;
}
.text-danger {
  color: @brand-danger;
}

// Text Alignment
.text-center-md {
	@media (min-width: @screen-md-min) {
		text-align: center;
	}
}
.text-right-sm {
	@media (min-width: @screen-sm-min) {
		text-align: right;
	}
}

// Sweeping Changes - MRC 2020-4-10
p, li, address {
	font-size: @body-font-size;
	line-height: 1.5;
	/*a, a:hover, a:focus {
		text-decoration: underline;
	}*/
	&.lead {
		font-weight: normal;
		font-size: @font-size-lg;
	}
	&.font-sm {
		font-size: @font-size-sm;
	}
}
.site-wrapper {
	.page-content {
		p, li {
			font-size: @article-font-size;
			line-height: 1.4;
		}
	}
}
// Lists
.list-2-cols-sm {
	@media (min-width: @screen-md-min) {
		column-count: 2;
		column-gap: 40px;
	}
}
.list-3-cols-sm {
	@media (min-width: @screen-md-min) {
		column-count: 3;
		column-gap: 40px;
	}
}