.contributor-container {
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  padding-bottom: 4rem;

  .contributor-media {
    img {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .contributor-bio {
    .heading1 {
      font-family: @main-font;
    }
  }

  &:last-of-type {
    margin-bottom: 3rem;
  }

  @media (min-width: @screen-sm-min) {
    flex-direction: row;
    .contributor-media {
      flex: 0 0 260px;
    }
    .contributor-bio {
      flex: 1;
      padding-left: 2rem;
    }
  }
  @media (min-width: @screen-lg-min) {
    .contributor-media {
      flex: 0 0 400px;
    }
  }
  @media (min-width: 1440px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
    .contributor-media {
      flex: 0 0 500px;
    }
    .contributor-bio {
      padding-left: 6rem;
      padding-right: 6rem;
      p {
        font-size: 1.75rem;
      }
    }
  }
}

.btn-container {
  background-color: @grey3;
  .btn {
    max-width: 40rem;
    margin: 3rem auto;
  }
}

// Button
.btn-contributors {
  background-color: #FFF;
  border-color: @secondary-07;
  color: @dark-gray;
  padding: 1rem 2rem 1rem 3rem;
  margin-bottom: 1.8rem; // 18px based on 10px
  font-family: @main-font;
  font-size: 2rem; // 20px based on 10px
  text-align: left;
  &:hover {
    background-color: @secondary-07;
    color: @white;
  }
  @media (min-width: @screen-sm-min) {
    padding-left: 4rem;
  }
  @media (min-width: @screen-md-min) {
    padding-left: 5rem;
  }
}
