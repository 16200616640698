.media-tile {
  margin-bottom: 2rem;
  .preview {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: @white;
    background-color: @black;
    background-size: cover;
    background-position: center center;
    padding: 1rem;
    .trigger {
      flex: 0 0 10rem;
      padding: 0 1rem;
    }
    .content {
      flex: 1;
      padding: 0 1rem;
      .title {
        font-family: @main-font;
        font-size: 2.25rem;
        margin: 0;
      }
      .desc {
        margin-top: 0;
      }
    }
    @media (min-width: 600px) {
      .trigger {
        flex: 0 0 7rem;
      }
      .content {
        .title {
          font-size: 1.8rem;
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      .content {
        .title {
          font-size: 2.8rem;
        }
      }
    }
    @media (min-width: @screen-md-min) {
      .content {
        .title {
          font-size: 2.25rem;
        }
      }
    }
    @media (min-width: @screen-lg-min) {
      .trigger {
        padding: 0;
      }
      .content {
        .title {
          font-size: 2.1rem;
        }
      }
    }
    @media (min-width: 1440px) {
      padding: 2rem 1rem;
      .trigger {
        padding-right: 1.5rem;
      }
      .content {
        .title {
          font-size: 2.8rem;
          line-height: 1.25;
        }
      }
    }
  }

  .dropup {
    .dropdown-menu {
      width: 100%;
      border-radius: 0;
      box-shadow: none;
      li {
        a {
          font-size: 2.1rem;
        }
      }
    }
  }

  &.ringtone {
    @media (min-width: 600px) {
      flex: 1;
      &:nth-child(odd) {
        margin-right: 1rem;
      }
    }
  }
}


.tile-img {
  position: relative;
  overflow: hidden;
  height: 250px;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    height: 175px;
  }
  @media (min-width: 992px) {
    height: 275px;
  }
  @media (min-width: 1200px) {
    height: 350px;
    margin-top: 3rem;
  }

  .bg-img {
    position: absolute;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      display: block;
      font-family: @main-font;
      font-size: 2.8rem;
      color: @white;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 1rem;

      @media (min-width: 768px) {
        font-size: 1.8rem;
      }
      @media (min-width: 992px) {
        font-size: 2.8rem;
      }
      @media (min-width: 1200px) {
        font-size: 3.2rem;
      }
    }
    .btn {
      max-width: 150px;
      margin-bottom: 0;
    }
  }
}
