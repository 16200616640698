//GLOBAL

html {
	position: relative;
  min-height: 100%;
}
.emoji {
  max-height: 30px;
}
body {
	font-family: @main-font-lt;
	color: @body-color;
  min-height: 100vh;
  //margin-bottom: 190px;
  //padding-top: @navbar-height-xs;
	// @media (min-width: @screen-sm-min) {
	// 	padding-top: @navbar-height-sm;
	// }
	// @media (min-width: @screen-md-min) {
	// 	padding-top: @navbar-height-md;
  // }
  &.nav-banner-open {
    padding-top: @navbar-height-xs + @banner-height;
    @media (min-width: @screen-sm-min) {
      padding-top: @navbar-height-sm + @banner-height;
    }
    @media (min-width: @screen-md-min) {
      padding-top: @navbar-height-md + @banner-height-md;
    }
  }
  app-root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    > main {
      min-height: 1px;
      flex-grow: 1;
    }
  }
}
.emoji {
  max-height: 30px;
}
.disfull {
	display: block;
}
// Site Wrapper / Page Template
.site-wrapper {
	max-width: @screen-xl-min;
	margin: 0 auto;
	//overflow: hidden;
  position: relative;
  &.skinny-site-wrapper, &.site-wrapper-lg {
		max-width: @screen-lg-min - 30px;
	}
  &.my-600-px-life-wrapper {
    max-width: 600px;
  }
  header.page-header {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 40px;
    h1, .h1 {
      &:extend(.heading1);
      .lead-in {
        font-size: 2.0rem; // 20px based on 10px
        line-height: 1.5;
      }
    }
    p, li {
      font-size: 2.0rem; // 20px based on 10px
      max-width: 600px;
      @media (min-width: @screen-md-min) {
        max-width: 825px;
      }
    }
  }
}
.section-header {
	> .row {
		@media (min-width: @screen-sm-min) {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
	border-bottom: 1px solid @secondary-01;
	margin-bottom: 20px;
}
.page-content {
	margin: 40px 0;
}
.img-responsive {
	width: 100%;
}
a:focus {
	//outline: none; disabling visible focus state on anchors is bad for accessibility
	text-decoration: none;
}
// COLORS
.white {
	color: @white!important;
}
.gray {
	color: @gray;
}
.gray-bg {
	background-color: #f4f4f4;
}
.red {
	color: @brand-primary!important;
}
.center {
	text-align: center;
}
.bold {
	font-weight: bold;
}
.font-bold {
	font-family: @main-font;
}
// avoid using this class for accessibility purposes
// https://accessiblewebsiteservices.com/headings-two-accessibility-tips-you-may-not-know/
.heading-small {
	margin: 0;
	font-size: 18px;
}
.no-margin {
	margin: 0;
}
.padding-20 {
  padding: 20px;
}
.heading-lg {
	font-family: @main-font;
	text-transform: uppercase;
	font-size: 40px;
}

.media-embed {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

hr {
  border-top: 2px solid @secondary-01;
}

.divider {
	padding-top: 2rem;
	padding-bottom: 2rem;

	&.dark {
		background-color: @dark-gray;
	}

	@media (min-width: @screen-md-min) {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}


#events, #locator, .event-header {
  .cta-img {
    padding: 10px;
    //max-width: 300px;
    @media (min-width: @screen-sm-min) {
      float: right;
    }
    &a {
      display: block;
    }
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
}
// New Image CTAs
.img-cta {
  display: block;
  height: 100%;
  img {
    height: 100%;
  }
}

.flex-row {
	@media (min-width: @screen-md-min) {
		display: flex;
		flex-direction: row;
	}

	&.full-flex {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		&:before, &:after {
			display: none;
		}
		.flex-column {
			float: none;
		}
	}

  .order-last {
    order: 13;
  }
  .order-last-md {
    @media (min-width: @screen-md-min) {
      order: 13;
    }
  }
  &:before, &:after {
    content: none;
  }
}
.spotlight-container {
  padding: 0;
  @media (min-width: @screen-md-min) {
    padding-left: 5px;
  }
}

.video-link {
	display: block;
	&:before {
		content: '\f04b';
		font-family: FontAwesome;
		font-size: 5em;
		color: @white;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
	}
}


//used for breaking out content in a different color
.color-bg {
	&.gray {
		background-color: @grey3;
	}
}

// Sign in
.sign-in-helper {
  font-size: 18px;
}

//embed footer
.embed-footer {
	padding: 1.5rem;
	background-color: @lightest-gray;
}

.full-embed {
	width: 100%;
	height: calc(~'100vh - 52px');

	@media (min-width: 768px) {
		height: calc(~'100vh - 92px');
	}
}

// Pagination
dg-pagination {
  display: block;
  text-align: center;
}

// Breadcrumbs
ol.breadcrumb {
  background: none;
  padding-left: 0;
  padding-right: 0;
}

// Utility Classes
.mt-0 { margin-top: 0 !important; }
.mt-1 { margin-top: (16px * 0.25) !important; } // 4px
.mt-2 { margin-top: (16px * 0.5) !important; } // 8px
.mt-3 { margin-top: (16px * 1) !important; } // 16px
.mt-4 { margin-top: (16px * 1.5) !important; } // 24px
.mt-5 { margin-top: (16px * 3) !important; } // 48px

.mb-0 { margin-bottom: 0 !important; }
.mb-1 { margin-bottom: (16px * 0.25) !important; } // 4px
.mb-2 { margin-bottom: (16px * 0.5) !important; } // 8px
.mb-3 { margin-bottom: (16px * 1) !important; } // 16px
.mb-4 { margin-bottom: (16px * 1.5) !important; } // 24px
.mb-5 { margin-bottom: (16px * 3) !important; } // 48px

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: @screen-sm-min) {
  .px-xs-4 {
    padding-left: 1.6rem; // 16px based on 10px
    padding-right: 1.6rem; // 16px based on 10px
  }
}
@media (min-width: @screen-xs-max) and (max-width: @screen-sm-min) {
  .px-sm-4 {
    padding-left: 1.6rem; // 16px based on 10px
    padding-right: 1.6rem; // 16px based on 10px
  }
}
@media (max-width: @screen-md-min) {
  .px-md-4 {
    padding-left: 1.6rem; // 16px based on 10px
    padding-right: 1.6rem; // 16px based on 10px
  }
}
.w-100 {
  width: 100% !important;
}

// Maps
#map {
	width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  .loading-icon {
    width: 100%;
    text-align: center;
  }
}

// Hidden-xl
.hidden-xl {
  @media (min-width: @screen-xl-min) {
    display: none !important;
  }
}
