.table {

  &#dodge-official-table {
    border: 1px solid @gray;
    background: linear-gradient(to right, lighten(@light-gray, 35%), lighten(@light-gray, 25%));
    thead {
      display: none;
    }
    tr {
      display: block; //table-row
      border-bottom: 1px solid @lighter-gray;
      padding: 1rem 0;
      td {
        font-size: 1.6rem;
        display: block; //table-cell
        border: 0;
        padding: 0.25rem 1rem;
        padding-bottom: 0;
        &:first-child {
          font-family: @main-font;
          font-size: 1.8rem;
          //color: @red1;
          padding-top: 0;
        }
      }
    }

    @media (min-width: @screen-sm-min) {
      background: @white;
      thead {
        display: table-header-group;
        th {
          font-size: 1.8rem;
          border: 1px solid @lighter-gray;
        }
      }
      tr {
        display: table-row;
        td {
          display: table-cell;
          vertical-align: middle;
          padding: 1rem;
          border: 1px solid @lighter-gray;
          &:first-child {
            padding-top: inherit;
          }
        }
      }
    }
    @media (min-width: @screen-md-min) {
      tr {
        td {
          font-size: 1.8rem;
        }
      }
    }
    @media (min-width: @screen-md-min) {
      thead {
        th {
          font-size: 2.4rem;
        }
      }
      tr {
        td, td:first-child {
          font-size: 2.1rem;
        }
      }
    }
  }

  &.table-default {
    th {
      border: 1px solid @black;
      border-top: 1px solid @black !important; //bs override
      background-color: @lighter-gray;
    }
    td {
      border: 1px solid @black;
    }
  }
}
