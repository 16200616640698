.feature-grid {
  .feature-tile {
    margin-top: 10px;

    a {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: all 0.25s ease-in-out;
      background-color: fade(@black, 20%);

      &:hover {
        background-color: fade(@black, 0%);
      }
    }
    &[data-feature="track-my-demon"] {
      a {
        
      }
    }
  }

	@media (min-width: @screen-sm-min) {
		display: flex;
		flex-direction: row;
		.feature-tile {
			overflow: hidden;
			padding-left: 0;
			padding-right: 0;
      border: 5px solid @white;
      border-top: 0;
		}
	}
}

.racing-hq-callout, .store-tile {
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.25s ease-in-out;
    background-color: fade(@black, 20%);

    &:hover {
      background-color: fade(@black, 0%);
    }
  }
}
