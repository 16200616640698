#global-footer {
  font-family: $font-condensed;
  font-size: 12px;
  color: $white;
  background-color: $black;
  padding: 45px 15px;
  z-index: 2;

  @media (min-width: 992px) {
    padding: 25px 15px;
  }
  @media (min-width: 1200px) {
    padding: 45px 25px;
  }
  @media (min-width: 1600px) {
    font-size: 14px;
  }

  .footer-logo {
    display: block;
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 45px;

    @media (min-width: 768px) {
      max-width: 200px;
      margin-left: 0;
      margin-bottom: 25px;
    }
  }

  p, a {
    font-size: inherit;
    color: $white;
  }

  .footer-heading {
    font-size: 21px;
    text-transform: uppercase;
  }

  .content-container {
    .content-links {
      color: $white;

      > a {
        color: inherit;
        margin: 0 5px;

        @media (min-width: 1200px) {
          font-size: 14px;
        }

        &:first-child { margin-left: 0;}

        &:hover {
          color: $primary;
        }
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  .quick-link-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 45px;

    li {
      flex: 0 0 50%;
      a {
        font-family: $font-primary;
        font-size: 14px;
        text-transform: uppercase;

        @media (min-width: 992px) {
          font-size: 12px;
        }
        @media (min-width: 1200px) {
          font-size: 14px;
        }

        &:hover {
          color: $primary-dark;
        }
      }
    }
  }

  .copyright, .sign-up, .brand-links {
    margin-bottom: 45px;

    @media (min-width: 992px) {
      margin-bottom: 25px;
    }
  }

  .quick-links {
    @media (min-width: 1200px) {
      max-width: 520px;
      margin-top: 17px;
      float: right;
    }

    .link-wrap {
      @media (min-width: 1200px) {
        margin-left: -40px;
        margin-right: 40px;
      }
    }
  }

  .brand-links {
    padding: 0 25px;

    @media (min-width: 992px) {
      margin-bottom: 0;
      padding: 0;
      width: auto !important;
    }

    .brand-link-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      font-family: $font-condensed;
      font-size: 14px;
      text-transform: uppercase;
      color: white;

      @media (min-width: 992px) {
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 0;
      }

      li {
        padding: 10px 5px;
      }

      sup, sub {
        font-size: 7px;
      }

      a {
        font-family: $font-condensed;
        font-size: 14px;
        text-transform: uppercase;

        &:hover, &:focus {
          color: $primary;
        }
      }
    }
  }

  .partner-logos {
    display: flex;
    flex-direction: column;
    align-items: center;


    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: center;
    }
    @media (min-width: 992px) {
      flex: 1;
      justify-content: flex-start;
      margin-left: 20px;
      width: auto !important;
    }

    a {
      padding: 15px;
      max-width: 300px;

      @media (min-width: 768px) {
        max-width: none;
        flex: 0 0 200px;
      }
      @media (min-width: 1200px) {
        flex: 0 0 210px;
      }
      @media (min-width: 1440px) {
        flex: 0 0 300px;
      }

      > img {
        width: 100%;
        height: auto;
      }

      &.driveability {
        flex: 0 0 50%;

        @media (min-width: 768px) {
          flex: 0 0 300px;
        }

        @media (min-width: 1200px) {
          flex: 0 0 320px;
        }
        @media (min-width: 1440px) {
          flex: 0 0 400px;
        }
      }
    }
  }


  // Sign up form
  .form-group {
    &.inline-combo {
      display: flex;
      border-radius: 6px;
      overflow: hidden;
      font-size: 14px;

      .form-control, .btn {
        font-size: inherit;
        border-radius: 0;

        @media (min-width: 768px) {
          padding: 5px 8px;
        }
      }
    }
  }
}

.row-lg-flex {
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }
}
