#cdm-homepage-banner {
  background-image: url('/assets/images/chief-donut-maker/homepage-banner.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  padding: 20px 10px;
  color: white;
  // button styles, remove as needed
  position: relative;
  margin-bottom: 0;

  .content-wrap {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
  }

  .banner-overlay {
    img {
      width: 120px;
      margin-bottom: 60px;
    }
  }

  .banner-copy {
    text-align: center;

    p > span {
      padding: 5px;
      margin-bottom: 0;
    }
  }

  .video-cta {
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: -40px;
    left: 0;
    right: 0;
    background-color: @brand-primary;

    .btn {
      margin-top: 8px;
      font-size: 1.2rem;
      padding: 6px 10px;
      position: static;
    }

    .btn:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: @screen-sm-min) {
    h3 {
      font-size: 2.6rem;
    }
  }

  @media (min-width: @screen-sm-min) {
    height: 400px;
    padding: 25px;

    .banner-overlay {
      margin-top: 0;

      img {
        width: 200px;
        margin-bottom: 20px;
      }
    }
  }

  @media (min-width: @screen-md-min) {
    padding: 70px 100px;
    height: 500px;

    .content-wrap {
      flex-direction: row;
      justify-content: space-between;
    }

    .banner-overlay, .banner-copy {
      width: 25vw;
    }

    .banner-overlay {
      img {
        width: 25vw;
        max-width: 396px;
      }
    }

    .banner-copy {
      text-align: left;
      padding-top: 210px;
      position: relative;
    }

    .video-cta {
      width: 320px;
      height: 180px; // 16:9
      background-color: transparent;
      background-image: url('/assets/images/chief-donut-maker/cdm-stay-tuned-thumb.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid @brand-primary;

      &:hover, &:focus {
        border: 2px solid @brand-primary-hover;
        transition: all 0.35s ease-in-out;
      }


    }
  }

  @media (min-width: @screen-xl-min) {
    padding: 55px 100px;

    .banner-copy {
      display: flex;
      flex-direction: column;
      //justify-content: flex-end; // for video button
      align-items: flex-start;
      padding-top: 255px;
    }

    .video-cta {
      width: 400px;
      height: 225px; // 16:9
    }
  }
}
