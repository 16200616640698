$indicator-size: 10px;
$rhombus-skew: 30deg;
$rhombus-small: 200px;
$rhombus-medium: 300px;
$rhombus-large: 400px;

#feature-image-slider {

  .feature-image-wrapper {
    position: relative;
    height: $rhombus-small;
    overflow: hidden;
  }

  .image-wrap {
    height: $rhombus-small;
    clip-path: polygon(calc(#{$rhombus-small} / 2) 0%, 100% 0%, calc(100% - (#{$rhombus-small} / 2)) 100%, 0% 100%);
    cursor: pointer;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (min-width: 992px) {
    .feature-image-wrapper {
      height: $rhombus-medium;
    }

    .image-wrap {
      height: $rhombus-medium;
      clip-path: polygon(calc(#{$rhombus-medium} / 2) 0%, 100% 0%, calc(100% - (#{$rhombus-medium} / 2)) 100%, 0% 100%);
    }
  }

  @media (min-width: 1600px) {
    .feature-image-wrapper {
      height: $rhombus-large;
    }

    .image-wrap {
      height: $rhombus-large;
      clip-path: polygon(calc(#{$rhombus-large} / 2) 0%, 100% 0%, calc(100% - (#{$rhombus-large} / 2)) 100%, 0% 100%);
    }
  }

  .carousel-button {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    transform: translateY(-50%);
    transition: all 0.25s ease-in-out;
    background: 0;
    border: 0;
    font-size: 1.8rem;
    color: $white;
    text-shadow: 0px 0px 15px rgba(black, 45%);

    @media (min-width: 992px) {
      font-size: 2rem;
      left: 20px;
    }

    &:hover {
      color: $primary;
    }

    &#carousel-next {
      left: auto;
      right: 0;

      @media (min-width: 992px) {
        right: 20px;
      }
    }
  }
}
