.modal {
  
  //fullscreen modals
  body.modal-open & {
    .modal-dialog {
      &.modal-video {
        .modal-content {
          .modal-header {
            display: flex;
            flex-wrap: wrap;
            padding: 0;

            .modal-title {
              flex: 0 0 100%;
              order: 1;
              padding: 0 2.5rem;
            }
            .modal-close {
              position: static;
              margin-left: auto;
              padding: 0 10px;
            }
          }
          .modal-body {
            padding-top: 0;

            .description {
              margin-top: 0;
            }
            .embed-responsive {
              margin-bottom: 0 !important;
            }
          }
        }
      }


      &.modal-full {
        .modal-content {
          padding-top: 60px;

          .modal-header {
            .modal-close {
              top: 25px;
              right: 25px;
              line-height: 0;
            }
          }
        }
      }
    }
  }
  
  //news/event modal
  &#news-modal, &#event-modal {
    .modal-dialog {
      padding-top: 0;

      body.fixed-nav & {
        padding-top: 62px;
      }

      .modal-close-container {
        top: 35px;

        body.fixed-nav & {
          top: calc(62px + 35px);
        }
      }
    }
  }

  // narrow modal used for displaying a simple list
  .package-modal {
    max-width: 480px;

    .modal-content {
      .modal-header {
        display: flex;
        align-items: flex-start !important;
        padding: 1rem 1.5rem;
        padding-top: 0;
        padding-right: 0;

        // @media (min-width:  )

        p {
          margin-bottom: 0;
        }
        .modal-close {
          position: static;
          line-height: 0;
          padding: 0.75rem;
          margin-left: auto;
        }
      }
      .modal-body {
        padding: 1.5rem;
        padding-top: 0;

        ul {
          padding-left: 2.25rem;
        }
      }
    }

    &.features-modal {
      .modal-content {
        padding: 2rem;
      }
    }
  }

  .allocation-modal {
    @extend .package-modal;
    width: 90%;
    max-width: 1200px !important;
  
    .modal-content {
      
      .modal-body {
        .intro {
          display: flex;
          flex-direction: column;
  
          @media (min-width: 768px) {
            flex-direction: row;
          }
  
          .logo {
            max-width: 110px;
            margin: 0 auto;
            margin-bottom: 1.5rem;
  
            @media (min-width: 768px) {
              flex: 0 0 110px;
              max-width: none;
              margin: 0;
              margin-right: 2rem;
              margin-bottom: 0;
            }
            @media (min-width: 1440px) {
              flex: 0 0 130px;
            }
  
            img {
              width: 100%;
              height: auto;
  
            }
          }
  
          .description {
            flex: 1;
            
            p {
              font-size: 1.4rem;
  
              @media (min-width: 1200px) {
                font-size: 1.6rem;
              }
  
              &.heading-sm {
                font-size: 2rem;
                line-height: 1.2;
  
                @media (min-width: 1200px) {
                  font-size: 2.8rem;
                  margin-bottom: 1rem;
                }
              }
            }
          }
        }
  
        .dealerships {
          display: flex;
          flex-direction: column;
          padding: 1.5rem;
  
          @media (min-width: 1200px) {
            gap: 1.5rem;
          }
  
          .province-group {
            flex: 0 0 100%;
            margin-bottom: 1.5rem;
            padding: 1.5rem;

            display: flex;
            flex-direction: column;

            .province-wrap {
              flex: 0 0 100%;
              display: flex;
              justify-content: center;
            }

            .dealership-wrap {
              flex: 0 0 100%;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
            }
  
            .province-heading {
              flex: 0 0 calc((100% / 3) - 1.5rem);
              font-family: $font-heading-bold;
              font-size: 1.6rem;
              text-align: center;
              text-transform: uppercase;
              padding: 0.75rem;
              margin-bottom: 1.5rem;
              border: 1px solid $black;
  
              @media (min-width: 1200px) {
                font-size: 2rem;
              } 
            }


  
            .dealership {
              flex: 0 0 100%;

              @media (min-width: 768px) {
                flex: 0 0 calc((100% / 2) - 1.5rem);
              }

              @media (min-width: 1200px) {
                flex: 0 0 calc((100% / 3) - 1.5rem);
              }


              display: flex;
              margin-bottom: 1rem;

              &.disabled-link a {
                  pointer-events: none !important;  // need to override the default when link should appear disabled
                  cursor: default !important; // need to override the default when link should appear disabled
                
              }
  
              .orders {
                display: flex;
                flex-direction: column;
                background-color: $primary;
                padding: 0.5rem 1rem;
  
                span {
                  font-family: $font-heading-light;
                  font-size: 1.6rem;
                  line-height: 1.2;
                  color: $white;
                  text-transform: uppercase;
                  text-align: center;
  
                  &.amount {
                    font-family: $font-heading-bold;
                    font-size: 2.8rem;
                  }
                }
              }
              .info {
                flex: 1;
                padding: 0 1rem;
                p {
                  font-size: 1.6rem;
                  line-height: 1.2;
                  text-transform: uppercase;
  
                  &.name {
                    font-family: $font-heading-bold;
                    font-size: 1.8rem;
                    color: $primary;
                    margin-bottom: 0.75rem;
                  }
  
                  &.location {
                    font-family: $font-heading-light;
                    margin-bottom: 0;
                  }
                }
                .dealer-link {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .video-modal {
    width: 100%;
    max-width: 1200px;

    .modal-content {
      .modal-container {
        .modal-header {
          display: flex;
          justify-content: flex-end;
          background: linear-gradient(to bottom, #1a1a1a, #333333);
          padding: 0;

          .modal-close {
            position: static;
            padding: 1rem;
            color: $white;
          }
        }
        .modal-body {
          padding: 0;
        }
      }
    }
  }

  // Modal for LB reveal video
  .modal-lb-video {
    width: 95%;
    max-width: 1200px;
    background-color: black; 

    .modal-content {
      border: 0;
    }
    
    .modal-container {
      background-color: black; 

      .modal-header {
        display: flex;
        padding: 0.5rem 1.5rem;

        .modal-close {
          position: static;
          margin-left: auto;
          color: white;
        }
      }
      .modal-body {
        padding: 0;
      }
    }
  }

  .modal-card {
    max-width: 460px;
  
    .modal-content {
      border-radius: 40px;
      .modal-header {
        padding: 0;
  
        .modal-close {
          top: 1rem;
          right: 2rem;
          z-index: 1;
        }
      }
      .modal-body {
        font-family: $font-condensed;
        padding: 60px 15px;
  
        @media (min-width: 600px) {
          padding: 60px 40px;
        }
  
        .modal-heading {
          font-size: 24px;
          font-weight: 700;
          line-height: 1.2;
          text-transform: uppercase;
        }
  
        ul {
          padding-left: 25px;
          li {
            font-size: 16px;
          }
        }
  
        .acknowledge {
          padding: 20px 0;
  
          .custom-control {
            display: flex;
            margin-bottom: 20px;
  
            .custom-control-input {
              position: relative;
              width: 20px;
              height: 20px;
              cursor: pointer;
          
              &:checked {
                background-color: #DC0000;
                border-color: #DC0000;
              }
          
              &:after {
                content: '';
                position: absolute;
                top: 2px;
                left: 6px;
                width: 6px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
              }
  
              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}
