.form-1320 {
  font-size: @body-font-size;
  .section-label {
    display: block;
    font-size: 2.1rem;
    margin-top: 2rem;
    margin-bottom: 1.5rem;

    &.control-label {
      @media (min-width: 768px) {
        //font-size: 1.8rem;
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }


  //INPUT STYLING
  .form-group {
    label {
      //font-size: 1.8rem;
    }

    .form-control-static:not(.username-verification) {
      @media (min-width: 1200px) {
        //font-size: 1.2rem;
      }
    }
  }



  //CUSTOM RADIO
  .dg-radios {
    display: flex;

    label {
      display: flex;
      align-items: center;
      padding-left: 0;

      &+ label {
        margin-left: 2.5rem;
      }

      input[type="radio"] {
        display: none;

        &:checked ~ .radio-icon {
          border-color: @red1;
          &:before {
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }

      .radio-icon {
        position: relative;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 2px solid @light-gray;
        margin-right: 0.75rem;
        transition: all 0.25s ease-in-out;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0);
          transform-origin: center center;
          transition: all 0.25s ease-in-out;
          width: 1rem;
          height: 1rem;
          background-color: @red1;
          border-radius: 50%;
        }
      }
      .label-text {
        order: 1;
      }

    }
  }

  .username-verification {
    @media (min-width: 768px) {
      //margin-top: 3.25rem;
    }
  }

  .btn-link {
    padding: 0;
    font-size: 2.1rem;
    color: @black;
    text-decoration: underline;
  }

  .btn-step {
    min-width: 190px;
    margin: 1.5rem 0;
  }

  .caption {
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0.75rem;
  }


  // CUSTOM FILE UPLOAD
  .dg-upload {
    margin-top: 1.25rem;

    label {
      //min-width: 235px;
    }

    input[type="file"] {
      display: none;
    }

    .upload-container {
      //display: flex;
      //align-items: center;

      label {
        //flex: 0 0 200px;
        margin-right: 2rem;
      }
      p {
        //flex: 1;
        margin-top: 2px;
        font-family: @main-font;
      }
    }
  }

  // CUSTOM SELECT
  // .dg-form-select {
  //   select {
  //     display: none;
  //   }
  // }


  .vehicle-group {
    @media (max-width: 767px) {
      .mods {
        order: 1;
      }
    }
    @media (min-width: 768px) {
      margin-top: 2rem;
    }
  }

  .agreement-container {
    p {
      font-size: 1.4rem;

      &.section-title {
        font-size: 1.8rem;
        margin-top: 2rem;
      }
      &.lead {
        font-size: 1.6rem;
      }

      @media (min-width: 992px) {
        font-size: 1.6rem;

        &.section-title {
          font-size: 2rem;
        }
        &.lead {
          font-size: 1.8rem;
        }
      }
    }
    ul {
      li {
        font-size: 1.4rem;
        margin-bottom: 1rem;

        @media (min-width: 992px) {
          font-size: 1.6rem;
        }
      }
    }

    input[type="checkbox"] {
      margin-bottom: 2rem;
    }
  }

}

.step-container {
  // transition: all 0.25s ease-in-out;
  // opacity: 0;
  // transform: translateX(100%);

  // &.active {
  //   opacity: 1;
  //   transform: translateX(0px);
  // }
  // &.inactive {
  //   opacity: 0;
  //   transform: translateX(-100%);
  // }
}

// .step-form {
//   display: flex;
//   flex-wrap: nowrap;
//   overflow-x: hidden;
//   .step {
//     flex: 0 0 100%;
//     min-height: 100vh;
//   }
// }


.input-block {
  margin-bottom: 2rem;
}

.add-vehicle-img-group {
  padding: 5px 0;
}
.add-vehicle-img {
  position: relative;
  display: inline-block;
  .btn-circle-delete {
    position: absolute;
    top: -10px;
    right: -10px;
    user-select: none;
    background-color: @red1;
    border: 1px solid #FFF;
    color: #FFF;
    border-radius: 50%;
    padding: 0 7px 2px 7px;
  }
}

@keyframes from-left {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes from-right {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
