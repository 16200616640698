// Downloads
.downloads {
	margin-bottom: 15px;
	background-color: @black;
  position: relative;
	max-height: 285px; // fix inconsistent heights for BS3
  a {
		&:hover {
			img {
				opacity: 0.6;
			}
		}
		img {
			margin: 20px 0 10px;
			opacity: 1;
			transition: all 0.5s ease-in-out;
		}
	}
	.btn {
		width: 100%;
	}
	.dropdown {
		.dropdown-menu {
      top: auto;
      bottom: 100%;
      //margin-bottom: 2px;
			width: 100%;
			//top: -500%;
			border-radius: 0;
			box-shadow: none;
			li {
				a {
					font-size: @body-font-size;
					&:hover, &:focus {
						color: @brand-primary;
						background-color: fade(@brand-primary, 5%);
					}
				}
			}
		}
  }
  &.coloring-sheet a img {
    margin: 0;
  }
}

performance-cars-downloads .nav-link {
  height: 100%;
}
