// Chief Donut Maker
// =================

@hero-height-small: 400px;
@hero-height-large: 575px;

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

// General styles
dg-chief-donut-maker {
  overflow: hidden;
}

#cdm-footer-application {
  position: relative;
}

// CDM Components
@import 'chief-donut-maker/nav';
@import 'chief-donut-maker/hero';
@import 'chief-donut-maker/card';
@import 'chief-donut-maker/carousel';
@import 'chief-donut-maker/application';
@import 'chief-donut-maker/offset-block';
@import 'chief-donut-maker/modal';
@import 'chief-donut-maker/video-hero';

// CDM Pages
@import 'chief-donut-maker/page';
@import 'chief-donut-maker/homepage';
@import 'chief-donut-maker/page-landing';
@import 'chief-donut-maker/page-job';
@import 'chief-donut-maker/page-perks';
@import 'chief-donut-maker/page-experience';
@import 'chief-donut-maker/page-application';
@import 'chief-donut-maker/page-application-status';
@import 'chief-donut-maker/page-hiring-process';
@import 'chief-donut-maker/page-rules';

