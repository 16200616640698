// CTA Tiles

.cta-tile {
  .tile-cta();
}

dg-racing-events-calendar-cta {
  background: #000 url('/assets/images/racing-hq/racing-calendar-320.png') center center no-repeat;
  background-size: cover;
	.tile-cta();
	//min-height: 200px;
  @media (min-width: 720px) {
    background: #000 url('/assets/images/racing-hq/racing-calendar-720.png') center center no-repeat;
  }
  @media (min-width: 1140px) {
    background: #000 url('/assets/images/racing-hq/racing-calendar-1400.png') center center no-repeat;
  }
  @media (min-width: 1600px) {
    background: #000 url('/assets/images/racing-hq/racing-calendar-1600.png') center center no-repeat;
  }

}
dg-racing-articles-cta {
  background: #000 url('/assets/images/racing-hq/racing-articles-cta.jpg') center center no-repeat;
  background-size: cover;
	.tile-cta();
	min-height: 250px;
}
dg-pro-racing-cta {
  background: #000 url('/assets/images/racing-hq/pro-racing-cta.jpg') center center no-repeat;
  background-size: cover;
  .tile-cta();
}
dg-sportsman-cta {
  background: #000 url('/assets/images/racing-hq/sportsman-cta.jpg') center center no-repeat;
  background-size: cover;
  .tile-cta();
}
dg-stock-super-stock-cta {
  background: #000 url('/assets/images/racing-hq/stock-super-stock-cta.jpg') center center no-repeat;
  background-size: cover;
  .tile-cta();
}
dg-bracket-racing-cta {
  background: #000 url('/assets/images/racing-hq/bracket-racing-cta.jpg') center center no-repeat;
  background-size: cover;
  .tile-cta();
}
dg-drag-racing-faq-cta {
  background: #000 url('/assets/images/racing-hq/drag-racing-faq-cta.jpg') center center no-repeat;
  background-size: cover;
  .tile-cta();
}
dg-track-locator-cta {
  background: #000 url('/assets/images/racing-hq/track-locator-cta.jpg') center center no-repeat;
  background-size: cover;
  .tile-cta();
}
dg-demon-faq-cta {
  background: #000 url('/assets/images/racing-hq/demon-faq-cta.jpg') center center no-repeat;
  background-size: cover;
  .tile-cta();
}
dg-racing-hq-cta {
  background: #000 url('/assets/images/cta-tiles/racing-hq-cta.jpg') right center no-repeat;
  background-size: cover;
  .tile-cta();
  // min-height: 275px;
  // @media (min-width: @screen-sm-min) {
  //   min-height: 325px;
  // }
  // @media (min-width: 1600px) {
  //   min-height: 450px;
  // }
  .title {
    font-size: 6.0rem; // 60px based on 10px
    @media (min-width: @screen-sm-min) {
      font-size: 7.0rem; // 90px based on 10px
    }
  }
  .description {
    max-width: 425px;
    font-size: 1.8rem; //18px based on 10px
    @media (min-width: @screen-sm-min) {
      font-size: 2.2rem; // 22px based on 10px
    }
  }
}
dg-merch-cta {
  background: #000 url('/assets/images/cta-tiles/merch-cta.jpg') center center no-repeat;
  background-size: cover;
  .tile-cta();

  @media (min-width: 768px) {
    background-image: url('/assets/images/cta-tiles/merch-cta.jpg');
  }

  a {
    // IE Hack for text border
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;

    //@media (max-width: @screen-sm-max) {
    //  // IE Hack for text border
    //  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
    //}
    font-size: 6rem;
    @media (min-width: @screen-lg-min) {
      font-size: 7.0rem; // 70px based on 10px
    }
  }
}
dg-downloads-cta {
  background: #000 url('/assets/images/cta-tiles/downloads-cta.jpg') center center no-repeat;
  background-size: cover;
  .tile-cta();

  .title {
    font-size: 6rem;
    @media (min-width: @screen-lg-min) {
      font-size: 7.0rem; // 70px based on 10px
    }
  }
  .description {
    font-size: 1.8rem; // 18px based on 10px
    @media (min-width: @screen-sm-min) {
      font-size: 2.2rem; // 22px based on 10px
    }
  }
}
dg-bondurant-cta {
  background-color: #000;
  background-image: url('/assets/images/cta-tiles/radford-racing-school-tile.jpg');
  background-size: cover;
  a > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .tile-cta();
  .description {
    font-size: 1.8rem; // 18px based on 10px
    margin-top: 10px;
    @media (min-width: @screen-sm-min) {
      font-size: 2.2rem;
    }
  }

  a {
    align-items: flex-end;
    .row {
      height: 100%;
      align-items: center;
      padding-left: 3rem;
      padding-right: 3rem;
      @media (min-width: 992px) {
        display: flex;
      }
    }
  }
}
dg-muscle-behind-us-cta {
  background: #000 url('/assets/images/cta-tiles/muscle-behind-us-xs-cta.jpg') top center no-repeat;
  background-size: cover;
  .tile-cta();
  min-height: 275px;
  @media (min-width: @screen-sm-min) {
    background: #000 url('/assets/images/cta-tiles/muscle-behind-us-cta.jpg') center center no-repeat;
    min-height: 165px;
  }
  a {
    justify-content: center;
    @media (min-width: @screen-sm-min) {
      justify-content: flex-start;
      align-items: center;
    }
  }
  svg {
    width: 100%;
    max-width: 250px;
    height: auto;
  }
}
dg-dodge-official-cta {
  background-color: #181818;
  .tile-cta();
  min-height: 275px;
  a {
    flex-wrap: wrap;
    align-content: flex-end;
    @media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {
      justify-content: flex-end;
      text-align: right;
    }
  }
  .title {
    font-size: 6.0rem; // 60px based on 10px
    line-height: 1;
    margin-bottom: 30px;
    @media (min-width: @screen-sm-min) {
      font-size: 7.0rem; // 70px based on 10px
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {
      max-width: 350px;
    }
    @media (min-width: @screen-md-min) {
      font-size: 6.0rem; // 60px based on 10px
    }
  }
  .description {
    max-width: 425px;
    font-size: 1.8rem; //18px based on 10px
    text-decoration: underline;
    transition: .35s ease-in-out;
    @media (min-width: @screen-sm-min) {
      font-size: 2.2rem; // 22px based on 10px
    }
  }
  &:hover .description {
    color: @primary-03;
  }
}
thirteen-twenty-cta {
  background: #000 url('/assets/images/cta-tiles/downloads-cta.jpg') center center no-repeat;
  background-size: cover;
  .tile-cta();
  // min-height: 275px;
  // @media (min-width: @screen-sm-min) {
  //   min-height: 325px;
  // }
  .title {
    font-size: 6rem;
    @media (min-width: @screen-lg-min) {
      font-size: 7.0rem; // 70px based on 10px
    }
  }
  .description {
    font-size: 1.8rem; // 18px based on 10px
    @media (min-width: @screen-sm-min) {
      font-size: 2.2rem; // 22px based on 10px
    }
  }
}

#direct-connection-cta {
  a {
    display: block;
  }
}
#triple-tiles {
  margin-bottom: 10px;


  .third-tile:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,.5);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);
  }
}

.third-tile {
  position: relative;
  display: block;

  &:hover, &:focus {
    span {
      color: @brand-primary;
    }
  }

  span {
    position: absolute;
    z-index: 2;
    display: block;
    bottom: 10px;
    left: 10px;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.8rem; //18px based on 10px
    transition: all 0.25s ease-in-out;

    @media (min-width: @screen-sm-min) {
      font-size: 2.2rem; // 22px based on 10px
    }
  }
}
