#home-articles {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: auto;

  @media (min-width: 1024px) {
    height: 420px;
    flex-wrap: nowrap;
  }
  @media (min-width: 1200px) {
    height: 500px;
  }
  @media (min-width: 1440px) {
    height: 600px;
  }

  > * { height: 100%; }

  .feature {
    flex: 0 0 100%;
    height: 400px;

    @media (min-width: 768px) {
      height: auto;
    }

    @media (min-width: 1024px) {
      flex: 0 0 50%;
      height: 100%;
    }

    .article-tile > a {
      .article-meta-container {
        background: linear-gradient(0.64deg, #C9071B 3.5%, rgba(0, 0, 0, 0) 95%);

        > * {
          max-width: 600px;
        }
      }
    }
  }

  .secondary {
    @media (min-width: 768px) {
      display: flex;
      flex-direction: row;
    }
    @media (min-width: 1024px) {
      flex: 0 0 25%;
      flex-direction: column;
    }

    .article-tile {
      height: 250px;

      @media (min-width: 768px) {
        flex: 0 0 50%;
        height: 100%;
      }
      
      @media (min-width: 1200px) {
        height: 50%;
      }

      a {
        &:before { background: linear-gradient(0deg, $black 10.4%, rgba($black, 0) 100%); }

        .article-meta-container {
          @media (min-width: 1024px) {
            padding: 20px;
          }

          .entry-title {
            font-size: 1.75em;

            @media (min-width: 1200px) {
              font-size: 1.5em;
            }
            @media (min-width: 1440px) {
              font-size: 1.75em;
            }
          }

          .article-meta {
            @media (min-width: 1200px) {
              font-size: 1em;
            }
            @media (min-width: 1600px) {
              font-size: 1.25em;
            }
          }
        }
      }

    }
  }

  .listing-container {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $white;

    @media (min-width: 1024px) {
      flex: 0 0 25%;
    }

    &:after {
      content: '';
      position: absolute;
      top: auto;
      bottom: 45px;
      left: 0;
      width: 100%;
      height: 45px;
      background: linear-gradient(to top, $white 20%, rgba($white, 0));

      @media (max-width: 1023px) {
        display: none;
      }
    }

    .articles {
      flex: 1;
      overflow-y: scroll;
      padding-bottom: 20px;

      // Scrollbar styling
      @include scrollbars(10px, rgba($black, 0.5), rgba($gray-dark, 0.05));

      @media (min-width: 768px) and (max-width: 1023px) {
        display: flex;
      }

      @media (max-width: 1023px) {
        padding-bottom: 0;
      }
    }

    .btn-view-news {
      flex: 0 0 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      background-color: $primary-dark;

      @media (min-width: 1024px) {
        font-size: 16px;
      }

      &:hover {
        color: $white;
        background-color: darken($primary-dark, 10%);
      }
    }
  }



  .article-tile {
    height: 100%;

    a {
      position: relative;
      display: block;
      height: 100%;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: auto;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        height: 75%;
        background: linear-gradient(0deg, $black 15%, rgba($black, 0) 100%);
      }

      &:hover {
        .article-img {
          img {
            transform: scale(1.05);
          }
        }
      }

      .article-img {
        height: 100%;
        img {
          object-fit: cover;
          height: 100%;
          transform: scale(1);
          transform-origin: center center;
          transition: all 0.25s ease-in-out;
        }
      }

      .article-meta-container {
        position: absolute;
        top: auto;
        left: 0;
        bottom: 0;
        z-index: 2;
        width: 100%;
        font-size: 14px;
        color: $white;
        padding: 15px;

        @media (min-width: 1024px) {
          padding: 40px;
        }
        @media (min-width: 1440px) {
          font-size: 16px;
        }

        .entry-title {
          font-size: 1.75em;

          @media (min-width: 1440px) {
            font-size: 2em;
          }
        }
        .author {
          display: block;
          margin-bottom: 1.25rem;
        }
        .description {
          p {
            font-size: 1.4em;
            margin-bottom: 2rem;
          }
        }
        .article-meta {
          font-size: 1.4em;
          color: $white;
        }
      }
    }
  }

  .article-listing {
    border-bottom: 1px solid $gray;

    @media (min-width: 768px) and (max-width: 1023px) {
      flex: 0 0 calc(100% / 3);
      border-right: 1px solid $gray;
      border-bottom: 0;
    }
    @media (max-width: 1023px) {
      &:nth-child(n + 4) {
        display: none;
      }
    }

    &:last-child {
      border-bottom: 0;

      @media (min-width: 768px) and (max-width: 1023px) {
        border-right: 0;
      }
    }

    a {
      display: flex;
      color: $black;
      text-decoration: none;
      padding: 20px 15px;
      transition: all 0.25s ease-in-out;

      &:hover {
        background-color: $gray-light;
      }

      .article-img {
        flex: 0 0 60px;
        display: flex;
        align-items: center;
        order: 1;

        @media (min-width: 1440px) {
          flex: 0 0 90px;
        }

        img {
          object-fit: cover;
          height: 60px;

          @media (min-width: 1440px) {
            height: 90px;
          }
        }
      }

      .article-meta-container {
        flex: 1;
        font-size: 10px;
        padding-right: 10px;

        @media (min-width: 1440px) {
          font-size: 14px;
        }

        .entry-title {
          font-size: 2em;
          margin-bottom: 1rem;
        }
        .author {
          display: block;
          font-size: 1.6em;
          margin-bottom: 1rem;
        }
        .article-meta {
          font-size: 1.4em;
        }

      }
    }
  }
}
