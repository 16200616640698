.loading-outer {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  .loading-inner {
    width: 100%;
    text-align: center;
  }
}
