.card {
  padding: 15px;

  &.card-event {
    background-color: $white;
    transform: scale(0.97);
    transform-origin: center center;
    transition: all 0.25s ease-in-out;

    @media (min-width: 1440px) {
      padding: 30px;
    }

    &:hover {
      transform: scale(1);
      a {
        text-decoration: none;
      }
    }

    .tile-container {
      display: flex;
      flex-direction: column;
      .event-img {
        position: relative;

        .date-label {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-family: $font-condensed;
          font-size: 21px;
          color: $white;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          background-color: $primary-dark;
          width: 50px;
          height: 50px;

          > span {
            line-height: 1;

            &.month {
              font-size: 0.65em;
              font-weight: 200;
            }
          }
        }
      }
      .event-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 10px 0;

        .event-time {
          font-size: 14px;
          margin-bottom: 0;
        }
        .event-name {
          font-family: $font-condensed;
          font-size: 16px;
          font-weight: 700;

          @media (min-width: 1440px) {
            font-size: 20px;
          }
        }
        .event-location {
          font-size: 14px;
          margin-top: auto;

          i {
            font-size: 1.25em;
            margin-right: 15px;
          }
        }
      }
    }
  }
}

.descriptor {
  padding: 2rem 1rem;
  margin-top: -1rem;
  opacity: 0;
  animation: fade-in 0.25s ease-in-out forwards;

  @media (min-width: 768px) {
    flex: 0 0 100%;
  }

  .close {
    opacity: 1;
  }

  .price {
    font-weight: 700;
    font-size: 2.4rem;
    text-transform: uppercase;
  }
  .product-contents {
    display: inline-flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;

    @media (min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    li {
      display: flex;
      padding: 1rem;
      padding-left: 0;

      @media (min-width: 768px) {
        padding-right: 2rem;
      }

      .marker {
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: $black;
        margin-right: 0.75rem;
        margin-top: 0.6em;
      }
    }
  }
}

// Slider styling for product cards on demon pages
[data-id="demon-product-carousel"] {
  .swiper-wrapper {
    .swiper-slide {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}