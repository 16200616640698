// CDM Hero
#cdm-hero {

  // TODO Mobile height/positioning
  background-position: center top;
  height: @hero-height-small;

  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    @media (min-width: @screen-md) {
      flex-direction: row;
    }

    img {
      width: auto;
      max-width: 100%;
    }
  }

  @media (min-width: @screen-md-min) {
    height: @hero-height-large;
  }

  // Page specific
  dg-cdm-job-page & {
    background-image: url('/assets/images/chief-donut-maker/job-page-banner.jpg');
  }

  dg-cdm-perks-page & {
    background-image: url('/assets/images/chief-donut-maker/perks-page-banner.jpg');
  }

  dg-cdm-experience-page & {
    background-image: url('/assets/images/chief-donut-maker/experience-page-banner.jpg');
  }

  dg-cdm-hiring-process-page & {
    background-image: url('/assets/images/chief-donut-maker/hiring-process-page-banner.jpg');
  }
}
