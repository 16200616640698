dg-track-locator {
  display: block;
  #map {
    margin-bottom: 25px;
  }
}

.track-search-container {
  margin-bottom: 25px;
  .form-control {
    min-width: 135px;
    margin-right: 5px;
  }
}
.track-results-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 25px -15px;
  .track-container {
    padding: 0 15px;
    margin-bottom: 25px;
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    @media (min-width: @screen-md-min) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: @screen-lg-min) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}

dg-track-locator, dg-track-info-window {
  .track {
    font-family: @main-font-lt;
    padding: 15px;
    height: 100%;
    font-size: 1.4rem; //14px based on 10px
    .title-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .heading3 {
        margin-top: 0;
        margin-bottom: 0;
      }
      > *:last-child {
        margin-left: 10px;
        min-width: 50px;
      }
    }
    .track-info {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      address {
        flex-grow: 1;
        margin-bottom: 2px;
      }
      .get-driections-link {
        text-decoration: underline;
        text-transform: uppercase;
      }
      > *:last-child {
        margin-left: 10px;
      }
    }
  }
}
dg-track-locator .track-results-container .track {
  box-shadow: 0 0 8px rgba(0,0,0,.15);
  border: 1px solid @secondary-03;
}
