performance-cars-homepage {
  .cta-tile {
    aspect-ratio: 1;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    
    a {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      font-family: $font-condensed;
      color: $white;
      text-decoration: none;
      padding: 30px 15px;
      //height: 260px;
      
      @media (min-width: 375px) {
        height: 50vw;
      }

      @media (min-width: 992px) {
        padding: 30px;
      }

      @media (min-width: 992px) {
        height: 25vw;
        max-height: 480px;
      }

      &:before {
        content: '';
        position: absolute;
        top: auto;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        z-index: -1;
        background: linear-gradient(to top, rgba($black, 0.8), rgba($black, 0));

        @media (min-width: 992px) {
          height: 70%;
        }
      }

      .cta-content {
        width: 100%;
        margin-top: auto;

        @media (min-width: 992px) {
          padding-bottom: 20px;
        }
        .title {
          font-size: 18px;
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: 10px;

          @media (min-width: 1600px) {
            font-size: 28px;
          }
        }
        .description {
          font-size: 14px;
          line-height: 1.3;
          text-transform: none;
          margin-top: 0 !important;

          @media (min-width: 1600px) {
            font-size: 18px;
          }
        }
      }
    }
  }

  dg-downloads-cta {
    background-image: url('/assets/images/cta-tiles/cta-bg-downloads.jpg');
  }
  dg-bondurant-cta {
    background-image: url('/assets/images/cta-tiles/cta-bg-radford.jpg');

    a > div { display: block; }
  }
  thirteen-twenty-cta {
    background-image: url('/assets/images/cta-tiles/cta-bg-1320.jpg');
  }
  direct-connection-cta {
    background-image: url('/assets/images/cta-tiles/cta-bg-direct-connection.jpg');
  }
  dg-drag-pak-cta {
    background-image: url('/assets/images/drag-pak/drag-pak-homepage-feature.jpg');
  }
  dg-nhra-membership-cta {
    background-image: url('/assets/images/cta-tiles/cta-bg-nhra.jpg');
  }

  hellcat-availability-cta.cta-tile {
    background-image: url('/assets/images/cta-tiles/durango-srt-burnout-bg.jpg');

    .logo {
      display: block;
      margin: 0 auto 20px;
      max-width: 80%;
    }
  }

  .text-logo-overlay {
    margin-bottom: 15px;
    aspect-ratio: auto;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
    @media (min-width: 992px) {
      height: inherit;
    }

    a {
      height: 100%;
      align-items: center;
      padding: 50px 15px;
      text-align: center;

      @media (min-width: 600px) {
        padding: 75px 15px;
      }
      @media (min-width: 1440px) {
        padding: 105px 15px;
      }

      &:before {
        display: none;
      }

      .cta-content {
        text-align: center;
        margin: auto;
        padding-bottom: 0;

        img {
          width: 100%;
          max-width: 375px;
          height: auto;
          margin-bottom: 25px;
        }

        .title {
          @media (min-width: 600px) {
            font-size: 32px;
          }
          @media (min-width: 992px) {
            font-size: 24px;
          }
          @media (min-width: 1200px) {
            font-size: 32px;
          }

          sup {
            font-size: 50%;
            top: -0.75em;
          }
        }
      }
    }
  }

  jailbreak-cta.cta-tile {
    background-image: url('/assets/images/cta-tiles/burnout-bg.jpg');
    margin-bottom: 15px;
    aspect-ratio: auto;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
    @media (min-width: 992px) {
      height: inherit;
    }

    a {
      height: 100%;
      align-items: center;
      padding: 50px 15px;

      @media (min-width: 600px) {
        font-size: 18px;
        padding: 75px 15px;
      }
      @media (min-width: 1440px) {
        padding: 105px 15px;
      }

      &:before {
        display: none;
      }

      .cta-content {
        text-align: center;
        margin: auto;
        padding-bottom: 0;

        img {
          width: 100%;
          max-width: 375px;
          height: auto;
          margin-bottom: 25px;
        }

        .title {
          @media (min-width: 600px) {
            font-size: 32px;
          }
          @media (min-width: 992px) {
            font-size: 24px;
          }
          @media (min-width: 1200px) {
            font-size: 32px;
          }

          sup {
            font-size: 50%;
            top: -0.75em;
          }
        }
      }
    }
  }
}

#demon-after-delivery {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 100%), url('../../assets/images/rkn/rkn-smoke-bg.webp'), no-repeat;
  background-size: cover;
  background-position: center center;
  margin-top: 0;
  margin-bottom: 3.5rem;

  @media (min-width: 992px) {
    height: 100%;
  }

  a {
    display: block;
    color: rgba($white, 0.75);
    text-decoration: none;
    transition: all 0.25s ease-in-out;

    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &:hover {
      color: rgba($white, 1);
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-condensed;
    font-size: 1.8rem;
    line-height: 1.2;
    text-transform: uppercase;
    padding: 3rem 1.5rem;

    @media (min-width: 768px) {
      font-size: 4rem;
      padding: 1.5rem 3rem;
    }
    @media (min-width: 992px) {
      font-size: 2.5rem;
      padding: 1.5rem 3rem;
    }
    @media (min-width: 1200px) {
      justify-content: center;
      font-size: 3.2rem;
      line-height: 1;
      font-weight: 500;
    }
    @media (min-width: 1440px) {
      font-size: 3.6rem;
      padding: 1.5rem 5rem;
    }
    @media (min-width: 1600px) {
      font-size: 4.2rem;
      width: 800px;
    }
    @media (min-width: 1920px) {
      font-size: 4.6rem;
      width: 900px;
    }

    img {
      width: 80px;
      height: auto;
      margin-right: 1.5rem;

      @media (min-width: 768px) {
        width: 160px;
        margin-right: 2.5rem;
      }
      @media (min-width: 992px) {
        width: 100px;
        margin-right: 1.5rem;
      }
      @media (min-width: 1200px) {
        width: 120px;
      }
      @media (min-width: 1440px) {
        width: 130px;
      }
      @media (min-width: 1600px) {
        width: 140px;
        margin-right: 3rem;
      }
      @media (min-width: 1920px) {
        width: 175px;
      }
    }

    span {
      @media (min-width: 1440px) {
        margin: auto;
      }
    }

    sup {
      font-size: 50%;
      top: -0.75em;
    }
  }
}
