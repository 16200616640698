.sebm-google-map-container {height: 400px;}

.event-card-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-direction: row;
  flex-direction: row;
  dg-event-card, .dodge-official-card {
    display:inline-block; /* set this for IE10 */
    width: 100%;
    opacity: 0.95;
		text-decoration: none;
		a, a:hover {
			text-decoration: none;
		}
    @media (min-width: @screen-sm-min) {
      width: 49%;
      margin-left: .5%;
      margin-right: .5%;
    }
    @media (min-width: @screen-md-min) {
      width: 33%;
      margin-left: .1666666665%;
      margin-right: .1666666665%;
    }
    @media (min-width: @screen-lg-min) {
      transition: all 0.25s ease-in-out;
      &:hover {
        opacity: 1;
        transform: perspective(1px) scale(1.025);
        z-index: 2;
      }
    }
  }
  .dodge-official-card {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 30px 25px;
    background-color: #181818;
    color: #FFF;
    font-family: @main-font;
		text-transform: uppercase;
    > div:first-child {
      font-size: 7rem; // 70px based on 10
      line-height: 7rem; // 70px based on 10
      margin-bottom: 10px;
    }
    > div:last-child {
      font-size: 2.3rem; // 23px based on 10
      text-decoration: underline;
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {
      width: 100%;
      min-height: 300px;
      margin-bottom: 10px;
      text-align: right;
      align-items: flex-end;
      > div:first-child {
        max-width: 350px;
      }
    }
    @media (min-width: @screen-md-min) {
      > div:first-child {
        font-size: 6rem; // 60px based on 10
        line-height: 6rem; // 60px based on 10
        margin-bottom: 30px;
      }
    }
    @media (min-width: @screen-lg-min) {
      padding: 40px 35px;
      > div:first-child {
        font-size: 7rem; // 70px based on 10
        line-height: 7rem; // 70px based on 10
      }
    }
    @media (min-width: 1475px) {
      > div:first-child {
        font-size: 8rem; // 80px based on 10
        line-height: 8rem; // 80px based on 10
      }
    }
  }
}
dg-event-card {
  border: 0;
  background-color: #ececec;
  margin-bottom: 1.5rem;
  &.event-homepage {
    background: -webkit-gradient(linear,left bottom,left top,from(#ddd),to(#eee));
    background: linear-gradient(to top,#ddd,#eee);
  }
  article {
    > a {
      display: block;
			height: 100%;
			text-decoration: none;
      .event-img	{
        position: relative;
        .img-container {
          margin-bottom: 0;
          border: 0;
          padding: 0;
          overflow: hidden;
          position: relative;
          .img-overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1.5);
            opacity: 0.25;
            z-index: 1;
          }
        }
        .view-event {
          position: absolute;
          bottom: -15px;
          background-color: @red1;
          color: @white;
          padding: 5px 15px;
          font-size: 18px;
          text-transform: uppercase;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .event-details {
        padding: 40px 15px;
        text-transform: uppercase;
        font-size: 18px;
        color: @dark-gray;
        .event-name {
          margin-bottom: 10px;
          font-size: 2.1rem;
        }
      }
    }
  }
}

.ghost-btn {
	&.ghost-btn-red {
		display: block;
		margin-bottom: 20px;
		color: @red1;
		border-color: @red1;
		&:hover {
			background-color: @red1;
		}

		@media (min-width: @screen-sm-min) {
			margin-bottom: 0;
			font-size: 1.5em;
		}
	}
}

.event-header {
	.event-heading {
		padding-top: 30px;
	}
	@media (min-width: @screen-sm-min) {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-top: 20px;
		padding-bottom: 20px;
		.event-heading {
			padding-top: 0;
			.heading1 {
				margin-bottom: 0;
			}
		}
	}
}

.track-locator-row {
	@media (min-width: @screen-sm-min) {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-left: 0;
		margin-right: 0;
	}
}

.event-map {
	@media (min-width: @screen-lg-min) {
		margin-left: -30px;
		margin-right: -30px;
	}
}


.event-heading {
	//padding-top: 30px;
}
.filter {
	padding: 40px 0;
	min-height: 110px;
	i {
		font-size: 40px;
		float: left;
		margin-right: 10px;
		color: @red1;
		margin-bottom: 20px;
	}
	a.view-calendar {
		text-transform: uppercase;
		color: @dark-gray;
		font-size: 22px;
		line-height: 40px;
		&:hover {
			color: @red1;
		}
	}
	.or-text {
		font-size: 21px;
		line-height: 35px;
		margin-bottom: 15px;
		display: block;
	}
}



//Individual Event Page
.individual-event {
	overflow-x: hidden;
	.event-heading-container {
		.h1 {
			margin:20px 0 0 0;
		}
		.event-date {
			font-family: @main-font;
			color: @brand-primary;
			font-size: 26px;
			display: inline-block;
			text-transform: uppercase;
			line-height: 0;
			margin: 15px 0 30px 0;
		}
		border-bottom: solid 1px @light-gray;
	}

  .logo-container {
    max-width: 300px;
    margin: 0 auto 15px auto;
    @media (min-width: @screen-sm-min) {
      margin: 0 0 15px 0;
    }
  }

	.event-directions {
		margin-bottom: 3rem;
		padding-top: 3rem;
		font-size: 1.6rem;
		line-height: 1.3;
		.location-details {
			margin-bottom: 1.5rem;
			.address-map {
				color: @gray;
				.anchor-red {
					&:hover {
						color: @red1;
					}
				}
			}

			.h2 {
				margin:0 0 10px 0;
			}
			.event-description {
				background-color: @lightest-gray;
				padding: 1.5rem;
				padding-bottom: 0.25rem;
				p {
					margin-top: 0;
					margin-bottom: 1.25rem;
				}
			}
			.event-information {
				padding-top: 3rem;
				padding-bottom: 3rem;
				.event-address {
					margin-bottom: 0;
					.anchor-red {
						display: block;
						margin-top: 1.5rem;
					}
				}
			}

			.btn-event {
				margin-bottom: 1.5rem;
			}
		}

		@media (min-width: @screen-sm-min) {
			.location-details {
				.event-description {
					background-color: transparent;
					padding-top: 0;

					& + .event-information {
						border-left: 1px solid @light-gray;
						padding-left: 3.5rem;
					}
				}
				.event-information {
					padding-top: 0;
					padding-bottom: 0;
				}
			}
		}
		@media (min-width: @screen-lg-min) {
			.location-details {
				.btn-event {
					margin-top: 2rem;
				}
			}
		}
	}

	.map-container {
		margin-top: 3rem;
		margin-left: -1.5rem;
		margin-right: -1.5rem;
		.the-map {
			width: 100%;
			height: 400px;
		}

		@media (min-width: @screen-md-min) {
			margin-top: 0;
			margin-left: 0;
			margin-right: 0;
			.the-map {
				height: 250px;
				border: 1px solid @light-gray;
			}
		}
		@media (min-width: @screen-lg-min) {
			.the-map {
				height: 400px;
			}
		}
	}
}





.toggle {
	padding-top: 40px;
	.nav-tabs {
		margin-bottom: 15px;
		margin-left: -15px;
		margin-right: -15px;
		li {
			a {
				font-size: 1.9rem;
				color: @grey;
				padding-left: 5px;
				padding-right: 5px;
				text-decoration: none;
				h2 {
					font-size: inherit;
					margin-bottom: 0;
				}
				&:hover {
					color: @red1;
				}
			}
			&.active {
				a {
					color: @red1;
				}
			}
		}
	}
	@media (min-width: 600px) {
		.nav-tabs {
			margin-left: 0;
			margin-right: 0;
			li {
				a {
					font-size: 2.4rem;
					padding-left: 15px;
					padding-right: 15px;
				}
			}
		}
	}
	@media (min-width: @screen-sm-min) {
		.nav-tabs {
			margin-bottom: 0;
		}
	}
	@media (min-width: @screen-md-min) {
		.nav-tabs {
			li {
				a {
					font-size: 3rem;
				}
			}
		}
	}
	@media (min-width: 1440px) {
		.nav-tabs {
			li {
				a {
					font-size: 3.75rem;
				}
			}
		}
	}
}

//Submit your events
.submit-your-event {
	background-color: @white;
	border: 1px solid @red1;
	padding: 1em;
	margin-bottom: 1em;
	font-size: 1.25em;
	.content {
		.submit-event {
			display: block;
		}
	}

	@media (min-width: 600px) {
		.content {
			.submit-event {
				display: inline;
			}
		}
	}
	@media (min-width: @screen-sm-min) {
		font-size: 1.75em;
	}
	@media (min-width: @screen-md-min) {
		font-size: 1.25em;
		padding: 0.5em 1em;
		float: right;
		margin-bottom: -2.5rem;
		transform: translateY(-3rem);
    position: relative;
		.content {
			.submit-event {
				display: block;
			}
		}
	}
	@media (min-width: @screen-lg-min) {
		transform: translateY(0rem);
		.content {
			.submit-event {
				display: inline;
			}
		}
	}
	@media (min-width: 1440px) {
		font-size: 1.5em;
		transform: translateY(2rem);
		padding: 0;
		background-color: transparent;
		border: 0;
	}
}


.date {
	width: 100%;
	.input-group-addon {
		background-color: transparent;
		border: none;
		cursor: pointer;
		color: @brand-primary;
		width: 90px;
		i {
			font-size: 20px;
		}
	}
}

.results-message {
	margin-top: 30px;
}

@media (min-width: @screen-sm-min) {
	.filter {
		i {
			margin-bottom: 0;
		}
		a.view-calendar {
			font-size: 16px;
			line-height: normal;
		}
		.or-text {
			margin-bottom: 0px;
		}
	}
	.date {
		width: auto;
		.input-group-addon {
			width: auto;
		}
	}
}

.tab-content {
	.tab-pane {
		margin-bottom: 25px;
	}
}

#events-home {
	.individual-event & {
		background-color: @lighter-gray;
		@media (min-width: @screen-md-min) {
			.heading-container {
				h2 {
					margin-bottom: 0;
				}
			}
		}
	}
	@media (min-width: @screen-sm-min) {
		.event-container {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
		}
	}
	@media (min-width: @screen-lg-min) {
		margin-top: 50px;
	}
}
