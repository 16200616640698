// BUTTONS
// Base Buttons
.btn {
    //white-space: normal; Not sure what effect this will have
	border-radius: 0;
	border-width: 2px;
	color: @white;
  transition: .35s ease-in-out;
	font-size: @body-font-size;
	&, &:hover, &:focus {
		text-decoration: none;
	}
	&.more-articles {
		margin-top: 20px;
  }
  &:not(.btn-link) {
    text-transform: uppercase;
	}
  .icon {
    display: inline-flex;
    align-self: center;
    width: 1em;
    height: 1em;
	}
	&.disabled.is-loading, &[disabled].is-loading, fieldset[disabled] &.is-loading {
		cursor: default;
	}
	&.color-btn {
    border-radius: 50%;
    border-color: #A5A5A5;
    height: 38px;
    width: 38px;
    position: relative;
    background-color: #FFF;
    transition: all .15s ease-in-out;
    margin-right: 3px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: rgba(0,0,0,0.25);
      opacity: 0;
      transition: opacity .15s ease-in-out;
    }
    &:hover {
      &:before {
        opacity: 1;
      }
    }
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:focus {
      border-width: 2px;
      border-color: #FFF;
      box-shadow: 0 0 0 2px @primary-03;
    }
    &.frostbite {
      background-color: #186485; // PCA: Frostbite
    }
    &.hellraisin {
      background-color: #522c42; // PCD: Hellraisin
    }
    &.sinamon-stik {
      background-color: #8a3010; // PEC: Sinamon Stik
    }
    &.f8-green {
      background-color: #1f3114; // PFQ: F8 Green
    }
    &.gold-rush {
      background-color: #b19d2c; // PJB: Gold Rush
    }
    &.torred {
      background-color: #de0a16; // PR3: TorRed
    }
    &.go-mango {
      background-color: #f85420; // PVP: Go ManGo
    }
  }
}
.btn-lg {
  font-size: @font-size-lg;
}
.btn-sm {
  font-size: @font-size-sm;
}
.btn-xs {
  font-size: @font-size-xs;
}
// Variable button sizes
.btn-variable-lg {
	@media (min-width: @screen-md-min) {
		padding: 10px 16px;
		font-size: @font-size-lg;
	}
}
.btn-solid {
	background-color: @brand-primary;
	background-image: none;
	border: none;
	text-transform: uppercase;
	padding: 5px 10px;
	font-size: 1.0em;
	transition: .35s ease-in-out;
  -webkit-transition: .35s ease-in-out;
  -moz-transition: .35s ease-in-out;
  margin-bottom: 30px;
  color: @white;
  text-align: center;
  &:hover {
  	color: @white;
  	background-color: lighten(@brand-primary, 10%)
  }
  &:focus {
  	color: @white;
  }
}

.btn-ghost {
	&-black {
		.btn-ghost(@black);
	}
	&-primary {
		.btn-ghost(@red1);

		&:hover {
			background-color: @red1;
			color: @white;
		}
	}
}

.btn-dark {
	background-color: @dark-gray;
	color: @white;

	&:hover, &:focus {
		background-color: lighten(@dark-gray, 15%);
		color: @white;
	}
}

.btn-darkish-gray {
	background-color: @darkish-gray;
	color: @white;

	&:hover, &:focus {
		background-color: lighten(@darkish-gray, 15%);
		color: @white;
	}
}

@media (min-width: @screen-sm-min) {
  .btn-solid {
    padding: 10px 20px;
    font-size: 1.3em;
  }
}

.btn-preview {
	position: relative;
	font-size: 2.5rem;
	color: @white;
	background-color: @red1;
	border: 0.5rem solid @white;
	padding-left: 1rem;
	border-radius: 50%;
	width: 7rem;
	height: 7rem;
	&.audio {
		&:focus {
			outline: 0;
		}
		&:before {
			content: '\f04b';
			font-family: FontAwesome;
			color: @white;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		&.playing {
			&:before {
				content: '\f04d';
			}
		}
	}
}

.btn-link {
  color: @brand-primary;
	text-decoration: underline;
  &:hover {
		color: @brand-primary-hover;
		text-decoration: underline;
	}
	&.no-underline, &.no-underline:hover {
		text-decoration: none;
  }
  i.fa {
    margin-right: .5rem; // 5px based on 10px
  }
}

// Solids
.btn-primary {
	border-color: @brand-primary;
	&:focus {
    background-color: @brand-primary-hover;
		border-color: @brand-primary-hover;
		outline: 1px solid @primary-03;
		outline-offset: 1px;
  }
  &:hover {
    background-color: @brand-primary-hover;
    border-color: @brand-primary-hover;
  }
}
.btn-white {
  &:extend(.btn-default);
  color: #585858;
}

// Outlines
.btn-outline-primary {
	background: transparent;
  border: @primary-03 2px solid;
  color: @primary-03;
  &:hover {
    background-color: @primary-03;
    color: @white;
	}
	&:focus {
    background-color: @primary-03;
    color: @white;
		outline: 1px solid @primary-03;
		outline-offset: 1px;
  }
}
// red border, white text, transparent background
.btn-outline-primary-white {
  background: transparent;
  border: @primary-03 2px solid;
  color: @white;
  &:hover {
    background-color: @primary-03;
    color: @white;
  }
}
// white border, white text, transparent background
.btn-outline-white {
  background: transparent;
  border: @white 2px solid;
  color: @white;
  &:hover {
    border-color: @primary-03;
    background-color: @primary-03;
    color: @white;
  }
}
.btn-outline-dark {
	background: transparent;
  border: @primary-01 2px solid;
	color: @primary-01;
	&:hover {
    background-color: @primary-01;
    color: @white;
	}
	&:focus {
    background-color: @primary-01;
    color: @white;
		outline: 1px solid @primary-03;
		outline-offset: 1px;
  }
}

// Checkbox and radio options
.btn-group-toggle {
  > .btn,
  > .btn-group > .btn {
    margin-bottom: 0; // Override default `<label>` value

    input[type="radio"],
    input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }
}

.btn-black {
  color: @white;
  background-color: @black;
  &:hover, &:focus {
    background-color: @brand-primary;
    color: @white;
  }
}

.btn-rounded {
  border-radius: 50%;
  color: @brand-primary;

  &:hover, &:focus {
    color: @white;
    background-color: @brand-primary;
  }
}
