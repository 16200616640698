.time-slip-details {
  &.container {
    margin-top: 50px;
  }
  .heading1 {
    margin-bottom: 25px;
  }
  .slip-detail {
    border-top: 1px solid @lighter-gray;
    padding-top: 15px;
    padding-bottom: 25px;
    display: flex;
    span {
      &:first-child {
        font-size: 2.0rem; // 20px based on 10px base
        text-transform: uppercase;
        color: @light-gray;
      }
      &:last-child {
        text-align: right;
        font-family: @main-font;
        font-size: 2.0rem; // 20px based on 10px base
        flex-grow: 1;
        margin-left: 5px;
      }
    }
    &.detail-lg {
      span {
        &:first-child {
          font-size: 2.4rem; // 24px based on 10px base
        }
        &:last-child {
          font-size: 3.0rem; // 30px based on 10px base
        }
      }
    }
    &.vehicle-mods {
      flex-wrap: wrap;
      span {
        &:first-child {
          width: 100%;
        }
      }
      > p {
        width: 100%;
      }
    }
  }
  dg-comments {
    margin-top: 40px;
  }
}
