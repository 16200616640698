//class applied to body when fixed navigation is enabled
#primary-nav {
  .non-fixed-nav-logo {
    display: block;
  }
  .fixed-nav-logo {
    display: none;
  }
	.fixed-nav & {
		box-shadow: 0 0 1em @black;
		position: fixed;
		top: 0;
		z-index: 10;
		width: 100%;
  }
}

// #primary-nav {
// 	position: fixed;
// 	top: 0;
// 	width: 100%;
//   max-width: 100vw;
// 	background-color: @black;
// 	font-family: @nav-font;
// 	font-weight: 700;
// 	z-index: 5;
//   //transition: all 0.3s ease-in;
//   min-height: 52px;
//   border: none;
//   border-radius: 0;
//   margin-bottom: 0;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
// 	justify-content: space-between;
// 	transition: all .2s ease-in-out;
//   .site-wrapper {
//     width: 100%;
//     overflow: visible;
// 	}
//   .container-fluid {
//     @media (min-width: @screen-md-min) {
//       display: flex;
//     }
//   }
//   .navbar-header {
// 		display: flex;
// 		transition: all .1s ease-in-out;
//     @media (min-width: @screen-sm-min) {
//       float: none;
//     }
//   }
//   .navbar-toggle {
//     background-image: none;
//     background-color: @brand-primary;
//     border: 0px none;
//     margin-top: 0px;
//     margin-right: 0px;
//     margin-bottom: 0px;
//     border-radius: 0px;
//     padding: 18px;
//     &:hover {
//       background-color: @brand-primary;
//     }
//     .icon-bar {
//       background-color: @white;
//       width: 32px;
//       height: 3px;
//     }
//   }
//   .navbar-collapse {
//     @media (min-width: @screen-md-min) {
//       flex-grow: 1;
//       display: flex !important; // Override a very stubborn BS3 style
//     }
//   }
//   .navbar-nav {
//     display: flex;
//     flex-direction: column;
//     list-style: none;
//     margin: 0;
//     @media (min-width: @screen-sm-min) {
//       flex-direction: row;
//       justify-content: space-between;
//     }
//     @media (min-width: @screen-md-min) {
//       &:first-child {
//         flex-grow: 1;
//         margin-right: auto;
//       }
//     }
//     > li {
//       display: flex;
//       flex-wrap: wrap;
// 			float: none;
// 			//transition: all .3s ease-in-out;
//       > a {
//         flex-grow: 1;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         text-transform: uppercase;
// 				color: #FFF;
// 				text-decoration: none;
//         > span {
//           display: inline-block;
//           position: relative;
//           //padding: 0 2px 2px 2px;
//           border-bottom: 2px solid transparent;
//         }
//         &:hover {
//           background-color: @darkish-gray;
//           color: fade(@white, 80%);
//         }
//         &.active span {
//           border-color: @primary-03;
//         }
//         @media (min-width: @screen-sm-min) {
//           padding-left: 5px;
//           padding-right: 5px;
//         }

//       }
//       > .dropdown-toggle {
//         padding: 0 15px 0 2px;
//         background: transparent;
//         border: 0;
//         color: #FFF;
//         &.dropdown-toggle-hide {
//           //display: none;
//           opacity: 0;
//           visibility: hidden;
//           @media (min-width: @screen-sm-min) {
//             display: none;
//           }
//         }
//       }
//       > .dropdown-menu {
//         transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
//         background-color: transparent;
//         box-shadow: 0 0 1em #000000;
//         border-radius: 0;
//         min-width: 100%;
//         > li {
//           > a {
//             color: #FFF;
//             transition: color .25s ease-in-out;
//             padding: 8px 15px 8px 25px;
// 						text-decoration: none;
//             &:hover {
//               color: @primary-03;
//               text-decoration: underline;
//               background-color: transparent;
//             }
//           }
//         }
//         @media (min-width: @screen-sm-min) {
//           margin: -8px 0 0 0;
//           background-color: rgba(255, 255, 255, 0.85);
//           > li > a {
//             color: @black;
//             text-transform: uppercase;
//             padding: 8px 25px;
//             &:hover {
//               color: @primary-03;
//             }
//           }
//         }
//       }
//       &.nav-item-has-children {
//         > a {
//           //padding-right: 2px;
//         }
//       }
//       &.open {
//         .dropdown-toggle-show {
//           //display: none;
//           opacity: 0;
//           visibility: hidden;
//           @media (min-width: @screen-sm-min) {
//             display: block;
//             opacity: 1;
//             visibility: visible;
//           }
//         }
//         .dropdown-toggle-hide {
//           //display: block;
//           opacity: 1;
//           visibility: visible;
//           @media (min-width: @screen-sm-min) {
//             display: none;
//           }
//         }
//         > a {
//           background-color: @darkish-gray;
//           color: fade(@white, 80%);
//         }
//       }
//       &.signup-share-mobile {
//         @media (min-width: @screen-sm-min) {
//           display: none;
//         }
//       }
//     }
//     .back-to-top-desktop {
// 			//display: none;
// 			width: 0;
// 			overflow: hidden;
// 			transition: all .2s ease-in-out;
// 			border-left: 1px solid @black;
// 			button {
// 				background-color: @darkish-gray;
// 				border: 0;
// 				padding: 15px;
// 				color: @white;
// 				font-family: @nav-font;
// 				font-weight: 700;
// 				transition: all .2s ease-in-out;
// 				//transition: background-color 0.25s ease-in-out;
// 				height: 100%;
// 			}
//     }
//     &:nth-child(1) {
//       @media (min-width: @screen-sm-min) {
//         width: 100%;
//       }
//       @media (min-width: @screen-md-min) {
//         width: auto;
//       }
//     }
//     &.utility-nav {
//       flex-direction: row;
//       > li {
//         flex: 0 0 50%;
//         max-width: 50%;
//         text-align: center;
//         a {
//           @media (min-width: @screen-sm-min) {
//             padding: 0 10px;
//           }
//         }
//         @media (min-width: @screen-sm-min) {
//           flex-basis: auto;
//           max-width: none;
//         }

  //     }
  //   }
  // }
  // .non-fixed-nav-logo {
  //   display: block;
  // }
  // .fixed-nav-logo {
  //   display: none;
  // }
	// .fixed-nav & {
	// 	box-shadow: 0 0 1em @black;
	// 	position: fixed;
	// 	top: 0;
	// 	z-index: 10;
	// 	width: 100%;
		//transition: all 0.25s ease-in-out;
		//transform: translateY(-200px);
		//animation: slide-in-v 0.25s forwards;

// 		.navbar-collapse {
// 			@media (min-width: @screen-lg-min) {
// 				max-height: @fixed-navbar-height-lg;
// 			}
// 			.navbar-nav {
// 				> li {
// 					//height: 52px;
// 					a {
// 						&.brand-linkback {
// 							//background-color: @red1;
//               .non-fixed-nav-logo {
//                 display: none;
//               }
//               .fixed-nav-logo {
//                 display: block;
//                 max-height: 40px;
//                 width: auto;
//                 //margin: 0;
//               }
// 							img {
// 								//display: none;
// 							}
// 							span {
// 								//display: block;
// 							}
// 							&:hover {
// 								color: @white;
// 								//background-color: #a70000;
// 							}
// 						}
// 					}
// 					&.signup-share, &.back-to-top-desktop {
// 						a {
// 							display: none;
// 						}
// 						.back-to-top {
// 							&:hover {
// 								background-color: lighten(@darkish-gray, 10%);
// 							}
// 							&:focus {
// 								outline: 0;
// 							}
//               @media (max-width: @screen-md-min) {
//                 //
//                 // height: 75px;
//               }
// 							@media (max-width: 767px) {
// 								display: none;
// 							}
// 						}
// 					}
//           &.back-to-top-desktop {
//             display: block;

// 						width: 51px;
//           }
// 				}
// 			}
// 		}

// 		@media (min-width: @screen-md-min) {
// 			min-height: 0;
// 			//height: 50px;
// 			.navbar-header {
// 				padding: 0 5px;
// 				display: flex;
// 				align-items: center;
// 				.navbar-brand {
// 					height: 50px;
// 					img {
// 						margin-top: 0;
// 					}
// 				}
// 			}
// 			.navbar-collapse {
// 				.navbar-nav {
// 					> li {
// 						> a {
// 							//height: 100%;
// 							//padding-top: 15px;
// 							//padding-bottom: 15px;
// 						}
// 					}
// 					&:nth-child(1) {
// 						li {
// 							a {
// 								//padding-top: 15px;
// 								//padding-bottom: 15px;
// 							}
// 						}
// 					}
// 					&.utility-nav {
// 						> li {
//               > a {
// 								height: 100%;
// 								//max-height: 54px;
// 								padding-top: 15px;
// 								padding-bottom: 15px;
// 							}
// 							&.signup-share {
// 								.back-to-top {
// 									height: 50px;
// 									font-size: 12px;
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}



// 	.container-fluid {
// 		@media (min-width: @screen-sm-min) {
// 			padding-left: 0;
// 			padding-right: 0;
// 		}
// 	}



// 	//Header
// 	.navbar-header {
// 		.navbar-brand {
// 			display: block;
// 			float: none;
// 			flex-grow: 1;
// 			position: relative;
// 			padding: 5px 0;
// 			> img {
// 				width: auto;
// 				height: 100%;
// 			}
// 		}
// 		@media (min-width: @screen-sm-min) {
// 			//width: 100%;
// 			width: auto;
// 			.navbar-brand {
// 				width: auto;
// 				height: 75px;
// 				margin-left: 0;
// 				> img {
// 					width: 100%;
// 					max-width: 225px;
// 					height: auto;
// 					margin-top: 5px;
// 				}
// 			}
// 		}
// 		@media (min-width: @screen-md-min) {
// 	    width: 190px;
// 			padding-top: 0;
// 			padding-bottom: 0;
// 			.navbar-brand {
// 				width: 100%;
// 				> img {
// 					position: absolute;
// 					top: 50%;
// 					transform: translateY(-50%);
// 				}
// 			}
// 		}
// 		@media (min-width: @screen-lg-min) {
// 			width: 200px;
// 			padding-top: 20px;
// 			padding-bottom: 20px;
// 			.navbar-brand {
// 				height: 50px;
// 				padding: 5px 0;
// 				> img {
// 					max-width: 270px;
// 					margin-top: 0;
// 				}
// 			}
// 		}
// 	}


// 	//Collapse
// 	.navbar-collapse {
// 		padding-left: 0;
// 		padding-right: 0;
// 		.navbar-nav {
// 			//width: 100%;
// 			//margin: 10px 0 0 0;
// 			li {
// 				//height: 100%;
// 				a {
// 					font-size: 1.4rem; // 14px based on 10px
// 					transition: background-color 0.25s ease-in-out;
// 					//transition: all .3s ease-in-out;
// 					&.brand-linkback {
// 						img {
// 							max-width: 175px;
// 						}
// 						span {
// 							display: none;
// 						}
//             &.mopar-nav {
//               img {
//                 max-width: 75px;
//               }
//             }
//             &:last-child {
//               //padding-bottom: 25px;
//             }
// 					}
// 					&.signup-share {
// 						background-color: @darkish-gray;
// 						&:hover {
// 							background-color: @red1;
//               color: @white;
// 						}
// 					}
// 				}
// 				&.signup-share {
// 					button {
// 						display: none;
// 					}
// 				}
// 				&.divider {
// 					height: 2px;
// 					background-color: white;
// 					padding-top: 0;
// 					padding-bottom: 0;
// 					margin: 10px 0;
// 				}
// 			}
// 		}

//     /*@media (max-width: (@screen-sm-min - 1px)) {
//       .navbar-nav.utility-nav {
//         display: flex;
//         > li {
//           flex-grow: 1;
//           width: 50%;
//           height: auto;
//           > a {
//             height: 100%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//           }
//         }
//       }
//     }*/

// 		@media (min-width: @screen-sm-min) {
// 			.navbar-nav {
// 				//width: 100%;
// 				li {
// 					a {
// 						//padding-left: 8px;
// 						//padding-right: 8px;
// 						&.brand-linkback {
// 							img {
// 								max-width: 145px;
// 							}
// 						}
// 					}
// 					&.divider {
// 						display: none;
// 					}
// 				}
// 				&:nth-child(1) {
// 					// float: left;
// 					// padding-left: 15px;
// 					margin-top: 0;
// 					//height: 50px;
//     			background-color: #333;
// 					> li {
// 						//width: calc(100% / 6);
// 						> a {
// 							//width: 100%;
// 							//text-align: center;
// 							//padding-top: 15px;
// 							//padding-bottom: 15px;
// 						}
// 					}
// 				}
// 				&.utility-nav {
// 					width: auto;
// 					position: absolute;
// 					top: 0;
// 					left: auto;
// 					right: 0;
// 					margin-top: 0;
// 					> li {
// 						> a {
// 							height: 75px;
//               line-height: 55px;
//               &.brand-linkback {
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 //padding-top: 0;
//                 //padding-bottom: 0;
//                 &.mopar-nav {
//                   img.non-fixed-nav-logo {
//                     max-height: 50px;
//                     width: auto;
//                     margin: 0;
//                   }
//                 }
//               }
// 							img {
// 								//margin-top: 15px;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 		@media (min-width: @screen-md-min) {
// 			.navbar-nav {
// 				//width: auto;
// 				//margin-top: 0;
// 				li {
// 					a {
// 						font-size: 1.15rem; // 11.5px based on 10px
// 						&.brand-linkback {
//               &.mopar-nav {
//                 img.non-fixed-nav-logo {
//                   max-height: 50px;
//                   width: auto;
//                   margin: 0;
//                 }
//               }
// 							img {
// 						    max-width: 105px;
//                 margin-top: 0;
//                 margin-bottom: 0;
// 							}
// 						}
// 					}
// 				}
// 				&:nth-child(1) {
// 					background-color: transparent;
// 					height: auto;
// 					//float: left;
// 					padding-left: 15px;
// 					li {
// 						width: auto;
// 						> a {
// 							//padding-top: 35px;
// 							//padding-bottom: 35px;
// 						}
// 					}
// 				}
// 				&.utility-nav {
// 					position: relative;
// 					max-width: 384px;
// 					> li {
// 						> a {
// 							height: 92px;
//               line-height: 22px;
// 							img {
// 								margin-top: 0;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 		@media (min-width: @screen-lg-min) {
// 			.navbar-nav {
// 				li {
// 					a {
// 						font-size: 1.4rem; // 14px based on 10px
// 						//padding-left: 13px;
// 						//padding-right: 13px;
//             &.brand-linkback {
//               img {
//                 max-width: 140px;
//               }
//             }
// 					}
// 				}
// 				&:nth-child(1) {
// 					margin-left: 3px;
// 				}
// 			}
// 		}
//     @media (min-width: (@screen-lg-min + 30px)) {
//       .navbar-nav {
//         &:nth-child(1) {
//           margin-left: 30px;
//         }
//       }
//     }
// 	}
// 	&.is-server .dropdown-menu {
// 		opacity: 0;
// 	}
// }