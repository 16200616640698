&#hero-cdm {
  display: flex;
  background-image: url('/assets/images/hero/cdm/cdm-banner-bg-winner-md.jpg');
  background-position: center;

  @media (min-width: 600px) {
    background-position: -100px center;
  }
  @media (min-width: 768px) {
    background-position: center center;
  }
  @media (min-width: 992px) {
    background-image: url('/assets/images/hero/cdm/cdm-banner-bg-winner-xl.jpg');
  }
  @media (min-width: 1600px) {
    background-position: center top;
  }
  .site-wrapper-xl {
    width: 100%;

    .img-logo {
      position: absolute;
      top: 25px;
      left: 25px;
      width: 150px;
      height: auto;
      display: none;

      @media (min-width: 600px) {
        width: 180px;
        display: block;
      }
      @media (min-width: 992px) {
        top: 50%;
        transform: translateY(-50%);
        width: 225px;
      }
      @media (min-width: 1200px) {
        width: 300px;
      }
      @media (min-width: 1600px) {
        left: 100px;
        width: 360px;
      }
    }

    .hero-content {
      position: absolute;
      top: auto;
      left: 20px;
      bottom: 20px;

      @media (min-width: 992px) {
        left: auto;
        right: 20px;
      }
      @media (min-width: 1200px) {
        top: 50%;
        left: auto;
        bottom: auto;
        right: 35px;
        transform: translateY(-50%);
      }

      .on-black {
        display: inline;
        background: $black;
        font-size: 24px;
        color: $white;
        line-height: 1.5;
        box-shadow: -10px 0 0 $black, 10px 0 0 $black;
        word-wrap: break-word;
        padding: 10px 0;

        @media (min-width: 992px) {
          font-size: 32px;
        }
      }

      .btn {
        margin-top: 20px;
        margin-left: -10px;
      }
    }
  }
}
