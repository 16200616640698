#cdm-landing-hero, #cdm-hiring-hero {
  @hero-height-small: 400px;
  @hero-height-large: 575px;

  background-image: url('/assets/images/chief-donut-maker/burnout-banner.jpg');
  background-position: center top;
  height: 100%;
  padding: 80px 0 40px;

  .hero-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .cdm-lead-image {
    position: absolute;
    width: 150px;
    top: -50px;
    left: -20px;
    z-index: 2;
    max-width: 300px;

    @media (min-width: @screen-xs-min) {
      width: 50%;
    }


    @media (min-width: @screen-md-min) {
      max-width: none;
      width: 325px;
      top: 0;
      left: auto;
      right: 49%;
      transform: translateX(-50%);
    }

    @media (min-width: @screen-lg-min) {
      width: auto;
      right: 40%;
      transform: translateX(-50%);
    }

    @media (min-width: @screen-xl-min) {
      right: 54%;
    }
  }

  .hero-application {
    position: absolute;
    bottom: -20px;
    right: 20px;
    z-index: 3; // on top of video container

    @media (min-width: @screen-lg-min) {
      right: -80px
    }
  }

  .video-container {
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex: 0 0 90%;

    &::after {
      content: "";
      padding-bottom: 56.25%;
    }

    @media (min-width: @screen-md-min) {
      flex: 0 0 765px;
    }
  }

  .cdm-hero-video {
    max-width: 765px;
    height: auto;
  }

  .btn-rounded {
    position: static;
    z-index: 2;

    &:before {
      cursor: pointer;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: @screen-md-min) {
    height: @hero-height-large;
    padding: 70px 0;
  }
}

.video-container {
  #cdm-landing-hero & {
    background-image: url('/assets/images/chief-donut-maker/landing-page-video-thumb.jpg');
  }
  #cdm-hiring-hero & {
    background-image: url('/assets/images/chief-donut-maker/hiring-process-page-video-thumb.jpg');

  }
}
