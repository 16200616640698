// CTA that appears on the homepage and racing HQ page
dg-thirteen-twenty-tile {
    display: block;
    height: 100%;
    width: 100%;
    background: url('/assets/images/1320-homepage-tile.webp') no-repeat left center;
    background-size: cover;
    /*@media (min-width: @screen-md-min) {
        min-height: 0;
    }*/
    body.no-webp & {
        background-image: url('/assets/images/1320-homepage-tile.jpg');
        background-size: cover;
    }
    > a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem; // 15px based on 10px
        color: #FFF;
        width: 100%;
        height: 100%;
        min-height: 450px;
        text-decoration: none;
        &:hover {
            color: #FFF;
            text-decoration: none;
        }
        @media (min-width: @screen-sm-min) {
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-around;
            //min-height: 0;
        }
        img {
            width: 100%;
            max-width: 128px;
            height: auto;
        }
        p {
            margin-top: auto;
            font-size: 22px;
            line-height: 27px;
            text-transform: uppercase;
            font-family: @main-font;
            text-align: center;
            @media (min-width: @screen-sm-min) {
                margin-top: 0;
                margin-bottom: 0;
                //text-align: left;
            }
        }
    }
}