// FONTS
@font-path: '../../fonts/';

@font-face {
	// Trade Gothic LT Light
	font-family: "Trade Gothic LT W01 Light";
	src:url("@{font-path}trade-gothic-lt-w01-light.eot?#iefix");
	src:url("@{font-path}trade-gothic-lt-w01-light.eot?#iefix") format("eot"), 
	url("@{font-path}trade-gothic-lt-w01-light.woff2") format("woff2"), 
	url("@{font-path}trade-gothic-lt-w01-light.woff") format("woff"),
	url("@{font-path}trade-gothic-lt-w01-light.ttf") format("truetype");
}
@font-face {
	// Trade Gothic LT Light Italic
	font-family: "Trade Gothic LT W01 Light Obl";
	src:url("@{font-path}trade-gothic-lt-w01-light-obl.eot?#iefix");
	src:url("@{font-path}trade-gothic-lt-w01-light-obl.eot?#iefix") format("eot"), 
	url("@{font-path}trade-gothic-lt-w01-light-obl.woff2") format("woff2"), 
	url("@{font-path}trade-gothic-lt-w01-light-obl.woff") format("woff"),
	url("@{font-path}trade-gothic-lt-w01-light-obl.ttf") format("truetype");
}
@font-face {
	// Trade Gothic LT Bold
	font-family: "Trade Gothic LT W01 Bold";
	src:url("@{font-path}trade-gothic-lt-w01-bold.eot?#iefix");
	src:url("@{font-path}trade-gothic-lt-w01-bold.eot?#iefix") format("eot"), 
	url("@{font-path}trade-gothic-lt-w01-bold.woff2") format("woff2"), 
	url("@{font-path}trade-gothic-lt-w01-bold.woff") format("woff"),
	url("@{font-path}trade-gothic-lt-w01-bold.ttf") format("truetype");
}
@font-face {
	// Trade Gothic LT Bold Italic
	font-family: "Trade Gothic LT W01 Bold Obl";
	src:url("@{font-path}trade-gothic-lt-w01-bold-obl.eot?#iefix");
	src:url("@{font-path}trade-gothic-lt-w01-bold-obl.eot?#iefix") format("eot"), 
	url("@{font-path}trade-gothic-lt-w01-bold-obl.woff2") format("woff2"), 
	url("@{font-path}trade-gothic-lt-w01-bold-obl.woff") format("woff"),
	url("@{font-path}trade-gothic-lt-w01-bold-obl.ttf") format("truetype");
}
@font-face {
	// Trade Gothic LT Condensed
	font-family: "Trade Gothic LT W01 Cond No-1";
	src:url("@{font-path}trade-gothic-lt-w01-cond-no-1.eot?#iefix");
	src:url("@{font-path}trade-gothic-lt-w01-cond-no-1.eot?#iefix") format("eot"), 
	url("@{font-path}trade-gothic-lt-w01-cond-no-1.woff2") format("woff2"), 
	url("@{font-path}trade-gothic-lt-w01-cond-no-1.woff") format("woff"),
	url("@{font-path}trade-gothic-lt-w01-cond-no-1.ttf") format("truetype");
}
@font-face {
	// Trade Gothic LT Condensed Italic
	font-family: "Trade Gothic LT W01 Cn No-18Ob";
	src:url("@{font-path}trade-gothic-lt-w01-Cn-No-18Ob.eot?#iefix");
	src:url("@{font-path}trade-gothic-lt-w01-Cn-No-18Ob.eot?#iefix") format("eot"), 
	url("@{font-path}trade-gothic-lt-w01-Cn-No-18Ob.woff2") format("woff2"), 
	url("@{font-path}trade-gothic-lt-w01-Cn-No-18Ob.woff") format("woff"),
	url("@{font-path}trade-gothic-lt-w01-Cn-No-18Ob.ttf") format("truetype");
}
@font-face {
	// Trade Gothic LT Bold Condensed
	font-family: "Trade Gothic LT W01 Bd Cn No-2";
	src:url("@{font-path}trade-gothic-lt-w01-BdCn-no-2.eot?#iefix");
	src:url("@{font-path}trade-gothic-lt-w01-BdCn-no-2.eot?#iefix") format("eot"), 
	url("@{font-path}trade-gothic-lt-w01-BdCn-no-2.woff2") format("woff2"), 
	url("@{font-path}trade-gothic-lt-w01-BdCn-no-2.woff") format("woff"),
	url("@{font-path}trade-gothic-lt-w01-BdCn-no-2.ttf") format("truetype");
}


// Currently unused font variants - Uncomment and create a new variable if we decide to use them

// @font-face {
// 	font-family: "Trade Gothic LT W01BdCnNo-20Ob";
// 	src:url("@{font-path}trade-gothic-lt-w01BdCnNo-20Ob.eot?#iefix");
// 	src:url("@{font-path}trade-gothic-lt-w01BdCnNo-20Ob.eot?#iefix") format("eot"), 
// 	url("@{font-path}trade-gothic-lt-w01BdCnNo-20Ob.woff2") format("woff2"), 
// 	url("@{font-path}trade-gothic-lt-w01BdCnNo-20Ob.woff") format("woff"),
// 	url("@{font-path}trade-gothic-lt-w01BdCnNo-20Ob.ttf") format("truetype");
// }
// @font-face {
// 	font-family: "Trade Gothic LT W01 Bold Ext";
// 	src:url("@{font-path}trade-gothic-lt-w01-bold-ext.eot?#iefix");
// 	src:url("@{font-path}trade-gothic-lt-w01-bold-ext.eot?#iefix") format("eot"), 
// 	url("@{font-path}trade-gothic-lt-w01-bold-ext.woff2") format("woff2"), 
// 	url("@{font-path}trade-gothic-lt-w01-bold-ext.woff") format("woff"),
// 	url("@{font-path}trade-gothic-lt-w01-bold-ext.ttf") format("truetype");
// }
// @font-face {
// 	font-family: "Trade Gothic LT W01 Roman";
// 	src:url("@{font-path}trade-gothic-lt-w01-roman.eot?#iefix");
// 	src:url("@{font-path}trade-gothic-lt-w01-roman.eot?#iefix") format("eot"), 
// 	url("@{font-path}trade-gothic-lt-w01-roman.woff2") format("woff2"), 
// 	url("@{font-path}trade-gothic-lt-w01-roman.woff") format("woff"),
// 	url("@{font-path}trade-gothic-lt-w01-roman.ttf") format("truetype");
// }
// @font-face {
// 	font-family: "Trade Gothic LT W01 Oblique";
// 	src:url("@{font-path}trade-gothic-lt-w01-light-obl.eot?#iefix");
// 	src:url("@{font-path}trade-gothic-lt-w01-light-obl.eot?#iefix") format("eot"), 
// 	url("@{font-path}trade-gothic-lt-w01-light-obl.woff2") format("woff2"), 
// 	url("@{font-path}trade-gothic-lt-w01-light-obl.woff") format("woff"),
// 	url("@{font-path}trade-gothic-lt-w01-light-obl.ttf") format("truetype");
// }