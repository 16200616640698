#mission-banner {
  position: relative;
  display: flex;
  align-items: center;
  color: $white;
  background-image: url('/assets/images/we-are-dodgegarage.jpg');
  background-size: cover;
  background-position: center right;
  padding: 60px 30px;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75672275746236) 66%, rgba(0,0,0,1) 100%);
  }

  @media (min-width: 1440px) {
    padding: 100px 30px;
  }

  .mission {
    position: relative;
    z-index: 1;
    max-width: 550px;
    font-size: 14px;

    @media (min-width: 1200px) {
      font-size: 18px;
    }
    @media (min-width: 1600px) {
      max-width: 650px;
      font-size: 21px;
    }

    .mission-heading {
      font-family: $font-condensed;
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 20px;

      @media (min-width: 1200px) {
        font-size: 32px;
      }
      @media (min-width: 1600px) {
        font-size: 45px;
      }
    }
    .subheading {
      display: block;
      font-size: 1.4em;
      margin-bottom: 20px;

      @media (min-width: 1600px) {
        font-size: 20px;
      }
    }
    p {
      font-size: 1.4em;
    }
  }

  .logo {
    position: absolute;
    top: 15px;
    left: auto;
    right: 15px;
    width: 150px;

    @media (min-width: 1440px) {
      top: 25px;
      right: 25px;
    }
    @media (min-width: 1600px) {
      width: 225px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
