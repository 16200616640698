#cdm-perks-secondary-hero{
  position: relative;

  h2 {
    position: absolute;
    top: 10%;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;

    @media (min-width: @screen-sm-min) {
      font-size: @h1-font-size;
    }
  }
}

#cdm-perks-up-to-speed {
  background-color: black;
  padding: 40px 0;

  > h2 {
    margin-bottom: 20px;
  }
}

#perks-radford::after {
  background-image: url('/assets/images/chief-donut-maker/perks-offset-radford.jpg');
}

#perks-behind::after {
  background-image: url('/assets/images/chief-donut-maker/perks-offset-behind-the-scenes.jpg');
}





