.step-header {
  .step-indicator {
    font-size: @h4-font-size;
  }
  .step-heading {
    font-size: @h3-font-size;
    margin-bottom: 3rem;

    & ~ .step-subheading {
      margin-bottom: 1.5rem;
    }
  }
  .step-subheading {
    font-size: @h4-font-size;
    color: @red1;
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;

    & ~ .step-heading {
      margin-bottom: 3rem;
    }
  }
}

.heading {
  .form-1320 & {
    font-family: @main-font, "Helvetica Neue", sans-serif;
    font-size: 2.8rem;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 2rem;
  }
}

.section-lead {
  p, ul {
    @media (min-width: 1200px) {
      font-size: 2rem;
    }
  }
  .subheading {
    font-size: 2.8rem;
  }
}

.subheading {
  font-size: 2.8rem;
}

.footer-text {
  margin-bottom: 2.5rem;
}
  