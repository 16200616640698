.profile-header {
	position: relative;
}
// .edit-header {
// 	position: absolute;
// 	top: 10px;
// 	right: 10px;
// 	padding: 5px 9px;
// 	box-shadow: 0px 0px 3px @black;
// }
// a.edit-header, a.edit-avatar {
// 	&:hover, &:active, &:focus {
// 		color: @white;
// 	}
// 	i {
// 		cursor: pointer;
// 		box-shadow: 0px 0px 3px @black;
// 	}
// }

.outline {
	border: solid 2px @white;
	border-radius: 100%;
}
// .edit-avatar {
// 	position: absolute;
// 	padding: 5px 9px;
// 	box-shadow: 0px 0px 3px @black;
// 	top: 42%;
// 	left: 42%;
// 	display: none;
// }

.profile-avatar {
	position: relative;
	top: -12vw;
	background-color: @black;
	border-radius: 100%;
	padding: 0;
	img {
		border-radius: 100%;
		border: solid 1px @light-gray;
		box-shadow: 0px 0px 10px @black;
		transition: all 0.5s ease;
	}
	// &:hover {
	// 	.edit-avatar {
	// 		display: block;
	// 	}
	// 	img {
	// 		opacity: 0.6;
	// 	}
	// }
}
.profile-details {
	margin-bottom: 10px;
	width: 100%;
}
.save-profile, .update-profile {
	.error {
		color: @brand-primary;
	}
	.success {
		color: #33a10d;
	}
}

.details-container {
	margin-top: -20px;
	span {
		margin-right: 10px;
		font-size: 18px;
		color: @light-gray;
	}
}




@media (min-width: @screen-sm-min) {
	.profile-avatar {
		position: relative;
		top: -7vw;
	}
	.details-container {
		margin-top: 0px;
	}

}




