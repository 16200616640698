#rkn-page {
    background-image: url(/assets/images/rkn/smoke-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  
    color: @white;
    padding-bottom: 10rem;

    .intro-container {
      @media (min-width: 992px) {
        display: flex;
      }
    }

  .intro-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      padding: 20px;
      width: 259px;
    }

    .intro-copy {
      margin-bottom: 10px;
    }

    @media (min-width: @screen-lg) {
      flex-direction: row;
    }
  }

  .content-header {
    display: flex;
    justify-content: space-between;
  }

  .lil-icons {
    color: black;
    font-size: 30px;
    margin-left: 10px;
  }

  .builder-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -50px;

    .builder {
      flex: 0 0 169px;
      text-align: center;
      margin: 0 50px 20px;

      div {
        position: relative;
        margin-bottom: 10px;
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
          content: "";
          opacity: 0;
          border: 2px solid @brand-primary;
        }
      }

      p {
        color: @white;
      }
    }
    a {
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: none;
        div::before {
          opacity: 1;
        }
        p {
          color: @brand-primary;
        }
      }
    }
  }

    #special {
      //margin-top: -8rem;
      padding: 3rem 2rem;

      @media (min-width: 1200px) {
        padding: 2.4rem 3rem;
      }

      // Quick and dirty flex styling for intro section.
      > .row {
        margin-top: 0 !important;

        @media (min-width: 768px) {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;
        }

        @media (min-width: 1200px) {
          flex-direction: row;
          margin-top: 15px !important;
          margin-bottom: 30px !important;
        }

        > div {

          @media (min-width: 1200px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          &:nth-child(1) {
            img {
              width: 220px;
              margin: 0 auto;
              margin-bottom: 30px;

              @media (min-width: 768px) {
                width: 250px;
              }
              @media (min-width: 992px) {
                width: 175px;
              }
              @media (min-width: 1200px) {
                margin-bottom: 0;
              }
              @media (min-width: 1440px) {
                width: 275px;
              }
            }
          }

          &:nth-child(2) {
            text-align: center;

            @media (min-width: 1200px) {
              text-align: left;
            }

            @media (min-width: 1440px) {
              padding-left: 30px;
            }

            h2 {
              font-size: 32px;

              @media (min-width: 1440px) {
                font-size: 40px;
              }
            }
            p {
              font-size: 18px;

              @media (min-width: 1200px) {
                font-size: 16px;
              }
              @media (min-width: 1440px) {
                font-size: 20px;
              }
            }
          }
        }
      }

      p {
        font-size: 18px;

        @media (min-width: 1200px) {
          font-size: 20px;
        }
      }
    }

    #breaker {
      @media (max-width: 992px) {
        display: block;
      }
    }

    .container {
        padding-top: 9rem;

        @media (max-width: 767px) {
          padding-top: 4rem;
        }

        &.container-landing {
          @media (min-width: 1200px) {
            width: 100%;
            max-width: 1500px;
          }
        }
    }



    .flex-rows {
        display: flex;
        align-items: stretch;
        @media(max-width: 992px) {
          display: inherit;
        }
    }

    .white-block {
        background: @white;
        color: @black;
        padding: 3rem 4.5rem;
        height: 100%;
        // max-height: 290px; // bring this back after Kate sees how bad the copy is

        &.short {
            height: inherit;
            max-height: inherit;
        }

        p{
            margin-bottom: 0;
        }
    }

    // Carousel styling
    .video-list {

      @media (min-width: 992px) {
        height: 420px;
      }
      @media (min-width: 1200px) {
        height: 490px;
      }

      .carousel-inner {
        height: inherit;
      }

      .item {
        height: inherit;
        // padding-left: 15px;
        // padding-right: 15px;
      }

      .video-thumbnail {
        display: block;
        position: relative;
        font-size: 60px;
        color: @white;
        cursor: pointer;

        @media (min-width: 992px) {
          height: inherit;
          overflow: hidden;
          margin-bottom: 50px !important;
        }

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 1;
          transform: translate(-50%, -50%);
          transition: all 0.25s ease-in-out;
          text-shadow: 0px 0px 15px @black;
          opacity: 0.85;
        }

        img {
          @media (min-width: 992px) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: auto;
            height: 100%;
            max-width: none;
          }
        }

        &:hover {
          i {
            opacity: 1;
          }
        }
      }

      .carousel-indicators {
        position: static;
        display: flex;
        justify-content: center;
        width: 100%;
        margin-left: 0;
        margin-bottom: 0;
        padding: 15px 0;

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          font-family: @main-font;
          font-size: 20px;
          color: @white;
          text-indent: 0;
          background-color: rgba(0, 0, 0, 0);
          border: 1px solid rgba(0, 0, 0, 0);
          border-radius: 0;
          margin: 0;
          margin-right: 20px;
          transition: all 0.25s ease-in-out;
          transform: scale(1);
          transform-origin: center center;

          &:last-of-type {
            margin-right: 0;
          }

          &.active, &:hover {
            color: @brand-primary;
            border-color: @white;
            transform: scale(1.25);
          }
        }
      }
    }

    // Polls
    .poll-container {
      padding: 55px 0;

      @media (min-width: 992px) {
        padding: 75px 0;
      }
      @media (min-width: 1440px) {
        padding-top: 125px;
      }

      form {
        @media (min-width: 768px) {
          display: flex;
        }
        @media (min-width: 992px) {
          flex-wrap: wrap;
          padding: 0;
        }
      }

      .poll-question {
        font-family: @main-font;
        font-size: 3.2rem;
        color: @white;
        text-transform: uppercase;
        line-height: 1.2;
        padding: 20px 0;

        @media (min-width: 768px) {
          display: flex;
          align-items: center;
        }

        @media (min-width: 992px) {
          flex: 0 0 100%;
          padding-top: 0;
          padding-bottom: 30px;
        }

      }
      .poll-form {
        margin-bottom: 0;

        @media (min-width: 768px) {
          flex: 0 0 340px;
          margin-left: auto;
          padding-left: 40px;
        }
        @media (min-width: 992px) {
          display: flex;
          flex: 0 0 100%;
          padding-left: 0;
        }

        .btn-radio {

          @media (min-width: 992px) {
            flex: 0 0 170px;
            font-size: 14px;
            margin-right: auto;
            margin-bottom: 0;
          }
          @media (min-width: 1200px) {
            flex: 0 0 17%;
          }
          @media (min-width: 1440px) {
            font-size: 20px;
          }

          &:first-of-type {
            margin-top: 0;
          }

          label {
            padding: 10px 15px;
          }
        }

        .btn-primary {
          width: 160px;
          margin-top: 20px;

          @media (min-width: 992px) {
            flex: 0 0 75px;
            width: auto;
            font-size: 14px;
            margin-left: auto;
            margin-top: 0;
          }
          @media (min-width: 1200px) {
            flex: 0 0 10%;
          }
          @media (min-width: 1440px) {
            font-size: 20px;
          }
        }

        .help-block {
          position: absolute;
          top: auto;
          right: 0;
          bottom: 0;
          width: 100%;
          padding: 15px;

          @media (min-width: 768px) {
            width: 340px;
            padding-left: 25px;
          }
          @media (min-width: 992px) {
            bottom: 10px;
            text-align: right;
          }
        }
      }

      .poll-result {
        position: relative;
        display: block;
        font-family: @main-font;
        font-size: 1.8rem;
        color: @black;
        text-align: left;
        margin-bottom: 5px;
        border: 0;
        padding: 14px 19px;
        background-color: @white;

        @media (min-width: 992px) {
          flex: 0 0 170px;
          font-size: 14px;
          margin-right: auto;
          margin-bottom: 0;
        }
        @media (min-width: 1200px) {
          flex: 0 0 17%;
        }
        @media (min-width: 1440px) {
          font-size: 20px;
        }

        .poll-result-background {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          height: 100%;
          background-color: #d9d9d9;
          pointer-events: none;
        }

        .poll-result-foreground {
          position: relative;

          .poll-result-foreground-container {
            display: flex;
          }
        }

        .percentage {
          display: block;
          font-size: inherit;
          text-align: center;
          text-transform: uppercase;
          margin-right: 12px;
          transform: scale(1.25) translateX(-3px);
        }

        &.highest {
          .percentage {
            color: @brand-primary;
          }
        }

        &.active {
          .poll-result-foreground {
            &:after {
              content: "\f05d";
              display: inline-block;
              position: absolute;
              top: 50%;
              left: auto;
              right: 0;
              transform: translateY(-50%);
              font-family: FontAwesome;
            }
          }
        }
      }

      .total-votes-container {
        display: flex;
        align-items: center;
        color: @white;
        margin-top: 20px;

        @media (min-width: 992px) {
          position: relative;
          flex: 0 0 75px;
          margin-top: 0;
        }

        @media (min-width: 1200px) {
          flex: 0 0 10%;
        }

        @media (min-width: 1440px) {
          font-size: 20px;
        }

        .total-votes {
          font-size: 18px;
          text-align: center;
          text-transform: uppercase;
          line-height: 1.1;
          margin-right: auto;
          margin-left: auto;
        }
        .flyout {
          justify-content: flex-start;
          .btn {
            color: @white;
          }
        }

        .addthis_inline_share_toolbox {
          @media (min-width: 992px) {
            position: absolute;
            width: 400px;
            top: auto;
            bottom: -60px;
            left: auto;
            right: 0;
            text-align: right;
          }
        }
      }
    }

    #influencer-ribbon {
        display: flex;
        justify-content: space-evenly;
        background-image: url(/assets/images/rkn/big-group.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0 100px;
        margin: 0 auto;
        margin-top: 25px;
        max-width: 1920px;

        .influencer-tile {
            padding-top: 2rem;

            a {
                color: @white;
                text-decoration: none;
            }

            p {
                text-transform: uppercase;
            }
        }

        @media (max-width: 1200px) {
          flex-wrap: wrap;
          .influencer-tile {
            width: 30%;
          }
        }

        @media (max-width: 500px) {
          background-image: none;
          flex-direction: column;
          .influencer-tile {
            flex-direction: column;
            width: 100%;
          }
        }
    }

    .modal-header {
        color: @black;
    }

    #roadkill-nights-video {
      background-color: @black;
      color: @white;
      min-height: 100vh;
    }

    #build-videos, #build-gallery {
      padding-top: 75px;
    }

    .section-heading {

      @media (min-width: 768px) {
        display: flex;
        align-items: flex-end;
      }

      .heading {
        font-size: 32px;
        line-height: 1;
      }

      p {
        @media (min-width: 768px) {
          margin-bottom: 0;
          margin-left: 15px;
        }
      }
    }

    // Gallery styling
    .image-container {
      //column-count: 2;

      .image {
        display: block;
        float: none;
        margin-bottom: 15px;

        opacity: 0;
        animation: fade-in 0.5s ease-in-out forwards;

        @media (min-width: 768px) {
          margin-bottom: 35px;
        }
      }
    }

    .button-container {
      padding-top: 45px;
      padding-bottom: 45px;

      .btn-rkn-outline {
        display: inline-flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0,);
        border: 0;
        color: @lighter-gray;
        transition: all 0.25s ease-in-out;

        @media (min-width: 992px) {
          font-size: 20px;
        }
        @media (min-width: 1440px) {
          font-size: 26px;
        }

        &:hover, &:focus {
          color: @white;
          outline: 0;
        }

        i {
          font-size: 0.75em;
          margin-left: 5px;
        }
      }
    }


    // Modal styling
    #video-modal,
    #other-modal {
      .modal-header {
        position: absolute;
        top: 0;
        left: auto;
        right: 0;

        .modal-close {
          top: 15px;
          right: 15px;
          width: 35px;
          height: 35px;
          z-index: 1;
          line-height: 0;
          background-color: white;
        }
      }
      .modal-body {
        padding: 0;


      }
    }


    // Test form styling
    .form-inline {
      display: flex;

      input {
        flex: 0 0 65%;
        border-radius: 0;
      }
      .btn {
        flex: 1;
      }
    }
}

.at-expanding-share-button[data-position=bottom-right] { display: block; }
