// Social Media
share-buttons {
  display: block;
  height: 100%;
}
.sb-button,
.sb-group {
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.sb-group {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.sb-button {
  margin: .3125em
}

.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125rem;
  height: 2.5rem;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571rem;
  background-color: transparent
}

.sb-wrapper .sb-count,
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.sb-wrapper .sb-inner {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.sb-wrapper .sb-content,
.sb-wrapper .sb-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%
}

.sb-wrapper .sb-text {
  padding: 0 .7em;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  white-space: nowrap
}

.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  //min-width: 2em
}

.sb-wrapper .sb-count {
  font-size: .9em;
  padding: 0 .7em
}

.sb-wrapper .sb-count,
.sb-wrapper .sb-text {
  font-weight: 700
}

.sb-show-text .sb-icon {
  width: 2em
}

.sb-show-count {
  min-width: 5.333em
}


// Flyout
.flyout {
  position: relative;
  text-align: right;
  min-width: 300px;
  display: flex;
  justify-content: flex-end;
  button.btn {
    color: @light-gray;
    font-size: 2.5rem;
    z-index: 2;
    background: transparent;
    position: relative;
  }
  .flyout-menu {
    max-width: 0;
    padding: 6px 0;
    overflow: hidden;
    //position: absolute;
    top: 0;
    left: 100%;
    transition: all .5s;
    .sb-group {
      flex-wrap: nowrap;
    }
  }
  &.is-open {
    .flyout-menu {
      max-width: 100%;
      left: 0;

    }
  }
}
/*.flyout-action-bar{
  position: relative;
  margin-top: 70px;
}
.flyout-action-bar-actions{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 65px;
  height: 65px;
  transform: translateX(-50%) translateY(-50%);
  .flyout-action-bar-action{
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    transform-origin: 50% 50%;
  }
  .flyout-action{
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);;
    svg{
      max-height: 50px;
      fill: #1a1a1a;
    }
  }
}
.flyout-icon {
  position: relative;
  width: 65px;
  height: 65px;
  cursor: pointer;
  &.is-active{
    > svg{
      transform: rotate(45deg);
    }
  }
  svg{
    width: 65px;
    height: 65px;
    transition: 400ms transform;
  }
}*/
