.gallery-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2rem auto;

  @media (min-width: 992px) {
    margin: 4rem auto;
  }

  .gallery-image {
    flex: 0 0 50%;
    max-width: 50%;
    border: 1px solid white;
    opacity: 0.85;
    transition: all 0.25s ease-in-out;

    &:hover {
      opacity: 1;
    }

    @media (min-width: 600px) {
      flex: 0 0 calc(100% / 3 );
      max-width: calc(100% / 3 );
    }
    @media (min-width: 992px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    @media (min-width: 1440px) {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  .show-more-container {
    margin: 20px 0;
    width: 100%;
    text-align: center;
  }
}

.gallery-directions {
  margin-top: 2rem;
  margin-bottom: -1.5rem;

  @media (min-width: 992px) {
    margin-bottom: -5rem;
  }
}

section#gallery {
  .modal {
    .modal-dialog {
      &.img-modal {
        width: 95%;
        max-width: 1024px;

        .in& {
          transform: translate(0, 100px);

          @media (min-width: 992px) {
            transform: translate(0, 0);
          }
        }
      }
      .modal-content {
        background-color: transparent;
        box-shadow: none;
        border: 0;
        .modal-header {
          padding: 1rem 0;
          .close {
            background-color: transparent;
            font-size: 3.5rem;
            span {
              font-size: inherit;
            }
          }
        }
        .modal-body {
          padding: 0;
        }
      }
    }
  }
}

.callout {
  padding: 1.5rem;
  font-size: 1.8rem;
  font-family: @main-font;
  max-width: 500px;
  margin: 0 auto;

  @media (min-width: 600px) {
    font-size: 2.5rem;
  }
  @media (min-width: 992px) {
    font-size: 1.4rem;
  }
  @media (min-width: 1200px) {
    font-size: 2.1rem;
    padding: 2.5rem 1.5rem;
    margin-top: 3rem;
    max-width: none;
  }
  @media (min-width: 1440px) {
    padding: 4rem 2rem;
  }

  .callout-heading {
    font-size: 2.1rem;
    text-transform: uppercase;
    margin-top: 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid @white;

    @media (min-width: 600px) {
      font-size: 2.8rem;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
    }
    @media (min-width: 992px) {
      font-size: 2.1rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 1200px) {
      font-size: 3.6rem;
    }
  }
  ul {
    margin-bottom: 0;
    li {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      span {
        flex: 1;
        &:first-of-type {
          font-family: @main-font-lt;
          font-size: 75%;
          flex: 0 0 50px;
        }
      }

      @media (min-width: 992px) {
        flex-direction: column;
        align-items: flex-start;
        line-height: 1.2;
        margin-bottom: 1.5rem;
        span {
          &:first-of-type {
            flex: 1;
            font-size: 85%;
          }
        }
      }
    }
  }

  &.callout-red {
    background-color: @red1;
    color: @white;
  }
}
