.banner-social {
  &#dodge-social-banner {
    height: 320px;
    padding: 0 !important;
    background: $gray-darkest;

    @media (min-width: 768px) {
      height: 170px;
    }

    .content-inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      bottom: 10px;
      max-width: 100% !important;
      padding-left: 0;

      @media (min-width: 768px) {
        flex-wrap: nowrap;
      }


      .juicer-container {
        background: none;
        width: 100%;

        @media (min-width: 768px) {
          max-height: 170px;
          overflow: hidden;
        }

        dg-juicer {
          max-width: none;
          height: 100% !important;

          .juicer-post {
            flex: 0 0 calc(100% / 3);
            height: 100%;
            max-height: 120px;
            border: 0;

            @media (min-width: 768px) {
              max-height: none;
              height: 50%;
            }
            @media (min-width: 992px) {
              flex: 0 0 calc(100% / 6);
              height: 100%;
            }
          }
        }
      }
      .social-ctas {
        flex: 0 0 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 0;

        @media (max-width: 767px) {
          flex-direction: row;
          padding: 20px 0;
          margin-top: 10px;
        }

        @media (min-width: 1200px) {
          flex: 0 0 25%;
        }

        h3 {
          font-size: 18px;
          text-transform: uppercase;
          margin-bottom: 10px;

          @media (max-width: 767px) {
            font-size: 24px;
            margin-right: 20px;
            margin-bottom: 0;
          }
          @media (min-width: 1200px) {
            font-size: 28px;
          }
          @media (min-width: 1920px) {
            font-size: 40px;
          }
        }

        .social-icons {
          order: 1;
          flex-grow: inherit;
          margin-bottom: 1px;

          li {
            font-size: 20px !important;
            margin-right: 10px;

            @media (max-width: 767px) {
              font-size: 24px !important;
            }
            @media (min-width: 1200px) {
              font-size: 32px !important;
            }
            @media (min-width: 1920px) {
              font-size: 55px !important;
            }

            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
