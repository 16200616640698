body {
  &.banner-active {
    .banner-bg {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100vw;
      height: 100vh;
      background-color: fade(@black, 80%);
      opacity: 0;
      animation: fade-in 0.25s ease-in-out forwards;
    }
  }
  // &.bracket-banner-open {
  //   padding-top: (@navbar-height-xs + 96px);

  //   @media (min-width: 380px) {
  //     padding-top: (@navbar-height-xs + 68px);
  //   }

  //   //@media (min-width: @screen-xs-min) {
  //   //  padding-top: (@navbar-height-xs + 68px);
  //   //}

  //   @media (min-width: @screen-sm-min) {
  //     padding-top: (@navbar-height-sm + 68px);
  //   }
  //   @media (min-width: @screen-md-min) {
  //     padding-top: (@navbar-height-md + 96px);
  //   }
  //   //@media (min-width: @screen-lg-min) {
  //   //  padding-top: (@navbar-height-md + 96px);
  //   //}
  // }
}

pledge-banner {
  display: inline-block;
  width: 100%;
  //margin-top: 10px;
  .pledge-banner {
    //background-color: @red1;
    background-size: cover;
    margin-bottom: 10px;
    transition: .35s ease-in-out;
    float: none;
    border: 5px solid #000;
    .btn-banner {
      position: relative;
      border: 0; //default reset
      border-radius: 0; //default reset
      background-color: transparent;
      transition: background-image .35s ease-in-out;
      background-image: url('/assets/images/tire-tread-gray.png');
      //font-family: @main-font-italic;
      font-family: @main-font;
      font-size: 1.8rem;
      color: #000;
      text-align: center;
      text-transform: uppercase;
      padding: 2.5rem 0;
      span {
        position: relative;
        display: block;
        //z-index: 1;
        transition: all 0.25s ease-in-out;
        transform: scale(0.95);
      }
      &:hover {
        span {
          transform: scale(1);
        }
      }
      /*&.active {
        &:before {
          width: 0;
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(to right, transparent, @red1 15%, @red1);
        transition: all 1s ease-in-out;
      }
      .fa-flag-checkered {
        &:first-of-type {
          margin-right: 2rem;
        }
        &:last-of-type {
          margin-left: 2rem;
        }
      }*/
      &:focus {
        outline: 0; //default reset
      }
    }

    @media (min-width: @screen-sm-min) {
      .btn-banner {
        font-size: 2.8rem;
      }
    }
    @media (min-width: @screen-md-min) {
      .btn-banner {
      }
    }
    &:hover {
      border-color: transparent;
      background-color: @red1;
      .btn-banner {
        background-image: url('/assets/images/tire-tread.png');
        color: @white;
      }
    }
  }
}


.banner {
  &.callout-banner {
    text-decoration: none;
    background-color: @red1;
    padding: 1em 0;
    color: @white;
    transition: background-color 0.25s ease-in-out;

    &.callout-alt {
      background-color: @white;
      color: @red1;

      &:hover {
        background-color: @red1;
        color: @white;
      }

      .site-wrapper {
        display: flex;
        align-items: center;

        @media (min-width: 992px) {
          justify-content: center;
        }

        > span {
          padding: 0 10px;
        }
      }

      .logo {
        flex: 0 0 120px;

        @media (min-width: 600px) {
          flex: 0 0 150px;
        }
        @media (min-width: 1200px) {
          flex: 0 0 175px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
      .announcement {
        flex: 1;
        line-height: 1.2;
        display: flex;
        flex-direction: column;

        @media (min-width: 600px) {
          font-size: 18px;
        }
        @media (min-width: 992px) {
          flex: 0 0 530px;
        }

        br {
          display: none;

          @media (min-width: 600px) {
            display: block;
          }
          @media (min-width: 768px) {
            display: none;
          }
        }
      }
    }

    livestream-banner &, dg-dodge-reveal-livetream-banner & {
      background-color: @white;
      color: @red1;
      span.gray {
        color: @gray-dark;
        margin-right: 1rem;
        transition: all 0.25s ease-in-out;
      }
      &:hover {
        background-color: @red1;
        color: @white;
        .gray {
          color: @white;
        }
      }
    }
  }
  &.promo-banner {
    display: block;
    @media (min-width: @screen-sm-min) {
      padding: 0.5em 0;
      font-size: 2rem;
    }
  }
  &.system-banner {
    background-color: @lighter-gray;
    color: @dark-gray;
    padding: 0;
    position: relative;
    z-index: 1;

    .close {
      color: inherit;
      font-size: 2.8rem;
      opacity: 0.75;
      position: absolute;
      top: 1rem;
      right: 1rem;
      transition: all 0.25s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: -1rem;
      left: 0;
      width: 100%;
      height: 1rem;
      box-shadow: 0px 0px 2rem fade(@black, 80%);
    }

    &.bg-danger {
      background-color: @red1;
      color: @white;
      .banner-message {
        background-color: darken(@red1, 5%);
      }
    }
    &.bg-success {
      background-color: @brand-success;
      color: @white;
      .banner-message {
        background-color: darken(@brand-success, 5%);
      }
    }

    &.rollup {
      animation: slide-up-v 0.25s ease-in-out forwards;
      //animation: slide-up-v 0.5s ease-in-out 10s forwards;
    }

    form {
      .banner-form {
        padding: 1rem;
        h4 {
          color: inherit;
        }
        .form-group {
          margin-bottom: 0;
          .form-control {
            font-size: 1.6rem;
          }
        }
      }
      .banner-message {
        padding: 1rem;
        .flex-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          animation: slide-in-right 0.5s forwards;
          .context-icon {
            flex: 0 0 40px;
            font-size: 4rem;
            margin-right: 1.5rem;
          }
          .context-message {
            flex: 1;
            h4, p {
              margin-top: 0;
              margin-bottom: 0;
            }
            h4 {
              font-family: @main-font;
              font-size: 1.8rem;
            }
            p {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }

  &#livestream-banner {
    // background-color: white;
    // box-shadow: inset 0px 5px 15px fade(@black, 20%);
    box-shadow: 0px 5px 15px fade(@black, 20%);
    cursor: pointer;
    &:hover {
      // background-color: @red1;

      #announcement-wrap,
      #sometimes,
      #spaces,
      #dash,
      #countdown,
      #warren-link,
      #ticker {
        color: white !important;
      }

      #skull-image img {
        // filter: invert(1) !important;
      }
    }
    .site-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #announcement-wrap {
      color: #DC0000;
      text-align: center;
      display: inline-block;
      &:hover {
        color: white;
      }
    }

    #ticker {
      color: black; padding-left: 0;
    }
    @media (min-width: @screen-sm-min) {
      padding: 1rem 0;
      font-size: 2rem;
    }
    span {
      font-family: 'Trade Gothic LT W01 Bd Cn No-2', "Helvetica Neue", sans-serif;
      padding: 0 1rem;
    }

    #skull-image img{
      max-height: 60px;
      width: auto;
    }
    #sometimes,
    #spaces {
      display: none;
    }
    #dash {
      padding: 0;
      color: black;
    }
    #countdown {
      color: black;
    }
    #warren-link {
      color: black;
      cursor: pointer;
    }

    @media (max-width: 576px) {
      #skull-image img{
        max-height: 50px;
        width: auto;
      }
      #announcement-wrap {
        text-align: center;
        padding-right: 13px;
      }
      span {
        text-align: center;
        padding: 0;
        // font-size: 17px;
      }
      #sometimes,
      #spaces {
        display: inherit;
      }
      #dash {
        display: none;
      }
      #countdown {
        display: block;
      }

      .callout-banner {
        padding: .35em 0;
      }
      .site-wrapper {
        justify-content: space-around;
      }
    }

    @media (max-width: 310px) {
      span {
        font-size: 15px;
      }
    }
  }
}

dg-dodge-reveal-livetream-banner {
  display: block;
  flex: 0 0 100%;
}

dg-dodge-neverlift-banner {
  display: block;
  width: 100vw;

  .banner {
    &#livestream-banner {
      background-color: @dark-gray;
      height: 96px;

      @media screen and (min-width: 380px) {
        height: 68px;
      }

      @media screen and (min-width: 992px) {
        height: 96px;
      }

      &:hover {
        background-color: @light-gray;
      }

      .site-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        @media (max-width: 1600px) {
          padding: 0 1rem;
        }
      }


      .banner-content {
        display: flex;
        flex-direction: column;

        .dash {
          display: none;
        }

        @media screen and (min-width: 1200px) {
          flex-direction: row;
          .dash {
            display: inline;
          }
        }
      }

      .banner-text {
        font-family: @main-font-lt;

        @media screen and (min-width: 1200px) {
          font-size: @h4-font-size;
        }
      }

      .banner-images {
        text-align: center;
        margin-bottom: 5px;

        @media screen and (min-width: 992px) {
          margin-bottom: 10px;
        }

        @media screen and (min-width: 1200px) {
          margin-bottom: 0
        }
      }

      .logo {
        margin-right: 15px;
        @media (min-width: 480px) {
          margin-left: auto;
        }
        @media (min-width: 992px) {
          margin-right: 35px;
        }

        img {
          width: auto;
          height: 18px;

          @media (min-width: 480px) {
            height: 20px;
          }
          @media (min-width: 768px) {
            height: 25px;
          }
          @media (min-width: 992px) {
            height: 30px;
          }
          @media (min-width: 1200px) {
            height: 35px;
          }
        }
      }
      .text-img {
        @media (min-width: 480px) {
          margin-right: auto;
        }

        img {
          height: 15px;

          @media (min-width: 480px) {
            height: 20px;
          }
          @media (min-width: 992px) {
            height: 30px;
          }
        }
      }
    }
  }
}

dg-bracket-banner, dg-car-show-banner {
  display: block;
  width: 100%;
  a.banner.callout-banner {
    background-color: #FFF;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.12), 0 4px 6px 0 rgba(0,0,0,0.13), 0 6px 10px 0 rgba(0,0,0,0.15);
    color: #000;
    font-family: @main-font;
    font-size: 1.6rem; // 16px based on 10px
    @media (min-width: @screen-md-min) {
      font-size: 2.4rem; // 24px based on 10px
    }
    .site-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > div > span {
      margin: 0 1.0rem; // 10px based on 10px
      @media (min-width: @screen-md-min) {
        margin: 0 2.0rem; // 20px based on 10px
      }
    }
    .red-text {
      display: block;
      color: @primary-03;
      @media (min-width: @screen-sm-min) {
        display: inline;
      }
    }
    svg {
      height: 1em;
      fill: currentColor;
    }
    &:hover {
      background-color: @primary-03;
      color: #FFF;
      span {
        color: inherit;
      }
    }
  }
}

#drag-racing-banner {
  .drag-banner {
    display: block;
    background-image: url('/assets/images/hpds-drag/drag-racing-banner.jpg');
    background-size: cover;
    background-position: left center;
    height: 225px;
    position: relative;
    .banner-content {
      position: absolute;
      bottom: 15px;
      right: 15px;
      font-size: 1.5em;
      width: 230px;
      color: @white;
      line-height: 1;
      text-align: right;
      text-transform: uppercase;
      font-family: @main-font;
    }

    @media (min-width: @screen-sm-min) {
      .banner-content {
        font-size: 2.25em;
        width: 340px;
      }
    }
    @media (min-width: @screen-md-min) {
      .banner-content {
        font-size: 2.5em;
        width: 460px;
      }
    }
  }
}

dg-sales-incentives {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin: 0 10px 10px 10px;
  font-size: 1.6rem; // 16px based on 10px


  .performance-days-logo img {
    max-width: 100px;
  }
  /*> div {
    display: flex;
    align-items: center;
    img {
      width: 100%;
      height: auto;
    }
  }*/
  > a {
    display: flex;
    flex-direction: column;
    justify-content: center; // IE 11 fallback
    justify-content: space-around; // Good browsers
    align-items: center;
    width: 100%;
    text-align: center;
    text-decoration: none;
    color: @body-color;
    padding: 40px 30px;

    @media (min-width: @screen-sm-min) {
      flex-direction: row;
      flex-wrap: wrap;
    }
    span {
      display: block;
      font-family: @main-font;
      font-size: @h4-font-size;
      @media (min-width: @screen-lg-min) {
        font-size: 26px;
        margin-top: 5px;
        white-space: nowrap;
      }
      @media (min-width: @screen-xl-min) {
        font-size: 26px;
        margin-top: 10px;
      }
    }
    > div {
      margin: 10px 0;
      &.trophy-container {
        display: flex;
        align-items: center;
        text-align: left;
        max-width: 300px;
        picture {
          margin-right: 25px;
        }
        img {
          max-width: 89px;
          @media (max-width: 400px) {
            width: 72px;
            margin-left: 1rem;
          }
        }
        .trophy-text {
          max-width: 191px;
          :first-child {
            font-size: 2.2rem;
            font-family: @main-font;
            line-height: normal;
            margin-bottom: 10px;
            @media (max-width: 400px) {
              font-size: 20px;
              line-height: 1.1;
            }
          }
          :nth-child(2) {
            font-family: Trade Gothic LT W01 Cond No-1,Helvetica Neue,sans-serif;
            line-height: 1.1;
            @media (max-width: 400px) {
              font-size: 14px;
            }
          }
          > div:last-child {
            font-size: 1.2rem; // 12px based on 10px
          }
        }
      }
      &.tablet-line-break {
        margin: 0;
        width: 100%;
        @media (min-width: @screen-lg-min) {
          display: none;
        }
      }
      &.vehicle {
         //max-width: 218px;

         picture {
          display: block;
         }

        @media (min-width: @screen-md-min) {
          width: 25%;
        }
        @media (min-width: 1200px) {
          width: 20%;
          margin-top: auto;
          max-width: 420px; // uncomment for 3
        }
        // @media (min-width: @screen-lg-min) {
        //   width: 12%;
        // }
        // @media (min-width: @screen-xl-min) {
        //   width: 17%;
        // }
        img {
          max-width: 100%;

          @media (min-width: 1200px) {
            width: 100%;
          }
        }
        span.text-uppercase {
          @media (max-width: @screen-md-min) {
            font-size: 2rem;
          }
        }

        //&[data-vehicle="charger"], &[data-vehicle="challenger"] {
        //  picture {
        //    padding: 0 1rem;
        //
        //    @media (min-width: 1200px) {
        //      padding: 0 .5rem;
        //    }
        //    @media (min-width: 1600px) {
        //      padding: 0;
        //    }
        //  }
        //}
        //&[data-vehicle="durango"] {
        //  @media (max-width: 1199px) {
        //    max-width: 240px;
        //  }
        //  @media (min-width: 1600px) {
        //    max-width: 250px;
        //  }
        //}
        //&[data-vehicle="hornet"] {
        //  picture {
        //    padding: 0 1rem;
        //
        //    @media (min-width: 1600px) {
        //      padding: 0 .5rem;
        //    }
        //  }
        //}
      }
    }
    &:hover {
      color: @body-color;
      text-decoration: none;
    }
  }
  /*> div {
    @media (min-width: @screen-md-min) {
      width: 40%;
    }
  }*/
  @media (min-width: @screen-md-min) {
    margin: 0;
  }
}

dg-1320-standings {
  display: block;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
  color: #FFF;
  .btn {
    font-family: @main-font;
    text-transform: uppercase;
    width: 100%;
    font-size: 1.8rem; // 18px based on 10
  }
  .profile-dragrcr {
    background-image: url('/assets/images/1320/profile-dragrcr.jpg');
  }
  .profile-siba-motorsports {
    background-image: url('/assets/images/1320/profile-siba-motorsports.jpg');
  }
  .profile-moparmangamble {
    background-image: url('/assets/images/1320/profile-moparmangamble.jpg');
  }
  .profile-budoboy {
    background-image: url('/assets/images/1320/profile-budoboy.jpg');
  }
  .dg-1320-standings-grid {
    > .col > .col {
      min-height: 275px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 15px 0;
      overflow: hidden;
      position: relative;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      color: #FFF;
      @media (min-width: @screen-lg-min) {
        min-height: 450px;
      }
      .profile-img {
        position: absolute;
        img {
          opacity: 0;
          visibility: hidden;
        }
      }
      .profile-content {
        position: relative;
        z-index: 2;
        > div {
          font-family: @main-font;
          font-size: 2.9rem; // 29px based on 10
          line-height: normal;
          @media (min-width: @screen-lg-min) {
            font-size: 3.65rem; // 36.5px based on 10
          }
          &:last-child {
            font-size: 2rem; // 20px based on 10
            color: #FFFFFF;
            text-decoration: underline;
            transition: 0.35s ease-in-out;
          }
        }
      }
      &:hover {
        .profile-content > div:last-child {
          color: @primary-03;
        }
      }
    }
    .header-container {
      padding: 30px;
      background-color: #1A1A1A;
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        width: 100%;
        height: auto;
        max-height: 134px;
        min-height: 1px;
        display: block;
        margin-bottom: 30px;
      }
      p {
        font-family: @main-font;
        font-size: 2rem; // 20px based on 10
        line-height: 1.25;
        margin: 0 0 30px 0;
      }
      a {
        font-family: @main-font;
        font-size: 1.3rem; // 13px based on 10
        line-height: 1.25;
        @media (min-width: @screen-lg-min) {
          font-size: 2rem; // 20px based on 10
        }
      }
      @media (min-width: @screen-md-min) {
        padding: 30px 15px;
      }
    }
    .stock-leaders-container {
      > .btn {
        background-color: @secondary-06;
        color: #FFF;
        &:hover {
          background-color: @secondary-07;
        }
      }
    }
    .modified-leaders-container {
      > .btn {
        background-color: @primary-03;
        color: #FFF;
        &:hover {
          &:extend(.btn-primary:hover);
        }
      }
    }
    // Flex row and ordering
    @media (min-width: @screen-md-min) {
      display: flex;
      > .col {
        width: 18%;
        &.stock-leaders-container, &.modified-leaders-container {
          display: flex;
          flex-wrap: wrap;
          width: 41%;
          > .col {
            width: 50%;
            flex-grow: 1;
          }
        }
        &.stock-leaders-container {
          order: 0;
        }
        &.header-container {
          order: 1;
        }
        &.modified-leaders-container {
          order: 2;
        }
      }
    }
  }
}

// Horsepower Challenge
dg-horsepower-challenge {
  display: block;
  margin-bottom: 10px;
  a {
    display: block;
    /*align-items: center;
    //flex-wrap: wrap;
    flex-direction: column;
    background: url('/assets/images/horsepower-challenge-bg.jpg') left top no-repeat;
    background-size: auto 120px;
    @media (min-width: @screen-sm-min) {
      min-height: 120px;
      background-position: left center;
    }
    .mobile-banner-container {
      @media (min-width: @screen-sm-min) {
        display: none;
      }
    }
    .logo-container {
      display: none;
      @media (min-width: @screen-sm-min) {
        max-width: 188px;
        height: 120px;
        display: flex;
        align-items: center;
        order: 2;
      }
    }
    .text-container {
      font-family: @main-font;
      font-size: 40px;
      color: #000000;
      //letter-spacing: -0.02px;
      text-align: center;
      text-transform: uppercase;
      background-color: #FFF;
    }*/
  }
}

.mountain-dew {
  height: 100%;
}

a.mountain-dew-link {
  display: block;
  height: 100%;
  text-decoration: none;
}

dg-mountain-dew-banner {
  color: #fff;

  .mountain-dew-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 40px;
    text-align: center;

    background-color: #000;
    background-size: cover;
    background-image: url('/assets/images/home/mountain-dew-background-mobile.jpg');
    background-position: bottom center;
    background-repeat: no-repeat;

    img {
      margin-bottom: 20px;
    }


    @media screen and (min-width: @screen-xs-min) {
      flex-direction: column;
      background-repeat: no-repeat;
      background-position: 60% center;
      padding: 50px;

      img {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    @media screen and (min-width: @screen-md-min) {
      justify-content: center;
      align-items: flex-start;
      background-image: url('/assets/images/home/mountain-dew-background.jpg'); //
      background-position: 72% 100%;
      text-align: left;
      padding: 20px;
    }

    @media screen and (min-width: @screen-lg-min) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-size: cover;
      text-align: center;

      img {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }

    @media screen and (min-width: @screen-xl-min) {
      flex-direction: row;
      align-items: center;
      background-position: 100%;
      background-size: cover;
      text-align: center;

    }
  }

  .contest-text {
    &:first-child {
      font-family: 'Trade Gothic LT W01 Bd Cn No-2', sans-serif;
      line-height: normal;
    }
    .lead {
      font-size: 2.4rem;
      margin-bottom: 10px;
    }
    > div:last-child {
      font-size: 1.6rem; // 12px based on 10px
    }

    @media screen and (min-width: @screen-xs-min) {
      max-width: 250px;
    }
  }
  .icon-wrap {
    pointer-events: none;
    color: #fff;
  }

  .fa {
    position: relative;
    z-index: 100;
    pointer-events: all;
  }

  .copy {
    margin-bottom: 200px;
    max-width: 320px;

    @media screen and (min-width: @screen-md-min) {
      margin-bottom: 0;
    }
  }
}

