#events-slider {
  position: relative;
  background-color: $gray-light;
  padding: 35px 0;

  @media (min-width: 1440px) {
    padding: 100px 0;
  }

  .slider-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 35px;

    @media (min-width: 1440px) {
      margin-bottom: 50px;
    }

    .heading {
      font-family: $font-condensed;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 0;
      margin-right: 25px;

      @media (min-width: 1200px) {
        font-size: 32px;
      }
    }
    .btn {
      font-size: 14px;
      margin-bottom: 0;
      padding: 8px 20px;
    }
    .slider-controls {
      margin-top: 10px;
      width: 100%;

      @media (min-width: 568px) {
        width: auto;
        margin-left: auto;
      }

      .carousel-button {
        font-size: 16px;
        color: $white;
        border-radius: 0;
        border: 0;
        background-color: $black;
        width: 35px;
        height: 35px;

        &:hover {
          background-color: $primary-dark;
        }

        &.swiper-button-disabled {
          background-color: $gray;
        }

        &:first-of-type {
          margin-right: 10px;
        }
      }
    }
  }

  .carousel-container {
    .swiper-container{
      width: 100%;
      .swiper-wrapper {
        .swiper-slide {
          height: auto;
          .event-homepage {
            margin-bottom: 0;
            article {
              height: 100%;
              a {
                .tile-container {
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
