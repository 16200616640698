$mobile-max: 1199px;

#primary-nav {
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0;
  margin-bottom: 0;

  @media (max-width: $mobile-max) {
    position: fixed;
  }

  &:before {
    @media (min-width: 1200px) {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100vw;
      height: $navbar-height;
      background: linear-gradient(to right, $primary-dark 50%, $gray-dark 50%);
    }
  }

  .fixed-nav & {
    position: fixed;
  }

  .navbar-brand:focus {
    background-color: #282B2D;
  }

  .navbar-header {
    position: relative;
    z-index: 1;
    display: flex;
    //overflow-x: hidden;
    width: 100%;
    max-width: 1920px;
    height: $navbar-height;
    background-color: $black;
    font-family: $font-condensed;
    font-size: 14px;
    font-weight: bold;
    margin: 0 auto;

    @media (min-width: 1200px) {
      font-size: 16px;
    }

    .navbar-content {
      display: flex;

      .control {
        flex: 0 0 $navbar-height;
        position: relative;
        display: flex;
        order: 1;

        @media (min-width: 992px) {
          flex: 0 0 90px;
        }
        @media (min-width: 1200px) {
          flex: 0 0 30px;
        }
        @media (min-width: 1440px) {
          flex: 0 0 75px;
        }

        &:before, &:after {
          content: '';
          position: absolute;
          top: 0;
          left: auto;
          right: 0;
          height: 100%;
          display: block;
          transform: skew($accent-skew);
          background-color: $primary-dark;
        }

        &:before {
          right: -10px;
          width: 200px;
        }
        &:after {
          right: -45px;
          width: 25px;
        }

        .navbar-toggle {
          background-color: rgba($black, 0);
          color: white;
          margin: 0;
          border: 0;
          border-radius: 0;
          width: 100%;
          font-size: 20px;

          &:hover {
            background-color: rgba($black, 0);
          }

          @media (min-width: 768px) {
            display: block;
          }
        }
      }
      .navbar-collapse {
        display: none;
        order: 0;
        z-index: 1;
        background-color: $primary-dark;
        border: 0;

        &.in-view {
          display: block;
        }

        @media (max-width: $mobile-max) {
          position: fixed;
          top: 0;
          left: 0;
          z-index: -1;
          width: 100vw;
          height: 100vh;
          padding-top: $navbar-height;
          background: linear-gradient(90deg, rgba($black, 0.9) 60%, rgba($black, 0.7));
        }

        .navbar-nav {
          display: flex;
          flex-direction: row;
          height: 100%;
          animation: fade-in 0.25s ease-in-out forwards;

          @media (max-width: $mobile-max) {
            flex-direction: column;
            overflow: auto;
            padding: 25px 0;
            @include scrollbars(10px, rgba($gray-dark, 0.5), rgba($gray-dark, 0.05));
          }

          > li {
            font-size: 16px; // default overrides
            line-height: 1;

            @media (max-width: $mobile-max) {
              font-size: 20px;
              padding: 10px 40px;
            }

            a, div.header-nav {
              display: flex;
              align-items: center;
              height: 100%;
              color: $white;
              font-size: inherit;
              text-decoration: none;
              text-transform: uppercase;
              white-space: nowrap;
              padding: 15px;

              @media (max-width: $mobile-max) {
                height: auto;
                padding: 10px;
                padding-left: 0;
              }
            }

            .dropdown-menu {
              padding: 0;
              border: 0;
              background-color: rgba($black, 0);
              animation: fade-in 0.25s ease-in-out forwards;

              @media (max-width: $mobile-max) {
                max-width: 400px;
              }

              li {
                font-size: 14px;

                @media (max-width: $mobile-max) {
                  font-size: 16px;
                  padding-left: 15px;
                  border-left: 1px solid $primary-dark;
                }

                a {
                  display: block;
                  font-size: inherit;
                  color: $white;
                  background-color: $gray-dark;
                  padding: 10px 15px;

                  @media (max-width: $mobile-max) {
                    background-color: rgba($black, 0);
                  }

                  &:hover, &:focus, &.active {
                    color: $black;
                    background-color: $white;
                  }
                }
              }
            }

            &.open {
              > a, > div.header-nav {
                background-color: $gray-dark;

                @media (max-width: $mobile-max) {
                  background-color: rgba($black, 0);
                }
              }
              .dropdown-menu {
                @media (max-width: $mobile-max) {
                  position: static;
                }
              }
            }
          }
        }
      }
    }

    .logo-container {
      flex: 1 1 auto;
      padding-left: 85px;

      @media (min-width: 992px) {
        flex: 0 1 auto;
        padding-left: 115px;
        margin-right: auto;
      }
      @media (min-width: 1200px) {
        padding-left: 50px;
      }
      @media (min-width: 1440px) {
        padding-left: 115px;
      }

      a {
        display: block;
        height: 100%;
        padding: 10px 15px;
        float: none !important;

        img {
          height: 42px;
          width: auto;
        }
      }
    }

    .user-controls {
      display: none;
      flex: 0 1 auto;
      position: relative;
      max-width: 200px;

      @media (min-width: 768px) {
        display: block;
        margin-left: 35px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -10px;
        width: 75%;
        height: 100%;
        display: block;
        transform: skew($accent-skew);
        background-color: $gray-dark;
        transition: all 0.25s ease-in-out;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        width: 75%;
        height: 100%;
        display: block;
        background-color: $gray-dark;
        transition: all 0.25s ease-in-out;
      }

      &:hover {
        &:before, &:after { background-color: darken($gray-dark, 10%); }
      }

      ul {
        margin-bottom: 0;
        padding-left: 0;
        height: 100%;

        li {
          height: inherit;
          font-size: inherit;

          a {
            display: flex;
            align-items: center;
            z-index: 1;
            width: 100%;
            height: 100%;
            padding: 10px 20px;
            font-size: 1em;
            color: $white;
            text-decoration: none;
            text-transform: uppercase;

            span {
              flex: 0 0 100%;
              z-index: 1;
              text-align: right;
            }

            &:focus {
              color: $primary;
            }
          }

          .dropdown-menu {
            padding: 0;
            border: 0;
            margin: 0;
            background-color: rgba($black, 0);
            animation: fade-in 0.25s ease-in-out forwards;

            @media (max-width: $mobile-max) {
              max-width: 400px;
            }

            li {
              font-size: 14px;
              height: auto;

              @media (max-width: $mobile-max) {
                font-size: 16px;
                padding-left: 15px;
                border-left: 1px solid $primary-dark;
              }

              a {
                display: block;
                font-size: inherit;
                color: $white;
                background-color: $gray-dark;
                padding: 10px 15px;

                @media (max-width: $mobile-max) {
                  background-color: rgba($black, 0);
                }

                &:hover, &:focus, &.active {
                  color: $black;
                  background-color: $white;
                }
              }
            }
          }
        }
      }
    }

    .nav-item-visible {
      display: none;
      @media (min-width: 1200px) { display: block; }

      &.mobile {
        display: block;
        @media (min-width: 1200px) { display: none; }
      }

      a {
        display: flex;
        align-items: center;
        height: 100%;
        color: $white;
        text-transform: uppercase;
        text-decoration: none;
        padding: 0 10px;

        &:hover, &:focus {
          color: $primary;
        }
      }
    }

    .nav-item.mobile-only {
      display: block;

      @media (min-width: 768px) { display: none; }
    }
  }
}

.fixed-nav {
  padding-top: $navbar-height + $banner-height;
}
