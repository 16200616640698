.hero-video {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-500;
  text-align: center;
  min-height: 325px;

  @media (min-width: 768px) {
    min-height: 420px;
  }
  @media (min-width: 1200px) {
    min-height: 520px;
  }
  
  // Larger screens with more height
  @media (min-width: 1440px) and (min-height: 700px) {
    min-height: 640px;
  }

  // &:before {
  //   content: '';
  //   position: absolute;
  //   z-index: 1;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba($black, 20%);
  // }

  .hero-headline {
    position: relative;
    z-index: 2;
    font-size: 4rem;
    color: $white;
    text-shadow: 0px 5px 15px rgba($black, 75%);

    @media (min-width: 768px) {
      font-size: 5.4rem;
    }

    // Larger screens with more height
  @media (min-width: 1440px) and (min-height: 700px) {
    font-size: 6.2rem;
  }

    sup {
      font-size: 50%;
      top: -0.75em;
    }
  }

  .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .controls {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 1rem;
    right: 1rem;

    .overlay-control {
      font-size: 1.8rem;
      color: $white;
      background-color: rgba($black, 85%);
      border: 0;
      padding: 0.5rem 1rem;
    }
  }
}