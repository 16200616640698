@feed-count: 10;
//@feed-height-xs: ;

.juicer-container {
  //@media (min-width: @screen-md-min) {
  padding-left: 0;
  padding-right: 0;
  //}
  @media (min-width: @screen-md-min) {
    //padding: 0 5px 0 0;
  }
  @media (min-width: @screen-lg-min) {
    background-image: url('/assets/images/social-media-bg.png');
    background-repeat: no-repeat;
    background-position: top center;
  }
  dg-juicer {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: flex;
    flex-wrap: wrap;
    height: 437px; // 6 items, 2 per row at 145px height
    @media (min-width: 600px) {
      height: 450px;
    }
    @media (min-width: @screen-md-min) {
      height: 540px;
    }
    @media (min-width: @screen-lg-min) {
      max-width: 550px;
      margin: 0 auto;
    }
    .juicer-post {
      flex: 0 0 50%;
      max-width: 50%;
      height: calc(100% / 3);
      position: relative;
      overflow: hidden;
      border: 1px solid #FFF;
      cursor: pointer;

      @media (min-width: 600px) {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        height: calc(100% / 2);
      }
      @media (min-width: @screen-md-min) {
        flex: 0 0 50%;
        max-width: 50%;
        height: calc(100% / 3);
      }
      &:hover {
        .juicer-post-content {
          opacity: 1;
        }
      }
      .featured-image {
        width: 100%;
        height: 100%;
        flex: 0 1 auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center bottom;
        }
      }
      .juicer-post-content {
        opacity: 0;
        position: absolute;
        bottom: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        transition: opacity 0.2s ease-in-out;
        color: #bababa;
        background: rgba(58,58,58,0.7);
        padding: 1rem;
        @media (min-width: @screen-lg-min) {
          padding: 2rem;
        }
        .media {
          display: flex;
          align-items: flex-start;
          margin-bottom: 5px;
          .media-img {
            width: 75px;
            height: auto;
            margin-right: 5px;
            margin-bottom: 5px;
            @media (min-width: @screen-lg-min) {
              margin-right: 1rem;
              margin-bottom: 1rem;
            }
          }
          .media-body {
            overflow: hidden;
          }
        }
      }
    }

    // HIGHWAY JUICER
    &.hwy-juicer {

      .juicer-post {
        flex: 0 0 calc( 100% / 3 );
      }
    }
  }
  .juicer-feed {
    height: 300px; // 10 items, 2 per row at 100px height
    max-height: none !important;
    overflow: hidden;
    .referral {
      display: none !important;
    }
    .j-stacker-wrapper {
      .j-stacker {
        .j-stack {
          width: 100% !important;
          display: block !important;
          .feed-item {
            width: 50%;
            height: 100px;
            float: left;
            overflow: hidden;
            .j-image {
              position: relative;
              display: block;
              height: 100%;
              img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
    }

    @media (min-width: 600px) {
      height: 450px;
      .j-stacker-wrapper {
        .j-stacker {
          .j-stack {
            .feed-item {
              height: 150px;
            }
          }
        }
      }
    }

    @media (min-width: @screen-sm-min) {
      height: calc( 85px * 2 );
      .j-stacker-wrapper {
        .j-stacker {
          .j-stack {
            .feed-item {
              height: 85px;
              width: calc( 100% / 5 );
            }
          }
        }
      }
    }

    @media (min-width: @screen-md-min) {
      height: 540px;
      .j-stacker-wrapper {
        height: 100%;
        .j-stacker {
          height: 100%;
          display: block;
          .j-stack {
            height: 100%;
            .feed-item {
              width: 50%;
              height: calc(100% / 3);
              .j-image {
                img {
                  min-height: 102%;
                  min-width: 102%;
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
    @media (min-width: 1199px) {
      .j-stacker-wrapper {
        .j-stacker {
          .j-stack {
            height: calc(100% / 3);
            .feed-item {
              height: 100%;
              border: 1px solid white;
              .j-image {
                img {
                  min-width: 105%;
                }
              }
            }
            &:first-child {
              .feed-item {
                &:last-child {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    @media (min-width: 1440px) {
      //height: 550px;
      max-height: none !important;
      .j-stacker-wrapper {
        //height: 360px;
      }
    }
    @media (min-width: 1599px) {
      height: 540px;
      .j-stacker-wrapper {
        .j-stacker {
          .j-stack {
            height: calc(100% / 3);
            &:first-child {
              .feed-item {
                &:last-child {
                  display: block;
                }
              }
            }
            &:nth-child(3) {
              .feed-item {
                border-bottom: 0;
              }
            }
            &:last-child {
              //display: none !important;
            }
          }
        }
      }
    }

  }
}

// Highway 93 Juicer
.highway-juicer-container {
  margin-bottom: 20px;
  
  highway-juicer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: @screen-xs) {
      flex-direction: row;
    }

    .juicer-post {
      overflow: hidden;
      position: relative;
      height: 300px;
      max-height: none !important;

      .featured-image {
        height: 300px;
        overflow: hidden;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover {
        .juicer-post-content {
          opacity: 1;
        }
      }
    }

    .juicer-post-content {
      opacity: 0;
      position: absolute;
      bottom: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      transition: opacity 0.2s ease-in-out;
      color: #bababa;
      background: rgba(58,58,58,0.7);
      padding: 1rem;
      @media (min-width: @screen-lg-min) {
        padding: 2rem;
      }
      .media {
        display: flex;
        align-items: flex-start;
        margin-bottom: 5px;
        .media-img {
          width: 75px;
          height: auto;
          margin-right: 5px;
          margin-bottom: 5px;
          @media (min-width: @screen-lg-min) {
            margin-right: 1rem;
            margin-bottom: 1rem;
          }
        }
        .media-body {
          overflow: hidden;
        }
      }
    }
  }
  @media (min-width: @screen-xs) {
    .juicer-post {
      flex: 0 0 ~"calc(100% / 2)";
    }
  }

  @media (min-width: @screen-md) {
    .juicer-post {
      flex: 0 0 ~"calc(100% / 3)";
    }
  }
  //
  @media (min-width: @screen-lg) {
    .juicer-post {
      flex: 0 0 ~"calc(100% / 4)";
    }
  }
}

.juicer-modal {
  .juicer-post-content {
    padding: 25px 0;
    @media (min-width: @screen-md-min) {
      padding-top: 0;
    }
  }
  .juicer-post-header {
    display: flex;
    align-items: center;
    .juicer-post-image {
      width: 30px;
      margin: 0 7px 0 0;
    }
    h3 {
      margin: 0;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .juicer-post-date {
      font-size: 14px;
      color: @secondary-04;
      margin: 0 0 0 7px;
    }
  }
  .juicer-replies-likes {
    color: @secondary-04;
    margin-bottom: 15px;
  }
}

#social-feed {
  margin-top: 1px;
  margin-bottom: 0;



}
