.colors {
  display: flex;
  flex-wrap: wrap;

  .color-item {
    flex: 0 0 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $font-condensed; 
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
      flex: 0 0 calc(100% / 7);
    }

    .swatch {
      cursor: pointer;
      position: relative;
      width: 6.5rem;
      height: 6.5rem;
      border-radius: 50%;
      //border: 5px solid $white;
      margin-bottom: 0.5rem;
      transition: all 0.25s ease-in-out;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid $black;
      }
    }
    .price {
      font-size: 1.2em;
      color: $primary;
      line-height: 1;
      font-weight: 700;
      

      &.first {
        margin-top: auto;
      }
    }

    &.active {
      .swatch {
        border: 5px solid $primary;
        border-color: $primary;
      }
    }
  }
}