// #brag-book {
//   display: flex;
//     align-items: center;
//     height: calc(~'100vh - 110px');
// }

.media-container-flex {
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  margin-bottom: 3rem;

  .media-item {
    flex: 1;
  }

  @media (min-width: @screen-md-min) {
    flex-direction: row;
    align-items: center;

    .media-item {
      &.media-caption {
        flex: 0 1 40%;
        padding-right: 3rem;
        &.right {
          padding-left: 3rem;
          padding-right: 0;
        }
      }
      &.media-asset {
        flex: 0 1 60%;
      }
    }

    @media (min-height: 900px) {
      padding-top: 12rem;
    }
    @media (min-height: 1080px) {
      padding-top: 20rem;
    }
  }
  @media (min-width: @screen-lg-min) {
    .media-item {
      &.media-caption {
        flex: 0 1 30%;
      }
      &.media-asset {
        flex: 0 1 70%;
      }
    }

    @media (min-height: 900px) {
      padding-top: 3rem;
    }
  }
}

.promo-bg {
  background-color: #eeeeee;
  margin-top: 2rem;
}

.promo-container {
  /*display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;*/

  @item-bg: #eeeeee;

  .item {
    display: block;
    padding: 30px 15px;
    //background-color: @item-bg;
    font-family: 'Trade Gothic W01 Cn 18', Helvetica, sans-serif;
    font-size: 16px;
    color: #222;
    img {
      max-width: 240px;
      margin: 0 auto;
    }
    p {
      font-size: 1em;
      margin: 20px 0;
    }
    .btn {
      background-color: #222;
      color: white;
      width: 50%;
      max-width: 200px;
      &:hover {
        background-color: fade(#222, 75%);
      }
    }

    &.logo-block {
      order: 2;
      border-bottom: 1px solid fade(black, 50%);
      &:last-of-type {
        border-bottom: 0;
      }
    }

    &.demon-block {
      //background-image: url('../../img/logo-bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      order: 1;
      padding: 20px 0;
      img {
        max-width: 30%;
        margin: auto;
      }
    }
  }


  @media (min-width: @screen-sm-min) {
    flex-direction: row;

    .item {
      font-size: 18px;
      .btn {
        font-size: 18px;
      }

      &.logo-block {
        //width: calc(100% / 3);
        order: 1;
        border-right: 1px solid black;
        border-bottom: 0;

        &:last-of-type {
          border-right: 0;
        }
      }
      &.demon-block {
        position: relative;
        height: 225px;
        width: 100%;
        order: 2;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          //max-width: 45%;
          max-width: 200px;
        }
      }
    }
  }
  @media (min-width: @screen-md-min) {
    .item {
      &.logo-block {
        img {
          max-width: 250px;
        }
      }
      p, .btn {
        font-size: 1.35em;
      }
    }
  }
  @media (min-width: @screen-lg-min) {
    .item.logo-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      p {
        font-size: 1.15em;
      }
      .btn {
        width: 175px;
        font-size: @body-font-size;
      }
    }
  }
  @media (min-width: 1440px) {
    .item {
      p, .btn {
        font-size: 1em;
      }
    }
  }
}

.promo-container {
  a {
    //display: block;
    //height: 100%;
    text-decoration: none;
    &:hover, &:focus {
      background-color: darken(@lightest-gray, 5%);
    }
    &:not(:last-child) {
      border-bottom: 1px solid #000;
    }
    @media (min-width: @screen-sm-min) {
      &, &:not(:last-child) {
        border-bottom: 0;
      }
      &:nth-child(odd):not(:last-child) {
        border-right: 1px solid #000;
      }
      &:nth-last-child(n+3) {
        border-bottom: 1px solid #000;
      }
    }
    @media (min-width: @screen-lg-min) {
      &:not(:last-child) {
        border-bottom: 0;
        border-right: 1px solid #000;
      }
    }
  }
}