// Print styling
@media print {

  @page { size: auto;  margin: 2mm; }

  html, body {
    width: 210mm !important; // A4 Paper width
    height: 100% !important;
    margin: 2mm 0 !important;
    padding-top: 0 !important;
  }

  // Hide urls that display when printing
  a[href]:after {
    content: none !important;
  }

  // Primarily used for HP Locator
  #primary-nav, #hero-area, #search-filters-component, #global-footer, #results-group { display: none !important; }

  #results-and-filter {
    .container-header {
      display: none !important;

      > span { 
        font-size: 18px;
        margin-left: auto; 
      }
      .btn { display: none; }
    }
  }

  .print-header {
    display: flex !important;
    align-items: flex-end;
    justify-content: space-between;
  }
  .print-footer {
    display: flex !important;
    align-items: center !important;
    margin-top: 25px !important;
    padding-bottom: 25px !important;

    .img-container {
      flex: 0 0 120px;
      img {
        width: 100% !important;
        height: auto !important;
      }
    }
    .content {
      flex: 0 0 520px;
    }
  }

  .filters-column { display: none; }
  .results-column { 
    width: 100%; 
  }
  
  .card-container-inner {
    width: 100% !important;
  }
  // for individual cards
  .hpl-card {
    width: 100% !important;
    min-width: 100% !important;
    flex: 0 0 100% !important;
    break-inside: avoid;

    // cap at 100
    &:nth-child(n + 100) {
      .dealer-card {
        display: none !important;
      }
    }

    hr { display: none; }
    .cta-row { 
      height: 0;
      > div, > a, > button { display: none; }
    }

    .dealer-card {
      position: relative !important;
      padding-bottom: 0 !important;

      .orders-dealer-name {
        display: flex !important;

        .available-orders {
          width: auto !important;
          height: 100% !important;
          flex: 0 0 80px !important;
          color: black;
          background-color: white;
          padding: 10px 5px !important;

          .available-number { 
            font-size: 28px !important; 
            line-height: 1; 
            margin-bottom: 5px;
          }
          .smaller-label {
            font-size: 10px;
            line-height: 1;
          }
        }
        .dealership-name {
          font-size: 18px !important;
          text-align: left !important;
          height: 100% !important;
          padding-top: 15px !important;
          padding-left: 15px !important;
          padding-bottom: 0 !important;
          margin-bottom: 0 !important;
          border-left: 4px solid lightgray;
        }
        .dealership-name-pb-logo img {
          display: none !important;
        }
      }

      .dealership-info {
        position: absolute;
        top: auto;
        bottom: 15px;
        flex-direction: row !important;
        justify-content: flex-start !important;
        width: 100% !important;
        padding-left: 100px !important;
        padding-right: 70px !important;
        column-gap: 0 !important;
        row-gap: 0 !important;
        
        > * { 
          flex: 0 1 auto !important; 
          font-size: 12px !important;
          padding-right: 15px !important;

          i {
            color: #A5A5A5 !important;
          }
        }

        > a {
          text-decoration: none;
        }

        br { display: none !important; }
      }
      .cta-row {
        .power-broker-img {
          position: absolute;
          top: 50%;
          left: auto;
          right: 10px;
          transform: translateY(-50%);
          height: 13px !important;
          width: auto !important;
        }
      }
    }
  }
}