// Articles
.single-article-container {
  min-height: 100vh;

  header.entry-header {
    //max-width: 800px;
    margin: 3.2rem auto 3.2rem auto; // 32px based on 10px
    .entry-title {
      font-family: @main-font;
      margin-top: 0;
      margin-bottom: 0.8rem; // 8px based on 10px
      font-size: @h2-font-size;
      text-transform: uppercase;
      a {
        color: @dark-gray;
        &:hover {
          color: @dark-gray;
        }
      }
      @media (min-width: @screen-md-min) {
        font-size: @h1-font-size;
      }
    }
    .article-meta span {
      font-size: 16px; // 18px based on 10px
    }

    .article-meta {
      .article-tags {
        text-transform: uppercase;
        margin-left: auto;
      	margin-right: 1.6rem;
        font-size: 16px;
        a {
          margin-right: 1.6rem;
        }
      }
    }
  }
  .entry-content {
    //max-width: 800px;
    margin: 1rem auto 70px auto;
    p, li {
      font-size: @article-font-size; // 26px (based on 10px)
      line-height: 1.4;
    }
    img {
      display: block;
      max-width: 100%;
      height: auto;
      &.alignleft {
        float: left;
        margin-right: 1rem;
      }
      &.alignright {
        float: right;
        margin-left: 1rem;
      }
    }
    .wp-caption {
      max-width: 100%;
      &.alignleft, &.alignright {
        margin-bottom: 1rem; max-width: 100%
      }
      &.alignleft {
        float:left;
        margin-right: 1rem;
      }
      &.alignright {
        float:right;
        margin-left: 1rem;
      }
    }
    @media (min-width: @screen-sm-min) {
      ul {
        margin-top: 1em;
        margin-bottom: 1em;
      }
      .embed-responsive {
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }
  }
  .container-fluid {
    margin: 0 auto 40px auto;
    @media (min-width: @screen-md-min) {
      max-width: 800px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .article-tags {
    font-size: 16px;
    margin-bottom: 15px;
    > span {
      font-weight: 700;
      font-family: @main-font;
    }
    a {
      display: inline-block;
      margin-right: 8px;
      margin-bottom: 8px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .page-content {
    article {

      .entry-content {
        margin-top: 1rem;
        margin-bottom: 70px;
        p, ul, ol {
          font-size: 2.604rem; // 26.04px (based on 10px)
          line-height: 1.5;
        }
        img {
          display: block;
          max-width: 100%;
          height: auto;
          &.alignleft {
            float: left;
            margin-right: 1rem;
          }
          &.alignright {
            float: right;
            margin-left: 1rem;
          }
        }
        .wp-caption {
          max-width: 100%;
          &.alignleft, &.alignright {
            margin-bottom: 1rem; max-width: 100%
          }
          &.alignleft {
            float:left;
            margin-right: 1rem;
          }
          &.alignright {
            float:right;
            margin-left: 1rem;
          }
        }
        @media (min-width: @screen-sm-min) {
          ul {
            margin-top: 1em;
            margin-bottom: 1em;
          }
          .embed-responsive {
            margin-top: 1em;
            margin-bottom: 1em;
          }
        }
      }
      .article-tags {
        font-size: 16px;
        margin-bottom: 15px;
        > span {
          font-weight: 700;
          font-family: @main-font;
        }
        a {
          display: inline-block;
          margin-right: 8px;
          margin-bottom: 8px;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }

  // Comment section
  .comment-container {
    min-height: 153px;
  }
}

// Article Liking
@keyframes slideInRight {
  0%{
      opacity:0;
      transform: translateX(1250px)
  }
  to{
      transform: translateX(0)
  }
}
.like-container {
  // animation-name: slideInRight;
  // animation-fill-mode: both;
  // animation-timing-function: ease-out;
  // animation-duration: .4s;
  position: fixed;
  z-index: 9999;
  bottom: 10px;
  right: 15px;
  width: 85px;
  height: 40px;
  background-color: #FFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
  border-radius: 20px;
  display: none;
  @media (min-width: @screen-md-min) {
    width: 95px;
    height: 50px;
    border-radius: 25px;
  }
  body.modal-open & {
    @media (min-width: @screen-lg-min) {
      right: calc(~'50% - '((@screen-lg-min/2) - 123px));
    }
  }
  body.news-article-open & { // Display the like icon (there are 2)
    display: flex;
  }
  body.news-article-open.modal-open .modal & { // Hide the icon in news-article-single-page.component when the modal is open
    display: none;
  }
  body.news-article-open.modal-open > app-root & { // Display the icon in app.component when the modal is open
    display: flex;
  }
  .like-count {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    font-size: @body-font-size;
    font-family: @main-font;
    @media (min-width: @screen-md-min) {
      font-size: @font-size-lg;
    }
  }
  .btn-like {
    width: 40px;
    border-radius: 20px;
    padding: 3px 0;
    background-color: #000;
    color: #FFF;
    @media (min-width: @screen-md-min) {
      width: 50px;
      border-radius: 25px;
      font-size: @font-size-lg;
    }
    &:hover, &.btn-like-is-liked {
      color: @brand-warning;
    }
  }
}
// Add This for Articles
.at-expanding-share-button[data-position=bottom-right] {
  display: none;
  body.news-article-open & {
    display: block;
    right: 20px;
  }
  body.modal-open & {
    @media (min-width: @screen-lg-min) {
      right: calc(~'50% - '((@screen-lg-min/2) - 20px));
    }
  }
}


// Article Card
dg-news-article-card.article-card, dg-gallery-open-card {
  display: block;
  article.article-container {
    a, a:hover, a:focus {
      text-decoration: none;
    }
    .entry-title {
      font-family: @main-font;
      margin-top: 0;
      margin-bottom: 0.8rem; // 8px based on 10px
      font-size: @article-font-size;
      text-transform: uppercase;
      color: @body-color;
      a, a:hover, a:focus {
        text-decoration: none;
      }
    }
    .entry-summary, .entry-summary p {
      font-size: @body-font-size;
    }
    .article-meta {
      font-size: @font-size-sm;
      a, a:hover, a:focus {
        text-decoration: underline;
      }
    }
  }
}
dg-gallery-open-card {
  .article-meta {
    margin-bottom: 1.6rem; // 16px based on 10px
  }
}

// List Page
.news-article-list-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 30px;
  @media (min-width: @screen-lg-min) {
    padding-left: 0;
    padding-right: 0;
  }
  .news-article-list-sidebar {
    transition: top 0.3s;
    width: 100%;
    align-self: flex-start;
    top: @navbar-height-xs;
    max-height: ~"calc(100vh - (@{navbar-height-xs} + 60px))";
    overflow-y: scroll;
    -ms-overflow-style: none; /** IE11 */
    &::-webkit-scrollbar {
      //width: 5px;
    }
    @media (min-width: @screen-sm-min) {
      position: sticky;
      top: @navbar-height-sm;
      width: 235px;
      max-height: ~"calc(100vh - (@{navbar-height-sm} + 60px))";
      padding-right: 15px;
      margin-right: 20px;
    }
    @media (min-width: @screen-md-min) {
      top: @navbar-height-md;
      max-height: ~"calc(100vh - (@{navbar-height-md} + 60px))";
    }
    &.fixed-sidebar {
      position: sticky;
      .btn-contributors {
        display: none;
      }
    }
    h1.heading1 {
      font-size: 3.2rem; // 32px based on 10px
    }
  }
  .news-article-list-content {
    position: relative;
    width: 100%;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    dg-news-article-card {
      p {
        //font-size: 2.0rem; // 20px based on 10px
      }
    }
  }
}

// News Sidebar
dg-news-article-search {
  margin-bottom: 1.6rem; // 16px based on 10px
  display: block;
}
.search {
	font-size: 18px;
	.form-control {
		border-radius: 0;
		//height: 34px;
		text-transform: uppercase;
	}
	button.btn {
		border-radius: 0;
		background-color: @brand-primary;
		background-image: none;
		color: @white;
    //padding: 4px 13px;
    padding: (@input-padding-y - 1.3rem) @input-padding-x;
    //font-size: 17px;
    min-width: 45px;
    &:hover {
      background-color: @brand-primary-hover;
      border-color: @brand-primary-hover;
    }
	}
}
dg-category-sidebar {
  display: block;
  font-size: 2rem; // 20px based on 10px
  margin-bottom: 1.6rem; // 16px based on 10px
  .btn.categories-dropdown {
    display: block;
    background-color: @secondary-07;
    text-align: left;
    font-size: 2rem; // 20px based on 10px
    font-family: @main-font;
    &:hover, &:focus, &[aria-expanded="true"] {
      background-color: @brand-primary;
      color: #FFF;
    }
    @media (min-width: @screen-sm-min) {
      &, &:hover, &:focus, &[aria-expanded="true"] {
        //border-bottom-color: @secondary-02;
        .current-cat {
          display: none;
        }
      }
      &:hover, &:focus {
        background-color: @brand-primary-hover;
      }
    }
    &::before, &::after {
      //right: 20px;
      @media (min-width: @screen-sm-min) {
        //content: none
      }
    }
  }
  #collapse-categories {
    border-left: 1px solid @secondary-02;
    border-right: 1px solid @secondary-02;
    border-bottom: 1px solid @secondary-02;
  }
  .category-menu {
    padding: 5px 0;
    margin: 2px 0 0;
    text-align: left;
    list-style: none;
    > li > a {
      display: block;
      padding: 5px 20px;
      clear: both;
      color: @dark-gray;
      font-size: 2.0rem; // 20px based on 10px
      white-space: nowrap;
      text-decoration: underline;
      &:hover, &:focus, &.active {
        color: @brand-primary;
        background-color: fade(@brand-primary, 5%);
      }
    }
  }
}

// Scroll to Top Button
.btn-scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: block;
  width: 40px;
  z-index: 20;
  @media (min-width: @screen-sm-min) {
    display: none;
  }
  body.news-article-open & {
    display: none;
  }
}

.article-title {
  &.comments-heading {
    margin-top: 3rem;
  }
}

// Comments
.muuts {
  background-color: fade(@light-gray, 10%);
  border: 1px solid #a5a5a5;
  border-left: 1px solid #a5a5a5 !important;
  padding: 15px;
  .m-facelink {
    display: none;
  }
  .m-follow {
    .m-icon {
      color: @gray;
    }
    .m-tooltip {
      top: 5px !important;
      left: 25px !important;
      &:after {
        display: none;
      }
    }
  }
  .m-nav-account {
    .m-me {
      .m-face {
        display: none !important;
      }
    }
  }
  .m-account {
    .m-upload {
      display: none !important;
      .m-face {

      }
    }
  }
  .m-post-upload {
    display: none;
  }
  &.is-commenting {
    .m-page {
      padding-top: 0.5rem;
      .m-list {
        .m-comment-head {
          font-size: 1.8rem;
          .m-comment_count {
            margin-top: 0;
          }
        }
        .m-replies {
          margin-top: 2.5rem;
          .m-reply {
            .m-post {
              font-size: 2.1rem;
              margin-left: 1.5rem;
            }
          }
        }
        .m-replyform {
          font-size: 2.1rem;
          margin-left: 0.5rem;
          &.m-focused {
            .m-postinput {
              min-height: 0;
            }
          }
          .m-preview {
            margin-left: 0;
            &.m-expanded {
              .m-post {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
  .muut-loading {
    text-align: center;
  }
}

.comments-container {
  .pending-comment {
    display: none;
  }
  margin-top: 45px;
  font-size: 18px;
  h2 {
    font-family: @main-font;
    color: #585858;
    margin-bottom: 30px;
  }
  font-family: @main-font-lt;
  .comment-box {
    padding: 20px 30px;
    background: #f5f5f5;
  }
  p.comment-info {
    margin-right: 10px;
    &:before {
      content: "\f069";
      font-family: FontAwesome;
      color: @red1;
      font-size: 20px;
      margin: 0px 5px 0px 0px;
    }
  }
  p.btn-pending {
    margin-top: 10px;
  }
  .btn-pending {
    a {
      border: 3px solid #aaaaaa;
      color: #aaaaaa;
      padding: 10px 25px;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .pending-comment {
    background: #f5f5f5;
    h3:before {
      content: "*";
      color: @red1;
    }
  }
  .single-comment-container {
    .more-comments {
      margin-top: 55px;
      a {
        display: block;
        width: 100%;
        color: @red1;
        display: inline-block;
        border: 1px solid @red2;
        padding: 10px 50px;
        &:hover {
          text-decoration: none;
          background: @red1;
          color: #fff;
        }
        &:after {
          content: "\f178";
          font-family: FontAwesome;
        }
      }
    }
    .single-comment.comment-reply {
      margin-left: 60px;
    }
    .single-comment {
      margin: 40px 0px 0px 0px;
      padding: 10px 0px 20px 20px;
      .signle-comment-left {
        margin: 17px 0px 0px 0px;
        max-width: 70px;
      }
      .single-comment-right {
        h3 {
          display: inline-block;
          color: @red1;
          font-size: 20px;
        }
        span {
          color: #b0b0b0;
          font-size: 16px;
          margin: 0px 0px 0px 12px;
          i {
            color: #b0b0b0;
            font-size: 14px;
            margin: 0px 4px 0px 4px;
          }
        }
        .comment-action {
          a {
            color: @red1;
            margin-right: 20px;
            font-family: @main-font-lt;
            text-transform: uppercase;
            font-size: 16px;
          }
          .reply {
            &:before {
              content: "\f112";
              font-family: FontAwesome;
              font-size: 14px;
              margin-right: 3px;
            }
          }
          .share {
            &:before {
              content: "\f1e0";
              font-family: FontAwesome;
              font-size: 14px;
              margin-right: 5px;
            }
          }
          .report {
            &:before {
              content: "\f071";
              font-family: FontAwesome;
              font-size: 14px;
              margin-right: 3px;
            }
          }
        }
      }
    }
  }
}

// Article Header Styles
.post-header {
  .header-img {
    img {
      max-width: 100%;
    }
  }
  .article-cat {
    position: absolute;
    right: 20px;
    bottom: 200px;
    a {
      display: inline-block;
      color: #fff;
      background: #DC0000;
      padding: 10px 25px;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
}
.header-text-bg {
  position: static;
  bottom: 0px;
  background-color: #999;
  padding: 2px 0px 35px 0px;
  width: 100%;
  .header-text {
    color: #fff;
    margin-left: 5%;
  }
}
.header-info-bg {
  position: relative;
  top: 0px;
  background: @red1;
  .header-info-text {
    color: #fff;
    margin-left: 5%;
    img {
      position: absolute;
      top: -23px;
      left: -8px;
      z-index: 2;
      width: 75px;
    }
    .profile p {
      margin: 10px 0px 0px 90px;
      width: 90%;
    }
    i {
      color: #fff;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  .header-comments {
    a {
      display: block;
      margin: 0px 0px 0px 20px;
      float: right;
      text-align: center;
    }
  }
  .header-info-links {
    a {
      display: block;
      float: right;
      width: 45px;
      text-align: center;
      height: 40px;
      padding: 10px 0px 0px 0px;
      &:hover {
        text-decoration: none;
      }
    }
    a.facebook {
      background: #39579d;
    }
    a.twitter {
      background: #00acec;
    }
    a.pintrest {
      background: #ca1f24;
    }
    a.plus {
      background: #f08f1d;
    }
    i {
      font-size: 20px;
      color: #fff;
    }
  }
  .header-comments.col-md-2 {
    padding: 0;
    margin: 0px;
    a:first-child {
      margin-left: 65px;
    }
  }
  .header-info-links.col-md-4 {
    margin: 0px 0px 0px 0px;
    padding: 0px;
  }
}
.article-title {
  font-family: @main-font;
  margin-top: 0;
  margin-bottom: 0.8rem; // 8px based on 10px
  color: @body-color;
  font-size: @h2-font-size;
  text-transform: uppercase;
  a {
    color: @dark-gray;
    &:hover {
      color: @dark-gray;
    }
  }
  @media (min-width: @screen-md-min) {
    font-size: @h1-font-size;
  }
}
.post-time, .article-meta, p.post-time, p.article-meta {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  //font-family: @main-font-lt;
  font-size: @meta-font-size;
  color: @secondary-06;
  //text-transform: uppercase;
  span {
    margin-right: 1.6rem; // 16px based on 10px
    //text-transform: uppercase;
    white-space: nowrap;
    //font-family: @main-font;
  }
  &.article-read-time {
    margin-bottom: 0.8rem; // 8px based on 10px
  }
}
.embeed-tweet {
  margin: 0 auto;
  width: 69%;
}
.article-icon-container {
  position: relative;
  .article-icon {
    position: absolute;
    top: 0;
    left: 0px;
    z-index: 999999;
    border-top: 69px solid #DC0000;
    border-right: 69px solid rgba(56, 5, 5, 0);
    width: 0;
    height: 0;
    i {
      color: #fff;
    }
    a {
      margin: 0px 0px -10px 0px;
      position: absolute;
      top: -77px;
      left: -10px;
      font-size: 18px;
      display: block;
      padding: 20px;
    }
  }
}

.next-prev-post {
  border-top: 1px solid @grey2;
  padding: 20px 0px 0px 0px;
  margin-top: 10px;
  min-height: 296px;
  
  span {
    font-size: 20px;
    text-transform: uppercase;
  }
  img {
    max-width: 100%;
    height: auto;
    transition: all .35s ease-in-out;
  }
  h3 {
    margin-top: 5px;
    a {
      padding-top: 190px;
      display: block;
      margin-top: -185px;
      position: relative;
    }
  }
  a {
    font-size: 22px;
    color: @grey;
    text-transform: uppercase;
    font-family: @main-font;
    text-decoration: none;
  }
  .img-zoom img {
    transform: scale(1.15)
  }
  .next-article-img, .prev-article-img {
    overflow: hidden;
    clear: both;
  }
}

// Article Images and Galleries
.wp-block-image {
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-width: 100%;

  // The image block is in a `figure` element, and many themes zero this out.
  // This rule explicitly sets it, to ensure at least some bottom-margin in the flow.
  margin-bottom: 1em;

  img {
    max-width: 100%;
  }

  &.aligncenter {
    text-align: center;
  }

  &.alignfull img,
  &.alignwide img {
    width: 100%;
  }

  // This resets the intrinsic margin on the figure in non-floated, wide, and full-wide alignments.
  margin-left: 0;
  margin-right: 0;

  // Floats get an extra wrapping <div> element, so the <figure> becomes a child.
  .alignleft,
  .alignright,
  .aligncenter,
  &.is-resized {
    display: table;

    // The figure is born with left and right margin.
    // We remove this by default, and then customize it for left, right, and center.
    margin-left: 0;
    margin-right: 0;

    > figcaption {
      display: table-caption;
      caption-side: bottom;
    }
  }

  .alignleft {
    /*rtl:ignore*/
    float: left;
    /*rtl:ignore*/
    margin-right: 1em;
  }

  .alignright {
    /*rtl:ignore*/
    float: right;
    /*rtl:ignore*/
    margin-left: 1em;
  }

  .aligncenter {
    margin-left: auto;
    margin-right: auto;
  }
  // Image Captions
  figcaption {
    font-size: 12px;
    line-height: 1.5;
    margin: 0;
    padding: 0.5rem;
    text-align: center;
  }
}
