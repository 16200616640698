.btn {
  &.btn-banner {
    font-size: 2.4rem;
    background-color: @black;
    margin: 3rem 0;
    padding: 3rem 1.5rem;

    span {
      color: @white;
      border: 3px solid @red1;
      margin: 0 auto;
      padding: 1rem;
      transition: all 0.25s ease-in-out;

      &:hover {
        background-color: @red1;
      }
    }

    &.btn-transparent {
      background-color: transparent;
    }
  }
}

.text-link {
  &.rules-link {
    display: block;
    text-decoration: underline;
    margin-top: 3rem;
  }
}