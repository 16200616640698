// TODO Optimize styling (replace font size percentages with rem) and see if HTML can be streamlined (better use of flexbox, etc)
social-banner {
  display: block;
  height: 100%;
  > .banner-social {
    min-height: 100%;
    display: flex;
    li {
      font-size: 4.4rem; // 44px based on 10px
      @media (min-width: @screen-sm-min) {
        font-size: 5.0rem; // 50px based on 10px
      }
      @media (min-width: @screen-md-min) {
        font-size: 4.25rem; // 42.5px based on 10px
      }
      @media (min-width: @screen-lg-min) {
        font-size: 6.0rem; // 60px based on 10px
      }
    }
  }
}
.banner-social {
  // &#dodge-social-banner {
  //   background-color: @black;
  //   background: linear-gradient(to right, @dark-gray, @black);
  // }

  height: 170px; // 425px
  position: relative;
  //margin-top: 3px;
  font-size: 1.65em;

  h3 {
    margin-top: 0;
  }
  .content-inner {
    width: 100%;
    position: absolute;
    bottom: 3rem;
    transform: translateY(0); //translateY(-50%)
    color: @white;
    h3 {
      font-family: @main-font;
      font-size: 5rem;
      color: inherit;
      margin-bottom: 0;
    }
    a {
      color: inherit;
    }
    .content {
      opacity: 0;
      transform: translateX(-100%);
      transition: all 0.50s ease-in-out;
      text-align: center;

      .active& {
        opacity: 1;
        transform: translateX(0);
      }
    }
    .social-icons {
      font-size: 190%;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-around;
      flex-grow: 1;
      //text-align: center;
      li {
        display: block;
        a {
          display: block;
          line-height: 0;
          transform: scale(0.95);
          transition: all 0.25s ease-in-out;
          &:hover {
            transform: scale(1);
          }
        }
      }
    }
  }

  @media (min-width: 480px) {
    //font-size: 2.25em;
    .content-inner {
      .social-icons {
        font-size: 135%;
        margin-bottom: 0;
      }
    }
  }
  @media (min-width: 600px) {
    height: 155px;
  }
  @media (min-width: @screen-sm-min) {
    height: auto;
    padding: 2.5rem 0;
    margin-bottom: 3px;
    .content-inner {
      position: static;
      transform: translateY(0);
      .content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        .social-icons {
          order: 1;
          font-size: 5rem;
        }
      }
      .content-audio {
        h3 {
          font-size: 2.4rem;
        }
      }
    }
  }
  @media (min-width: @screen-md-min) {
    //padding-left: 2rem;
    .content-inner {
      max-width: 800px;
      .content {
        justify-content: flex-start;
        .social-icons {
          font-size: 4.25rem;
          margin-left: 3rem;
          li {
            //margin-right: 0.75rem;
            &:last-child {
              //margin-right: 0;
            }
          }
        }
      }
    }
  }
  @media (min-width: @screen-lg-min) {
    .content-inner {
      padding-left: 10px;
      .content {
        h3 {
          font-size: 4.5rem;
        }
        .social-icons {
          font-size: 6rem;
          //margin-left: 3rem;
          li {
            //margin-right: 1rem;
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .content-inner {
      padding-left: 1em;
      .content {
        h3 {
          font-size: 5rem;
        }
        .social-icons {
          //margin-left: 6rem;
          li {
            //margin-right: 1.5rem;
          }
        }
      }
    }
  }
}
