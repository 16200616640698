// Comments

.comment {
  display: block;
  margin-bottom: 15px;
  .comment-user {
    font-family: @main-font;
    font-size: 2rem; // 20px based on 10px
  }
  .comment-meta {
    color: @light-gray;
    padding: 5px 0;
  }
  .comment-body {
    p, li {
      font-size: 2rem // 20px based on 10px
    }
    p {
      margin: 0 0 10px 0;
    }
  }
  .comment-footer {
    display: flex;
    button {
      color: @light-gray;
      border: 0;
      font-size: 1.4rem; // 14px based on 10px
      .fa-star {
        color: @yellow;
      }
    }
  }
}
dg-comments {
  display: block;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}
.comment-thread {
  .post-replies {
    border-left: 2px solid @lighter-gray;
    padding-left: 15px;
  }
  hr {
    margin-top: 30px;
    margin-bottom: 30px;
    border-top: 2px solid @lighter-gray;
  }
}
