$hero-height-xs-default: 420px; //lol, adjust as needed
$hero-height-md-default: 500px;
$hero-height-lg-default: 600px;

// Restore defaults here for normal banners.
// For garage reveals, see comments below.

$hero-height-xs: $hero-height-xs-default; // 'auto' for garage reveal
$hero-height-md: $hero-height-md-default;
$hero-height-lg: $hero-height-lg-default; // 500px for garage

.hero-wrapper {
  width: 100%;
  height: $hero-height-xs;

  @media (min-width: 1200px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
  }

  // Added additional breakpoint for LB reveal 3/7/24
  @media (min-width: 768px) {
    height: 400px; //$hero-height-md
  }
  @media (min-width: 992px) {
    height: $hero-height-md; //UNCOMMENT ME WHEN REMOVING THE FULL WIDTH BUTTON
  }
  @media (min-width: 1200px) {
    height: $hero-height-lg;
  }

  > * {
    display: block;
    height: 100%;
  }

  // Adjust hero sizing for mobile/tablet when never lift garage is used as a fixed hero.
  &.never-lift { 
    overflow: hidden;

    @media (max-width: 884px) {
      height: auto;
    }
    @media (min-width: 885px) and (max-width: 1199px) {
      height: $hero-height-xs-default;
    }
  }

  .fixed-hero {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;

    @import 'heroes/cdm';
    @import 'heroes/hwy93';
    @import 'heroes/rkn-countdown';
  }
}

// Temp styling fix for safari issue with neverlift hero on tablet
.no-webp {
  .hero-wrapper {
    #garage-teaser {
      .teaser-inner {
        @media (min-width: 1200px) {
          background-size: 1890px 600px;
        }
      }
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .hero-wrapper {
    #garage-teaser {
      .article-cta {
        @media (min-width: 885px) and (max-width: 1199px) {
          right: 23% !important;
          top: 13% !important; 
        }
      }
    }
  }
}


// Body adjustments
body.fixed-hero-active {
  overflow-x: hidden;

  @media (min-width: 1200px) {
    margin-top: $hero-height-lg-default;
  }

  @media (min-width: 1440px) {
    margin-top: $hero-height-lg;
  }
}
