.card {
  padding: 1.6rem;
  &.spec-card {
    background-size: cover;
    background-position: center center;
    font-family: @main-font;
    margin-bottom: 1.5rem;

    .h2 {
      font-size: 2.3rem;
      margin-top: 0;
      margin-bottom: 2rem;
    }

    ul {
      margin-bottom: 0;
      li {
        font-size: 1.8rem;
        margin-bottom: 1rem;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    @media (min-width: @screen-md-min) {
      margin-bottom: 0;
      padding: 3rem 2rem;
      .h2 {
        font-size: 3rem;
      }
      ul {
        li {
          font-size: 2.1rem;
        }
      }
    }

    &#viper-specs {
      background-image: url('/assets/images/ultimate-last-chance/viper-bg-faded.jpg');
      background-position: bottom center;
    }
    &#demon-specs {
      background-image: url('/assets/images/ultimate-last-chance/demon-bg-faded.jpg');
    }
  }
}

dg-last-chance {
  p.lead {
    @media (min-width: @screen-lg-min) {
      font-size: 2.4rem;
      line-height: 1.5;
      margin-top: 2.5rem;
    }
  }
}

.cta-card {
  height: 100%;
	display: flex;
	flex-direction: column;
	border-top: 1px solid @secondary-02;
	border-left: 1px solid @secondary-02;
	border-right: 1px solid @secondary-02;
	> div:first-child {
		font-family: @main-font;
		flex-grow: 1;
		font-size: @meta-font-size;
		margin: 20px 20px 5px 20px;
	}
	img {
		max-height: 264px;
	}
}

dg-card-collapse {
  position: relative;
  display: block;
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid @secondary-04;
    padding: 0;
    overflow: hidden;
    border-radius: 4px;
    font-size: 1.8rem; // 18px based on 10px
    .card-img-top {
      flex-shrink: 0;
      width: 100%;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    .card-body {
      flex: 1 1 auto;
      padding: 2.0rem; // 20px based on 10px
      position: relative;
      z-index: 2;
      background-color: #FFF;
    }
    .card-title {
      font-size: 1.8rem; // 18px based on 10px
      font-family: @main-font;
      margin-bottom: 1rem; // 10px based on 10px
    }
  }
  a.card {   
    transition: .35s ease-in-out;
    color: @body-color;
    text-decoration: none;
    .card-img-top img {
      transform-origin: center;
      transition: all .25s ease-in-out;
      transform: scale(1.05);
    }
    &:hover {
      border: 2px solid @primary-03;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,0.11), 0 2px 12px 0 rgba(0,0,0,0.26);
      .card-img-top img {
        transform: scale(1);
      }
    }
    
  }
  .collapse-body {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
    background-color: #FFF;
    padding: 2.0rem; // 20px based on 10px
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.16), 0 8px 18px 0 rgba(0,0,0,0.16);
    border-radius: 4px;
    .collapse-body-title {
      font-size: 1.8rem; // 18px based on 10px
      font-family: @main-font;
      margin-bottom: 1rem; // 10px based on 10px
    }
  }
}