#cdm-footer-application{
  background-image: url('/assets/images/chief-donut-maker/light-asphalt-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 40px 0;

  @media (min-width: @screen-md-min) {
    padding: 100px;
  }
}

#cdm-form, #cdm-lookup-form, #cdm-id-lookup-form {
  label {
    font-size: 20px;
    padding: 0;

    &:not(.checkbox-inline) {
      text-align: right;
    }

    &.checkbox-inline input[type="checkbox"] {
      &:focus, &:hover {
        box-shadow: 0 0 8px rgb(220,0,0,.25);
      }
    }
  }

  .has-error {
    label, .help-block {
      color: @brand-primary;
    }
  }

  input[type="text"], input[type="email"], input[type="url"] {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid @light-gray;
    font-size: 20px;
    color: @white;

    &:focus, &:hover {
      outline: none;
      box-shadow: none;
      border-bottom: 2px solid @brand-primary-hover;
    }
  }

  #video_link {
    width: 100%;
  }

  .input-wrap {
    width: auto;
    flex: 1 1 auto;
  }
}

#cdm-application {
  min-height: 400px;

  .cdm-card-header {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .form-group {
    margin-left: 0;
    margin-right: 0;
  }

  button[type="submit"] {
    margin: 40px 0;
  }

  .cdm-card-content p {
    max-width: 600px;
    margin: 0 auto;
  }
}

#cdm-lookup-form, #cdm-id-lookup-form  {
  text-align: left;

  .form-group {
    display: flex;
  }

  label {
    text-align: left !important;
    display: inline-block;
    margin-right: 20px;

    &[for="applicationId"] {
      width: 128px;
    }
  }

  input {
    display: inline-block;
  }
}
