#drag-pak-experience {
  margin: 20px 0;

  .quote {
    width: 100%;
    max-width: 560px;
    font-size: 18px;
    padding: 20px 15px;
    border-top: 5px solid @secondary-01;
    border-bottom: 5px solid @secondary-01;

    @media (min-width: 768px) {
      width: 45%;
      font-size: 24px;
    }
    @media (min-width: 1200px) {
      font-size: 32px;
    }

    // &:before, &:after {
    //   display: block;
    //   content: '';
    //   background-color: @secondary-01;
    //   height: 5px;
    //   width: 100%;
    // }
    // &:before {
    //   top: 0;
    //   margin-bottom: 30px;
    // }
    // &:after {
    //   bottom: 0;
    //   margin-top: 30px;
    // }

    &.pull-left {
      margin-right: 25px;
    }
    &.pull-right {
      margin-left: 25px;
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (min-width: @screen-xs-min) {
      flex-direction: row;
    }

    .btn {
      margin-bottom: 20px;
    }
  }

  // sup and sub styling
  h1, h2, h3, h4, h5 {
    sup, sub {
      font-size: 40%;
    }
    sup {
      top: -1.15em;
    }
    sub {
      bottom: -0.1em;
    }
  }

  p, li, .btn {
    sup, sub {
      font-size: 55%;
    }
    sup {
      top: -0.75em;
    }
    sub {
      bottom: -0.15em;
    }
  }

  //ol, ul
  .spaced {
    li {
      margin-bottom: 3rem;
    }
    // nested lists as normal
    ol, ul {
      li {
        margin-bottom: 0;
      }
    }
  }

  //ol, ul
  .indented {
    list-style-type: none;
    padding-left: 3rem;
  }

  .flex-container {
    display: flex;
    flex-direction: column;

    &.vertically-centered {
      align-items: center;
    }
  }

  .text-blue {
    color: @accent-blue;
  }


  // Layout types
  .wrapped-image {
    @media (min-width: 992px) {
      img {
        width: auto;
        max-width: 60%;
        margin-bottom: 15px;

        &.pull-left {
          margin-right: 25px;
        }
        &.pull-right {
          margin-left: 25px;
        }
      }
    }
  }

  .card-horizontal {
    padding: 0;

    @media (min-width: 768px) {
      display: flex;
    }

    .img-container {
      background-color: black;
      padding: 0;
      margin-bottom: 0;

      @media (min-width: 768px) {
        flex: 0 0 250px;
        max-width: 250px;
        display: flex;
        align-items: center;
      }
      @media (min-width: 768px) {
        flex: 0 0 300px;
        max-width: 300px;
      }
    }
    .content {
      padding: 15px;
      
      @media (min-width: 768px) {
        flex: 1;
        display: flex;
        align-items: center;
      }
    }

    &#analog-gauges-card {
      @media (min-width: 1200px) {
        .img-container {
          flex: 0 0 460px;
          max-width: 460px;
        }
      }
    }
  }

  .list-labels {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      justify-content: space-between;
    }

    li {
      flex: 0 0 100%;
      font-size: 14px;
      line-height: 1.2;
      background-color: white;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 10px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);

      @media (min-width: 768px) {
        display: flex;
        align-items: center;
        flex: 0 0 32%;
      }

      @media (min-width: 992px) {
        font-size: 16px;
      }
      
      @media (min-width: 1200px) {
        font-size: 2rem;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  hr.my-5 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .btn-container {
    display: flex;
    margin-top: 25px;
    padding: 25px;
    justify-content: space-between;
    align-items: center;
    

    .btn-footer-nav {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      padding: 15px 20px;
      flex: 0 0 48%;
      max-width: 48%;
      margin: 0;
      white-space: normal;

      @media (min-width: 768px) {
        font-size: 14px;
      }

      @media (min-width: 1200px) {
        font-size: 18px;
      }

      @media (min-width: 1440px) {
        flex: 0 0 40%;
        max-width: 40%;
        font-size: 21px;
      }

      i {
        &.fa-chevron-right { margin-left: 10px;}
        &.fa-chevron-left { margin-right: 10px;}
      }
    }
  }
}

.scroll-to-top-container {
  // Scroll to Top Button
  .btn-scroll-to-top {
    bottom: 50px;
    height: 40px;
    border-radius: 20px;
    font-size: 2em;

    &:focus {
      outline: 0;
    }

    .fa {
      position: relative;
      bottom: 10px;
      right: 3px;
    }

    i:before {
      padding-bottom: 5px;
    }
  }
}

.floating-button-container {
  animation-name: slideInRight;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-duration: .4s;
  position: fixed;
  z-index: 9999;
  bottom: 50px;
  right: 200px;
  width: 85px;
  height: 40px;
  background-color: #FFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
  border-radius: 20px;
  display: flex;
  @media screen and (min-width: @screen-sm-min) {
    right: 133px;
  }

    @media screen and (min-width: @screen-md-min) {
    right: 103px;
    width: 95px;
    height: 50px;
    border-radius: 25px;
  }
  //body.news-article-open & { // Display the like icon (there are 2)
  //  display: flex;
  //}
  //body.news-article-open.modal-open .modal & { // Hide the icon in news-article-single-page.component when the modal is open
  //  display: none;
  //}
  //body.news-article-open.modal-open > app-root & { // Display the icon in app.component when the modal is open
  //  display: flex;
  //}
  .comment-count {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    font-size: @body-font-size;
    font-family: @main-font;
    @media (min-width: @screen-md-min) {
      font-size: @font-size-lg;
    }
  }

  .btn-comment {
    width: 40px;
    border-radius: 20px;
    padding: 3px 0;
    background-color: #000;
    color: #FFF;
    @media (min-width: @screen-md-min) {
      width: 50px;
      border-radius: 25px;
      font-size: @font-size-lg;
    }
    &:hover { // &.btn-like-is-liked
      color: @brand-warning;
    }
  }
}

.at-expanding-share-button[data-position=bottom-right] {
  body.drag-pak-experience & {
    display: block;
    bottom: 50px;

    right: 110px;
    //height: 40px;
    //width: 40px;
    @media screen and (min-width: @screen-sm-min) {
      right: 10px;
    }
  }
  body.drag-pak-experience.modal-open & {
    display: none;
  }
}

body.drag-pak-experience {
  .at-expanding-share-button.at-expanding-share-button-desktop {
    .at-expanding-share-button-toggle-bg {

      height: 40px;
      line-height: 40px;
      width: 40px;

      &> span {
        height: 40px;
        width: 40px;
      }

      @media screen and (min-width: @screen-md-min) {
        height: 50px;
        line-height: 50px;
        width: 50px;

        &> span {
          height: 50px;
          width: 50px;
        }
      }
    }
    .at-expanding-share-button-toggle {
      height: 40px;
      @media screen and (min-width: @screen-md-min) {
        height: 50px;
      }
    }
  }

  // minor sidebar styling for sup/sub tags
  .sidebar-container {
    ul {
      > li {
        a {
          sub, sup {
            font-size: 45%;
          }
        }
      }
    }
  }
}


.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.absolute {
  position: absolute !important;
}
.pointer-none {
  pointer-events: none;
}
.h-0 {
  height: 0 !important;
}
.display-none {
  display: none !important;
}


// Comments modal mobile styling
// @media (max-width: 767px) {
.modal {
  .modal-dialog {
    &.comments-modal {
      padding-top: @navbar-height-xs;
      margin: 0 auto;
      width: 100%;
      max-width: 600px;
      height: 100vh;

      @media (min-width: 768px) {
        padding-top: @navbar-height-sm;
      }
      
      @media (min-width: 992px) {
        padding-top: @navbar-height-md;

        body.fixed-nav & {
          padding-top: 70px; // Fixed version nav tablet
        }
      }
      
      @media (min-width: 1200px) {

        body.fixed-nav & {
          padding-top: 54px; // Fixed version nav tablet
        }
      }

      .modal-content {
        height: 100%;

        .modal-header {
          border-bottom: 1px solid @secondary-05;
        }

        #comments-modal-body {
          height: calc(~'100% - 95px');
          overflow-y: auto;
        }
      }
    }
  }
}