.disclaimer-tooltip {

  &.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #999;
  }
  .tooltip-inner {
    background: #000;
    // background: #999;
    color: #FFF;
    pointer-events: all;
    text-align: left;

    @media screen and (min-width: @screen-lg-min) {
      max-width: 400px;
    }
  }
}

.hpl-tooltip {
  &.in {
    display: none;
    flex: 0;
  }
  
  &.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #000;
  }
  .tooltip-inner {
    background: #000;
    color: #FFF;
    pointer-events: all;
    text-align: center;
    max-width: 135px;
  }
}

.disclaimer-tooltip {
  &.in {
    display: none;
    flex: 0;
  }
  
  &.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #000;
  }
  .tooltip-inner {
    background: #000;
    font-size: 10px;
    color: #FFF;
    pointer-events: all;
    text-align: center;
    max-width: 300px;
  }
}


.username-tooltip .tooltip-inner {
  width: 100%;
  max-width: 320px;
  text-align: left;

  p, li {
    font-size: 14px !important;
  }
}
