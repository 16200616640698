@btn-font-size: 1.6rem;

dg-audio-player {
  display: block;
}

.audio-player {
  background-color: @white;
  color: @dark-gray;
  margin-top: 1rem;
  max-width: 700px;
  .player {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: linear-gradient(270deg, @lighter-gray, @white);
    //height: 100px;
    .poster {
      flex-basis: 100%;
      max-width: 90px;
      display: none;
      @media (min-width: @screen-md-min) {
        display: block;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    > div:not(.player) {
      flex: 1;
    }
    .top-controls {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .meta {
        font-size: 1.4rem;
        line-height: 1.2;
        span {
          display: block;
          &.date {
            color: @light-gray;
            padding-top: 15px;
          }
          &.title {
            font-size: 1.8rem;
            width: 100%;
            //line-height: 1.5;
            margin-top: 3px;
            @media (min-width: @screen-sm-min) {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .play-pause-container {
      max-width: 125px;
      min-width: 100px;
      font-size: 1.8rem;
      text-align: center;
      padding: 10px;
      > div {
        background: @red1;
        border-radius: 50%;
        width: 54px;
        height: 54px;
        padding: 2px;
        margin: 0 auto;
      }
      button {
        //background-color: transparent;
        position: relative;
        z-index: 3;
        border-radius: 50%;
        border: 2px solid #FFF;
        background-color: @red1;
        width: 50px;
        height: 50px;
        color: #FFF;
        &:hover {
          background-color: darken(@brand-primary, 4%);
        }
      }
    }
    .bottom-controls {
      display: flex;
      font-size: 14px;
      padding-bottom: 5px;
    }
    .timeline-container {
      flex: 1;
      display: flex;
      margin: 0 20px 0 15px;

      .timeline-bar {
        margin: 5px;
        position: relative;
        background-color: @gray-light;
        //min-width: 200px;
        height: 10px;
        flex: 1;
        .timeline-fill {
          background-color: @red1;
          width: 0;
          height: inherit;
        }
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        color: @red1;
        &::-webkit-progress-bar { // Progress box
          background-color: @gray-light;
        }
        &::-webkit-progress-value, &::-ms-fill { // Progress value
          background-color: @red1;
          color: @red1;
        }
      }
      .timeline-bar::-webkit-progress-value {
        background-color: @red1;
      }
      .timeline-bar::-moz-progress-bar {
        background: @red1;
      }
    }
    .control-volume {
      position: relative;
      padding: 0 5px;
      display: flex;
      width: 25%;
      max-width: 125px;
      i {
        text-align: center;
        font-size: 1.5rem;
        line-height: 1.3;
        margin-right: 5px;
      }
      input {
        margin: 5px;
        padding-top: 0; //ie fix
        padding-bottom: 0; //ie fix
      }
    }

    .btn-download {
      display: block;
      margin: 0 15px 0 10px;
      color: @dark-gray;
      &:hover {
        color: @red1;
      }
    }
  }
  .btn {
    &.btn-view {
      background-color: @lighter-gray;
      border: 0;
      font-size: 1.4rem;
      padding: 0.3rem 1.2rem;
      &:hover {
        background-color: darken(@lighter-gray, 15%);
      }
    }
  }

  @media (min-width: @screen-lg-min) {
    //margin: 1rem auto;
    margin-bottom: 0;
  }
}


/*Chrome*/

input[type='range'] {
  overflow: hidden;
  width: 100%;
  //height: 1.4rem;
  -webkit-appearance: none;
  background-color: @gray-light;

  @media (min-width: 600px) {
    height: 1rem;
  }

  &:focus {
    outline: 0;
  }

  &::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #13bba4;
    margin-top: -1px;
  }

  &::-webkit-slider-thumb {
    width: 0;
    -webkit-appearance: none;
    height: 100%;
    cursor: grab;
    background: lighten(@red1, 30%);
    box-shadow: -3000px 0 0 3000px @red1;
  }

  //FF
  &::-moz-range-progress {
    background-color: @red1;
    height: 100%;
  }
  &::-moz-range-track {
    background-color: @gray-light;
    height: 100%;
  }
  &::-moz-range-thumb {
    background-color: @red1;
    width: 0;
    height: 0;
    display: none;
    border: 0;
  }
  &::-moz-focus-outer {
    border: 0;
  }

  //IE
  &::-ms-fill-lower {
    background-color: @red1;
  }
  &::-ms-fill-upper {
    background-color: @gray-light;
  }
  &::-ms-thumb {
    background-color: @red1;
    width: 0;
    height: 0;
    display: none;
    border: 0;
  }

}
