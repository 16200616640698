// CDM Landing Page hero
#cdm-landing-hero {
  padding-bottom: 150px;

  @media (min-width: @screen-lg-min) {
    padding-bottom: 0;
  }

  .not-a-button {
    font-size: 20px;
    background-color: black;
    color: white;
    text-transform: uppercase;
    padding: 10px 20px;
    position: absolute;
    bottom: -20px;
    right: 20px;
    z-index: 3; // on top of video container

    @media (min-width: @screen-lg-min) {
      right: -80px;
    }
  }

  h2 {
    position: absolute;
    z-index: 2;
    bottom: -100px;
    left: 20px;
    max-width: 400px;

    // override giga
    @media screen and (min-width: @screen-md-min) {
      font-size: 56px;
    }

    @media screen and (min-width: @screen-lg-min) {
      font-size: 56px;
      top: 50%;
      left: -28%;
      transform: translateY(-50%);
      height: 210px;
    }
  }
}


#cdm-landing-tiles {
  display: flex;
  flex-direction: column;
  margin-left: -5px;
  margin-right: -5px;
  flex-wrap: wrap;

  @media (min-width: @screen-md-min) {
    flex-direction: row;
  }

  .landing-tile {
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-bottom: @homepage-gap;

    &:last-child {
      margin-bottom: 0;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      transform-origin: center;
      transform: scale(1.05);
      transition: all 0.25s ease-in-out;
    }

    &:hover, &:focus {
      &::after {
        transform: scale(1);
        transition: all 0.25s ease-in-out;
      }
    }

    h2 {
      margin-bottom: 30px;
    }

    a {
      &::before {
        cursor: pointer;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.jobs::after {
      background-image: url('/assets/images/chief-donut-maker/home-tile-job.jpg');
    }
    &.perks::after {
      background-image: url('/assets/images/chief-donut-maker/home-tile-perks.jpg');
    }
    &.experience::after {
      background-image: url('/assets/images/chief-donut-maker/home-tile-experience.jpg');
    }
    &.hiring-process::after {
      background-image: url('/assets/images/chief-donut-maker/home-tile-hiring-process.jpg');
    }

    @media (min-width: @screen-md-min) {
      flex: 0 0 calc(~"50% - 10px");
      margin-right: 5px;
      margin-left: 5px;
    }

    @media (min-width: @screen-xl-min) {
      flex: 0 0 calc(~"25% - 10px");
      margin-right: 5px;
      margin-left: 5px;
      margin-bottom: 0;
    }
  }
}

#cdm-landing-copy {
  color: @white;
  background-color: @black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  h3, p {
    max-width: 800px;
  }

  @media (min-width: @screen-md-min) {
    padding: 50px;
  }
}

#cdm-landing-top-ten {
  background-image: url('/assets/images/chief-donut-maker/asphalt-background.jpg');
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  color: @white;
  position: relative;

  #top-ten-header {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 50px 2rem;

    @media (min-width: @screen-md-min) {
      padding: 100px 2rem;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .card-container, .elimination-copy  {
    width: 100%;

    @media (min-width: @screen-md-min) {
      flex: 0 0 50%
    }
  }


  .elimination-copy h2 {
    text-align: center;
  }

  .cdm-card {
    min-height: 0;
  }

  .cdm-card-label {
    display: none;
  }

  .elimination-copy {
    padding-bottom: 2rem;
  }

  @media (min-width: @screen-md-min) {
    .cdm-card-label {
      display: block;
    }

    .card-container {
      padding: 2rem;
    }


    .elimination-copy {
      padding-left: 4rem;
      padding-bottom: 0;
      h2 {
        text-align: left;

      }
    }
  }

  .elimination-video {
    width: 100%;
    max-width: 720px;
  }


  .intro-card {
    img {
      margin: 0 auto;
      display: block;
      max-width: 415px;
    }

    @media (min-width: @screen-sm-min) {
      flex: 0 0 415px;
    }

  }

  .intro-copy {
    padding: 50px 20px;

    @media (min-width: @screen-sm-min) {
      flex: 0 1 650px;
      padding-bottom: 0;
    }
  }

  #top-ten-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding: 0 20px 50px;

    @media (min-width: @screen-xs-min) {
      flex-direction: row;
    }

    @media (min-width: @screen-lg-min) {
      padding: 0 20px 100px;
    }

    @media (min-width: @screen-xl-min) {
      padding: 0 0 100px;
    }
  }

  cdm-finalist-card {
    position: relative;
    transition: all .25s ease-in-out;

    img {
      margin-bottom: 20px;
    }

    .finalist-name {
      font-weight: bold;
    }

    .finalist-city {
      font-weight: 100;
    }

    .finalist-name, .finalist-city {
      font-size: 2rem;
      text-transform: uppercase;
    }

    .icon {
      position: absolute;
      bottom: 2.5rem;
      right: 2.5rem;
      height: 36px;
      width: 36px;
      border-radius: 50%;
      border: 1px solid @white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // outline
    &::before {
      transition: all .25s ease-in-out;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: "";
      border: 2px inset @brand-primary-hover;
    }

    &:hover, &:focus {
      cursor: pointer;

      .icon {
        transition: all .25s ease-in-out;
        border-color: @brand-primary;
        background-color: @brand-primary;
      }

      &::before {
        opacity: 1;
      }
    }

    @media (min-width: @screen-xs-min) {
      flex: 0 0 50%;
    }

    @media (min-width: @screen-sm-min) {
      flex: 0 0 33.3333%;
      margin: 0;
    }
    @media (min-width: @screen-lg-min) {
      flex: 0 0 25%;
    }
  }

  .spacer {
    padding: 2rem;
    @media (min-width: @screen-xs-min) {
      flex: 0 0 50%;
    }

    @media (min-width: @screen-sm-min) {
      flex: 0 0 33.3333%;
      margin: 0;
    }
    @media (min-width: @screen-lg-min) {
      flex: 0 0 25%;
    }
  }

  .finalist-card {
    padding: 2.5rem;
    background-color: transparent;
  }

  .vertical-text-left, .vertical-text-right {
    display: none;
  }

  @media (min-width: @screen-lg-min) {
    .vertical-text-left  {
      display: block;
      position: absolute;
      top: 50%;
      left: -20px;
      transform: translateY(-50%) rotate(-90deg) ;
      white-space: nowrap;
      font-size: 20px;
    }

    .vertical-text-right  {
      display: block;
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%) rotate(-90deg) ;
      white-space: nowrap;
      font-size: 20px;
    }
  }

}

.cdm-carousel-modal {

  @media (min-width: @screen-sm-min) {
    width: 100%;
  }



  .cdm-card, .cdm-card-content {
    min-height: 0;
  }

  .cdm-card-content {
    align-items: flex-start;
  }

  #cdm-carousel.swiper-container {
    padding-bottom: 60px;
  }

  #cdm-carousel .swiper-slide {
    width: 100%;
    max-width: 800px;
  }

  #cdm-carousel .swiper-pagination {
    display: none;

    @media (min-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0;
      height: 60px;
      padding: 60px 60px 0;
    }
  }

  .carousel-navigation {
    width: 100%;
    max-width: 800px;
    position: relative;
    margin: 0 auto;
    height: 30px;
  }

  .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  .modal-close {
    color: @white;
  }

  .carousel-button {
    transform: none;
    bottom: 0;
    top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    background-color: @brand-primary;
    z-index: 3;
  }

  .carousel-button#carousel-next {
    right: 0;
  }
  .carousel-button#carousel-prev {
    left: 0;
  }

  .top-ten-video {
    width: 100%;
    max-width: 720px;
    margin-bottom: 20px;

  }

  .iframe-loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: @brand-primary;
    background-color: @black;
    height: 100%;
    width: 100%;
  }

  .top-ten-meta {
    display: flex;
    align-items: flex-start;
    text-align: left;
    width: 100%;

    img {
      height: 60px;
      width: 60px;
      margin-right: 20px;
    }

  }

  @media (min-width: 768px) {
    margin-top: 0 !important;
  }
}

dg-cdm-landing-page .floating-button-container {
  right: 5vw;
}

.elimination-episode-card {
  padding: 0;

  .cdm-card, .cdm-card-content {
    min-height: 0;
  }
}

#cdm-landing-winner {
  background-image: url('/assets/images/chief-donut-maker/burnout-banner.jpg');
  background-position: center top;
  height: 100%;
  padding: 40px 10px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
  }

  .text-white {
    color: white;
  }

  .hero-content {
    z-index: 2;
    h2, h3 {
      text-align: center;
    }
  }

  .winner-profile-column {
    padding-top: 75px;
    position: relative;
  }

  .winner-profile-row {
    display: none;
    position: relative;
  }

  .winner-profile-content {
    padding: 20px 0;
    max-width: 600px;
  }

  .offset-logo {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100px;
  }

  .winner-image {
    max-width: 600px;
  }

  @media (min-width: @screen-xs-min) {
    .offset-logo {
      width: 180px;
    }
  }

  @media (min-width: @screen-sm-min) {
    .offset-logo {
      left: -80px;
    }
  }

  @media (min-width: @screen-md-min) {
    flex-direction: row;
    //align-items: flex-start;
    padding: 80px 40px;

    .hero-content {
      h2, h3{
        text-align: left;
      }
    }

    .winner-profile-content {
      padding: 40px 100px 40px 0;
    }

    .winner-profile-column {
      display: none;
    }

    .winner-profile-row {
      display: block;
    }

    .offset-logo {
      top: -60px;
      left: -60px;
    }

    .giga {
      font-size: 56px;
    }
  }
  @media (min-width: @screen-lg-min) {
    .giga {
      font-size: 56px;
    }

    .offset-logo {
      width: 240px;
    }

    .winner-profile-row {
      padding-left: 100px;
    }
  }

  @media (min-width: @screen-xl-min) {
    .giga {
      font-size: 72px;
    }
  }
}
