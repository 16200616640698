// Racing HQ
dg-racing-hq {
	display: block;
	padding-left: 0;
	padding-right: 0;
	overflow-x: hidden;
  @media (min-width: @screen-md-min) {
    padding-left: 1.6rem; // 16px based on 10px
    padding-right: 1.6rem; // 16px based on 10px
  }
	dg-thirteen-twenty-tile a {
    min-height: 490px;
    @media (min-width: @screen-md-min) {
      min-height: 400px;
    }
  }
  .nmca-tile {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    border: 1px solid @secondary-02;
    padding: 30px 20px;
    margin: 0 1.6rem; // 16px based on 10px
    @media (min-width: @screen-md-min) {
      margin: 0;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    > div {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
		}
		.content-container {
			@media (min-width: @screen-md-min) {
				flex-basis: 100%;
			}
		}
    .logo-container {
      flex-basis: 100%;
      @media (min-width: 720px) and (max-width: @screen-md-min) {
        flex-basis: 0;
      }

    }
    .cta-container {
      flex-basis: 100%;
    }
  }
  .driver {
    display: flex;
    position: relative;
    flex: 0 0 auto;

      @media (min-width: @screen-md-min) {
          height: 222px;
      }
    .driver-name {
      position: absolute;
      bottom: 15px;
      left: 32px;
      right: 20px;
      width: 100%;
      max-width: 110px;
      margin: 0 auto;
      padding: 5px 10px;
      font-family: @main-font;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.7);
      color: #FFF;
      text-transform: uppercase;
			font-size: 1.8rem; //18px based on 10px
			line-height: normal;
			transition: all 0.35s ease-in-out;
      
      @media (max-width:1200px) {
        bottom: 0;
      }

      &[data-driver="hagan"] {
        left: 55px;
      }
    }

    img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    &:hover, &:focus {
      .driver-name {
          background-color: #000;
      }
    }
  }
  .dsr-container {
    border: 1px solid @secondary-02;
    height: 100%;
    padding: 10px 0px 0 20px;

    @media (min-width: @screen-md-min) {
      padding: 0px 0px 0 20px;
      display: flex;
      padding-left: 20px;
      justify-content: space-between;
    }
    .logo-container {
      align-self: center;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      flex-shrink: 0;


      @media (min-width: @screen-md-min) {
        margin-right: 0;
        margin-left: 0;
        flex: 0 1 150px;
      }

      @media (min-width: @screen-lg-min) {
        width: 180px;
      }
    }
    .dsr-slideshow-container {
      position: relative;
    //   padding: 0 25px;
      min-height: 182px;
      @media (min-width: @screen-md-min) {
        flex: 1;
        // min-height: 0;
        overflow: auto;
      }
    }
    .dsr-slideshow {
      max-height: 500px;
      display: flex;
      align-items: baseline;
      @media (min-width: @screen-md-min) {
        max-height: 227px;
      }

    }
    .controls {
      margin: 0;
    }
    .controls .prev, .controls .next {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      list-style: none;
      font-size: 35px;
      background: transparent;
      border: 0;
      &:disabled {
        //color: @secondary-02;
        cursor: not-allowed;
        opacity: 0.65;
        filter: alpha(opacity=65);
        box-shadow: none;
			}
			&:focus {
				border: 1px solid @primary-03;
				outline: none;
			}
    }
    .controls .prev {
			left: -10px;
			@media (min-width: @screen-sm-min) {
				left: 0;
			}
    }
    .controls .next {
			right: -10px;
			@media (min-width: @screen-sm-min) {
				right: 0;
			}
    }

    &.duo-container {
      @media (max-width: 991px) {
        display: flex;
        flex-wrap: wrap;

        .logo-container {
          flex: 0 0 100%;
          margin-bottom: 10px;

          a {
            display: block;
            width: 150px;
            margin: 0 auto;
          }
        }
        .driver {
          flex: 0 0 50%;
        }
      }
    }
  }
  .cruz-pedregon-racing-container {
    border: 1px solid @secondary-02;
    padding: 5px 20px 0 20px;
		height: 100%;
		display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (min-width: @screen-md-min) {
      flex-direction: row;
      justify-content: center;
    }
    > div {
      text-align: center;
      &.logo-container {
        width: auto;
        //@media (min-width: @screen-lg-min) {
        //	width: 180px;
        //}
        // &.driver img {
        // 	max-height: 221px;
        // 	@media (min-width: @screen-md-min) {
        // 		max-height: 169px;
        // 	}
        // 	@media (min-width: @screen-lg-min) {
        // 		max-height: 225px;
        // 	}
        // 	@media (min-width: @screen-xl-min) {
        // 		max-height: 244px;
        // 	}
        // }
      }
    }
  }
  .mello-yello-container {
    height: 100%;
    border: 1px solid @secondary-02;
    padding: 20px;
    text-align: center;
    p {
      font-family: @main-font;
      text-align: center;
      font-size: 2.2rem; // 22px based on 10px
    }
	img {
		max-width: 70%;
    	margin: auto;
	}
	@media (max-width: 1199px) {
		img {
			max-width: 60%;
			margin: auto;
		}
	}
	@media (max-width: 768px) {
		img {
			max-width: 60%;
			margin: auto;
		}
	}
  }
  .guide-guide-cta {
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    padding: 30px 20px;
    background: #000 url('/assets/images/racing-hq/guide-guide-cta.jpg') center center no-repeat;
    background-size: cover;
    color: #FFF;
    font-family: @main-font;
    text-transform: uppercase;
    font-size: 4.4rem; // 44px based on 10px
  }

  // Hide the 4th video on smaller screen sizes
  dg-video-list .video-list .video-thumbnail:last-child {
    display: none;
    @media (min-width: @screen-lg-min) {
      display: block;
    }
  }

  .track-locator-cta-demon-faq-cta-container {
    dg-track-locator-cta, dg-demon-fag-cta {
      @media (min-width: @screen-md-min) {
        min-height: 270px;
      }
      @media (min-width: @screen-lg-min) {
        min-height: 400px;
      }
    }
  }

  dg-recent-articles {
    display: block;
		.event-container { // TODO update this class name
			article {
				margin-bottom: (16px * 1.5); // 24px
				a {
					color: @body-color;
					text-decoration: none;
				}
				.article-title {
					font-size: @h4-font-size;
					@media (min-width: @screen-md-min) {
						font-size: @h3-font-size;
					}
				}
			}
      @media (min-width: @screen-md-min) {
				display: flex;
				article {
					flex: 1;
					margin-right: 30px;
					&:nth-child(3) {
						margin-right: 0;
					}
				}
      }
    }
    .view-news {
      display: none;
    }
	}
	.news-article-list-content {
		display: flex;
    flex-direction: column;
		margin: 0 -15px 2.4rem -15px; // 24px based on 10px

    @media (min-width: @screen-md-min) {
      flex-direction: row;
    }
		dg-news-article-card {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}

// Guest Guides
dg-guest-guides {
  display: block;
  @media (min-width: @screen-md-min) {
    padding-left: 1.6rem; // 16px based on 10px
    padding-right: 1.6rem; // 16px based on 10px
  }
}

// TODO Audit everything below this line

.bottom-margin {
	margin-bottom: 20px;
}

.racing-hq {
	margin-top: 20px;
}
.racing-calendar, .track-locator {
	position: relative;
	margin-bottom: 20px;
	text-align: center;
	.heading1 {
		margin-top: 0;
		margin-bottom: 10px;
		font-size: 30px;
	}
}
.overlay {
	position: absolute;
	background: rgba(0, 0, 0, 0.5);
	padding: 20px;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
}

.faq {
	img {
		border: solid 1px #b1b1b1;
	}
}

.drivers-corner {
	color: @white;
	text-transform: uppercase;
	position: relative;
	.heading1 {
		font-size: 50px;
		margin-bottom: 0px;
	}
	.heading-small {
		font-size: 15px;
	}
}

.nhra-class {
	background-color: #e6e6e6;
	padding: 30px 0;
	margin-top: 30px;
	.heading1 {
		margin-bottom: 0;
		font-size: 7vw;
	}
	.heading-small {
		font-size: 4vw;
	}
	.classes {
		opacity: 1;
		transition: all 0.35s ease-in-out;
		margin-top: 20px;
		&:hover {
			opacity: .75;
		}
	}
}
.points-container	{
	overflow: hidden;
	padding: 40px 0;
}
.points-results {
	border: solid 1px #e6e6e6;
	padding: 12px;
	margin-bottom: 20px;
	background: #ffffff; /* Old browsers */
	background: -moz-linear-gradient(top,  #ffffff 0%, #f5f5f5 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #ffffff 0%,#f5f5f5 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #ffffff 0%,#f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5',GradientType=0 ); /* IE6-9 */
	.heading1 {
		font-size: 5vw;
	}
}


.nhra-classes {
	li {
    margin-bottom: 1.5rem; // 15px based on 10px
  }
  // Bottom CTAs / Classes
  dg-pro-racing-cta, dg-sportsman-cta, dg-stock-super-stock-cta, dg-bracket-racing-cta {
    @media (min-width: @screen-lg-min) {
      min-height: 400px;
    }
  }

  .heading1 {
    color: #000;

  }

  .faq-heading {
    color: #000;
    margin-bottom: 30px;
  }
	.heading3 {
		//font-size: 24px;
	}
	.classes {
		margin-bottom: 10px;
    opacity: 1;
    transition: all 0.35s ease-in-out;
    &:hover {
      opacity: .75;
    }
    a {
      position: relative;
      display: inline-block;
      &.active:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 5px solid @brand-primary;
      }
    }

	}
}
.racing-sidebar {
	.points-container {
		padding-bottom: 15px;
	}
	.points-results {
		margin-bottom: 0;
	}
}
.nhra-gallery {
	img {
		margin-bottom: 30px;
	}
}



// FAQ Page
.glossary-pod {
	padding: 10px;
	background-color: #f5f5f5;
	margin-top: 15px;
}
.glossary-letters {
	font-size: 16px;
	margin-right: 3px;
}
.glossary {
	.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
		color: @white;
    background-color: @brand-primary;
	}
	.nav-tabs {
		border-bottom: none;
		> li {
			> a {
				border-radius: 0;
				&:hover {
			    border-color: transparent;
					color: @white;
			    background-color: @brand-primary;
				}
			}
		}
	}
}

// PROFILE
.profile-sidebar {
	background-color: #3d3d3b;
	padding: 30px;
	.profile-pic-container	{
		border-radius: 100%;
		.profile-pic {
			border-radius: 100%;
		}
	}
	.profile-name {
		font-family: @main-font;
		text-transform: uppercase;
		color: #dddddd;
		font-size: 8vw;
	}
	.profile-social-icons {
		margin-bottom: 20px;
		.social-icon {
			padding: 7px 10px;
			background-color: #646462;
			color: #31312f;
			border-radius: 100%;
			display: inline-block;
			width: 34px;
			height: 34px;
			text-align: center;
			margin-right: 5px;
		}
	}
	.profile-links {
		color: #828282;
		a {
			color: #828282;
			&:hover, &:focus {
				color: darken(#828282, 10%);
			}
		}
		.icon-container {
			margin-bottom: 10px;
			.icon {
				display: inline-block;
				width: 30px;
				text-align: center;
			}
		}
		.fa {
			margin-right: 4px;
			font-size: 22px;
		}
	}
}

.garage-info {
	background-color: #f2f2f2;
	padding: 15px;
	overflow: hidden;
	margin-bottom: 20px;
	p {
		margin-bottom: 5px;
	}
}
.garage-gallery {
	margin: 20px 0;
	li {
		// margin: 0 5px;
		img {
			width: 100%;
		}
	}
	li:first-child {
		margin-left: 0;
	}
	li:last-child {
		margin-right: 0;
	}
}
.video-container {
	margin: 20px 0;
}
.hero-card {
	margin-bottom: 20px;
}
#image-gallery-caption {
	padding: 20px 15px;
}

.upcoming-event {
	background-color: #f2f2f2;
	overflow: hidden;
	padding: 10px;
	margin-bottom: 10px;
}
.race-info {
	h3 {
		margin: 5px 0;
		font-family: @main-font;
	}
}
.race-date {
	background-color: @white;
	padding: 0 20px;
	border-left: solid 8px @brand-primary;
	text-transform: uppercase;
	.month {
		margin: 0;
		text-align: center;
	}
	.date {
		margin: 0;
		text-align: center;
		font-family: @main-font;
		font-size: 2em;
	}
	.year {
		margin: 0;
		text-align: center;
	}
}

.now-streaming {
	background-color: @brand-primary;
	padding: 15px 0;
	color: @white;
	overflow: hidden;
	p {
		font-size: 20px!important;
	}
	a {
		font-size: 14px;
	}
}


@media (min-width: @screen-sm-min) {
	.nhra-class {
		.heading1 {
			font-size: 40px;
		}
		.heading-small {
			font-size: 18px;
		}
	}
	.points-container	{
		.points-results {
			padding: 15px;
			.heading1 {
				font-size: 18px;
			}
		}
	}
	.profile-sidebar {
		.profile-name {
			font-size: 2vw;
		}
	}
	// FAQ Page
	#faq-accordion {
		.heading2 {
			//font-size: 30px;
		}
		.question-q {
			width: 12%;
		}
		.question {
			width: 88%;
		}
	}
}

@media (min-width: @screen-md-min) {
  .nhra-classes {
    //margin: 60px 0;
    // 2020 racing styles
    .heading1 {
      //font-size: 49px;
      margin-bottom: 30px;
      .lead-in {
        //font-size: 20px;
        //line-height: 24px;
      }
    }
    .heading2 {
      //font-size: 40px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .heading3 {
      //font-size: 32px;
      //margin-bottom: 30px;
    }
  }
	.drivers-corner {
		.heading1 {
			font-size: 60px;
		}
		.heading-small {
			font-size: 18px;
		}
	}
	.nhra-class {
		.heading1 {
			margin-bottom: 0;
			font-size: 40px;
		}
	}
	.points-container	{
		.points-results {
			.heading1 {
				font-size: 25px;
			}
		}
	}
	// FAQ Page
	#faq-accordion {
		.question-q {
			width: 9%;
		}
		.question {
			width: 91%;
		}
	}

}
@media (min-width: @screen-lg-min) {
	// FAQ Page
  .nhra-classes {
    //margin: 90px 0;
  }
	#faq-accordion {
		.question-q {
			width: 5%;
		}
		.question {
			width: 95%;
		}
	}

}


// Racing HQ Guest Guide (Updated 10/22/20 by SL)
.racing-guide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: none;
  height: 100%;
  background-image: url('/assets/images/racing-hq/guest-guide-background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;

  .racing-guide-title {
    flex: 0 1 25%;
    h3 {
      text-align: center;
      color: @white;
    }
  }
  .racing-guide-download {
    display: flex;
    flex: 0 1 75% ;
    padding: 0 20px;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .racing-guide-preview {
    flex: 0 1 auto;
    max-width: 410px;
    margin-bottom: 20px;
  }

  .racing-guide-button {
    display: block;
    flex: 0 1 auto;
    max-width: 210px;
  }

  @media(min-width: @screen-sm-min) {
    flex-direction: row;
    .racing-guide-title h3 {
      text-align: left;
    }
  }

    // Med screens up
  @media(min-width: @screen-md-min) {
    .racing-guide-title h3 {
      font-size: 4.2rem;
    }
  }

  // XL screens up
  @media (min-width: @screen-xl-min) {
    .racing-guide-preview {
      margin-bottom: 0;
    }
    .racing-guide-download {
      flex-direction: row;
    }
  }
}

#cruz-img {
	max-height: -webkit-fill-available !important;
	width: auto !important;
}

@media (max-width: 710px) {
	.col-tiny {
		width: 100%;
		flex-basis: 100%;
		max-width: 100%;
	}
}

// 2022 Racing HQ changes
dg-racing-hq {
  #direct-connection, #nhra-drag-racing, #ncma-muscle-car, #current-season-info, #cars-and-classes {
    margin: 20px;
    scroll-margin-top: @navbar-height-xs;
  	@media (min-width: @screen-sm-min) {
      scroll-margin-top: @navbar-height-sm;
	}
    @media (min-width: @screen-md-min) {
      scroll-margin-top: @navbar-height-md;
      margin: 0;
    }
  }

  .mello-yello-container {
    height: auto;

    @media (min-width: @screen-md-min) {
      margin: 0;
      height: 100%;
    }
  }

  #nhra-drag-racing, #ncma-muscle-car {
    border: 1px solid #ccc;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
    }

    .events {
      padding: 20px;
    }

    .logo {
      max-width: 300px;
      text-align: center;

      img {
        margin-bottom: 10px;
      }

      p {
        font-weight: bold;

      }
    }

    .event-list-container {
      display: flex;
      flex-direction: column;
      max-width: 100%;

      @media (min-width: @screen-sm) {
        flex-direction: row;
        gap: 20px;
      }
    }

    .event-list {
      flex: 1;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;

      dd {
        margin-bottom: 20px;
      }
    }

    @media (min-width: @screen-md-min) {

      .content {
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 40px 40px 0 40px;
      }

      .copy {
        flex: 0 0 65%;
      }

      .logo {
        display: block;
      }

      .events {
        padding: 0 40px;
      }

      .event-list {
        max-height: 420px;

        div {
          max-width: 50%;
          padding-right: 40px;
        }
      }
    }

    @media (min-width: @screen-lg-min) {
      .event-list {
        max-height: 380px;
      }
    }

    @media (min-width: @screen-xl-min) {
      .event-list {
        max-height: 320px;
      }
    }
  }
  #schedules {
    margin-bottom: 0;

    img {
      margin-bottom: 24px;
    }

    @media (min-width: @screen-sm-min) {
      margin: 0 20px 24px;

      img {
        margin-bottom: 0;
      }
    }

    @media (min-width: @screen-md-min) {
      margin: 0 0 24px;
    }
  }

  .mello-yello-container img {
    margin: 0 auto 10px;
    max-width: 300px;

    @media (min-width: @screen-md-min) {
      max-width: 160px;
    }
  }

  small {
    font-size: 50%;
  }

  .mobile-banner {
    margin: 0 auto;
  }

  .stacked-links {
    display: flex;
    flex-direction: column;
  }

  dg-racing-events-calendar-cta {
    background-image: url('/assets/images/racing-hq/racing-calendar-bg.jpg') !important;
  }

  dg-track-locator-cta, dg-racing-events-calendar-cta {
    margin-bottom: 20px;
  }

  dg-track-locator-cta, dg-racing-events-calendar-cta, dg-drag-racing-faq-cta {
    cursor: pointer;
    min-height: 0;
    max-height: 200px;

    span {
      position: relative;
      z-index: 2;
      height: 100%;
      margin-top: auto;
    }

    a {
      display: flex;
      align-items: baseline;
      padding: 120px 10px 10px;

      &::before {
        content: "";
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (min-width: @screen-lg-min) {
    .driver {
      align-self: end;
    }
  }
}

// 2025 Adding Ida and grid
dg-racing-hq {
  #current-season-info .season-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-bottom: 3rem;
  }

  @media (min-width: 992px) {
    #current-season-info .season-grid {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
    }
  }

  @media (min-width: 1400px) {
    #current-season-info .season-grid {
      grid-template-columns: repeat(4, auto);
      grid-template-rows: repeat(1, auto);
    }
  }

  .ida-container {
    border: 1px solid #D9D9D9;
    padding: 5px 20px 0 20px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
      max-width: 300px;
      max-height: 319px; // ida can't be taller than cruz
    }
  }


}

