@cell-height-xs: 162.5px; //height + margin-bottom
@cell-height-lg: 45px;

.leaderboard-container {
  margin-bottom: 3rem;

  .leaderboard-content {
    position: relative;
  }
  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: linear-gradient(45deg, @gray, @lighter-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    .loading-inner {
      flex: 0 0 auto;
      font-size: 2.8rem;
      color: @red1;
    }
  }

  .leaderboard-header {
    background-image: url('/assets/images/1320/table-header-bg.jpg');
    min-height: 200px;
    padding: 2.5rem;

    @media (min-width: 600px) {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    .logo {
      width: 100%;
      @media (min-width: 600px) {
        flex: 0 0 200px;
        min-width: 200px;
        margin-right: 5.5rem;
        //width: auto; //IE BS
        width: 100%;
        height: auto; //IE BS
      }
      @media (min-width: 992px) {
        flex: 0 0 260px;
        min-width: 260px;
      }
    }

    .title {
      display: block;
      font-size: 3.6rem;
      text-transform: uppercase;
      text-align: center;
      line-height: 1;
      margin-top: 2rem;

      @media (min-width: 600px) {
        text-align: left;
        margin-top: 0;
      }
      @media (min-width: 992px) {
        font-family: @main-font;
        font-size: 4.9rem;
      }
    }
    .flyout-container {
      flex-grow: 1;
      // AddThis
      min-width: 200px;
      text-align: center;
      @media (min-width: 600px) {
        text-align: right;
      }
    }

    .stock& {

    }
    .modified& {

    }
  }

  .leaderboard-content {
    .table-leaderboard {
      .mobile-heading {
        display: none;
        font-size: 1.6rem;
      }

      @media (max-width: 768px) {
        margin-top: 1rem;
        thead {
          display: none;
        }
        tbody {
          tr {
            display: flex;
            flex-wrap: wrap;
            background-color: @lighter-gray;
            border: 1px solid @gray;
            margin-bottom: 1rem;
            padding: 1rem;
            td {
              flex: 0 0 calc(100% / 3);
              min-width: calc(100% / 3);
              width: auto;
              float: none;
              border: 0;

              .mobile-heading {
                display: block;
              }

              &.position, &.username {
                font-family: @main-font;
                font-size: 2rem;
                padding-bottom: 0;
              }
              &.vehicle, &.quarter-mile, &.top-speed, &.sixty {
                order: 1;
              }
              &.quarter-mile, &.top-speed, &.sixty {
                font-family: @main-font;
                font-size: 2rem;
                text-align: center;
              }
              &.position {
                flex: 0 0 30px;
                min-width: 30px;
                text-align: center;
              }
              &.username {
                flex: 1;
              }
              &.vehicle {
                flex: 0 0 100%;
                min-width: 100%;
                font-size: 1.6rem;
                color: @gray;
                padding-top: 0;
              }
              &.track {
                display: none;
              }
              &.view-run {
                flex: 0 0 75px;
                min-width: 75px;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}

.leaderboard {
  position: relative;
  overflow: hidden;

  @media (min-width: 992px) {
    border: 1px solid @dark-gray;
  }

  .overlay-nodata {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: url('/assets/images/1320/leaderboard-overlay-bg.png');
    background-size: cover;
    background-position: center center;
    background-color: #181818;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: block;
      text-align: center;
      text-transform: uppercase;
      color: @white;
      font-family: @main-font;
      font-size: 2.8rem;

      @media (min-width: 600px) {
        font-size: 4.2rem;
      }
    }
  }

  .lb-section {

    &.head {
      color: @white;
      background-color: @dark-gray;
      .lb-row {
        .lb-cell {
          // background-color: aliceblue;
          font-size: 1.4rem;
          // pointer only on 1/4 mile heading
          &.quarter-mile {
            cursor: pointer;
          }

          button.btn-link {
            padding: 0;
            vertical-align: middle;
          }

          i {
            font-size: 0.75em;
            position: relative;
            top: -0.15em;
          }
          @media (min-width: 1200px) {
            font-size: 1.8rem;
          }
        }
      }
    }

    &.filters {
      //box-shadow: 0px 5px 10px 0px fade(@black, 20%);
      background-color: #f4f4f4;
      margin-bottom: 1rem;
      .lb-row {
        .lb-cell {
          .form-control {
            max-width: 200px;
          }

          /*&.track {
            display: flex;
            align-items: center;
            .form-control {
              flex: 1;
            }
            span {
              flex: 0 1 auto;
              margin-left: 1.5rem;
              color: @red1;
              text-align: right;
              cursor: pointer;
              text-decoration: underline;

              @media (min-width: 1440px) {
                margin-left: 3rem;
              }
            }
          }*/
        }
      }
    }

    &.body {
      overflow: hidden;

      &.logged-user {
        @media (min-width: 992px) {
          height: 450px + 45px !important;
          padding-bottom: 45px;
        }
      }

      .lb-row {
        transition: all 0.25s ease-in-out;
        color: black;
        &:nth-child(even) {
          background-color: #f4f4f4;
        }

        &.user-row {
          box-shadow: inset 0px 0px 0px 2px @red1;
        }

        .lb-cell {
          &.action {
            //transition: all 0.25s ease-in-out;
            //opacity: 0;
            //visibility: hidden;
            //pointer-events: none;
          }
          a, span, button.btn-link {
            vertical-align: middle;
          }

          &.username a {
            color: black;
            &:hover, &:focus {
              color: rgba(0,0,0,.75);
            }
          }

          button.btn-link {
            font-size: 16px;
            padding: 0;
          }
        }
      }


      @media (max-width: 991px) {
        margin-top: 1rem;
        margin-bottom: 1rem;
        .lb-row {
          width: 100%;
          border: 1px solid @gray;
          border-radius: 5px;
          padding: 0.75rem 1.25rem;
          margin-bottom: 0.75rem;
          align-items: flex-start;
          flex-wrap: wrap;
          position: relative;

          .lb-cell {
            padding: 0;
            &.position {
              font-size: 2.6rem;
              text-align: left;
              flex: 0 0 45px;
            }
            &.username {
              font-size: 2.6rem;
              flex: 1;
            }
            &.vehicle {
              flex: 0 0 100%;
              order: 2;
            }
            &.track {
              display: none;
            }
            &.quarter-mile, &.sixty, &.quarter-speed {
              flex: 0 0 calc(100% / 3);
              min-width: 0;
              font-size: 2.8rem;
              text-align: left;
              margin-top: 1.5rem;
              order: 2;
              .mobile-heading {
                font-size: 1.4rem;
              }
            }
            &.action {
              order: 1;
              opacity: 1;
              min-width: 0;
              flex: 0 0 60px;
              margin-top: 0.5rem;
            }
          }
        }
      }
    }

    &.controls {
      display: flex;
      justify-content: center;
      background-color: @black;
      box-shadow: 0px -5px 10px 0px fade(@black, 20%);
      margin-top: 1rem;

      @media (min-width: 992px) {
        gap: 10px;
      }

      .btn {
        flex: 0 0 200px;
        min-width: 200px;
        font-size: 1.6rem;

        @media (max-width: 991px) {
          min-width: 0;
          flex: 1;

          &.to-top, &.to-bottom {
            flex: 0 0 15%;
          }
        }

        &.to-top, &.to-bottom {
          flex: 0 0 44px;
          min-width: 44px;
          background-color: @gray;
          color: @black;

          @media (min-width: 992px) {
            flex: 0 0 50px;
            min-width: 50px;
          }

          &:hover {
            background-color: darken(@gray, 20%);
          }

          &[disabled] {
            &:hover {
              background-color: @gray;
            }
          }
        }
      }
    }

    .lb-row {
      display: flex;
      flex: 0 0 100%;
      width: 100%;
      .lb-cell {
        flex: 1;
        font-size: 1.8rem;
        padding: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .mobile-heading {
          display: none;
          font-size: 1.6rem;

          @media (max-width: 991px) {
            display: block;
          }
        }

        &.position {
          flex: 0 0 75px;
          text-align: center;
        }
        &.action {
          font-weight: bold;
          color: @red1;
          text-decoration: underline;
        }
        &.quarter-mile, &.sixty, &.quarter-speed, &.action {
          flex: 0 0 100px;
          min-width: 100px;
          text-align: right;

          @media (min-width: 1200px) {
            flex: 0 0 120px;
            min-width: 120px;
          }
          @media (min-width: 1600px) {
            flex: 0 0 140px;
            min-width: 140px;
          }
        }
        &.quarter-mile {
          flex: 0 0 105px;
          min-width: 105px;
          @media (min-width: 1200px) {
            flex: 0 0 125px;
            min-width: 125px;
          }
        }
        &.sixty {
          @media (min-width: 1200px) {
            flex: 0 0 125px;
            min-width: 125px;
          }
        }
        &.quarter-speed {
          flex: 0 0 120px;
          min-width: 120px;
          @media (min-width: 1200px) {
            flex: 0 0 150px;
            min-width: 150px;
          }
        }
      }
    }
  }
  .lb-scroll {
    transition: all 0.5s ease-in-out;
  }
}

.search-filter-mobile {
  background-color: @light-gray-bg;

  .btn {
    font-size: 2rem;

    &.btn-filter {
      color: @black;
      background-color: @light-gray;
      padding: 1.5rem;
      i {
        margin-left: 1.5rem;
        transition: all 0.25s ease-in-out;
        transform-origin: center center;
        transform: rotate(0deg);
      }

      &[aria-expanded="true"] {
        i {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .mobile-collapse {
    .filter-container {
      padding: 2rem;

      input {
        display: block;
        width: 100%;
        font-size: 1.8rem;
        padding: 1rem;
        margin-bottom: 1.25rem;
        border: 1px solid @dark-gray;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .sorting-container {
      padding: 2rem;
      display: flex;
      justify-content: space-between;

      .sorting-item {
        flex: 0 0 20%;
        display: flex;
        flex-direction: column;
        text-align: center;
        span {
          font-size: 1.8rem;
          margin-bottom: 0.75rem;
        }
        .btn-sorting {
          border: 1px solid @light-gray;
          border-radius: 8px;
          color: black;
          width: 65px;
          height: 65px;
          margin: 0 auto;

          i {
            transition: all 0.25s ease-in-out;
            transform: rotate(0deg);
          }

          &.sorting {
            background-color: @red1;
            color: @white;

            i {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
