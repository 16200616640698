// CDM sub-nav
.cdm-nav {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 0;
  flex-wrap: wrap;
  position: sticky;
  top: 53px;
  left: 0;
  right: 0;
  z-index: 4;
  background-color: @white;

  ul {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin-bottom: 0;
    flex-wrap: wrap;
    width: 100%;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .home-button-wrap {
    position: relative;
    flex: 1 1 100%;
    margin-right: 0;

    a {
      justify-content: flex-start;
    }
    // expand icon
    .expand-subnav {
      position: absolute;
      right: 5px;
      top: 5px;
      &.open .fa {
        transform: rotate(180deg);
      }
    }
  }

  .nav-container {
    width: 100%;
  }

  a {
    text-decoration: none;
    &:not(.btn) {
      width: 100%;
      height: 100%;
      margin: 10px;
      padding: 5px 15px;
      color: #000;

      &:hover, &:focus {
        background-color: @brand-primary-hover;
        color: @white;
      }
    }

    &.active {
      background-color: @lighter-gray;
    }
  }

  a#cdm-home-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    background-color: #eee;
    text-transform: none;
    font-weight: bold;
    text-align: left;
    padding: 15px 25px;
    margin: 0;

    &:hover, &:focus {
      background-color: @black;
      color: @white;
    }
  }

  .application-button-wrap {
    display: flex;
    flex-direction: column;
    width: auto;

    .btn {
      margin: 0 15px 15px;
    }
  }

  @media (min-width: @screen-sm-min) {
    li {
      flex: 1 0 auto;
    }
    .application-button-wrap {
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .btn {
        margin: 10px
      }
    }
  }

  @media (min-width: @screen-lg-min) {
    flex-wrap: nowrap;
    height: 65px;

    .fixed-nav & {
      top: 54px;
    }

    .home-button-wrap {
      flex: 0 0 auto;
    }

    a.active {
      background-color: inherit;
      border-bottom: 4px solid @brand-primary;
    }

    a#cdm-home-link {
      text-align: center;
    }

    a:not(.btn) {
      width: auto;
      margin: 0;
      padding: 15px 20px;
    }

    .nav-container {
      display: flex;
    }

    ul {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow: visible !important; // override collapse visibility
    }

    li {
      padding: 0;
      flex: 0 0 auto;
    }

    .button-wrap {
      flex-direction: row;
      justify-content: flex-end;
    }
    .application-button-wrap {
      justify-content: flex-end;
    }

    .push-right {
      margin-left: auto;
    }
  }

  @media (min-width: @screen-xl-min) {
    a#cdm-home-link {
      margin-right: 50px;
    }

    a:not(.btn) {
      padding: 15px 25px;
    }
  }

  .closed-message {
    width: 180px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    color: #999;

    @media (min-width: @screen-md-min) {
      margin: 0;
    }
  }
}
