/* Hide the default checkbox */
.custom-control-input {
  display: none;
}

/* Style the custom checkbox */
.custom-checkbox {
  position: relative;
  display: inline-block;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid $black;
  cursor: pointer;
}

/* Checkmark style when checked */
.custom-checkbox:after {
  content: '';
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* When the input is checked, show the checkmark */
.custom-control-input:checked + .custom-checkbox {
  background-color: $primary;
  border-color: $primary;
}

.custom-control-input:checked + .custom-checkbox:after {
  display: block;
}

/* Label style */
.custom-control-label {
  font-weight: normal;
  margin-left: 10px;
  margin-bottom: 0;
  user-select: none;
  cursor: pointer;
}

.custom-control {
  display: flex;
}