.inline-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -20px;

  .image-wrap {
    // two-column
    flex: 0 0 calc(50% - 20px);
    margin: 0 20px 20px 0;

    &:nth-child(2n) {
      margin-right: 0;
    }

    // three-column
    @media (min-width: @screen-sm-min) {
      flex: 0 0 calc(33.33333% - 20px);

      &:nth-child(2n) {
        margin: 0 20px 20px 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    // four-column
    @media (min-width: @screen-md-min) {
      flex: 0 0 calc(25% - 20px);

      &:nth-child(3n) {
        margin: 0 20px 20px 0;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

  }
}
