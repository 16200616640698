.overview-card {
  padding: 1.5rem 0;
  border-top: 1px solid @lighter-gray;
  border-bottom: 1px solid @lighter-gray;

  @media (min-width: 600px) {
    display: flex;
  }

  .thumbnail {
    border: 0;
    padding: 0;
    margin-bottom: 1rem;

    @media (min-width: 600px) {
      flex: 0 0 125px;
    }

    img {

    }
  }
  .content {
    @media (min-width: 600px) {
      flex: 1;
      padding-left: 2rem;
    }

    .content-header {
      display: flex;
      align-items: center;
      padding: 1.5rem 0;

      @media (min-width: 600px) {
        padding-top: 0;
      }
      .title {
        flex: 1;
        font-family: @main-font;
        font-size: 2.1rem;
        margin-top: 0;
        margin-bottom: 0;
      }
      .tools {
        flex: 0 0 10rem;
        display: flex;
        .btn {
          flex: 1;
          font-size: 1.6rem;
        }
      }
    }

    .details {
      display: flex;
      flex-wrap: wrap;

      > * {

        //variable widths
        @width-year: 60px;
        @width-model: 120px;
        @width-trim: 130px;
        @width-group: 115px;

        margin-bottom: 1.5rem;
        flex: 0 0 100%;
        min-width: 100%;

        &.year, &.model, &.trim {
          flex: 0 0 calc(100% / 3);
          min-width: calc(100% / 3);
        }
        &.vin {
          flex: 1;
          min-width: 30%;
        }
        &.group {
          flex: 0 0 @width-group;
          min-width: @width-group;
        }

        @media (min-width: 600px) {
          &.year {
            flex: 0 0 @width-year;
            min-width: @width-year;
          }
          &.model, &.trim {
            flex: 0 0 calc(~'(100% / 2) - @{width-year}');
            min-width: calc(~'(100% / 2) - @{width-year}');
          }
          &.group {
            flex: 1;
          }
        }
        @media (min-width: 768px) {
          &.model {
            flex: 0 0 @width-model;
            min-width: @width-model;
          }
          &.trim {
            flex: 0 0 @width-trim;
            min-width: @width-trim;
          }
          &.vin {
            flex: 1;
            min-width: 0;
          }
          &.group {
            flex: 0 0 @width-group;
          }
          
          // &.group {
          //   padding-left: 1rem;
          // }
        }

        span {
          display: block;
          font-size: 1.8rem;

          &:first-child {
            font-family: @main-font;
          }
        }
      }
    }
  }
}